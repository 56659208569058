import {
   Accordion,
   AccordionDetails,
   AccordionSummary, Autocomplete,
   Button, Checkbox, Drawer,
   FormControl,
   FormControlLabel,
   FormGroup, FormLabel, ImageList, ImageListItem, InputLabel, MenuItem, Radio, RadioGroup, Select,
   Stack, TextField
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import {PhotoCamera} from "@mui/icons-material";
import Box from "@mui/material/Box";
import AssistantDirectionIcon from "@mui/icons-material/AssistantDirection";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import * as React from "react";
import {useContext, useEffect} from "react";
import ECContext from "../../context/inspections/ECContext";
import reduce from "image-blob-reduce";
import {getImageUrl, savePhotoIntoFolder} from "../../context/inspections/ECActions";
import GalleryIcon from "@mui/icons-material/CollectionsOutlined";
import {Image} from "mui-image";

export default function Photos() {

   const { inspectionAllData, access_token_data, inspectionPhotosBlockInfo, dispatch } = useContext(ECContext)
   const [show, setShow] = React.useState(false);

   const [jobsitesPhotos, setJobsitesPhotos] = React.useState([]);
   const [elevationsPhotos, setElevationsPhotos] = React.useState([]);
   const [roofFields, setRoofFields] = React.useState([]);
   const [ventilationsPhotos, setVentilationsPhotos] = React.useState([]);
   const [windowsPhotos, setWindowsPhotos] = React.useState([]);
   const [guttersPhotos, setGuttersPhotos] = React.useState([]);
   const [testSquaresPhotos, setTestSquaresPhotos] = React.useState([]);
   const [roofPhotos, setRoofPhotos] = React.useState([]);
   const [interiorPhotos, setInteriorPhotos] = React.useState([]);



   const collectJobsitePhotos = async () => {

      if(jobsitesPhotos.length > 0)
         return;

      let images = []

      const photosDoesTheDrivewayHaveExistingCracks = async () => {        //jobsite.Cracks/stains Photos
         if(inspectionAllData.hasOwnProperty('photosDoesTheDrivewayHaveExistingCracks' )) {
            if(inspectionAllData.photosDoesTheDrivewayHaveExistingCracks.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.photosDoesTheDrivewayHaveExistingCracks.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.photosDoesTheDrivewayHaveExistingCracks[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'FILE_ID': inspectionAllData.photosDoesTheDrivewayHaveExistingCracks[i].FILE_ID })
               }
               //setJobsitesPhotos([...jobsitesPhotos, ...images])
               return images

            }
         }
         return []
      }
      let tmpImages = await photosDoesTheDrivewayHaveExistingCracks();
      images = [...images, ...tmpImages]

      const photosDoesTheJobSideHaveRemoteAccess = async () => {        //jobsite.Remote access photos
         if(inspectionAllData.hasOwnProperty('photosDoesTheJobSideHaveRemoteAccess' )) {
            if(inspectionAllData.photosDoesTheJobSideHaveRemoteAccess.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.photosDoesTheJobSideHaveRemoteAccess.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.photosDoesTheJobSideHaveRemoteAccess[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'FILE_ID': inspectionAllData.photosDoesTheJobSideHaveRemoteAccess[i].FILE_ID})
               }
               //setJobsitesPhotos([...jobsitesPhotos, ...images])
               return images

            }
         }
         return []
      }

      tmpImages = await photosDoesTheJobSideHaveRemoteAccess()
      images = [...images, ...tmpImages]


      const photosDoesBuildingHaveAnyPreexistingImperfections = async () => {        //jobsite.Pre-existing imperfections or damages (i.e. broken siding, light fixtures, fence, glass etc) Photos
         if(inspectionAllData.hasOwnProperty('photosDoesBuildingHaveAnyPreexistingImperfections' )) {
            if(inspectionAllData.photosDoesBuildingHaveAnyPreexistingImperfections.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.photosDoesBuildingHaveAnyPreexistingImperfections.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.photosDoesBuildingHaveAnyPreexistingImperfections[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'FILE_ID': inspectionAllData.photosDoesBuildingHaveAnyPreexistingImperfections[i].FILE_ID})
               }
               //setJobsitesPhotos([...jobsitesPhotos, ...images])
               return images

            }
         }
         return []
      }
      tmpImages = await photosDoesBuildingHaveAnyPreexistingImperfections()
      images = [...images, ...tmpImages]


      const photosDoesBuildingNeedDecking = async () => {        //jobsite.Attic photo w/tape measure (required if Mpls or St.Paul) Photos
         if(inspectionAllData.hasOwnProperty('photosDoesBuildingNeedDecking' )) {
            if(inspectionAllData.photosDoesBuildingNeedDecking.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.photosDoesBuildingNeedDecking.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.photosDoesBuildingNeedDecking[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'FILE_ID': inspectionAllData.photosDoesBuildingNeedDecking[i].FILE_ID})
               }
               //setJobsitesPhotos([...jobsitesPhotos, ...images])
               return images
            }
         }
         return []
      }
      tmpImages = await photosDoesBuildingNeedDecking()
      images = [...images, ...tmpImages]


      const photosDoesItHaveAdeck = async () => {        //jobsite.Photos of deck and railing
         if(inspectionAllData.hasOwnProperty('photosDoesItHaveAdeck' )) {
            if(inspectionAllData.photosDoesItHaveAdeck.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.photosDoesItHaveAdeck.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.photosDoesItHaveAdeck[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'FILE_ID': inspectionAllData.photosDoesItHaveAdeck[i].FILE_ID})
               }
               //setJobsitesPhotos([...jobsitesPhotos, ...images])
               return images
            }
         }
         return []
      }
      tmpImages = await photosDoesItHaveAdeck()
      images = [...images, ...tmpImages]

      setJobsitesPhotos(images)
   }

   const collectElevationsPhotos = async () => {

      if(elevationsPhotos.length > 0)
         return;

      let images = []

      const frontLeft = async () => {
         if(inspectionAllData.hasOwnProperty('frontLeft' )) {
            if(inspectionAllData.frontLeft.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.frontLeft.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.frontLeft[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               return images
            }
         }
         return []
      }
      let tmpImages = await frontLeft()
      images = [...images, ...tmpImages]


      const front = async () => {
         if(inspectionAllData.hasOwnProperty('front' )) {
            if(inspectionAllData.front.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.front.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.front[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               return images
            }
         }
         return []
      }
      tmpImages = await front()
      images = [...images, ...tmpImages]


      const frontRight = async () => {
         if(inspectionAllData.hasOwnProperty('frontRight' )) {
            if(inspectionAllData.frontRight.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.frontRight.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.frontRight[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               return images
            }
         }
         return []
      }
      tmpImages = await frontRight()
      images = [...images, ...tmpImages]



      const right = async () => {
         if(inspectionAllData.hasOwnProperty('right' )) {
            if(inspectionAllData.right.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.right.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.right[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               return images
            }
         }
         return []
      }

      tmpImages = await right()
      images = [...images, ...tmpImages]


      const backRight = async () => {
         if(inspectionAllData.hasOwnProperty('backRight' )) {
            if(inspectionAllData.backRight.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.backRight.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.backRight[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               return images
            }
         }
         return []
      }
      tmpImages = await backRight()
      images = [...images, ...tmpImages]


      const back = async () => {
         if(inspectionAllData.hasOwnProperty('back' )) {
            if(inspectionAllData.back.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.back.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.back[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               return images
            }
         }
         return []
      }
      tmpImages = await back()
      images = [...images, ...tmpImages]


      const backLeft = async () => {
         if(inspectionAllData.hasOwnProperty('backLeft' )) {
            if(inspectionAllData.backLeft.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.backLeft.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.backLeft[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               return images
            }
         }
         return []
      }
      tmpImages = await backLeft()
      images = [...images, ...tmpImages]


      const left = async () => {
         if(inspectionAllData.hasOwnProperty('left' )) {
            if(inspectionAllData.left.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.left.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.left[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               return images
            }
         }
         return []
      }
      tmpImages = await left()
      images = [...images, ...tmpImages]






      console.log('elevations photos: ')
      console.log(images)


      setElevationsPhotos(images)
   }

   const collectRoof_RoofField_Photos = async () => {

      if(roofFields.length > 0)
         return;

      let images = []




      const photosNumberOfStories = async () => {
         if(inspectionAllData.hasOwnProperty('photosNumberOfStories' ))
            if(inspectionAllData.photosNumberOfStories.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.photosNumberOfStories.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.photosNumberOfStories[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               return images
               }
         return []
      }
      let tmpImages = await photosNumberOfStories()
      images = [...images, ...tmpImages]


      const pitchGauge = async () => {
         if(inspectionAllData.hasOwnProperty('pitchGauge' ))
            if(inspectionAllData.pitchGauge.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.pitchGauge.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.pitchGauge[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               return images
            }
         return []
      }

      tmpImages = await pitchGauge()
      images = [...images, ...tmpImages]


      const photosInchesOfEaveOverhang = async () => {
         if(inspectionAllData.hasOwnProperty('photosInchesOfEaveOverhang' ))
            if(inspectionAllData.photosInchesOfEaveOverhang.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.photosInchesOfEaveOverhang.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.photosInchesOfEaveOverhang[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               return images
            }
         return []
      }
      tmpImages = await photosInchesOfEaveOverhang()
      images = [...images, ...tmpImages]


      const photosIceWaterShield = async () => {
         if(inspectionAllData.hasOwnProperty('photosIceWaterShield' ))
            if(inspectionAllData.photosIceWaterShield.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.photosIceWaterShield.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.photosIceWaterShield[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               return images
            }
         return []
      }
      tmpImages = await photosIceWaterShield()
      images = [...images, ...tmpImages]


      const photosRedeckRequired = async () => {
         if(inspectionAllData.hasOwnProperty('photosRedeckRequired' ))
            if(inspectionAllData.photosRedeckRequired.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.photosRedeckRequired.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.photosRedeckRequired[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               return images
            }
         return []
      }
      tmpImages = await photosRedeckRequired()
      images = [...images, ...tmpImages]



      const photosRidgeCapShingle = async () => {
         if(inspectionAllData.hasOwnProperty('photosRidgeCapShingle' ))
            if(inspectionAllData.photosRidgeCapShingle.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.photosRidgeCapShingle.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.photosRidgeCapShingle[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               return images
            }
         return []
      }
      tmpImages = await photosRidgeCapShingle()
      images = [...images, ...tmpImages]



      const photosEaveFlashing = async () => {
         if(inspectionAllData.hasOwnProperty('photosEaveFlashing' ))
            if(inspectionAllData.photosEaveFlashing.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.photosEaveFlashing.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.photosEaveFlashing[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               return images
            }
         return []
      }
      tmpImages = await photosEaveFlashing()
      images = [...images, ...tmpImages]



      const roofOverview = async () => {
         if(inspectionAllData.hasOwnProperty('roofOverview' ))
            if(inspectionAllData.roofOverview.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.roofOverview.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.roofOverview[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               return images
            }
         return []
      }
      tmpImages = await roofOverview()
      images = [...images, ...tmpImages]



      const photosShingleLayers = async () => {
         if(inspectionAllData.hasOwnProperty('photosShingleLayers' ))
            if(inspectionAllData.photosShingleLayers.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.photosShingleLayers.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.photosShingleLayers[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               return images
            }
         return []
      }
      tmpImages = await photosShingleLayers()
      images = [...images, ...tmpImages]


      const roofLeftSlope = async () => {
         if(inspectionAllData.hasOwnProperty('roofLeftSlope' ))
            if(inspectionAllData.roofLeftSlope.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.roofLeftSlope.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.roofLeftSlope[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               return images
            }
         return []
      }
      tmpImages = await roofLeftSlope()
      images = [...images, ...tmpImages]


      const roofRightSlope = async () => {
         if(inspectionAllData.hasOwnProperty('roofRightSlope' ))
            if(inspectionAllData.roofRightSlope.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.roofRightSlope.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.roofRightSlope[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               return images
            }
         return []
      }
      tmpImages = await roofRightSlope()
      images = [...images, ...tmpImages]


      const roofRearSlope = async () => {
         if(inspectionAllData.hasOwnProperty('roofRearSlope' ))
            if(inspectionAllData.roofRearSlope.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.roofRearSlope.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.roofRearSlope[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               return images
            }
         return []
      }
      tmpImages = await roofRearSlope()
      images = [...images, ...tmpImages]


      const roofFrontSlope = async () => {
         if(inspectionAllData.hasOwnProperty('roofFrontSlope' ))
            if(inspectionAllData.roofFrontSlope.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.roofFrontSlope.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.roofFrontSlope[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               return images
            }
         return []
      }
      tmpImages = await roofFrontSlope()
      images = [...images, ...tmpImages]



      const starterShingle = async () => {
         if(inspectionAllData.hasOwnProperty('starterShingle' ))
            if(inspectionAllData.starterShingle.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.starterShingle.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.starterShingle[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               return images
            }
         return []
      }
      tmpImages = await starterShingle()
      images = [...images, ...tmpImages]






      console.log('roofFields photos: ')
      console.log(images)

      setRoofFields(images)
   }

   const collectVentilationsPhotos = async () => {

      if(ventilationsPhotos.length > 0)
         return;

      let images = []




      const furriceCap = async () => {
         if(inspectionAllData.hasOwnProperty('furriceCap' )) {
            if (inspectionAllData.furriceCap.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.furriceCap.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.furriceCap[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               return images
            }
         }
         return []
      }
      let tmpImages = await furriceCap()
      images = [...images, ...tmpImages]


      const splitBoot = async () => {
         if(inspectionAllData.hasOwnProperty('splitBoot' )) {
            if(inspectionAllData.splitBoot.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.splitBoot.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.splitBoot[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               return images
            }
         }
         return []
      }
      tmpImages = await splitBoot()
      images = [...images, ...tmpImages]


      const turtleVents = async () => {
         if(inspectionAllData.hasOwnProperty('turtleVents' )) {
            if(inspectionAllData.turtleVents.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.turtleVents.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.turtleVents[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               return images
            }
         }
         return []
      }
      tmpImages = await turtleVents()
      images = [...images, ...tmpImages]







      console.log('Ventilations photos: ')
      console.log(images)

      setVentilationsPhotos(images)
   }

   const collectWindowsPhotos = async () => {

      if(windowsPhotos.length > 0)
         return;

      let images = []

      const coveringsPhotos = async () => {
         if(inspectionAllData.hasOwnProperty('coveringsPhotos' )) {
            if (inspectionAllData.coveringsPhotos.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.coveringsPhotos.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.coveringsPhotos[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               return images
            }
         }
         return []
      }
      let tmpImages = await coveringsPhotos()
      images = [...images, ...tmpImages]

      const interiorPhotos = async () => {
         if(inspectionAllData.hasOwnProperty('interiorPhotos' )) {
            if (inspectionAllData.interiorPhotos.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.interiorPhotos.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.interiorPhotos[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               return images
            }
         }
         return []
      }
      tmpImages = await interiorPhotos()
      images = [...images, ...tmpImages]

      const manufacturerMarkingsPhotos = async () => {
         if(inspectionAllData.hasOwnProperty('manufacturerMarkingsPhotos' )) {
            if (inspectionAllData.manufacturerMarkingsPhotos.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.manufacturerMarkingsPhotos.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.manufacturerMarkingsPhotos[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               return images
            }
         }
         return []
      }
      tmpImages = await manufacturerMarkingsPhotos()
      images = [...images, ...tmpImages]


      const closeUpOfWindowsDamagePhotos = async () => {
         if(inspectionAllData.hasOwnProperty('closeUpOfWindowsDamagePhotos' )) {
            if (inspectionAllData.closeUpOfWindowsDamagePhotos.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.closeUpOfWindowsDamagePhotos.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.closeUpOfWindowsDamagePhotos[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               return images
            }
         }
         return []
      }
      tmpImages = await closeUpOfWindowsDamagePhotos()
      images = [...images, ...tmpImages]

      const elevationsPhotos = async () => {
         if(inspectionAllData.hasOwnProperty('elevationsPhotos' )) {
            if (inspectionAllData.elevationsPhotos.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.elevationsPhotos.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.elevationsPhotos[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               return images
            }
         }
         return []
      }
      tmpImages = await elevationsPhotos()
      images = [...images, ...tmpImages]

      // let readyImages = []
      // for (let i = 0; i < images.length; i+=2) {
      //    if( (i % 2 == 0) && (i < (images.length)) ) {
      //       readyImages.push({
      //          ImageType: 'Windows',
      //          Image1: images[i].DETAIL_URL,
      //          Image2: images[i+1] != undefined ? images[i+1].DETAIL_URL : '',
      //       })
      //    }
      // }



      console.log('finishing Windows photos')

      setWindowsPhotos(images)
      //setWindowsPhotos(readyImages)
   }

   const collectGuttersPhotos = async () => {

      if(guttersPhotos.length > 0)
         return;

      let images = []






      const guttersAdditionalDamaggToSupplementPhotos = async () => {
         if(inspectionAllData.hasOwnProperty('guttersAdditionalDamaggToSupplementPhotos' )) {
            if (inspectionAllData.guttersAdditionalDamaggToSupplementPhotos.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.guttersAdditionalDamaggToSupplementPhotos.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.guttersAdditionalDamaggToSupplementPhotos[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               return images
            }
         }
         return []
      }
      let tmpImages = await guttersAdditionalDamaggToSupplementPhotos()
      images = [...images, ...tmpImages]

      const gutterCoversPhotos = async () => {
         if(inspectionAllData.hasOwnProperty('gutterCoversPhotos' )) {
            if (inspectionAllData.gutterCoversPhotos.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.gutterCoversPhotos.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.gutterCoversPhotos[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               return images
            }
         }
         return []
      }
      tmpImages = await gutterCoversPhotos()
      images = [...images, ...tmpImages]

      const gutterSizePhotos = async () => {
         if(inspectionAllData.hasOwnProperty('gutterSizePhotos' )) {
            if (inspectionAllData.gutterSizePhotos.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.gutterSizePhotos.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.gutterSizePhotos[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               return images
            }
         }
         return []
      }
      tmpImages = await gutterSizePhotos()
      images = [...images, ...tmpImages]


      const guttersLeftPhotos = async () => {
         if(inspectionAllData.hasOwnProperty('guttersLeftPhotos' )) {
            if (inspectionAllData.guttersLeftPhotos.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.guttersLeftPhotos.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.guttersLeftPhotos[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               return images
            }
         }
         return []
      }
      tmpImages = await guttersLeftPhotos()
      images = [...images, ...tmpImages]

      const guttersBackPhotos = async () => {
         if(inspectionAllData.hasOwnProperty('guttersBackPhotos' )) {
            if (inspectionAllData.guttersBackPhotos.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.guttersBackPhotos.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.guttersBackPhotos[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               return images
            }
         }
         return []
      }
      tmpImages = await guttersBackPhotos()
      images = [...images, ...tmpImages]

      const guttersRightPhotos = async () => {
         if(inspectionAllData.hasOwnProperty('guttersRightPhotos' )) {
            if (inspectionAllData.guttersRightPhotos.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.guttersRightPhotos.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.guttersRightPhotos[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               return images
            }
         }
         return []
      }
      tmpImages = await guttersRightPhotos()
      images = [...images, ...tmpImages]

      const guttersFrontPhotos = async () => {
         if(inspectionAllData.hasOwnProperty('guttersFrontPhotos' )) {
            if (inspectionAllData.guttersFrontPhotos.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.guttersFrontPhotos.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.guttersFrontPhotos[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               return images
            }
         }
         return []
      }
      tmpImages = await guttersFrontPhotos()
      images = [...images, ...tmpImages]

      const downspoutsWidthPhotos = async () => {
         if(inspectionAllData.hasOwnProperty('downspoutsWidthPhotos' )) {
            if (inspectionAllData.downspoutsWidthPhotos.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.downspoutsWidthPhotos.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.downspoutsWidthPhotos[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               return images
            }
         }
         return []
      }
      tmpImages = await downspoutsWidthPhotos()
      images = [...images, ...tmpImages]

      const downspoutsLeftPhotos = async () => {
         if(inspectionAllData.hasOwnProperty('downspoutsLeftPhotos' )) {
            if (inspectionAllData.downspoutsLeftPhotos.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.downspoutsLeftPhotos.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.downspoutsLeftPhotos[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               return images
            }
         }
         return []
      }
      tmpImages = await downspoutsLeftPhotos()
      images = [...images, ...tmpImages]

      const downspoutsBackPhotos = async () => {
         if(inspectionAllData.hasOwnProperty('downspoutsBackPhotos' )) {
            if (inspectionAllData.downspoutsBackPhotos.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.downspoutsBackPhotos.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.downspoutsBackPhotos[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               return images
            }
         }
         return []
      }
      tmpImages = await downspoutsBackPhotos()
      images = [...images, ...tmpImages]

      const downspoutsRightPhotos = async () => {
         if(inspectionAllData.hasOwnProperty('downspoutsRightPhotos' )) {
            if (inspectionAllData.downspoutsRightPhotos.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.downspoutsRightPhotos.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.downspoutsRightPhotos[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               return images
            }
         }
         return []
      }
      tmpImages = await downspoutsRightPhotos()
      images = [...images, ...tmpImages]

      const downspoutsFrontPhotos = async () => {
         if(inspectionAllData.hasOwnProperty('downspoutsFrontPhotos' )) {
            if (inspectionAllData.downspoutsFrontPhotos.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.downspoutsFrontPhotos.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.downspoutsFrontPhotos[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               return images
            }
         }
         return []
      }
      tmpImages = await downspoutsFrontPhotos()
      images = [...images, ...tmpImages]





      // let readyImages = []
      // for (let i = 0; i < images.length; i+=2) {
      //    if( (i % 2 == 0) && (i < (images.length)) ) {
      //       readyImages.push({
      //          ImageType: 'Gutters',
      //          Image1: images[i].DETAIL_URL,
      //          Image2: images[i+1] != undefined ? images[i+1].DETAIL_URL : '',
      //       })
      //    }
      // }

      console.log('finishing Gutters photos')

      setGuttersPhotos(images)
      //setGuttersPhotos(readyImages)
   }


   const collectTestSquaresPhotos = async () => {

      if(ventilationsPhotos.length > 0)
         return;

      let images = []



      const closeUp2Left = async () => {
         if(inspectionAllData.hasOwnProperty('closeUp2Left' )) {
            if(inspectionAllData.closeUp2Left.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.closeUp2Left.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.closeUp2Left[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               return images
            }
         }
         return []
      }
      let tmpImages = await closeUp2Left()
      images = [...images, ...tmpImages]


      const closeUp1Left = async () => {
         if(inspectionAllData.hasOwnProperty('closeUp1Left' )) {
            if(inspectionAllData.closeUp1Left.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.closeUp1Left.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.closeUp1Left[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               return images
            }
         }
         return []
      }
      tmpImages = await closeUp1Left()
      images = [...images, ...tmpImages]


      const damageLeft = async () => {
         if(inspectionAllData.hasOwnProperty('damageLeft' )) {
            if(inspectionAllData.damageLeft.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.damageLeft.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.damageLeft[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               return images
            }
         }
         return []
      }

      tmpImages = await damageLeft()
      images = [...images, ...tmpImages]


      const overallTestSquareLeft = async () => {
         if(inspectionAllData.hasOwnProperty('overallTestSquareLeft' )) {
            if(inspectionAllData.overallTestSquareLeft.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.overallTestSquareLeft.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.overallTestSquareLeft[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               return images
            }
         }
         return []
      }

      tmpImages = await overallTestSquareLeft()
      images = [...images, ...tmpImages]


      const closeUp2Back = async () => {
         if(inspectionAllData.hasOwnProperty('closeUp2Back' )) {
            if(inspectionAllData.closeUp2Back.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.closeUp2Back.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.closeUp2Back[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               return images
            }
         }
         return []
      }

      tmpImages = await closeUp2Back()
      images = [...images, ...tmpImages]


      const closeUp1Back = async () => {
         if(inspectionAllData.hasOwnProperty('closeUp1Back' )) {
            if(inspectionAllData.closeUp1Back.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.closeUp1Back.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.closeUp1Back[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               return images
            }
         }
         return []
      }

      tmpImages = await closeUp1Back()
      images = [...images, ...tmpImages]


      const damageBack = async () => {
         if(inspectionAllData.hasOwnProperty('damageBack' )) {
            if(inspectionAllData.damageBack.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.damageBack.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.damageBack[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               return images
            }
         }
         return []
      }

      tmpImages = await damageBack()
      images = [...images, ...tmpImages]


      const overallTestSquareBack = async () => {
         if(inspectionAllData.hasOwnProperty('overallTestSquareBack' )) {
            if(inspectionAllData.overallTestSquareBack.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.overallTestSquareBack.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.overallTestSquareBack[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               return images
            }
         }
         return []
      }

      tmpImages = await overallTestSquareBack()
      images = [...images, ...tmpImages]


      const closeUp2Right = async () => {
         if(inspectionAllData.hasOwnProperty('closeUp2Right' )) {
            if(inspectionAllData.closeUp2Right.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.closeUp2Right.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.closeUp2Right[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               return images
            }
         }
         return []
      }

      tmpImages = await closeUp2Right()
      images = [...images, ...tmpImages]


      const closeUp1Right = async () => {
         if(inspectionAllData.hasOwnProperty('closeUp1Right' )) {
            if(inspectionAllData.closeUp1Right.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.closeUp1Right.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.closeUp1Right[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               return images
            }
         }
         return []
      }

      tmpImages = await closeUp1Right()
      images = [...images, ...tmpImages]


      const damageRight = async () => {
         if(inspectionAllData.hasOwnProperty('damageRight' )) {
            if(inspectionAllData.damageRight.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.damageRight.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.damageRight[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               return images
            }
         }
         return []
      }

      tmpImages = await damageRight()
      images = [...images, ...tmpImages]


      const overallTestSquareRight = async () => {
         if(inspectionAllData.hasOwnProperty('overallTestSquareRight' )) {
            if(inspectionAllData.overallTestSquareRight.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.overallTestSquareRight.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.overallTestSquareRight[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               return images
            }
         }
         return []
      }

      tmpImages = await overallTestSquareRight()
      images = [...images, ...tmpImages]


      const closeUp2Front = async () => {
         if(inspectionAllData.hasOwnProperty('closeUp2Front' )) {
            if(inspectionAllData.closeUp2Front.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.closeUp2Front.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.closeUp2Front[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               return images
            }
         }
         return []
      }

      tmpImages = await closeUp2Front()
      images = [...images, ...tmpImages]


      const closeUp1Front = async () => {
         if(inspectionAllData.hasOwnProperty('closeUp1Front' )) {
            if(inspectionAllData.closeUp1Front.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.closeUp1Front.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.closeUp1Front[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               return images
            }
         }
         return []
      }

      tmpImages = await closeUp1Front()
      images = [...images, ...tmpImages]


      const damageFront = async () => {
         if(inspectionAllData.hasOwnProperty('damageFront' )) {
            if(inspectionAllData.damageFront.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.damageFront.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.damageFront[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               return images
            }
         }
         return []
      }

      tmpImages = await damageFront()
      images = [...images, ...tmpImages]


      const overallTestSquareFront = async () => {
         if(inspectionAllData.hasOwnProperty('overallTestSquareFront' )) {
            if(inspectionAllData.overallTestSquareFront.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.overallTestSquareFront.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.overallTestSquareFront[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               return images
            }
         }
         return []
      }

      tmpImages = await overallTestSquareFront()
      images = [...images, ...tmpImages]

      setTestSquaresPhotos(images)
   }

   const collectRoofPhotos = async () => {

      if(roofPhotos.length > 0)
         return;

      let images = []



      const roofPhotos1 = async () => {
         if(inspectionAllData.hasOwnProperty('roofPhotos' )) {
            if(inspectionAllData.roofPhotos.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.roofPhotos.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.roofPhotos[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               return images
            }
         }
         return []
      }

      let tmpImages = await roofPhotos1()
      images = [...images, ...tmpImages]

      setRoofPhotos(images)
   }

   const collectInteriorPhotos = async () => {

      if(interiorPhotos.length > 0)
         return;

      let images = []

      const other = async () => {
         if(inspectionAllData.hasOwnProperty('other' ))
            if(inspectionAllData.other.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.other.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.other[i].ID)
                  images.push({'ID': imageUrl.ID, 'FILE_ID': imageUrl.FILE_ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               return images
            }
         return []
      }

      let tmpImages = await other()
      images = [...images, ...tmpImages]


      const attic = async () => {
         if(inspectionAllData.hasOwnProperty('attic' ))
            if(inspectionAllData.attic.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.attic.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.attic[i].ID)
                  images.push({'FILE_ID': imageUrl.FILE_ID, 'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               return images
            }
         return []
      }

      tmpImages = await attic()
      images = [...images, ...tmpImages]


      const garage = async () => {
         if(inspectionAllData.hasOwnProperty('garage' ))
            if(inspectionAllData.garage.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.garage.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.garage[i].ID)
                  images.push({'FILE_ID': imageUrl.FILE_ID, 'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               return images
            }
         return []
      }

      tmpImages = await garage()
      images = [...images, ...tmpImages]


      const bedroom6 = async () => {
         if(inspectionAllData.hasOwnProperty('bedroom6' ))
            if(inspectionAllData.bedroom6.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.bedroom6.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.bedroom6[i].ID)
                  images.push({'FILE_ID': imageUrl.FILE_ID, 'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               return images
            }
         return []
      }

      tmpImages = await bedroom6()
      images = [...images, ...tmpImages]


      const bedroom5 = async () => {
         if(inspectionAllData.hasOwnProperty('bedroom5' ))
            if(inspectionAllData.bedroom5.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.bedroom5.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.bedroom5[i].ID)
                  images.push({'FILE_ID': imageUrl.FILE_ID, 'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               return images
            }
         return []
      }

      tmpImages = await bedroom5()
      images = [...images, ...tmpImages]



      const bedroom4 = async () => {
         if(inspectionAllData.hasOwnProperty('bedroom4' ))
            if(inspectionAllData.bedroom4.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.bedroom4.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.bedroom4[i].ID)
                  images.push({'FILE_ID': imageUrl.FILE_ID, 'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               return images
            }
         return []
      }

      tmpImages = await bedroom4()
      images = [...images, ...tmpImages]


      const bedroom3 = async () => {
         if(inspectionAllData.hasOwnProperty('bedroom3' ))
            if(inspectionAllData.bedroom3.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.bedroom3.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.bedroom3[i].ID)
                  images.push({'FILE_ID': imageUrl.FILE_ID, 'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               return images
            }
         return []
      }

      tmpImages = await bedroom3()
      images = [...images, ...tmpImages]


      const bedroom2 = async () => {
         if(inspectionAllData.hasOwnProperty('bedroom2' ))
            if(inspectionAllData.bedroom2.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.bedroom2.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.bedroom2[i].ID)
                  images.push({'FILE_ID': imageUrl.FILE_ID, 'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               return images
            }
         return []
      }

      tmpImages = await bedroom2()
      images = [...images, ...tmpImages]


      const bedroom1 = async () => {
         if(inspectionAllData.hasOwnProperty('bedroom1' ))
            if(inspectionAllData.bedroom1.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.bedroom1.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.bedroom1[i].ID)
                  images.push({'FILE_ID': imageUrl.FILE_ID, 'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               return images
            }
         return []
      }

      tmpImages = await bedroom1()
      images = [...images, ...tmpImages]


      const bathroom4 = async () => {
         if(inspectionAllData.hasOwnProperty('bathroom4' ))
            if(inspectionAllData.bathroom4.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.bathroom4.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.bathroom4[i].ID)
                  images.push({'FILE_ID': imageUrl.FILE_ID, 'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               return images
            }
         return []
      }

      tmpImages = await bathroom4()
      images = [...images, ...tmpImages]


      const bathroom3 = async () => {
         if(inspectionAllData.hasOwnProperty('bathroom3' ))
            if(inspectionAllData.bathroom3.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.bathroom3.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.bathroom3[i].ID)
                  images.push({'FILE_ID': imageUrl.FILE_ID, 'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               return images
            }
         return []
      }

      tmpImages = await bathroom3()
      images = [...images, ...tmpImages]


      const bathroom2 = async () => {
         if(inspectionAllData.hasOwnProperty('bathroom2' ))
            if(inspectionAllData.bathroom2.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.bathroom2.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.bathroom2[i].ID)
                  images.push({'FILE_ID': imageUrl.FILE_ID, 'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               return images
            }
         return []
      }

      tmpImages = await bathroom2()
      images = [...images, ...tmpImages]


      const bathroom1 = async () => {
         if(inspectionAllData.hasOwnProperty('bathroom1' ))
            if(inspectionAllData.bathroom1.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.bathroom1.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.bathroom1[i].ID)
                  images.push({'FILE_ID': imageUrl.FILE_ID, 'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               return images
            }
         return []
      }

      tmpImages = await bathroom1()
      images = [...images, ...tmpImages]


      const office = async () => {
         if(inspectionAllData.hasOwnProperty('office' ))
            if(inspectionAllData.office.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.office.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.office[i].ID)
                  images.push({'FILE_ID': imageUrl.FILE_ID, 'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               return images
            }
         return []
      }

      tmpImages = await office()
      images = [...images, ...tmpImages]


      const hallway3 = async () => {
         if(inspectionAllData.hasOwnProperty('hallway3' ))
            if(inspectionAllData.hallway3.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.hallway3.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.hallway3[i].ID)
                  images.push({'FILE_ID': imageUrl.FILE_ID, 'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               return images
            }
         return []
      }

      tmpImages = await hallway3()
      images = [...images, ...tmpImages]


      const hallway2 = async () => {
         if(inspectionAllData.hasOwnProperty('hallway2' ))
            if(inspectionAllData.hallway2.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.hallway2.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.hallway2[i].ID)
                  images.push({'FILE_ID': imageUrl.FILE_ID, 'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               return images
            }
         return []
      }

      tmpImages = await hallway2()
      images = [...images, ...tmpImages]


      const hallway1 = async () => {
         if(inspectionAllData.hasOwnProperty('hallway1' ))
            if(inspectionAllData.hallway1.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.hallway1.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.hallway1[i].ID)
                  images.push({'FILE_ID': imageUrl.FILE_ID, 'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               return images
            }
         return []
      }

      tmpImages = await hallway1()
      images = [...images, ...tmpImages]


      const den = async () => {
         if(inspectionAllData.hasOwnProperty('den' ))
            if(inspectionAllData.den.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.den.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.den[i].ID)
                  images.push({'FILE_ID': imageUrl.FILE_ID, 'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               return images
            }
         return []
      }

      tmpImages = await den()
      images = [...images, ...tmpImages]



      const kitchen = async () => {
         if(inspectionAllData.hasOwnProperty('kitchen' ))
            if(inspectionAllData.kitchen.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.kitchen.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.kitchen[i].ID)
                  images.push({'FILE_ID': imageUrl.FILE_ID, 'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               return images
            }
         return []
      }

      tmpImages = await kitchen()
      images = [...images, ...tmpImages]


      const diningRoom = async () => {
         if(inspectionAllData.hasOwnProperty('diningRoom' ))
            if(inspectionAllData.diningRoom.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.diningRoom.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.diningRoom[i].ID)
                  images.push({'FILE_ID': imageUrl.FILE_ID, 'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               return images
            }
         return []
      }

      tmpImages = await diningRoom()
      images = [...images, ...tmpImages]


      const bonusRoom = async () => {
         if(inspectionAllData.hasOwnProperty('bonusRoom' ))
            if(inspectionAllData.bonusRoom.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.bonusRoom.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.bonusRoom[i].ID)
                  images.push({'FILE_ID': imageUrl.FILE_ID, 'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               return images
            }
         return []
      }

      tmpImages = await bonusRoom()
      images = [...images, ...tmpImages]


      const familyRoom = async () => {
         if(inspectionAllData.hasOwnProperty('familyRoom' ))
            if(inspectionAllData.familyRoom.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.familyRoom.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.familyRoom[i].ID)
                  images.push({'FILE_ID': imageUrl.FILE_ID, 'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               return images
            }
         return []
      }

      tmpImages = await familyRoom()
      images = [...images, ...tmpImages]


      const livingRoom = async () => {
         if(inspectionAllData.hasOwnProperty('livingRoom' ))
            if(inspectionAllData.livingRoom.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.livingRoom.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.livingRoom[i].ID)
                  images.push({'FILE_ID': imageUrl.FILE_ID, 'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               return images
            }
         return []
      }

      tmpImages = await livingRoom()
      images = [...images, ...tmpImages]




      setInteriorPhotos(images)
   }




   useEffect(() => {

      if (inspectionPhotosBlockInfo.showBlock == true) {

         collectJobsitePhotos()
         collectElevationsPhotos()
         collectRoof_RoofField_Photos()
         collectVentilationsPhotos()
         collectWindowsPhotos()
         collectGuttersPhotos()
         collectTestSquaresPhotos()
         collectRoofPhotos()
         collectInteriorPhotos()
      }

      setShow(inspectionPhotosBlockInfo.showBlock)
   }, [inspectionPhotosBlockInfo]);




   return (

      <Stack
         direction="column"
         justifyContent="left"
         alignItems="left"
         padding={2}
         spacing={1}
         sx={{ display: show === true ? 'flex' : 'none' }}
      >

            <Typography>Jobsite</Typography>
            <Stack direction="column" alignItems="left" spacing={1}>
               {jobsitesPhotos.map((image, index) => (
                  <Image src={image.DETAIL_URL} showLoading width={300} style={{ borderRadius: 6 }} />
               ))}
            </Stack>

         <br/>
         <Typography>Initial Damage</Typography>
         <Stack direction="column" alignItems="left" spacing={1}>
            {testSquaresPhotos.map((image, index) => (
               <Image src={image.DETAIL_URL} showLoading width={300} style={{ borderRadius: 6 }} />
            ))}
         </Stack>


         <br/>
         <Typography>Elevations</Typography>
         <Stack direction="column" alignItems="left" spacing={1}>
            {elevationsPhotos.map((image, index) => (
               <Image src={image.DETAIL_URL} showLoading width={300} style={{ borderRadius: 6 }} />
            ))}
         </Stack>

         <br/>
         <Typography>RoofFields</Typography>
         <Stack direction="column" alignItems="left" spacing={1}>
            {roofFields.map((image, index) => (
               <Image src={image.DETAIL_URL} showLoading width={300} style={{ borderRadius: 6 }} />
            ))}
         </Stack>

         <br/>
         <Typography>Ventilatioins</Typography>
         <Stack direction="column" alignItems="left" spacing={1}>
            {ventilationsPhotos.map((image, index) => (
               <Image src={image.DETAIL_URL} showLoading width={300} style={{ borderRadius: 6 }} />
            ))}
         </Stack>

         <br/>
         <Typography>Windows</Typography>
         <Stack direction="column" alignItems="left" spacing={1}>
            {windowsPhotos.map((image, index) => (
               <Image src={image.DETAIL_URL} showLoading width={300} style={{ borderRadius: 6 }} />
            ))}
         </Stack>

         <br/>
         <Typography>Gutters</Typography>
         <Stack direction="column" alignItems="left" spacing={1}>
            {guttersPhotos.map((image, index) => (
               <Image src={image.DETAIL_URL} showLoading width={300} style={{ borderRadius: 6 }} />
            ))}
         </Stack>


         <br/>
         <Typography>Roof Photos</Typography>
         <Stack direction="column" alignItems="left" spacing={1}>
            {roofPhotos.map((image, index) => (
               <Image src={image.DETAIL_URL} showLoading width={300} style={{ borderRadius: 6 }} />
            ))}
         </Stack>


         <br/>
         <Typography>Iterior</Typography>
         <Stack direction="column" alignItems="left" spacing={1}>
            {interiorPhotos.map((image, index) => (
               <Image src={image.DETAIL_URL} showLoading width={300} style={{ borderRadius: 6 }} />
            ))}
         </Stack>


         {/*</Stack>*/}

      </Stack>

   );
}