import {
   Button, Drawer,
   FormControl, FormControlLabel, FormLabel, ImageList, ImageListItem,
   InputLabel, MenuItem, Radio, RadioGroup, Select, Stack
} from "@mui/material";
import Box from "@mui/material/Box";
import * as React from "react";
import {useContext, useEffect} from "react";
import ECContext from "../../context/inspections/ECContext";
import ElevationFrontLeft from "./elevations/elevationFrontLeft";
import ElevationFront from "./elevations/elevationFront";
import ElevationFrontRight from "./elevations/elevationFrontRight";
import ElevationRight from "./elevations/elevationRight";
import ElevationBackRight from "./elevations/elevationBackRight";
import ElevationBack from "./elevations/elevationBack";
import ElevationBackLeft from "./elevations/elevationBackLeft";
import ElevationLeft from "./elevations/elevationLeft";
import IconButton from "@mui/material/IconButton";
import {PhotoCamera} from "@mui/icons-material";
import {getImageUrl, savePhotoIntoFolder} from "../../context/inspections/ECActions";
import GalleryIcon from "@mui/icons-material/CollectionsOutlined";
import Typography from "@mui/material/Typography";
import reduce from "image-blob-reduce";
import {Image} from "mui-image";

export default function Elevations() {

   const { showCameraFor, flagReloadDatafromInspectionAllData, flagCleanAllControlsData, access_token_data, saveRequest, inspectionAllData, flagNeedToUpdatePhotosForElevationsBlock, inspectionElevationsBlockInfo, dispatch } = useContext(ECContext)
   const [show, setShow] = React.useState(false);
   /*const [elevationSection, setElevationSection] = React.useState(10);*/
   const reduce = require('image-blob-reduce')();

   const [frontLeft, setFrontLeft] = React.useState([]);
   const [front, setFront] = React.useState([]);
   const [frontRight, setFrontRight] = React.useState([]);
   const [right, setRight] = React.useState([]);
   const [backRight, setBackRight] = React.useState([]);
   const [back, setBack] = React.useState([]);
   const [backLeft, setBackLeft] = React.useState([]);
   const [left, setLeft] = React.useState([]);


   useEffect(() => {

      setShow(inspectionElevationsBlockInfo.showBlock)
//      dispatch({ type: 'SET_INSPECTION_ELEVATIONS_SUB_DATA', payload: {inspectionId: 99, elevationType: "frontLeft", showBlock: true} })

   }, [inspectionElevationsBlockInfo]);

   useEffect(() => {
      setFrontLeft([]);
      setFront([]);
      setFrontRight([]);
      setRight([]);
      setBackRight([]);
      setBack([]);
      setBackLeft([]);
      setLeft([]);
   }, [flagCleanAllControlsData]);

   useEffect(() => {

      if(flagNeedToUpdatePhotosForElevationsBlock == true) {

         dispatch({ type: 'DROP_UPDATE_PHOTO_FOR_ELEVATIONS' })

console.log("---> updating data in useEffect elevations.jsx")

         if(inspectionAllData.hasOwnProperty('frontLeft') && showCameraFor.property == 'frontLeft') {
            setFrontLeft([...inspectionAllData.frontLeft])
         }
         if(inspectionAllData.hasOwnProperty('front') && showCameraFor.property == 'front' ) {
            setFront([...inspectionAllData.front])
         }
         if(inspectionAllData.hasOwnProperty('frontRight') && showCameraFor.property == 'frontRight') {
            setFrontRight([...inspectionAllData.frontRight])
         }
         if(inspectionAllData.hasOwnProperty('right') && showCameraFor.property == 'right') {
            setRight([...inspectionAllData.right])
         }
         if(inspectionAllData.hasOwnProperty('backRight') && showCameraFor.property == 'backRight') {
            setBackRight([...inspectionAllData.backRight])
         }
         if(inspectionAllData.hasOwnProperty('back') && showCameraFor.property == 'back') {
            setBack([...inspectionAllData.back])
         }
         if(inspectionAllData.hasOwnProperty('backLeft') && showCameraFor.property == 'backLeft') {
            setBackLeft([...inspectionAllData.backLeft])
         }
         if(inspectionAllData.hasOwnProperty('left') && showCameraFor.property == 'left') {
            setLeft([...inspectionAllData.left])
         }

// а тут надо очистить inspectionAllData от только что забранных оттуда фоток
//          const photosToClear = {...inspectionAllData}
//          delete photosToClear.frontLeft;
//          delete photosToClear.front;
//          delete photosToClear.frontRight;
//          delete photosToClear.right;
//          delete photosToClear.backRight;
//          delete photosToClear.back;
//          delete photosToClear.backLeft;
//          delete photosToClear.left;
//
//          dispatch({ type: 'CLEAR_PHOTOS_ELEVATION', payload: {...photosToClear} } )


      }

   }, [flagNeedToUpdatePhotosForElevationsBlock]);

   useEffect(() => {


                        const transformSavingData = (prop) => {

                           return prop.map((fileData, index) => {
                              if(fileData.IS_NEW == true) {
                                 prop[index].IS_NEW = false
                                 return fileData.FILE_ID
                              }
                           }).filter(x => x !== undefined)
                        }


      if(saveRequest === true) {

         let elevationsBlockData = {

            PROPERTY_859: transformSavingData(frontLeft),
            PROPERTY_861: transformSavingData(front),
            PROPERTY_863: transformSavingData(frontRight),
            PROPERTY_867: transformSavingData(right),
            PROPERTY_869: transformSavingData(backRight),
            PROPERTY_871: transformSavingData(back),
            PROPERTY_873: transformSavingData(backLeft),
            PROPERTY_875: transformSavingData(left),

            NO_PHOTOS_DATA: {}

               // front.map((fileData, index) => {
               //    if(fileData.IS_NEW == true) {
               //       front[index].IS_NEW = false
               //       return fileData.FILE_ID
               //    }
               // }).filter(x => x !== undefined),

         }


         // if (elevationsBlockData.PROPERTY_861.length > 2)
         //    elevationsBlockData.PROPERTY_861[2].FILE_ID = 'n'+elevationsBlockData.PROPERTY_861[2].FILE_ID

         dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA_WITH_ELEVATIONS_BLOCK', payload: elevationsBlockData } )

         // triggering Save in InspectionEdit component
         dispatch({ type: 'SET_ELEVATIOINS_READY_TO_SAVE_FLAG' })

      }
   }, [saveRequest]);

   useEffect(() => {

      if(!inspectionAllData.hasOwnProperty('PROPERTY_799' )) return;

      console.log('RELOADING data... in elevation.jsx')



      const frontLeft = async () => {
         if(inspectionAllData.hasOwnProperty('frontLeft' )) {
            if(inspectionAllData.frontLeft.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.frontLeft.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.frontLeft[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               setFrontLeft(images)
               dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                  payload: {...inspectionAllData,
                     'frontLeft': images
                  }})

            }
         }
      }
      frontLeft()

      const front = async () => {
         if(inspectionAllData.hasOwnProperty('front' )) {
            if(inspectionAllData.front.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.front.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.front[i].ID)
                  images.push({'FILE_ID': imageUrl.FILE_ID, 'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               setFront(images)
               dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                  payload: {...inspectionAllData,
                     'front': images
                  }})

            }
         }
      }
      front()

      const frontRight = async () => {
         if(inspectionAllData.hasOwnProperty('frontRight' )) {
            if(inspectionAllData.frontRight.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.frontRight.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.frontRight[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               setFrontRight(images)
               dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                  payload: {...inspectionAllData,
                     'frontRight': images
                  }})

            }
         }
      }
      frontRight()

      const right = async () => {
         if(inspectionAllData.hasOwnProperty('right' )) {
            if(inspectionAllData.right.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.right.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.right[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               setRight(images)
               dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                  payload: {...inspectionAllData,
                     'right': images
                  }})

            }
         }
      }
      right()

      const backRight = async () => {
         if(inspectionAllData.hasOwnProperty('backRight' )) {
            if(inspectionAllData.backRight.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.backRight.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.backRight[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               setBackRight(images)
               dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                  payload: {...inspectionAllData,
                     'backRight': images
                  }})

            }
         }
      }
      backRight()

      const back = async () => {
         if(inspectionAllData.hasOwnProperty('back' )) {
            if(inspectionAllData.back.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.back.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.back[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               setBack(images)
               dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                  payload: {...inspectionAllData,
                     'back': images
                  }})

            }
         }
      }
      back()

      const backLeft = async () => {
         if(inspectionAllData.hasOwnProperty('backLeft' )) {
            if(inspectionAllData.backLeft.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.backLeft.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.backLeft[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               setBackLeft(images)
               dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                  payload: {...inspectionAllData,
                     'backLeft': images
                  }})

            }
         }
      }
      backLeft()

      const left = async () => {
         if(inspectionAllData.hasOwnProperty('left' )) {
            if(inspectionAllData.left.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.left.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.left[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               setLeft(images)
               dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                  payload: {...inspectionAllData,
                     'left': images
                  }})

            }
         }
      }
      left()




   }, [flagReloadDatafromInspectionAllData]);



   const toBase64 = file => new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
   });



   const [drawerVar, setDrawerVar] = React.useState([])
   const [drawerVarName, setDrawerVarName] = React.useState('')
   const [drawerSetter, setDrawerSetter] = React.useState('')
   const [state, setState] = React.useState({top: false, left: false, bottom: false, right: false,});

   const toggleDrawer = (anchor, open, photosValues) => (event) => {
      if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
         return;
      }

      setDrawerVar(photosValues)
      setDrawerVarName(anchor)

      setState({ ...state, [anchor]: open });
   };

   const list = (drawerVar) => (
      <Box
         sx={{ width: '400px' }}
         role="presentation"
         onClick={toggleDrawer(drawerVarName, false, drawerVar)}
         onKeyDown={toggleDrawer(drawerVarName, false,drawerVar)}
      >

         <Stack direction="row" justifyContent="center" alignItems="center" padding={2}>
            <Button variant="text" onClick={toggleDrawer(drawerVarName, false, drawerVar)}>Back</Button>
         </Stack>

         <ImageList sx={{ width: '400px' }} cols={1} >
            {drawerVar.map((item) => (
               <ImageListItem key={item.DETAIL_URL}>
                  <img
                     src={`${item.DETAIL_URL}`}
                     srcSet={`${item.DETAIL_URL}`}
                     loading="lazy"
                  />

                  <Button
                     disableElevation={true}
                     value={item.FILE_ID}
                     onClick={ (event) => {
                        event.stopPropagation()

                        const updatedPhotos = drawerVar.filter( photo1 => photo1.FILE_ID != event.target.value )


                        console.log(`drawerVarName = ${drawerVarName}`)
                        console.log(`event.target.value = ${event.target.value}`)
                        console.log("drawerVar:")
                        console.log(drawerVar)
                        console.log(`updated photos:`)
                        console.log(updatedPhotos)


                        if(drawerVarName == "frontLeft") {
                           setFrontLeft(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }
                        if(drawerVarName == "front") {
                           setFront(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }
                        if(drawerVarName == "frontRight") {
                           setFrontRight(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }
                        if(drawerVarName == "right") {
                           setRight(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }
                        if(drawerVarName == "backRight") {
                           setBackRight(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }
                        if(drawerVarName == "back") {
                           setBack(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }
                        if(drawerVarName == "backLeft") {
                           setBackLeft(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }
                        if(drawerVarName == "left") {
                           setLeft(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }




                        let tmp = inspectionAllData
                        const updatedPhotos2 = tmp[drawerVarName].filter( photo1 => photo1.FILE_ID != event.target.value )
                        tmp[drawerVarName] = updatedPhotos2

                        console.log(`tmp[drawerVarName]:`)
                        console.log(tmp[drawerVarName])


                        dispatch({ type: 'SET_INSPECTION_ALL_DATA', payload: tmp } )
                     }}>Delete
                  </Button>

                  <br/>
               </ImageListItem>
            ))}
         </ImageList>
      </Box>
   );




   return (
      <>

      <Box sx={{mx: 'auto', mt: 2, width: '100%', display: show === true ? 'flex' : 'none', flexDirection: 'column'}}>

         {/*<Box sx={{display: 'flex', mt: 0, p: 2, gap: 1, width: '100%'}}>*/}

         <React.Fragment key={'right'} >
               <Drawer
                  anchor={'right'}
                  open={state[drawerVarName]}
                  //open={state[drawerVarName]}
                  onClose={toggleDrawer(drawerVarName, false, drawerVar)}
                  sx={{zIndex: 9999}}
               >
                  {list(drawerVar)}
               </Drawer>
            </React.Fragment>

         <Box sx={{display: 'flex', mt: 0, p: 2, gap: 1, width: '100%'}}>

            <Typography variant="body">Front: </Typography>
            <Stack direction="row" justifyContent="right" alignItems="right" padding={2} sx={{ml: 'auto', width: '200px'}}>
               <IconButton color="primary" aria-label="upload picture" component="label"
                           onClick={ () => {
                              dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'front', page: 'elevations'} })
                           }}>
                  <PhotoCamera />
               </IconButton>
               <IconButton color="primary" aria-label="upload picture" component="label">
                  <input
                     type="file" accept="image/*" multiple hidden name="myImage"
                     onChange={ async (event) => {

                        const files = event.target.files
                        let photos = []

                        const mainwrap = async () => {

                           for (let i = 0; i < files.length; i++) {
                              const blob = await reduce.toBlob(files[i], { max: 900 })

                              const base64 = await toBase64(blob);
                              const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', '').replace('data:image/png;base64,', ''), generateUniqueName: true}

                              const tmpSavedPhotoData = await savePhotoIntoFolder(access_token_data, params)
                              const savedPhotoData = {...tmpSavedPhotoData, FILE_ID: `${tmpSavedPhotoData.FILE_ID}` }
                              photos.push(savedPhotoData)
                           }
                        }
                        await mainwrap()

// console.log('photos from Front:')
// console.log(photos)
//
// console.log('и inspectioinAllData мы хотим обновить вот этим:')
// console.log({...inspectionAllData, front: [...front, ...photos]})


                        dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, front: [...front, ...photos]} })
                        setFront([...front, ...photos])


                     }}
                  />
                  <GalleryIcon />
               </IconButton>
            </Stack>

         </Box>

         <Stack direction="row" alignItems="center" spacing={1}>
            {front.map((image, index) => (
               <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('front', true, front)}/>
            ))}
         </Stack>


         <Box sx={{display: 'flex', mt: 0, p: 2, gap: 1, width: '100%'}}>

            <Typography variant="body">Front Right: </Typography>
            <Stack direction="row" justifyContent="right" alignItems="right" padding={2} sx={{ml: 'auto', width: '200px'}}>
               <IconButton color="primary" aria-label="upload picture" component="label"
                           onClick={ () => {
                              dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'frontRight', page: 'elevations'} })
                           }}>
                  <PhotoCamera />
               </IconButton>
               <IconButton color="primary" aria-label="upload picture" component="label">
                  <input
                     type="file" accept="image/*" multiple hidden name="myImage"
                     onChange={(event) => {

                        const files = event.target.files
                        let photos = []

                        //files.forEach((file) => {
                        for (let i = 0; i < files.length; i++) {
                           reduce.toBlob(files[i], { max: 900 }).then(blob => {
                              const wrapSaveImage = async (dataUri) => {
                                 //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                                 const wrapper = async () => {
                                    const base64 = await toBase64(dataUri);
                                    const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', '').replace('data:image/png;base64,', ''), generateUniqueName: true}
                                    const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                    dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, frontRight: [...frontRight, ...photos, savedPhotoData]} })

                                    const updatedPhotos = [...photos, savedPhotoData]
                                    setFrontRight([...frontRight, ...updatedPhotos])
                                    photos.push(savedPhotoData)
                                 }
                                 wrapper()
                              }
                              wrapSaveImage(blob)
                           });
                        }


                     }}
                  />
                  <GalleryIcon />
               </IconButton>
            </Stack>

         </Box>

         <Stack direction="row" alignItems="center" spacing={1}>
            {frontRight.map((image, index) => (
               <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('frontRight', true, frontRight)}/>
            ))}
         </Stack>

         <Box sx={{display: 'flex', mt: 0, p: 2, gap: 1, width: '100%'}}>

            <Typography variant="body">Right: </Typography>
            <Stack direction="row" justifyContent="right" alignItems="right" padding={2} sx={{ml: 'auto', width: '200px'}}>
               <IconButton color="primary" aria-label="upload picture" component="label"
                           onClick={ () => {
                              dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'right', page: 'elevations'} })
                           }}>
                  <PhotoCamera />
               </IconButton>
               <IconButton color="primary" aria-label="upload picture" component="label">
                  <input
                     type="file" accept="image/*" multiple hidden name="myImage"
                     onChange={(event) => {

                        const files = event.target.files
                        let photos = []

                        //files.forEach((file) => {
                        for (let i = 0; i < files.length; i++) {
                           reduce.toBlob(files[i], { max: 900 }).then(blob => {
                              const wrapSaveImage = async (dataUri) => {
                                 //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                                 const wrapper = async () => {
                                    const base64 = await toBase64(dataUri);
                                    const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', '').replace('data:image/png;base64,', ''), generateUniqueName: true}
                                    const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                    dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, right: [...right, ...photos, savedPhotoData]} })

                                    const updatedPhotos = [...photos, savedPhotoData]
                                    setRight([...right, ...updatedPhotos])
                                    photos.push(savedPhotoData)
                                 }
                                 wrapper()
                              }
                              wrapSaveImage(blob)
                           });
                        }


                     }}
                  />
                  <GalleryIcon />
               </IconButton>
            </Stack>

         </Box>

         <Stack direction="row" alignItems="center" spacing={1}>
            {right.map((image, index) => (
               <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('right', true, right)}/>
            ))}
         </Stack>


         <Box sx={{display: 'flex', mt: 0, p: 2, gap: 1, width: '100%'}}>

            <Typography variant="body">Back Right: </Typography>
            <Stack direction="row" justifyContent="right" alignItems="right" padding={2} sx={{ml: 'auto', width: '200px'}}>
               <IconButton color="primary" aria-label="upload picture" component="label"
                           onClick={ () => {
                              dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'backRight', page: 'elevations'} })
                           }}>
                  <PhotoCamera />
               </IconButton>
               <IconButton color="primary" aria-label="upload picture" component="label">
                  <input
                     type="file" accept="image/*" multiple hidden name="myImage"
                     onChange={(event) => {

                        const files = event.target.files
                        let photos = []

                        //files.forEach((file) => {
                        for (let i = 0; i < files.length; i++) {
                           reduce.toBlob(files[i], { max: 900 }).then(blob => {
                              const wrapSaveImage = async (dataUri) => {
                                 //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                                 const wrapper = async () => {
                                    const base64 = await toBase64(dataUri);
                                    const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', '').replace('data:image/png;base64,', ''), generateUniqueName: true}
                                    const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                    dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, backRight: [...backRight, ...photos, savedPhotoData]} })

                                    const updatedPhotos = [...photos, savedPhotoData]
                                    setBackRight([...backRight, ...updatedPhotos])
                                    photos.push(savedPhotoData)
                                 }
                                 wrapper()
                              }
                              wrapSaveImage(blob)
                           });
                        }


                     }}
                  />
                  <GalleryIcon />
               </IconButton>
            </Stack>

         </Box>

         <Stack direction="row" alignItems="center" spacing={1}>
            {backRight.map((image, index) => (
               <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('backRight', true, backRight)}/>
            ))}
         </Stack>


         <Box sx={{display: 'flex', mt: 0, p: 2, gap: 1, width: '100%'}}>

            <Typography variant="body">Back: </Typography>
            <Stack direction="row" justifyContent="right" alignItems="right" padding={2} sx={{ml: 'auto', width: '200px'}}>
               <IconButton color="primary" aria-label="upload picture" component="label"
                           onClick={ () => {
                              dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'back', page: 'elevations'} })
                           }}>
                  <PhotoCamera />
               </IconButton>
               <IconButton color="primary" aria-label="upload picture" component="label">
                  <input
                     type="file" accept="image/*" multiple hidden name="myImage"
                     onChange={(event) => {

                        const files = event.target.files
                        let photos = []

                        //files.forEach((file) => {
                        for (let i = 0; i < files.length; i++) {
                           reduce.toBlob(files[i], { max: 900 }).then(blob => {
                              const wrapSaveImage = async (dataUri) => {
                                 //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                                 const wrapper = async () => {
                                    const base64 = await toBase64(dataUri);
                                    const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', '').replace('data:image/png;base64,', ''), generateUniqueName: true}
                                    const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                    dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, back: [...back, ...photos, savedPhotoData]} })

                                    const updatedPhotos = [...photos, savedPhotoData]
                                    setBack([...back, ...updatedPhotos])
                                    photos.push(savedPhotoData)
                                 }
                                 wrapper()
                              }
                              wrapSaveImage(blob)
                           });
                        }


                     }}
                  />
                  <GalleryIcon />
               </IconButton>
            </Stack>

         </Box>

         <Stack direction="row" alignItems="center" spacing={1}>
            {back.map((image, index) => (
               <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('back', true, back)}/>
            ))}
         </Stack>

         <Box sx={{display: 'flex', mt: 0, p: 2, gap: 1, width: '100%'}}>

            <Typography variant="body">Back Left: </Typography>
            <Stack direction="row" justifyContent="right" alignItems="right" padding={2} sx={{ml: 'auto', width: '200px'}}>
               <IconButton color="primary" aria-label="upload picture" component="label"
                           onClick={ () => {
                              dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'backLeft', page: 'elevations'} })
                           }}>
                  <PhotoCamera />
               </IconButton>
               <IconButton color="primary" aria-label="upload picture" component="label">
                  <input
                     type="file" accept="image/*" multiple hidden name="myImage"
                     onChange={(event) => {

                        const files = event.target.files
                        let photos = []

                        //files.forEach((file) => {
                        for (let i = 0; i < files.length; i++) {
                           reduce.toBlob(files[i], { max: 900 }).then(blob => {
                              const wrapSaveImage = async (dataUri) => {
                                 //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                                 const wrapper = async () => {
                                    const base64 = await toBase64(dataUri);
                                    const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', '').replace('data:image/png;base64,', ''), generateUniqueName: true}
                                    const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                    dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, backLeft: [...backLeft, ...photos, savedPhotoData]} })

                                    const updatedPhotos = [...photos, savedPhotoData]
                                    setBackLeft([...backLeft, ...updatedPhotos])
                                    photos.push(savedPhotoData)
                                 }
                                 wrapper()
                              }
                              wrapSaveImage(blob)
                           });
                        }


                     }}
                  />
                  <GalleryIcon />
               </IconButton>
            </Stack>

         </Box>

         <Stack direction="row" alignItems="center" spacing={1}>
            {backLeft.map((image, index) => (
               <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('backLeft', true, backLeft)}/>
            ))}
         </Stack>

         <Box sx={{display: 'flex', mt: 0, p: 2, gap: 1, width: '100%'}}>

            <Typography variant="body">Left: </Typography>
            <Stack direction="row" justifyContent="right" alignItems="right" padding={2} sx={{ml: 'auto', width: '200px'}}>
               <IconButton color="primary" aria-label="upload picture" component="label"
                           onClick={ () => {
                              dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'left', page: 'elevations'} })
                           }}>
                  <PhotoCamera />
               </IconButton>
               <IconButton color="primary" aria-label="upload picture" component="label">
                  <input
                     type="file" accept="image/*" multiple hidden name="myImage"
                     onChange={(event) => {

                        const files = event.target.files
                        let photos = []

                        //files.forEach((file) => {
                        for (let i = 0; i < files.length; i++) {
                           reduce.toBlob(files[i], { max: 900 }).then(blob => {
                              const wrapSaveImage = async (dataUri) => {
                                 //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                                 const wrapper = async () => {
                                    const base64 = await toBase64(dataUri);
                                    const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', '').replace('data:image/png;base64,', ''), generateUniqueName: true}
                                    const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                    dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, left: [...left, ...photos, savedPhotoData]} })

                                    const updatedPhotos = [...photos, savedPhotoData]
                                    setLeft([...left, ...updatedPhotos])
                                    photos.push(savedPhotoData)
                                 }
                                 wrapper()
                              }
                              wrapSaveImage(blob)
                           });
                        }


                     }}
                  />
                  <GalleryIcon />
               </IconButton>
            </Stack>

         </Box>

         <Stack direction="row" alignItems="center" spacing={1}>
            {left.map((image, index) => (
               <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('left', true, left)}/>
            ))}
         </Stack>



         <Typography variant="body">Front Left: </Typography>
         <Stack direction="row" justifyContent="right" alignItems="right" padding={2} sx={{ml: 'auto', width: '200px'}}>
            <IconButton color="primary" aria-label="upload picture" component="label"
                        onClick={ () => {
                           dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'frontLeft', page: 'elevations'} })
                        }}>
               <PhotoCamera />
            </IconButton>
            <IconButton color="primary" aria-label="upload picture" component="label">
               <input
                  type="file" accept="image/*" multiple hidden name="myImage"
                  onChange={(event) => {

                     const files = event.target.files
                     let photos = []

                     //files.forEach((file) => {
                     for (let i = 0; i < files.length; i++) {
                        reduce.toBlob(files[i], { max: 900 }).then(blob => {
                           const wrapSaveImage = async (dataUri) => {
                              //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                              const wrapper = async () => {
                                 const base64 = await toBase64(dataUri);
                                 const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', '').replace('data:image/png;base64,', ''), generateUniqueName: true}
                                 const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                 dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, frontLeft: [...frontLeft, ...photos, savedPhotoData]} })

                                 const updatedPhotos = [...photos, savedPhotoData]
                                 setFrontLeft([...frontLeft, ...updatedPhotos])
                                 photos.push(savedPhotoData)
                              }
                              wrapper()
                           }
                           wrapSaveImage(blob)
                        });
                     }


                  }}
               />
               <GalleryIcon />
            </IconButton>
         </Stack>

         <Stack direction="row" alignItems="center" spacing={1}>
            {frontLeft.map((image, index) => (
               <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('frontLeft', true, frontLeft)}/>
            ))}
         </Stack>




      {/*</Box>*/}


      </Box>
      </>
   );
}