import * as React from 'react';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import {useContext} from "react";
import ECContext from "../context/inspections/ECContext";

const style = {
   margin: 0,
   top: 'auto',
   right: 20,
   bottom: 20,
   left: 'auto',
   position: 'fixed',
};



export default function AddNewIinspectionFAB() {

   const { dispatch, inspectionAllData } = useContext(ECContext)

   const newInspection = (event) => {

      dispatch({type: 'SET_SHOW_NEW_INSPECTION_DIALOG', payload: {...inspectionAllData, isNew: true}  })
   }



   return (
      <Box /*sx={{ m: 1, position: 'absolute', bottom: 16, right: 16 } }*/>
         <Fab color="primary" aria-label="add" style={style} onClick={newInspection}>
            <AddIcon />
         </Fab>
      </Box>
   );
}

