import {
   Accordion,
   AccordionDetails,
   AccordionSummary, Autocomplete,
   Button, Checkbox, Drawer,
   FormControl,
   FormControlLabel,
   FormGroup, FormLabel, ImageList, ImageListItem, InputLabel, MenuItem, Radio, RadioGroup, Select,
   Stack, TextField
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import {PhotoCamera} from "@mui/icons-material";
import Box from "@mui/material/Box";
import AssistantDirectionIcon from "@mui/icons-material/AssistantDirection";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import * as React from "react";
import {useContext, useEffect} from "react";
import ECContext from "../../../context/inspections/ECContext";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import {getImageUrl, savePhotoIntoFolder} from "../../../context/inspections/ECActions";
import GalleryIcon from "@mui/icons-material/CollectionsOutlined";
import {Image} from "mui-image";





export default function RoofTestSquares() {

   const { inspectionDamageBlockInfo, showCameraFor, flagReloadDatafromInspectionAllData, flagNeedToUpdatePhotosForTestSquareBlock, saveRequest, flagCleanAllControlsData, inspectionAllData, access_token_data, inspectionRoofSUBBlockInfo, dispatch } = useContext(ECContext)
   const [show, setShow] = React.useState(false);
   const reduce = require('image-blob-reduce')();

   const [overallTestSquareFront, setOverallTestSquareFront] = React.useState([]);
   const [damageFront, setDamageFront] = React.useState([]);
   const [closeUp1Front, setCloseUp1Front] = React.useState([]);
   const [closeUp2Front, setCloseUp2Front] = React.useState([]);
   const [descriptionFront, setDescriptionFront] = React.useState('');
   const [hallImpactsFront, setHallImpactsFront] = React.useState('');
   const [windDamageFront, setWindDamageFront] = React.useState('');

   const [overallTestSquareRight, setOverallTestSquareRight] = React.useState([]);
   const [damageRight, setDamageRight] = React.useState([]);
   const [closeUp1Right, setCloseUp1Right] = React.useState([]);
   const [closeUp2Right, setCloseUp2Right] = React.useState([]);
   const [descriptionRight, setDescriptionRight] = React.useState('');
   const [hallImpactsRight, setHallImpactsRight] = React.useState('');
   const [windDamageRight, setWindDamageRight] = React.useState('');

   const [overallTestSquareBack, setOverallTestSquareBack] = React.useState([]);
   const [damageBack, setDamageBack] = React.useState([]);
   const [closeUp1Back, setCloseUp1Back] = React.useState([]);
   const [closeUp2Back, setCloseUp2Back] = React.useState([]);
   const [descriptionBack, setDescriptionBack] = React.useState('');
   const [hallImpactsBack, setHallImpactsBack] = React.useState('');
   const [windDamageBack, setWindDamageBack] = React.useState('');

   const [overallTestSquareLeft, setOverallTestSquareLeft] = React.useState([]);
   const [damageLeft, setDamageLeft] = React.useState([]);
   const [closeUp1Left, setCloseUp1Left] = React.useState([]);
   const [closeUp2Left, setCloseUp2Left] = React.useState([]);
   const [descriptionLeft, setDescriptionLeft] = React.useState('');
   const [hallImpactsLeft, setHallImpactsLeft] = React.useState('');
   const [windDamageLeft, setWindDamageLeft] = React.useState('');


   const [damageRoof, setDamageRoof] = React.useState([]);
   const [damageSiding, setDamageSiding] = React.useState([]);
   const [damageGutterDownSpouts, setDamageGutterDownSpouts] = React.useState([]);
   const [damageWindows, setDamageWindows] = React.useState([]);
   const [damageMetals, setDamageMetals] = React.useState([]);

   // damageRoof PROPERTY_1301
   // damageSiding PROPERTY_1303
   // damageGutterDownSpouts PROPERTY_1305
   // damageWindows PROPERTY_1307
   // damageMetals PROPERTY_1309




   useEffect(() => {

         setShow(inspectionDamageBlockInfo.showBlock)
   }, [inspectionDamageBlockInfo]);

   useEffect(() => {

      setOverallTestSquareFront([]);
      setDamageFront([]);
      setCloseUp1Front([]);
      setCloseUp2Front([]);
      setDescriptionFront('');
      setHallImpactsFront('');
      setWindDamageFront('');

      setOverallTestSquareRight([]);
      setDamageRight([]);
      setCloseUp1Right([]);
      setCloseUp2Right([]);
      setDescriptionRight('');
      setHallImpactsRight('');
      setWindDamageRight('');

      setOverallTestSquareBack([]);
      setDamageBack([]);
      setCloseUp1Back([]);
      setCloseUp2Back([]);
      setDescriptionBack('');
      setHallImpactsBack('');
      setWindDamageBack('');

      setOverallTestSquareLeft([]);
      setDamageLeft([]);
      setCloseUp1Left([]);
      setCloseUp2Left([]);
      setDescriptionLeft('');
      setHallImpactsLeft('');
      setWindDamageLeft('');

      setDamageRoof([]);
      setDamageSiding([]);
      setDamageGutterDownSpouts([]);
      setDamageWindows([]);
      setDamageMetals([]);


   }, [flagCleanAllControlsData]);

   useEffect(() => {

      if(flagNeedToUpdatePhotosForTestSquareBlock == true) {
         dispatch({ type: 'DROP_UPDATE_PHOTO_FOR_TESTSQUARE' })

         console.log("We're in Roof.TestSquare. Updating the photos after we shoot them. inspectionAllData:")
         console.log(inspectionAllData)

         if(inspectionAllData.hasOwnProperty('overallTestSquareFront') && showCameraFor.property == 'overallTestSquareFront') {
            setOverallTestSquareFront([...inspectionAllData.overallTestSquareFront])
         }
         if(inspectionAllData.hasOwnProperty('damageFront') && showCameraFor.property == 'damageFront') {
            setDamageFront([...inspectionAllData.damageFront])
         }
         if(inspectionAllData.hasOwnProperty('closeUp1Front') && showCameraFor.property == 'closeUp1Front') {
            setCloseUp1Front([...inspectionAllData.closeUp1Front])
         }
         if(inspectionAllData.hasOwnProperty('closeUp2Front') && showCameraFor.property == 'closeUp2Front') {
            setCloseUp2Front([...inspectionAllData.closeUp2Front])
         }

         if(inspectionAllData.hasOwnProperty('overallTestSquareRight') && showCameraFor.property == 'overallTestSquareRight') {
            setOverallTestSquareRight([...inspectionAllData.overallTestSquareRight])
         }
         if(inspectionAllData.hasOwnProperty('damageRight') && showCameraFor.property == 'damageRight') {
            setDamageRight([...inspectionAllData.damageRight])
         }
         if(inspectionAllData.hasOwnProperty('closeUp1Right') && showCameraFor.property == 'closeUp1Right') {
            setCloseUp1Right([...inspectionAllData.closeUp1Right])
         }
         if(inspectionAllData.hasOwnProperty('closeUp2Right') && showCameraFor.property == 'closeUp2Right') {
            setCloseUp2Right([...inspectionAllData.closeUp2Right])
         }

         if(inspectionAllData.hasOwnProperty('overallTestSquareBack') && showCameraFor.property == 'overallTestSquareBack') {
            setOverallTestSquareBack([...inspectionAllData.overallTestSquareBack])
         }
         if(inspectionAllData.hasOwnProperty('damageBack') && showCameraFor.property == 'damageBack') {
            setDamageBack([...inspectionAllData.damageBack])
         }
         if(inspectionAllData.hasOwnProperty('closeUp1Back') && showCameraFor.property == 'closeUp1Back') {
            setCloseUp1Back([...inspectionAllData.closeUp1Back])
         }
         if(inspectionAllData.hasOwnProperty('closeUp2Back') && showCameraFor.property == 'closeUp2Back') {
            setCloseUp2Back([...inspectionAllData.closeUp2Back])
         }
         if(inspectionAllData.hasOwnProperty('overallTestSquareLeft') && showCameraFor.property == 'overallTestSquareLeft') {
            setOverallTestSquareLeft([...inspectionAllData.overallTestSquareLeft])
         }
         if(inspectionAllData.hasOwnProperty('damageLeft') && showCameraFor.property == 'damageLeft') {
            setDamageLeft([...inspectionAllData.damageLeft])
         }
         if(inspectionAllData.hasOwnProperty('closeUp1Left') && showCameraFor.property == 'closeUp1Left') {
            setCloseUp1Left([...inspectionAllData.closeUp1Left])
         }
         if(inspectionAllData.hasOwnProperty('closeUp2Left') && showCameraFor.property == 'closeUp2Left') {
            setCloseUp2Left([...inspectionAllData.closeUp2Left])
         }


         if(inspectionAllData.hasOwnProperty('damageRoof') && showCameraFor.property == 'damageRoof') {
            setDamageRoof([...inspectionAllData.damageRoof])
         }
         if(inspectionAllData.hasOwnProperty('damageSiding') && showCameraFor.property == 'damageSiding') {
            setDamageSiding([...inspectionAllData.damageSiding])
         }
         if(inspectionAllData.hasOwnProperty('damageGutterDownSpouts') && showCameraFor.property == 'damageGutterDownSpouts') {
            setDamageGutterDownSpouts([...inspectionAllData.damageGutterDownSpouts])
         }
         if(inspectionAllData.hasOwnProperty('damageWindows') && showCameraFor.property == 'damageWindows') {
            setDamageWindows([...inspectionAllData.damageWindows])
         }
         if(inspectionAllData.hasOwnProperty('damageMetals') && showCameraFor.property == 'damageMetals') {
            setDamageMetals([...inspectionAllData.damageMetals])
         }


      }

   }, [flagNeedToUpdatePhotosForTestSquareBlock]);

   useEffect(() => {


            const transformSavingData = (prop) => {

               return prop.map((fileData, index) => {
                  if(fileData.IS_NEW == true) {
                     prop[index].IS_NEW = false
                     return fileData.FILE_ID
                  }
               }).filter(x => x !== undefined)
            }

      if(saveRequest === true) {

         let testSquareBlockData = {

            PROPERTY_1035: windDamageLeft,               //roof.TestSquares.Left.Wind Damage, %
            PROPERTY_1033: hallImpactsLeft,              //roof.TestSquares.Left.Hall Impacts
            PROPERTY_1031: descriptionLeft,              //roof.TestSquares.Left.Description
            PROPERTY_1029: transformSavingData(closeUp2Left),                              //roof.TestSquares.Left.Close Up 2
               // inspectionAllData.hasOwnProperty('closeUp2Left') ?
               //    inspectionAllData.closeUp2Left.map((fileData, index) => {
               //          return fileData.FILE_ID
               //       }
               //    ) : '',
            PROPERTY_1027: transformSavingData(closeUp1Left),                              //roof.TestSquares.Left.Close Up 1
               // inspectionAllData.hasOwnProperty('closeUp1Left') ?
               //    inspectionAllData.closeUp1Left.map((fileData, index) => {
               //          return fileData.FILE_ID
               //       }
               //    ) : '',
            PROPERTY_1025: transformSavingData(damageLeft),                              //roof.TestSquares.Left.Damage
               // inspectionAllData.hasOwnProperty('damageLeft') ?
               //    inspectionAllData.damageLeft.map((fileData, index) => {
               //          return fileData.FILE_ID
               //       }
               //    ) : '',
            PROPERTY_1023: transformSavingData(overallTestSquareLeft),                              //roof.TestSquares.Left.Overall Test Square
               // inspectionAllData.hasOwnProperty('overallTestSquareLeft') ?
               //    inspectionAllData.overallTestSquareLeft.map((fileData, index) => {
               //          return fileData.FILE_ID
               //       }
               //    ) : '',
            PROPERTY_1021: windDamageBack,               //roof.TestSquares.Back.Wind Damage, %
            PROPERTY_1019: hallImpactsBack,              //roof.TestSquares.Back.Hall Impacts
            PROPERTY_1017: descriptionBack,              //roof.TestSquares.Back.Description
            PROPERTY_1015: transformSavingData(closeUp2Back),                              //roof.TestSquares.Back.Close Up 2
               // inspectionAllData.hasOwnProperty('closeUp2Back') ?
               //    inspectionAllData.closeUp2Back.map((fileData, index) => {
               //          return fileData.FILE_ID
               //       }
               //    ) : '',
            PROPERTY_1013: transformSavingData(closeUp1Back),                              //roof.TestSquares.Back.Close Up 1
               // inspectionAllData.hasOwnProperty('closeUp1Back') ?
               //    inspectionAllData.closeUp1Back.map((fileData, index) => {
               //          return fileData.FILE_ID
               //       }
               //    ) : '',
            PROPERTY_1011: transformSavingData(damageBack),                              //roof.TestSquares.Back.Damage
               // inspectionAllData.hasOwnProperty('damageBack') ?
               //    inspectionAllData.damageBack.map((fileData, index) => {
               //          return fileData.FILE_ID
               //       }
               //    ) : '',
            PROPERTY_1009: transformSavingData(overallTestSquareBack),                              //roof.TestSquares.Back.Overall Test Square
               // inspectionAllData.hasOwnProperty('overallTestSquareBack') ?
               //    inspectionAllData.overallTestSquareBack.map((fileData, index) => {
               //          return fileData.FILE_ID
               //       }
               //    ) : '',
            PROPERTY_1007: windDamageRight,              //roof.TestSquares.Right.Wind Damage, %
            PROPERTY_1005: hallImpactsRight,             //roof.TestSquares.Right.Hall Impacts
            PROPERTY_1003: descriptionRight,             //roof.TestSquares.Right.Description
            PROPERTY_1001: transformSavingData(closeUp2Right),                              //roof.TestSquares.Right.Close Up 2
               // inspectionAllData.hasOwnProperty('closeUp2Right') ?
               //    inspectionAllData.closeUp2Right.map((fileData, index) => {
               //          return fileData.FILE_ID
               //       }
               //    ) : '',
            PROPERTY_999: transformSavingData(closeUp1Right),                               //roof.TestSquares.Right.Close Up 1
               // inspectionAllData.hasOwnProperty('closeUp1Right') ?
               //    inspectionAllData.closeUp1Right.map((fileData, index) => {
               //          return fileData.FILE_ID
               //       }
               //    ) : '',
            PROPERTY_997: transformSavingData(damageRight),                               //roof.TestSquares.Right.Damage
               // inspectionAllData.hasOwnProperty('damageRight') ?
               //    inspectionAllData.damageRight.map((fileData, index) => {
               //          return fileData.FILE_ID
               //       }
               //    ) : '',
            PROPERTY_995: transformSavingData(overallTestSquareRight),                               //roof.TestSquares.Right.Overall Test Square
               // inspectionAllData.hasOwnProperty('overallTestSquareRight') ?
               //    inspectionAllData.overallTestSquareRight.map((fileData, index) => {
               //          return fileData.FILE_ID
               //       }
               //    ) : '',
            PROPERTY_993: windDamageFront,               //roof.TestSquares.Front.Wind Damage, %
            PROPERTY_991: hallImpactsFront,              //roof.TestSquares.Front.Hall Impacts
            PROPERTY_989: descriptionFront,              //roof.TestSquares.Front.Description
            PROPERTY_987: transformSavingData(closeUp2Front),                               //roof.TestSquares.Front.Close Up 2
               // inspectionAllData.hasOwnProperty('closeUp2Front') ?
               //    inspectionAllData.closeUp2Front.map((fileData, index) => {
               //          return fileData.FILE_ID
               //       }
               //    ) : '',
            PROPERTY_985: transformSavingData(closeUp1Front),             //roof.TestSquares.Front.Close Up 1
               // inspectionAllData.hasOwnProperty('closeUp1Front') ?
               //    inspectionAllData.closeUp1Front.map((fileData, index) => {
               //          return fileData.FILE_ID
               //       }
               //    ) : '',
            PROPERTY_983: transformSavingData(damageFront),//roof.TestSquares.Front.Damage
               // inspectionAllData.hasOwnProperty('damageFront') ?
               //    inspectionAllData.damageFront.map((fileData, index) => {
               //          return fileData.FILE_ID
               //       }
               //    ) : '',
            PROPERTY_981: transformSavingData(overallTestSquareFront),//roof.TestSquares.Front.Overall Test Square
               // inspectionAllData.hasOwnProperty('overallTestSquareFront') ?
               //    inspectionAllData.overallTestSquareFront.map((fileData, index) => {
               //          return fileData.FILE_ID
               //       }
               //    ) : '',

             PROPERTY_1301: transformSavingData(damageRoof),
             PROPERTY_1303: transformSavingData(damageSiding),
             PROPERTY_1305: transformSavingData(damageGutterDownSpouts),
             PROPERTY_1307: transformSavingData(damageWindows),
             PROPERTY_1309: transformSavingData(damageMetals),

            NO_PHOTOS_DATA: {
               "Front.Description": descriptionFront,              //roof.TestSquares.Front.Description
               "Front.Hall Impacts": hallImpactsFront,              //roof.TestSquares.Front.Hall Impacts
               "Front.Wind Damage, %": windDamageFront,               //roof.TestSquares.Front.Wind Damage, %

               "Right.Description": descriptionRight,             //roof.TestSquares.Right.Description
               "Right.Hall Impacts": hallImpactsRight,             //roof.TestSquares.Right.Hall Impacts
               "Right.Wind Damage, %": windDamageRight,              //roof.TestSquares.Right.Wind Damage, %

               "Back.Description": descriptionBack,              //roof.TestSquares.Back.Description
               "Back.Hall Impacts": hallImpactsBack,              //roof.TestSquares.Back.Hall Impacts
               "Back.Wind Damage, %": windDamageBack,               //roof.TestSquares.Back.Wind Damage, %

               "Left.Description": descriptionLeft,              //roof.TestSquares.Left.Description
               "Left.Hall Impacts": hallImpactsLeft,              //roof.TestSquares.Left.Hall Impacts
               "Left.Wind Damage, %": windDamageLeft,               //roof.TestSquares.Left.Wind Damage, %


            }

         }

         dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA_WITH_TESTSQUARE_BLOCK', payload: testSquareBlockData } )

         // triggering Save in InspectionEdit component
         dispatch({ type: 'SET_TESTSQUARE_READY_TO_SAVE_FLAG' })

      }
   }, [saveRequest]);


   useEffect(() => {

      if(!inspectionAllData.hasOwnProperty('PROPERTY_799' )) return;

      console.log('RELOADING data... in roofTestSquares.jsx')


      setWindDamageLeft(inspectionAllData.PROPERTY_1035)
      setHallImpactsLeft(inspectionAllData.PROPERTY_1033)
      setDescriptionLeft(inspectionAllData.PROPERTY_1031)
      setWindDamageBack(inspectionAllData.PROPERTY_1021)
      setHallImpactsBack(inspectionAllData.PROPERTY_1019)
      setDescriptionBack(inspectionAllData.PROPERTY_1017)
      setWindDamageRight(inspectionAllData.PROPERTY_1007)
      setHallImpactsRight(inspectionAllData.PROPERTY_1005)
      setDescriptionRight(inspectionAllData.PROPERTY_1003)
      setWindDamageFront(inspectionAllData.PROPERTY_993)
      setHallImpactsFront(inspectionAllData.PROPERTY_991)
      setDescriptionFront(inspectionAllData.PROPERTY_989)


      const closeUp2Left = async () => {
         if(inspectionAllData.hasOwnProperty('closeUp2Left' )) {
            if(inspectionAllData.closeUp2Left.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.closeUp2Left.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.closeUp2Left[i].ID)
                  images.push({'FILE_ID': imageUrl.FILE_ID, 'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               setCloseUp2Left(images)
               dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                  payload: {...inspectionAllData,
                     'closeUp2Left': images
                  }})

            }
         }
      }
      closeUp2Left()

      const closeUp1Left = async () => {
         if(inspectionAllData.hasOwnProperty('closeUp1Left' )) {
            if(inspectionAllData.closeUp1Left.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.closeUp1Left.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.closeUp1Left[i].ID)
                  images.push({'FILE_ID': imageUrl.FILE_ID, 'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               setCloseUp1Left(images)
               dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                  payload: {...inspectionAllData,
                     'closeUp1Left': images
                  }})

            }
         }
      }
      closeUp1Left()

      const damageLeft = async () => {
         if(inspectionAllData.hasOwnProperty('damageLeft' )) {
            if(inspectionAllData.damageLeft.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.damageLeft.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.damageLeft[i].ID)
                  images.push({'FILE_ID': imageUrl.FILE_ID, 'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               setDamageLeft(images)
               dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                  payload: {...inspectionAllData,
                     'damageLeft': images
                  }})

            }
         }
      }
      damageLeft()

      const overallTestSquareLeft = async () => {
         if(inspectionAllData.hasOwnProperty('overallTestSquareLeft' )) {
            if(inspectionAllData.overallTestSquareLeft.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.overallTestSquareLeft.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.overallTestSquareLeft[i].ID)
                  images.push({'FILE_ID': imageUrl.FILE_ID, 'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               setOverallTestSquareLeft(images)
               dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                  payload: {...inspectionAllData,
                     'overallTestSquareLeft': images
                  }})

            }
         }
      }
      overallTestSquareLeft()

      const closeUp2Back = async () => {
         if(inspectionAllData.hasOwnProperty('closeUp2Back' )) {
            if(inspectionAllData.closeUp2Back.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.closeUp2Back.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.closeUp2Back[i].ID)
                  images.push({'FILE_ID': imageUrl.FILE_ID, 'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               setCloseUp2Back(images)
               dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                  payload: {...inspectionAllData,
                     'closeUp2Back': images
                  }})

            }
         }
      }
      closeUp2Back()

      const closeUp1Back = async () => {
         if(inspectionAllData.hasOwnProperty('closeUp1Back' )) {
            if(inspectionAllData.closeUp1Back.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.closeUp1Back.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.closeUp1Back[i].ID)
                  images.push({'FILE_ID': imageUrl.FILE_ID, 'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               setCloseUp1Back(images)
               dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                  payload: {...inspectionAllData,
                     'closeUp1Back': images
                  }})

            }
         }
      }
      closeUp1Back()

      const damageBack = async () => {
         if(inspectionAllData.hasOwnProperty('damageBack' )) {
            if(inspectionAllData.damageBack.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.damageBack.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.damageBack[i].ID)
                  images.push({'FILE_ID': imageUrl.FILE_ID, 'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               setDamageBack(images)
               dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                  payload: {...inspectionAllData,
                     'damageBack': images
                  }})

            }
         }
      }
      damageBack()

      const overallTestSquareBack = async () => {
         if(inspectionAllData.hasOwnProperty('overallTestSquareBack' )) {
            if(inspectionAllData.overallTestSquareBack.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.overallTestSquareBack.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.overallTestSquareBack[i].ID)
                  images.push({'FILE_ID': imageUrl.FILE_ID, 'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               setOverallTestSquareBack(images)
               dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                  payload: {...inspectionAllData,
                     'overallTestSquareBack': images
                  }})

            }
         }
      }
      overallTestSquareBack()

      const closeUp2Right = async () => {
         if(inspectionAllData.hasOwnProperty('closeUp2Right' )) {
            if(inspectionAllData.closeUp2Right.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.closeUp2Right.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.closeUp2Right[i].ID)
                  images.push({'FILE_ID': imageUrl.FILE_ID, 'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               setCloseUp2Right(images)
               dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                  payload: {...inspectionAllData,
                     'closeUp2Right': images
                  }})

            }
         }
      }
      closeUp2Right()

      const closeUp1Right = async () => {
         if(inspectionAllData.hasOwnProperty('closeUp1Right' )) {
            if(inspectionAllData.closeUp1Right.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.closeUp1Right.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.closeUp1Right[i].ID)
                  images.push({'FILE_ID': imageUrl.FILE_ID, 'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               setCloseUp1Right(images)
               dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                  payload: {...inspectionAllData,
                     'closeUp1Right': images
                  }})

            }
         }
      }
      closeUp1Right()

      const damageRight = async () => {
         if(inspectionAllData.hasOwnProperty('damageRight' )) {
            if(inspectionAllData.damageRight.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.damageRight.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.damageRight[i].ID)
                  images.push({'FILE_ID': imageUrl.FILE_ID, 'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               setDamageRight(images)
               dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                  payload: {...inspectionAllData,
                     'damageRight': images
                  }})

            }
         }
      }
      damageRight()

      const overallTestSquareRight = async () => {
         if(inspectionAllData.hasOwnProperty('overallTestSquareRight' )) {
            if(inspectionAllData.overallTestSquareRight.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.overallTestSquareRight.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.overallTestSquareRight[i].ID)
                  images.push({'FILE_ID': imageUrl.FILE_ID, 'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               setOverallTestSquareRight(images)
               dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                  payload: {...inspectionAllData,
                     'overallTestSquareRight': images
                  }})

            }
         }
      }
      overallTestSquareRight()

      const closeUp2Front = async () => {
         if(inspectionAllData.hasOwnProperty('closeUp2Front' )) {
            if(inspectionAllData.closeUp2Front.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.closeUp2Front.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.closeUp2Front[i].ID)
                  images.push({'FILE_ID': imageUrl.FILE_ID, 'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               setCloseUp2Front(images)
               dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                  payload: {...inspectionAllData,
                     'closeUp2Front': images
                  }})

            }
         }
      }
      closeUp2Front()

      const closeUp1Front = async () => {
         if(inspectionAllData.hasOwnProperty('closeUp1Front' )) {
            if(inspectionAllData.closeUp1Front.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.closeUp1Front.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.closeUp1Front[i].ID)
                  images.push({'FILE_ID': imageUrl.FILE_ID, 'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               setCloseUp1Front(images)
               dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                  payload: {...inspectionAllData,
                     'closeUp1Front': images
                  }})

            }
         }
      }
      closeUp1Front()

      const damageFront = async () => {
         if(inspectionAllData.hasOwnProperty('damageFront' )) {
            if(inspectionAllData.damageFront.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.damageFront.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.damageFront[i].ID)
                  images.push({'FILE_ID': imageUrl.FILE_ID, 'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               setDamageFront(images)
               dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                  payload: {...inspectionAllData,
                     'damageFront': images
                  }})

            }
         }
      }
      damageFront()

      const overallTestSquareFront = async () => {
         if(inspectionAllData.hasOwnProperty('overallTestSquareFront' )) {
            if(inspectionAllData.overallTestSquareFront.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.overallTestSquareFront.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.overallTestSquareFront[i].ID)
                  images.push({'FILE_ID': imageUrl.FILE_ID, 'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               setOverallTestSquareFront(images)
               dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                  payload: {...inspectionAllData,
                     'overallTestSquareFront': images
                  }})

            }
         }
      }
      overallTestSquareFront()


      const damageRoof = async () => {
         if(inspectionAllData.hasOwnProperty('damageRoof' )) {
            if(inspectionAllData.damageRoof.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.damageRoof.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.damageRoof[i].ID)
                  images.push({'FILE_ID': imageUrl.FILE_ID, 'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               setDamageRoof(images)
               dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                  payload: {...inspectionAllData,
                     'damageRoof': images
                  }})

            }
         }
      }
      damageRoof()

      const damageSiding = async () => {
         if(inspectionAllData.hasOwnProperty('damageSiding' )) {
            if(inspectionAllData.damageSiding.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.damageSiding.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.damageSiding[i].ID)
                  images.push({'FILE_ID': imageUrl.FILE_ID, 'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               setDamageSiding(images)
               dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                  payload: {...inspectionAllData,
                     'damageSiding': images
                  }})

            }
         }
      }
      damageSiding()

      const damageGutterDownSpouts = async () => {
         if(inspectionAllData.hasOwnProperty('damageGutterDownSpouts' )) {
            if(inspectionAllData.damageGutterDownSpouts.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.damageGutterDownSpouts.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.damageGutterDownSpouts[i].ID)
                  images.push({'FILE_ID': imageUrl.FILE_ID, 'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               setDamageGutterDownSpouts(images)
               dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                  payload: {...inspectionAllData,
                     'damageGutterDownSpouts': images
                  }})

            }
         }
      }
      damageGutterDownSpouts()


      const damageWindows = async () => {
         if(inspectionAllData.hasOwnProperty('damageWindows' )) {
            if(inspectionAllData.damageWindows.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.damageWindows.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.damageWindows[i].ID)
                  images.push({'FILE_ID': imageUrl.FILE_ID, 'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               setDamageWindows(images)
               dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                  payload: {...inspectionAllData,
                     'damageWindows': images
                  }})

            }
         }
      }
      damageWindows()

      const damageMetals = async () => {
         if(inspectionAllData.hasOwnProperty('damageMetals' )) {
            if(inspectionAllData.damageMetals.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.damageMetals.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.damageMetals[i].ID)
                  images.push({'FILE_ID': imageUrl.FILE_ID, 'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               setDamageMetals(images)
               dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                  payload: {...inspectionAllData,
                     'damageMetals': images
                  }})

            }
         }
      }
      damageMetals()



   }, [flagReloadDatafromInspectionAllData]);




   const [drawerVar, setDrawerVar] = React.useState([])
   const [drawerVarName, setDrawerVarName] = React.useState('')
   const [drawerSetter, setDrawerSetter] = React.useState('')
   const [state, setState] = React.useState({top: false, left: false, bottom: false, right: false,});

   const toggleDrawer = (anchor, open, photosValues) => (event) => {
      if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
         return;
      }

      setDrawerVar(photosValues)
      setDrawerVarName(anchor)

      setState({ ...state, [anchor]: open });
   };

   const list = (drawerVar) => (
      <Box
         sx={{ width: '400px' }}
         role="presentation"
         onClick={toggleDrawer(drawerVarName, false, drawerVar)}
         onKeyDown={toggleDrawer(drawerVarName, false,drawerVar)}
      >

         <Stack direction="row" justifyContent="center" alignItems="center" padding={2}>
            <Button variant="text" onClick={toggleDrawer(drawerVarName, false, drawerVar)}>Back</Button>
         </Stack>

         <ImageList sx={{ width: '400px' }} cols={1} >
            {drawerVar.map((item) => (
               <ImageListItem key={item.DETAIL_URL}>
                  <img
                     src={`${item.DETAIL_URL}`}
                     srcSet={`${item.DETAIL_URL}`}
                     loading="lazy"
                  />

                  <Button
                     disableElevation={true}
                     value={item.FILE_ID}
                     onClick={ (event) => {
                        event.stopPropagation()

                        const updatedPhotos = drawerVar.filter( photo1 => photo1.FILE_ID != event.target.value )


                        console.log(`drawerVarName = ${drawerVarName}`)
                        console.log(`event.target.value = ${event.target.value}`)
                        console.log("drawerVar:")
                        console.log(drawerVar)
                        console.log(`updated photos:`)
                        console.log(updatedPhotos)


                        if(drawerVarName == "overallTestSquareFront") {
                           setOverallTestSquareFront(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }
                        if(drawerVarName == "damageFront") {
                           setDamageFront(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }
                        if(drawerVarName == "closeUp1Front") {
                           setCloseUp1Front(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }
                        if(drawerVarName == "closeUp2Front") {
                           setCloseUp2Front(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }
                        if(drawerVarName == "overallTestSquareRight") {
                           setOverallTestSquareRight(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }
                        if(drawerVarName == "damageRight") {
                           setDamageRight(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }
                        if(drawerVarName == "closeUp1Right") {
                           setCloseUp1Right(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }
                        if(drawerVarName == "closeUp2Right") {
                           setCloseUp2Right(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }
                        if(drawerVarName == "overallTestSquareBack") {
                           setOverallTestSquareBack(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }
                        if(drawerVarName == "damageBack") {
                           setDamageBack(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }
                        if(drawerVarName == "closeUp1Back") {
                           setCloseUp1Back(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }
                        if(drawerVarName == "closeUp2Back") {
                           setCloseUp2Back(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }
                        if(drawerVarName == "overallTestSquareLeft") {
                           setOverallTestSquareLeft(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }
                        if(drawerVarName == "damageLeft") {
                           setDamageLeft(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }
                        if(drawerVarName == "closeUp1Left") {
                           setCloseUp1Left(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }
                        if(drawerVarName == "closeUp2Left") {
                           setCloseUp2Left(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }

                        if(drawerVarName == "damageRoof") {
                           setDamageRoof(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }
                        if(drawerVarName == "damageSiding") {
                           setDamageSiding(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }
                        if(drawerVarName == "damageGutterDownSpouts") {
                           setDamageGutterDownSpouts(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }
                        if(drawerVarName == "damageWindows") {
                           setDamageWindows(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }
                        if(drawerVarName == "damageMetals") {
                           setDamageMetals(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }




                        let tmp = inspectionAllData
                        const updatedPhotos2 = tmp[drawerVarName].filter( photo1 => photo1.FILE_ID != event.target.value )
                        tmp[drawerVarName] = updatedPhotos2

                        console.log(`tmp[drawerVarName]:`)
                        console.log(tmp[drawerVarName])


                        dispatch({ type: 'SET_INSPECTION_ALL_DATA', payload: tmp } )
                     }}>Delete
                  </Button>

                  <br/>
               </ImageListItem>
            ))}
         </ImageList>
      </Box>
   );





   const toBase64 = file => new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
   });


   return (

      <>
         <Stack
            direction="column"
            justifyContent="left"
            alignItems="left"
            padding={1}
            spacing={1}

            sx={{display: show === true ? 'flex' : 'none'}}
         >

            <React.Fragment key={'right'} >
               <Drawer
                  anchor={'right'}
                  open={state[drawerVarName]}
                  //open={state[drawerVarName]}
                  onClose={toggleDrawer(drawerVarName, false, drawerVar)}
                  sx={{zIndex: 9999}}
               >
                  {list(drawerVar)}
               </Drawer>
            </React.Fragment>

{/*Front*/}
            <Stack
               direction="column"
               justifyContent="left"
               alignItems="left"
               padding={2}
               spacing={1}
            >


               <Stack direction="row" justifyContent="left" alignItems="left" padding={0}>
                  <Typography sx={{my: 'auto'}}>Roof</Typography>

                  <Stack direction="row" justifyContent="right" alignItems="right" padding={2} sx={{ml: 'auto', width: '200px'}}>
                     <IconButton color="primary" aria-label="upload picture" component="label"
                                 onClick={ () => {
                                    dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'damageRoof', page: 'testSquare'} })
                                 }}>
                        <PhotoCamera />
                     </IconButton>
                     <IconButton color="primary" aria-label="upload picture" component="label">
                        <input
                           type="file" accept="image/*" multiple hidden name="myImage"
                           onChange={(event) => {

                              console.log('event.target.files:')
                              console.log(event.target.files)

                              const files = event.target.files
                              let photos = []

                              //files.forEach((file) => {
                              for (let i = 0; i < files.length; i++) {
                                 reduce.toBlob(files[i], { max: 900 }).then(blob => {
                                    const wrapSaveImage = async (dataUri) => {
                                       //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                                       const wrapper = async () => {
                                          const base64 = await toBase64(dataUri);
                                          const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', ''), generateUniqueName: true}
                                          const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                          dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, damageRoof: [...damageRoof, ...photos, savedPhotoData]} })
                                          //dispatch({ type: 'SET_UPDATE_PHOTO_FOR_JOBSITE' })

                                          const updatedPhotos = [...photos, savedPhotoData]
                                          setDamageRoof([...damageRoof, ...updatedPhotos])
                                          photos.push(savedPhotoData)
                                       }
                                       wrapper()
                                    }
                                    wrapSaveImage(blob)
                                 });
                              }


                           }}
                        />
                        <GalleryIcon />
                     </IconButton>
                  </Stack>
               </Stack>
               <Stack direction="row" alignItems="center" spacing={1}>
                  {damageRoof.map((image, index) => (
                     <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('damageRoof', true, damageRoof)}/>
                  ))}
               </Stack>

               <Stack direction="row" justifyContent="left" alignItems="left" padding={0}>
                  <Typography sx={{my: 'auto'}}>Siding</Typography>

                  <Stack direction="row" justifyContent="right" alignItems="right" padding={2} sx={{ml: 'auto', width: '200px'}}>
                     <IconButton color="primary" aria-label="upload picture" component="label"
                                 onClick={ () => {
                                    dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'damageSiding', page: 'testSquare'} })
                                 }}>
                        <PhotoCamera />
                     </IconButton>
                     <IconButton color="primary" aria-label="upload picture" component="label">
                        <input
                           type="file" accept="image/*" multiple hidden name="myImage"
                           onChange={(event) => {

                              console.log('event.target.files:')
                              console.log(event.target.files)

                              const files = event.target.files
                              let photos = []

                              //files.forEach((file) => {
                              for (let i = 0; i < files.length; i++) {
                                 reduce.toBlob(files[i], { max: 900 }).then(blob => {
                                    const wrapSaveImage = async (dataUri) => {
                                       //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                                       const wrapper = async () => {
                                          const base64 = await toBase64(dataUri);
                                          const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', ''), generateUniqueName: true}
                                          const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                          dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, damageSiding: [...damageSiding, ...photos, savedPhotoData]} })
                                          //dispatch({ type: 'SET_UPDATE_PHOTO_FOR_JOBSITE' })

                                          const updatedPhotos = [...photos, savedPhotoData]
                                          setDamageSiding([...damageSiding, ...updatedPhotos])
                                          photos.push(savedPhotoData)
                                       }
                                       wrapper()
                                    }
                                    wrapSaveImage(blob)
                                 });
                              }


                           }}
                        />
                        <GalleryIcon />
                     </IconButton>
                  </Stack>
               </Stack>
               <Stack direction="row" alignItems="center" spacing={1}>
                  {damageSiding.map((image, index) => (
                     <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('damageSiding', true, damageSiding)}/>
                  ))}
               </Stack>

               <Stack direction="row" justifyContent="left" alignItems="left" padding={0}>
                  <Typography sx={{my: 'auto'}}>Gutter/DownSpouts</Typography>

                  <Stack direction="row" justifyContent="right" alignItems="right" padding={2} sx={{ml: 'auto', width: '200px'}}>
                     <IconButton color="primary" aria-label="upload picture" component="label"
                                 onClick={ () => {
                                    dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'damageGutterDownSpouts', page: 'testSquare'} })
                                 }}>
                        <PhotoCamera />
                     </IconButton>
                     <IconButton color="primary" aria-label="upload picture" component="label">
                        <input
                           type="file" accept="image/*" multiple hidden name="myImage"
                           onChange={(event) => {

                              console.log('event.target.files:')
                              console.log(event.target.files)

                              const files = event.target.files
                              let photos = []

                              //files.forEach((file) => {
                              for (let i = 0; i < files.length; i++) {
                                 reduce.toBlob(files[i], { max: 900 }).then(blob => {
                                    const wrapSaveImage = async (dataUri) => {
                                       //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                                       const wrapper = async () => {
                                          const base64 = await toBase64(dataUri);
                                          const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', ''), generateUniqueName: true}
                                          const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                          dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, damageGutterDownSpouts: [...damageGutterDownSpouts, ...photos, savedPhotoData]} })
                                          //dispatch({ type: 'SET_UPDATE_PHOTO_FOR_JOBSITE' })

                                          const updatedPhotos = [...photos, savedPhotoData]
                                          setDamageGutterDownSpouts([...damageGutterDownSpouts, ...updatedPhotos])
                                          photos.push(savedPhotoData)
                                       }
                                       wrapper()
                                    }
                                    wrapSaveImage(blob)
                                 });
                              }


                           }}
                        />
                        <GalleryIcon />
                     </IconButton>
                  </Stack>
               </Stack>
               <Stack direction="row" alignItems="center" spacing={1}>
                  {damageGutterDownSpouts.map((image, index) => (
                     <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('damageGutterDownSpouts', true, damageGutterDownSpouts)}/>
                  ))}
               </Stack>

               <Stack direction="row" justifyContent="left" alignItems="left" padding={0}>
                  <Typography sx={{my: 'auto'}}>Windows</Typography>

                  <Stack direction="row" justifyContent="right" alignItems="right" padding={2} sx={{ml: 'auto', width: '200px'}}>
                     <IconButton color="primary" aria-label="upload picture" component="label"
                                 onClick={ () => {
                                    dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'damageWindows', page: 'testSquare'} })
                                 }}>
                        <PhotoCamera />
                     </IconButton>
                     <IconButton color="primary" aria-label="upload picture" component="label">
                        <input
                           type="file" accept="image/*" multiple hidden name="myImage"
                           onChange={(event) => {

                              console.log('event.target.files:')
                              console.log(event.target.files)

                              const files = event.target.files
                              let photos = []

                              //files.forEach((file) => {
                              for (let i = 0; i < files.length; i++) {
                                 reduce.toBlob(files[i], { max: 900 }).then(blob => {
                                    const wrapSaveImage = async (dataUri) => {
                                       //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                                       const wrapper = async () => {
                                          const base64 = await toBase64(dataUri);
                                          const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', ''), generateUniqueName: true}
                                          const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                          dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, damageWindows: [...damageWindows, ...photos, savedPhotoData]} })
                                          //dispatch({ type: 'SET_UPDATE_PHOTO_FOR_JOBSITE' })

                                          const updatedPhotos = [...photos, savedPhotoData]
                                          setDamageWindows([...damageWindows, ...updatedPhotos])
                                          photos.push(savedPhotoData)
                                       }
                                       wrapper()
                                    }
                                    wrapSaveImage(blob)
                                 });
                              }


                           }}
                        />
                        <GalleryIcon />
                     </IconButton>
                  </Stack>
               </Stack>
               <Stack direction="row" alignItems="center" spacing={1}>
                  {damageWindows.map((image, index) => (
                     <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('damageWindows', true, damageWindows)}/>
                  ))}
               </Stack>

               <Stack direction="row" justifyContent="left" alignItems="left" padding={0}>
                  <Typography sx={{my: 'auto'}}>Metals</Typography>

                  <Stack direction="row" justifyContent="right" alignItems="right" padding={2} sx={{ml: 'auto', width: '200px'}}>
                     <IconButton color="primary" aria-label="upload picture" component="label"
                                 onClick={ () => {
                                    dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'damageMetals', page: 'testSquare'} })
                                 }}>
                        <PhotoCamera />
                     </IconButton>
                     <IconButton color="primary" aria-label="upload picture" component="label">
                        <input
                           type="file" accept="image/*" multiple hidden name="myImage"
                           onChange={(event) => {

                              console.log('event.target.files:')
                              console.log(event.target.files)

                              const files = event.target.files
                              let photos = []

                              //files.forEach((file) => {
                              for (let i = 0; i < files.length; i++) {
                                 reduce.toBlob(files[i], { max: 900 }).then(blob => {
                                    const wrapSaveImage = async (dataUri) => {
                                       //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                                       const wrapper = async () => {
                                          const base64 = await toBase64(dataUri);
                                          const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', ''), generateUniqueName: true}
                                          const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                          dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, damageMetals: [...damageMetals, ...photos, savedPhotoData]} })
                                          //dispatch({ type: 'SET_UPDATE_PHOTO_FOR_JOBSITE' })

                                          const updatedPhotos = [...photos, savedPhotoData]
                                          setDamageMetals([...damageMetals, ...updatedPhotos])
                                          photos.push(savedPhotoData)
                                       }
                                       wrapper()
                                    }
                                    wrapSaveImage(blob)
                                 });
                              }


                           }}
                        />
                        <GalleryIcon />
                     </IconButton>
                  </Stack>
               </Stack>
               <Stack direction="row" alignItems="center" spacing={1}>
                  {damageMetals.map((image, index) => (
                     <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('damageMetals', true, damageMetals)}/>
                  ))}
               </Stack>

<br/>
<br/>








               <Stack direction="row" justifyContent="left" alignItems="left" padding={0}>
                  <Typography sx={{my: 'auto'}}>Overall Test Square (Front)</Typography>

                  <Stack direction="row" justifyContent="right" alignItems="right" padding={2} sx={{ml: 'auto', width: '200px'}}>
                     <IconButton color="primary" aria-label="upload picture" component="label"
                                 onClick={ () => {
                                    dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'overallTestSquareFront', page: 'testSquare'} })
                                 }}>
                        <PhotoCamera />
                     </IconButton>
                     <IconButton color="primary" aria-label="upload picture" component="label">
                        <input
                           type="file" accept="image/*" multiple hidden name="myImage"
                           onChange={(event) => {

                              console.log('event.target.files:')
                              console.log(event.target.files)

                              const files = event.target.files
                              let photos = []

                              //files.forEach((file) => {
                              for (let i = 0; i < files.length; i++) {
                                 reduce.toBlob(files[i], { max: 900 }).then(blob => {
                                    const wrapSaveImage = async (dataUri) => {
                                       //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                                       const wrapper = async () => {
                                          const base64 = await toBase64(dataUri);
                                          const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', ''), generateUniqueName: true}
                                          const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                          dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, overallTestSquareFront: [...overallTestSquareFront, ...photos, savedPhotoData]} })
                                          //dispatch({ type: 'SET_UPDATE_PHOTO_FOR_JOBSITE' })

                                          const updatedPhotos = [...photos, savedPhotoData]
                                          setOverallTestSquareFront([...overallTestSquareFront, ...updatedPhotos])
                                          photos.push(savedPhotoData)
                                       }
                                       wrapper()
                                    }
                                    wrapSaveImage(blob)
                                 });
                              }


                           }}
                        />
                        <GalleryIcon />
                     </IconButton>
                  </Stack>

               </Stack>

               <Stack direction="row" alignItems="center" spacing={1}>
                  {overallTestSquareFront.map((image, index) => (
                     <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('overallTestSquareFront', true, overallTestSquareFront)}/>
                  ))}
               </Stack>



               <Stack direction="row" justifyContent="left" alignItems="left" padding={0}>
                  <Typography sx={{my: 'auto'}}>Damage (Front)</Typography>

                  <Stack direction="row" justifyContent="right" alignItems="right" padding={2} sx={{ml: 'auto', width: '200px'}}>
                     <IconButton color="primary" aria-label="upload picture" component="label"
                                 onClick={ () => {
                                    dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'damageFront', page: 'testSquare'} })
                                 }}>
                        <PhotoCamera />
                     </IconButton>
                     <IconButton color="primary" aria-label="upload picture" component="label">
                        <input
                           type="file" accept="image/*" multiple hidden name="myImage"
                           onChange={(event) => {

                              console.log('event.target.files:')
                              console.log(event.target.files)

                              const files = event.target.files
                              let photos = []

                              //files.forEach((file) => {
                              for (let i = 0; i < files.length; i++) {
                                 reduce.toBlob(files[i], { max: 900 }).then(blob => {
                                    const wrapSaveImage = async (dataUri) => {
                                       //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                                       const wrapper = async () => {
                                          const base64 = await toBase64(dataUri);
                                          const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', ''), generateUniqueName: true}
                                          const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                          dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, damageFront: [...damageFront, ...photos, savedPhotoData]} })
                                          //dispatch({ type: 'SET_UPDATE_PHOTO_FOR_JOBSITE' })

                                          const updatedPhotos = [...photos, savedPhotoData]
                                          setDamageFront([...damageFront, ...updatedPhotos])
                                          photos.push(savedPhotoData)
                                       }
                                       wrapper()
                                    }
                                    wrapSaveImage(blob)
                                 });
                              }


                           }}
                        />
                        <GalleryIcon />
                     </IconButton>
                  </Stack>

               </Stack>

               <Stack direction="row" alignItems="center" spacing={1}>
                  {damageFront.map((image, index) => (
                     <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('damageFront', true, damageFront)}/>
                  ))}
               </Stack>




               <Stack direction="row" justifyContent="left" alignItems="left" padding={0}>
                  <Typography sx={{my: 'auto'}}>Close Up 1 (Front)</Typography>

                  <Stack direction="row" justifyContent="right" alignItems="right" padding={2} sx={{ml: 'auto', width: '200px'}}>
                     <IconButton color="primary" aria-label="upload picture" component="label"
                                 onClick={ () => {
                                    dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'closeUp1Front', page: 'testSquare'} })
                                 }}>
                        <PhotoCamera />
                     </IconButton>
                     <IconButton color="primary" aria-label="upload picture" component="label">
                        <input
                           type="file" accept="image/*" multiple hidden name="myImage"
                           onChange={(event) => {

                              console.log('event.target.files:')
                              console.log(event.target.files)

                              const files = event.target.files
                              let photos = []

                              //files.forEach((file) => {
                              for (let i = 0; i < files.length; i++) {
                                 reduce.toBlob(files[i], { max: 900 }).then(blob => {
                                    const wrapSaveImage = async (dataUri) => {
                                       //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                                       const wrapper = async () => {
                                          const base64 = await toBase64(dataUri);
                                          const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', ''), generateUniqueName: true}
                                          const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                          dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, closeUp1Front: [...closeUp1Front, ...photos, savedPhotoData]} })
                                          //dispatch({ type: 'SET_UPDATE_PHOTO_FOR_JOBSITE' })

                                          const updatedPhotos = [...photos, savedPhotoData]
                                          setCloseUp1Front([...closeUp1Front, ...updatedPhotos])
                                          photos.push(savedPhotoData)
                                       }
                                       wrapper()
                                    }
                                    wrapSaveImage(blob)
                                 });
                              }


                           }}
                        />
                        <GalleryIcon />
                     </IconButton>
                  </Stack>

               </Stack>

               <Stack direction="row" alignItems="center" spacing={1}>
                  {closeUp1Front.map((image, index) => (
                     <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('closeUp1Front', true, closeUp1Front)}/>
                  ))}
               </Stack>



               <Stack direction="row" justifyContent="left" alignItems="left" padding={0}>
                  <Typography sx={{my: 'auto'}}>Close Up 2 (Front)</Typography>

                  <Stack direction="row" justifyContent="right" alignItems="right" padding={2} sx={{ml: 'auto', width: '200px'}}>
                     <IconButton color="primary" aria-label="upload picture" component="label"
                                 onClick={ () => {
                                    dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'closeUp2Front', page: 'testSquare'} })
                                 }}>
                        <PhotoCamera />
                     </IconButton>
                     <IconButton color="primary" aria-label="upload picture" component="label">
                        <input
                           type="file" accept="image/*" multiple hidden name="myImage"
                           onChange={(event) => {

                              console.log('event.target.files:')
                              console.log(event.target.files)

                              const files = event.target.files
                              let photos = []

                              //files.forEach((file) => {
                              for (let i = 0; i < files.length; i++) {
                                 reduce.toBlob(files[i], { max: 900 }).then(blob => {
                                    const wrapSaveImage = async (dataUri) => {
                                       //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                                       const wrapper = async () => {
                                          const base64 = await toBase64(dataUri);
                                          const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', ''), generateUniqueName: true}
                                          const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                          dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, closeUp2Front: [...closeUp2Front, ...photos, savedPhotoData]} })
                                          //dispatch({ type: 'SET_UPDATE_PHOTO_FOR_JOBSITE' })

                                          const updatedPhotos = [...photos, savedPhotoData]
                                          setCloseUp2Front([...closeUp2Front, ...updatedPhotos])
                                          photos.push(savedPhotoData)
                                       }
                                       wrapper()
                                    }
                                    wrapSaveImage(blob)
                                 });
                              }


                           }}
                        />
                        <GalleryIcon />
                     </IconButton>
                  </Stack>

               </Stack>

               <Stack direction="row" alignItems="center" spacing={1}>
                  {closeUp2Front.map((image, index) => (
                     <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('closeUp2Front', true, closeUp2Front)}/>
                  ))}
               </Stack>



               <TextField sx={{mx: 'auto'}} id="other" label="Description (Front)" variant="outlined"
                          value={descriptionFront}
                          onChange={(event) => {
                             setDescriptionFront(event.target.value)
                          }}
               />

               <Stack direction="row" justifyContent="left" alignItems="left"  spacing={2}>
                  <Button variant="text" onClick={() => setHallImpactsFront(Number(hallImpactsFront)-1) }><RemoveCircleOutlineIcon/></Button>
                  <TextField sx={{width: "250px"}} id="other" label="Hall Impacts (Front)" variant="outlined"
                             value={hallImpactsFront}
                             onChange={(event) => {
                                setHallImpactsFront(event.target.value)
                             }}
                  />
                  <Button variant="text" onClick={() => setHallImpactsFront(Number(hallImpactsFront)+1) }><AddCircleOutlineIcon/></Button>
               </Stack>

               <Stack direction="row" justifyContent="left" alignItems="left"  spacing={2}>
                  <Button variant="text" onClick={() => setWindDamageFront(Number(windDamageFront)-1) }><RemoveCircleOutlineIcon/></Button>
                  <TextField sx={{width: "250px"}} id="other" label="Wind Damage, % (Front)" variant="outlined"
                             value={windDamageFront}
                             onChange={(event) => {
                                setWindDamageFront(event.target.value)
                             }}
                  />
                  <Button variant="text" onClick={() => setWindDamageFront(Number(windDamageFront)+1) }><AddCircleOutlineIcon/></Button>
               </Stack>

            </Stack>

{/*Right*/}

            <Stack
               direction="column"
               justifyContent="left"
               alignItems="left"
               padding={2}
               spacing={1}
            >

               <Stack direction="row" justifyContent="left" alignItems="left" padding={0}>
                  <Typography sx={{my: 'auto'}}>Overall Test Square (Right)</Typography>

                  <Stack direction="row" justifyContent="right" alignItems="right" padding={2} sx={{ml: 'auto', width: '200px'}}>
                     <IconButton color="primary" aria-label="upload picture" component="label"
                                 onClick={ () => {
                                    dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'overallTestSquareRight', page: 'testSquare'} })
                                 }}>
                        <PhotoCamera />
                     </IconButton>
                     <IconButton color="primary" aria-label="upload picture" component="label">
                        <input
                           type="file" accept="image/*" multiple hidden name="myImage"
                           onChange={(event) => {

                              console.log('event.target.files:')
                              console.log(event.target.files)

                              const files = event.target.files
                              let photos = []

                              //files.forEach((file) => {
                              for (let i = 0; i < files.length; i++) {
                                 reduce.toBlob(files[i], { max: 900 }).then(blob => {
                                    const wrapSaveImage = async (dataUri) => {
                                       //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                                       const wrapper = async () => {
                                          const base64 = await toBase64(dataUri);
                                          const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', ''), generateUniqueName: true}
                                          const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                          dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, overallTestSquareRight: [...overallTestSquareRight, ...photos, savedPhotoData]} })
                                          //dispatch({ type: 'SET_UPDATE_PHOTO_FOR_JOBSITE' })

                                          const updatedPhotos = [...photos, savedPhotoData]
                                          setOverallTestSquareRight([...overallTestSquareRight, ...updatedPhotos])
                                          photos.push(savedPhotoData)
                                       }
                                       wrapper()
                                    }
                                    wrapSaveImage(blob)
                                 });
                              }


                           }}
                        />
                        <GalleryIcon />
                     </IconButton>
                  </Stack>

               </Stack>

               <Stack direction="row" alignItems="center" spacing={1}>
                  {overallTestSquareRight.map((image, index) => (
                     <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('overallTestSquareRight', true, overallTestSquareRight)}/>
                  ))}
               </Stack>



               <Stack direction="row" justifyContent="left" alignItems="left" padding={0}>
                  <Typography sx={{my: 'auto'}}>Damage (Right)</Typography>

                  <Stack direction="row" justifyContent="right" alignItems="right" padding={2} sx={{ml: 'auto', width: '200px'}}>
                     <IconButton color="primary" aria-label="upload picture" component="label"
                                 onClick={ () => {
                                    dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'damageRight', page: 'testSquare'} })
                                 }}>
                        <PhotoCamera />
                     </IconButton>
                     <IconButton color="primary" aria-label="upload picture" component="label">
                        <input
                           type="file" accept="image/*" multiple hidden name="myImage"
                           onChange={(event) => {

                              console.log('event.target.files:')
                              console.log(event.target.files)

                              const files = event.target.files
                              let photos = []

                              //files.forEach((file) => {
                              for (let i = 0; i < files.length; i++) {
                                 reduce.toBlob(files[i], { max: 900 }).then(blob => {
                                    const wrapSaveImage = async (dataUri) => {
                                       //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                                       const wrapper = async () => {
                                          const base64 = await toBase64(dataUri);
                                          const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', ''), generateUniqueName: true}
                                          const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                          dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, damageRight: [...damageRight, ...photos, savedPhotoData]} })
                                          //dispatch({ type: 'SET_UPDATE_PHOTO_FOR_JOBSITE' })

                                          const updatedPhotos = [...photos, savedPhotoData]
                                          setDamageRight([...damageRight, ...updatedPhotos])
                                          photos.push(savedPhotoData)
                                       }
                                       wrapper()
                                    }
                                    wrapSaveImage(blob)
                                 });
                              }


                           }}
                        />
                        <GalleryIcon />
                     </IconButton>
                  </Stack>

               </Stack>

               <Stack direction="row" alignItems="center" spacing={1}>
                  {damageRight.map((image, index) => (
                     <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('damageRight', true, damageRight)}/>
                  ))}
               </Stack>



               <Stack direction="row" justifyContent="left" alignItems="left" padding={0}>
                  <Typography sx={{my: 'auto'}}>Close Up 1 (Right)</Typography>

                  <Stack direction="row" justifyContent="right" alignItems="right" padding={2} sx={{ml: 'auto', width: '200px'}}>
                     <IconButton color="primary" aria-label="upload picture" component="label"
                                 onClick={ () => {
                                    dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'closeUp1Right', page: 'testSquare'} })
                                 }}>
                        <PhotoCamera />
                     </IconButton>
                     <IconButton color="primary" aria-label="upload picture" component="label">
                        <input
                           type="file" accept="image/*" multiple hidden name="myImage"
                           onChange={(event) => {

                              console.log('event.target.files:')
                              console.log(event.target.files)

                              const files = event.target.files
                              let photos = []

                              //files.forEach((file) => {
                              for (let i = 0; i < files.length; i++) {
                                 reduce.toBlob(files[i], { max: 900 }).then(blob => {
                                    const wrapSaveImage = async (dataUri) => {
                                       //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                                       const wrapper = async () => {
                                          const base64 = await toBase64(dataUri);
                                          const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', ''), generateUniqueName: true}
                                          const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                          dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, closeUp1Right: [...closeUp1Right, ...photos, savedPhotoData]} })
                                          //dispatch({ type: 'SET_UPDATE_PHOTO_FOR_JOBSITE' })

                                          const updatedPhotos = [...photos, savedPhotoData]
                                          setCloseUp1Right([...closeUp1Right, ...updatedPhotos])
                                          photos.push(savedPhotoData)
                                       }
                                       wrapper()
                                    }
                                    wrapSaveImage(blob)
                                 });
                              }


                           }}
                        />
                        <GalleryIcon />
                     </IconButton>
                  </Stack>

               </Stack>

               <Stack direction="row" alignItems="center" spacing={1}>
                  {closeUp1Right.map((image, index) => (
                     <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('closeUp1Right', true, closeUp1Right)}/>
                  ))}
               </Stack>


               <Stack direction="row" justifyContent="left" alignItems="left" padding={0}>
                  <Typography sx={{my: 'auto'}}>Close Up 2 (Right)</Typography>

                  <Stack direction="row" justifyContent="right" alignItems="right" padding={2} sx={{ml: 'auto', width: '200px'}}>
                     <IconButton color="primary" aria-label="upload picture" component="label"
                                 onClick={ () => {
                                    dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'closeUp2Right', page: 'testSquare'} })
                                 }}>
                        <PhotoCamera />
                     </IconButton>
                     <IconButton color="primary" aria-label="upload picture" component="label">
                        <input
                           type="file" accept="image/*" multiple hidden name="myImage"
                           onChange={(event) => {

                              console.log('event.target.files:')
                              console.log(event.target.files)

                              const files = event.target.files
                              let photos = []

                              //files.forEach((file) => {
                              for (let i = 0; i < files.length; i++) {
                                 reduce.toBlob(files[i], { max: 900 }).then(blob => {
                                    const wrapSaveImage = async (dataUri) => {
                                       //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                                       const wrapper = async () => {
                                          const base64 = await toBase64(dataUri);
                                          const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', ''), generateUniqueName: true}
                                          const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                          dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, closeUp2Right: [...closeUp2Right, ...photos, savedPhotoData]} })
                                          //dispatch({ type: 'SET_UPDATE_PHOTO_FOR_JOBSITE' })

                                          const updatedPhotos = [...photos, savedPhotoData]
                                          setCloseUp2Right([...closeUp2Right, ...updatedPhotos])
                                          photos.push(savedPhotoData)
                                       }
                                       wrapper()
                                    }
                                    wrapSaveImage(blob)
                                 });
                              }


                           }}
                        />
                        <GalleryIcon />
                     </IconButton>
                  </Stack>

               </Stack>

               <Stack direction="row" alignItems="center" spacing={1}>
                  {closeUp2Right.map((image, index) => (
                     <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('closeUp2Right', true, closeUp2Right)}/>
                  ))}
               </Stack>



               <TextField sx={{mx: 'auto'}} id="other" label="Description (Right)" variant="outlined"
                          value={descriptionRight}
                          onChange={(event) => {
                             setDescriptionRight(event.target.value)
                          }}
               />

               <Stack direction="row" justifyContent="left" alignItems="left"  spacing={2}>
                  <Button variant="text" onClick={() => setHallImpactsRight(Number(hallImpactsRight)-1) }><RemoveCircleOutlineIcon/></Button>
                  <TextField sx={{width: "250px"}} id="other" label="Hall Impacts (Right)" variant="outlined"
                             value={hallImpactsRight}
                             onChange={(event) => {
                                setHallImpactsRight(event.target.value)
                             }}
                  />
                  <Button variant="text" onClick={() => setHallImpactsRight(Number(hallImpactsRight)+1) }><AddCircleOutlineIcon/></Button>
               </Stack>

               <Stack direction="row" justifyContent="left" alignItems="left"  spacing={2}>
                  <Button variant="text" onClick={() => setWindDamageRight(Number(windDamageRight)-1) }><RemoveCircleOutlineIcon/></Button>
                  <TextField sx={{width: "250px"}} id="other" label="Wind Damage, % (Right)" variant="outlined"
                             value={windDamageRight}
                             onChange={(event) => {
                                setWindDamageRight(event.target.value)
                             }}
                  />
                  <Button variant="text" onClick={() => setWindDamageRight(Number(windDamageRight)+1) }><AddCircleOutlineIcon/></Button>
               </Stack>

            </Stack>


            
            
{/*Back*/}
            <Stack
               direction="column"
               justifyContent="left"
               alignItems="left"
               padding={2}
               spacing={1}
            >

               <Stack direction="row" justifyContent="left" alignItems="left" padding={0}>
                  <Typography sx={{my: 'auto'}}>Overall Test Square (Back)</Typography>

                  <Stack direction="row" justifyContent="Back" alignItems="Back" padding={2} sx={{ml: 'auto', width: '200px'}}>
                     <IconButton color="primary" aria-label="upload picture" component="label"
                                 onClick={ () => {
                                    dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'overallTestSquareBack', page: 'testSquare'} })
                                 }}>
                        <PhotoCamera />
                     </IconButton>
                     <IconButton color="primary" aria-label="upload picture" component="label">
                        <input
                           type="file" accept="image/*" multiple hidden name="myImage"
                           onChange={(event) => {

                              console.log('event.target.files:')
                              console.log(event.target.files)

                              const files = event.target.files
                              let photos = []

                              //files.forEach((file) => {
                              for (let i = 0; i < files.length; i++) {
                                 reduce.toBlob(files[i], { max: 900 }).then(blob => {
                                    const wrapSaveImage = async (dataUri) => {
                                       //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                                       const wrapper = async () => {
                                          const base64 = await toBase64(dataUri);
                                          const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', ''), generateUniqueName: true}
                                          const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                          dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, overallTestSquareBack: [...overallTestSquareBack, ...photos, savedPhotoData]} })
                                          //dispatch({ type: 'SET_UPDATE_PHOTO_FOR_JOBSITE' })

                                          const updatedPhotos = [...photos, savedPhotoData]
                                          setOverallTestSquareBack([...overallTestSquareBack, ...updatedPhotos])
                                          photos.push(savedPhotoData)
                                       }
                                       wrapper()
                                    }
                                    wrapSaveImage(blob)
                                 });
                              }


                           }}
                        />
                        <GalleryIcon />
                     </IconButton>
                  </Stack>

               </Stack>

               <Stack direction="row" alignItems="center" spacing={1}>
                  {overallTestSquareBack.map((image, index) => (
                     <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('overallTestSquareBack', true, overallTestSquareBack)}/>
                  ))}
               </Stack>



               <Stack direction="row" justifyContent="left" alignItems="left" padding={0}>
                  <Typography sx={{my: 'auto'}}>Damage (Back)</Typography>

                  <Stack direction="row" justifyContent="Back" alignItems="Back" padding={2} sx={{ml: 'auto', width: '200px'}}>
                     <IconButton color="primary" aria-label="upload picture" component="label"
                                 onClick={ () => {
                                    dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'damageBack', page: 'testSquare'} })
                                 }}>
                        <PhotoCamera />
                     </IconButton>
                     <IconButton color="primary" aria-label="upload picture" component="label">
                        <input
                           type="file" accept="image/*" multiple hidden name="myImage"
                           onChange={(event) => {

                              console.log('event.target.files:')
                              console.log(event.target.files)

                              const files = event.target.files
                              let photos = []

                              //files.forEach((file) => {
                              for (let i = 0; i < files.length; i++) {
                                 reduce.toBlob(files[i], { max: 900 }).then(blob => {
                                    const wrapSaveImage = async (dataUri) => {
                                       //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                                       const wrapper = async () => {
                                          const base64 = await toBase64(dataUri);
                                          const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', ''), generateUniqueName: true}
                                          const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                          dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, damageBack: [...damageBack, ...photos, savedPhotoData]} })
                                          //dispatch({ type: 'SET_UPDATE_PHOTO_FOR_JOBSITE' })

                                          const updatedPhotos = [...photos, savedPhotoData]
                                          setDamageBack([...damageBack, ...updatedPhotos])
                                          photos.push(savedPhotoData)
                                       }
                                       wrapper()
                                    }
                                    wrapSaveImage(blob)
                                 });
                              }


                           }}
                        />
                        <GalleryIcon />
                     </IconButton>
                  </Stack>

               </Stack>

               <Stack direction="row" alignItems="center" spacing={1}>
                  {damageBack.map((image, index) => (
                     <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('damageBack', true, damageBack)}/>
                  ))}
               </Stack>



               <Stack direction="row" justifyContent="left" alignItems="left" padding={0}>
                  <Typography sx={{my: 'auto'}}>Close Up 1 (Back)</Typography>

                  <Stack direction="row" justifyContent="Back" alignItems="Back" padding={2} sx={{ml: 'auto', width: '200px'}}>
                     <IconButton color="primary" aria-label="upload picture" component="label"
                                 onClick={ () => {
                                    dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'closeUp1Back', page: 'testSquare'} })
                                 }}>
                        <PhotoCamera />
                     </IconButton>
                     <IconButton color="primary" aria-label="upload picture" component="label">
                        <input
                           type="file" accept="image/*" multiple hidden name="myImage"
                           onChange={(event) => {

                              console.log('event.target.files:')
                              console.log(event.target.files)

                              const files = event.target.files
                              let photos = []

                              //files.forEach((file) => {
                              for (let i = 0; i < files.length; i++) {
                                 reduce.toBlob(files[i], { max: 900 }).then(blob => {
                                    const wrapSaveImage = async (dataUri) => {
                                       //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                                       const wrapper = async () => {
                                          const base64 = await toBase64(dataUri);
                                          const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', ''), generateUniqueName: true}
                                          const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                          dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, closeUp1Back: [...closeUp1Back, ...photos, savedPhotoData]} })
                                          //dispatch({ type: 'SET_UPDATE_PHOTO_FOR_JOBSITE' })

                                          const updatedPhotos = [...photos, savedPhotoData]
                                          setCloseUp1Back([...closeUp1Back, ...updatedPhotos])
                                          photos.push(savedPhotoData)
                                       }
                                       wrapper()
                                    }
                                    wrapSaveImage(blob)
                                 });
                              }


                           }}
                        />
                        <GalleryIcon />
                     </IconButton>
                  </Stack>

               </Stack>

               <Stack direction="row" alignItems="center" spacing={1}>
                  {closeUp1Back.map((image, index) => (
                     <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('closeUp1Back', true, closeUp1Back)}/>
                  ))}
               </Stack>



               <Stack direction="row" justifyContent="left" alignItems="left" padding={0}>
                  <Typography sx={{my: 'auto'}}>Close Up 2 (Back)</Typography>

                  <Stack direction="row" justifyContent="Back" alignItems="Back" padding={2} sx={{ml: 'auto', width: '200px'}}>
                     <IconButton color="primary" aria-label="upload picture" component="label"
                                 onClick={ () => {
                                    dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'closeUp2Back', page: 'testSquare'} })
                                 }}>
                        <PhotoCamera />
                     </IconButton>
                     <IconButton color="primary" aria-label="upload picture" component="label">
                        <input
                           type="file" accept="image/*" multiple hidden name="myImage"
                           onChange={(event) => {

                              console.log('event.target.files:')
                              console.log(event.target.files)

                              const files = event.target.files
                              let photos = []

                              //files.forEach((file) => {
                              for (let i = 0; i < files.length; i++) {
                                 reduce.toBlob(files[i], { max: 900 }).then(blob => {
                                    const wrapSaveImage = async (dataUri) => {
                                       //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                                       const wrapper = async () => {
                                          const base64 = await toBase64(dataUri);
                                          const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', ''), generateUniqueName: true}
                                          const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                          dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, closeUp2Back: [...closeUp2Back, ...photos, savedPhotoData]} })
                                          //dispatch({ type: 'SET_UPDATE_PHOTO_FOR_JOBSITE' })

                                          const updatedPhotos = [...photos, savedPhotoData]
                                          setCloseUp2Back([...closeUp2Back, ...updatedPhotos])
                                          photos.push(savedPhotoData)
                                       }
                                       wrapper()
                                    }
                                    wrapSaveImage(blob)
                                 });
                              }


                           }}
                        />
                        <GalleryIcon />
                     </IconButton>
                  </Stack>

               </Stack>

               <Stack direction="row" alignItems="center" spacing={1}>
                  {closeUp2Back.map((image, index) => (
                     <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('closeUp2Back', true, closeUp2Back)}/>
                  ))}
               </Stack>



               <TextField sx={{mx: 'auto'}} id="other" label="Description (Back)" variant="outlined"
                          value={descriptionBack}
                          onChange={(event) => {
                             setDescriptionBack(event.target.value)
                          }}
               />

               <Stack direction="row" justifyContent="left" alignItems="left"  spacing={2}>
                  <Button variant="text" onClick={() => setHallImpactsBack(Number(hallImpactsBack)-1) }><RemoveCircleOutlineIcon/></Button>
                  <TextField sx={{width: "250px"}} id="other" label="Hall Impacts (Back)" variant="outlined"
                             value={hallImpactsBack}
                             onChange={(event) => {
                                setHallImpactsBack(event.target.value)
                             }}
                  />
                  <Button variant="text" onClick={() => setHallImpactsBack(Number(hallImpactsBack)+1) }><AddCircleOutlineIcon/></Button>
               </Stack>

               <Stack direction="row" justifyContent="left" alignItems="left"  spacing={2}>
                  <Button variant="text" onClick={() => setWindDamageBack(Number(windDamageBack)-1) }><RemoveCircleOutlineIcon/></Button>
                  <TextField sx={{width: "250px"}} id="other" label="Wind Damage, % (Back)" variant="outlined"
                             value={windDamageBack}
                             onChange={(event) => {
                                setWindDamageBack(event.target.value)
                             }}
                  />
                  <Button variant="text" onClick={() => setWindDamageBack(Number(windDamageBack)+1) }><AddCircleOutlineIcon/></Button>
               </Stack>

            </Stack>

            
            
{/*Left*/}

            <Stack
               direction="column"
               justifyContent="left"
               alignItems="left"
               padding={2}
               spacing={1}
            >

               <Stack direction="row" justifyContent="left" alignItems="left" padding={0}>
                  <Typography sx={{my: 'auto'}}>Overall Test Square (Left)</Typography>

                  <Stack direction="row" justifyContent="Left" alignItems="Left" padding={2} sx={{ml: 'auto', width: '200px'}}>
                     <IconButton color="primary" aria-label="upload picture" component="label"
                                 onClick={ () => {
                                    dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'overallTestSquareLeft', page: 'testSquare'} })
                                 }}>
                        <PhotoCamera />
                     </IconButton>
                     <IconButton color="primary" aria-label="upload picture" component="label">
                        <input
                           type="file" accept="image/*" multiple hidden name="myImage"
                           onChange={(event) => {

                              console.log('event.target.files:')
                              console.log(event.target.files)

                              const files = event.target.files
                              let photos = []

                              //files.forEach((file) => {
                              for (let i = 0; i < files.length; i++) {
                                 reduce.toBlob(files[i], { max: 900 }).then(blob => {
                                    const wrapSaveImage = async (dataUri) => {
                                       //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                                       const wrapper = async () => {
                                          const base64 = await toBase64(dataUri);
                                          const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', ''), generateUniqueName: true}
                                          const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                          dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, overallTestSquareLeft: [...overallTestSquareLeft, ...photos, savedPhotoData]} })
                                          //dispatch({ type: 'SET_UPDATE_PHOTO_FOR_JOBSITE' })

                                          const updatedPhotos = [...photos, savedPhotoData]
                                          setOverallTestSquareLeft([...overallTestSquareLeft, ...updatedPhotos])
                                          photos.push(savedPhotoData)
                                       }
                                       wrapper()
                                    }
                                    wrapSaveImage(blob)
                                 });
                              }


                           }}
                        />
                        <GalleryIcon />
                     </IconButton>
                  </Stack>

               </Stack>

               <Stack direction="row" alignItems="center" spacing={1}>
                  {overallTestSquareLeft.map((image, index) => (
                     <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('overallTestSquareLeft', true, overallTestSquareLeft)}/>
                  ))}
               </Stack>



               <Stack direction="row" justifyContent="left" alignItems="left" padding={0}>
                  <Typography sx={{my: 'auto'}}>Damage (Left)</Typography>

                  <Stack direction="row" justifyContent="Left" alignItems="Left" padding={2} sx={{ml: 'auto', width: '200px'}}>
                     <IconButton color="primary" aria-label="upload picture" component="label"
                                 onClick={ () => {
                                    dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'damageLeft', page: 'testSquare'} })
                                 }}>
                        <PhotoCamera />
                     </IconButton>
                     <IconButton color="primary" aria-label="upload picture" component="label">
                        <input
                           type="file" accept="image/*" multiple hidden name="myImage"
                           onChange={(event) => {

                              console.log('event.target.files:')
                              console.log(event.target.files)

                              const files = event.target.files
                              let photos = []

                              //files.forEach((file) => {
                              for (let i = 0; i < files.length; i++) {
                                 reduce.toBlob(files[i], { max: 900 }).then(blob => {
                                    const wrapSaveImage = async (dataUri) => {
                                       //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                                       const wrapper = async () => {
                                          const base64 = await toBase64(dataUri);
                                          const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', ''), generateUniqueName: true}
                                          const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                          dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, damageLeft: [...damageLeft, ...photos, savedPhotoData]} })
                                          //dispatch({ type: 'SET_UPDATE_PHOTO_FOR_JOBSITE' })

                                          const updatedPhotos = [...photos, savedPhotoData]
                                          setDamageLeft([...damageLeft, ...updatedPhotos])
                                          photos.push(savedPhotoData)
                                       }
                                       wrapper()
                                    }
                                    wrapSaveImage(blob)
                                 });
                              }


                           }}
                        />
                        <GalleryIcon />
                     </IconButton>
                  </Stack>

               </Stack>

               <Stack direction="row" alignItems="center" spacing={1}>
                  {damageLeft.map((image, index) => (
                     <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('damageLeft', true, damageLeft)}/>
                  ))}
               </Stack>



               <Stack direction="row" justifyContent="left" alignItems="left" padding={0}>
                  <Typography sx={{my: 'auto'}}>Close Up 1 (Left)</Typography>

                  <Stack direction="row" justifyContent="Left" alignItems="Left" padding={2} sx={{ml: 'auto', width: '200px'}}>
                     <IconButton color="primary" aria-label="upload picture" component="label"
                                 onClick={ () => {
                                    dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'closeUp1Left', page: 'testSquare'} })
                                 }}>
                        <PhotoCamera />
                     </IconButton>
                     <IconButton color="primary" aria-label="upload picture" component="label">
                        <input
                           type="file" accept="image/*" multiple hidden name="myImage"
                           onChange={(event) => {

                              console.log('event.target.files:')
                              console.log(event.target.files)

                              const files = event.target.files
                              let photos = []

                              //files.forEach((file) => {
                              for (let i = 0; i < files.length; i++) {
                                 reduce.toBlob(files[i], { max: 900 }).then(blob => {
                                    const wrapSaveImage = async (dataUri) => {
                                       //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                                       const wrapper = async () => {
                                          const base64 = await toBase64(dataUri);
                                          const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', ''), generateUniqueName: true}
                                          const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                          dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, closeUp1Left: [...closeUp1Left, ...photos, savedPhotoData]} })
                                          //dispatch({ type: 'SET_UPDATE_PHOTO_FOR_JOBSITE' })

                                          const updatedPhotos = [...photos, savedPhotoData]
                                          setCloseUp1Left([...closeUp1Left, ...updatedPhotos])
                                          photos.push(savedPhotoData)
                                       }
                                       wrapper()
                                    }
                                    wrapSaveImage(blob)
                                 });
                              }


                           }}
                        />
                        <GalleryIcon />
                     </IconButton>
                  </Stack>

               </Stack>

               <Stack direction="column" alignItems="center" spacing={1}>
                  {closeUp1Left.map((image, index) => (
                     <Image src={image.DETAIL_URL} showLoading width={400} style={{ borderRadius: 16 }} />
                  ))}
               </Stack>

               <Stack direction="row" alignItems="center" spacing={1}>
                  {closeUp1Left.map((image, index) => (
                     <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('closeUp1Left', true, closeUp1Left)}/>
                  ))}
               </Stack>



               <Stack direction="row" justifyContent="left" alignItems="left" padding={0}>
                  <Typography sx={{my: 'auto'}}>Close Up 2 (Left)</Typography>

                  <Stack direction="row" justifyContent="Left" alignItems="Left" padding={2} sx={{ml: 'auto', width: '200px'}}>
                     <IconButton color="primary" aria-label="upload picture" component="label"
                                 onClick={ () => {
                                    dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'closeUp2Left', page: 'testSquare'} })
                                 }}>
                        <PhotoCamera />
                     </IconButton>
                     <IconButton color="primary" aria-label="upload picture" component="label">
                        <input
                           type="file" accept="image/*" multiple hidden name="myImage"
                           onChange={(event) => {

                              console.log('event.target.files:')
                              console.log(event.target.files)

                              const files = event.target.files
                              let photos = []

                              //files.forEach((file) => {
                              for (let i = 0; i < files.length; i++) {
                                 reduce.toBlob(files[i], { max: 900 }).then(blob => {
                                    const wrapSaveImage = async (dataUri) => {
                                       //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                                       const wrapper = async () => {
                                          const base64 = await toBase64(dataUri);
                                          const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', ''), generateUniqueName: true}
                                          const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                          dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, closeUp2Left: [...closeUp2Left, ...photos, savedPhotoData]} })
                                          //dispatch({ type: 'SET_UPDATE_PHOTO_FOR_JOBSITE' })

                                          const updatedPhotos = [...photos, savedPhotoData]
                                          setCloseUp2Left([...closeUp2Left, ...updatedPhotos])
                                          photos.push(savedPhotoData)
                                       }
                                       wrapper()
                                    }
                                    wrapSaveImage(blob)
                                 });
                              }


                           }}
                        />
                        <GalleryIcon />
                     </IconButton>
                  </Stack>

               </Stack>

               <Stack direction="row" alignItems="center" spacing={1}>
                  {closeUp2Left.map((image, index) => (
                     <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('closeUp2Left', true, closeUp2Left)}/>
                  ))}
               </Stack>



               <TextField sx={{mx: 'auto'}} id="other" label="Description (Left)" variant="outlined"
                          value={descriptionLeft}
                          onChange={(event) => {
                             setDescriptionLeft(event.target.value)
                          }}
               />

               <Stack direction="row" justifyContent="left" alignItems="left"  spacing={2}>
                  <Button variant="text" onClick={() => setHallImpactsLeft(Number(hallImpactsLeft)-1) }><RemoveCircleOutlineIcon/></Button>
                  <TextField sx={{width: "250px"}} id="other" label="Hall Impacts (Left)" variant="outlined"
                             value={hallImpactsLeft}
                             onChange={(event) => {
                                setHallImpactsLeft(event.target.value)
                             }}
                  />
                  <Button variant="text" onClick={() => setHallImpactsLeft(Number(hallImpactsLeft)+1) }><AddCircleOutlineIcon/></Button>
               </Stack>

               <Stack direction="row" justifyContent="left" alignItems="left"  spacing={2}>
                  <Button variant="text" onClick={() => setWindDamageLeft(Number(windDamageLeft)-1) }><RemoveCircleOutlineIcon/></Button>
                  <TextField sx={{width: "250px"}} id="other" label="Wind Damage, % (Left)" variant="outlined"
                             value={windDamageLeft}
                             onChange={(event) => {
                                setWindDamageLeft(event.target.value)
                             }}
                  />
                  <Button variant="text" onClick={() => setWindDamageLeft(Number(windDamageLeft)+1) }><AddCircleOutlineIcon/></Button>
               </Stack>

            </Stack>            



         </Stack>
      </>

   );
}