import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import {useContext, useEffect} from "react";
import ECContext from "../context/inspections/ECContext";
import {getB24accessToken, getDealsTest, getInspections, runB24apiMethod} from "../context/inspections/ECActions";
import {useNavigate} from "react-router-dom";

const Search = styled('div')(({ theme }) => ({
   position: 'relative',
   borderRadius: theme.shape.borderRadius,
   backgroundColor: alpha(theme.palette.common.white, 0.15),
   '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
   },
   marginLeft: 0,
   width: '100%',

}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
   padding: theme.spacing(0, 2),
   height: '100%',
   position: 'absolute',
   pointerEvents: 'none',
   display: 'flex',
   alignItems: 'center',
   justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
   color: 'inherit',
   width: '100%',
   '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 1),
      // vertical padding + font size from searchIcon
      //paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      //transition: theme.transitions.create('width'),
      width: '100%',
   },
}));

export default function SearchAppBar() {

   const { dispatch, access_token_data, isUserAuthorized, flagNeedToRefreshInspectionsList } = useContext(ECContext)
   const navigate = useNavigate();
   const [searchText, setSearchText] = React.useState('');

   useEffect(() => {

// uncomment this after test
      dispatch({ type: 'SET_LOADING' })
// and remove this:
      //dispatch({ type: 'SET_USER_AUTHORIZED' })


      async function getAuthorization() {
         const params = new URLSearchParams(window.location.search);

         if(Object.keys(access_token_data).length === 0 && params.get("code") == null) {

            console.log('--- performing redirect to B24 OAuth server')
            window.location.href = 'https://estateclaim.bitrix24.com/oauth/authorize/?client_id=local.63f66464884702.74717056'
         }
         else if (Object.keys(access_token_data).length === 0 && params.get("code") != null) {

            console.log('--- Need to get access_token')
            console.log('--- current access_token_data: ')
            console.log(access_token_data)
            async function getToken(code) {
               const b24_access_token_data = await getB24accessToken(code)

               dispatch({ type: 'SET_B24_ACCESS_TOKEN', payload: b24_access_token_data })
               dispatch({ type: 'SET_USER_AUTHORIZED'})

               navigate('/')
            }
            await getToken(params.get('code'));
         }
      }
//uncomment this after test
      getAuthorization();

   }, []);

   useEffect(() => {

      async function getInspectionsFromB24(searchCriteria = '') {
         if(isUserAuthorized === true) {
            // getting inspection element from B24 List
            console.log("The user's authorized. Getting inspection element from B24 List.")

            const managersIDs = [1, 4, 63]
            let filter = {}
            let filterForSecondaryRespInspections = {}

            if(managersIDs.indexOf(access_token_data.user_id) !== -1) {
               filter = {
                  PROPERTY_795: 189, // в списке инстпекций показываем только по главным строениям
                  '!NAME': '  '  // Name не должно быть пусто (это пустые Inspections и мы их не показываем
               }
            }
            else {
               filter = {
                  PROPERTY_795: 189, // в списке инстпекций показываем только по главным строениям
                  '!NAME': '  ',  // Name не должно быть пусто (это пустые Inspections и мы их не показываем
                  PROPERTY_1111: access_token_data.user_id
               }
               filterForSecondaryRespInspections = {
                  PROPERTY_795: 189, // в списке инстпекций показываем только по главным строениям
                  '!NAME': '  ',  // Name не должно быть пусто (это пустые Inspections и мы их не показываем
                  PROPERTY_1313: access_token_data.user_id // фильтра для поиска Inspetions, где тек. пользователь SecondaryRespPerson
               }
            }

            const params = {
               'IBLOCK_TYPE_ID': 'lists',
               'IBLOCK_ID': '101',
               'FILTER': filter,
               'ELEMENT_ORDER': {
                  'PROPERTY_1311': 'DESC'
               }
            };
            const inspections = await runB24apiMethod(access_token_data, "lists.element.get", params)

            const paramsSRP = {
               'IBLOCK_TYPE_ID': 'lists',
               'IBLOCK_ID': '101',
               'FILTER': filterForSecondaryRespInspections,
               'ELEMENT_ORDER': {
                  'PROPERTY_1311': 'DESC'
               }
            };
            const inspectionsSRP = await runB24apiMethod(access_token_data, "lists.element.get", paramsSRP)

            // теперь объединим две полученные выборки (Inspection, где тек. пользователь главный ответственный и те, в который он SRP (second responsible person)
            const totalInspections = inspections.result.concat(inspectionsSRP.result)


            dispatch({ type: 'SET_INSPECTIONS', payload: totalInspections })
            //dispatch({ type: 'SET_INSPECTIONS', payload: inspections.result })


// and remove these two rows
//             const inspections = [
//             {
//                "ID": "399",
//                "IBLOCK_ID": "101",
//                "NAME": "Test Inspection1",
//                "IBLOCK_SECTION_ID": null,
//                "CREATED_BY": "4",
//                "BP_PUBLISHED": "Y",
//                "CODE": null,
//                "DETAIL_PICTURE": null,
//                "PROPERTY_789": {
//                   "2137": "D_4691"
//                },
//                "PROPERTY_795": {
//                   "2141": "189"
//                },
//                "PROPERTY_799": {
//                   "2143": "Denis"
//                }
//             },
//                {
//                   "ID": "401",
//                   "IBLOCK_ID": "101",
//                   "NAME": "Test Inspection2",
//                   "IBLOCK_SECTION_ID": null,
//                   "CREATED_BY": "4",
//                   "BP_PUBLISHED": "Y",
//                   "CODE": null,
//                   "DETAIL_PICTURE": null,
//                   "PROPERTY_795": {
//                      "2125": "189"
//                   }
//                },
//                {
//                   "ID": "403",
//                   "IBLOCK_ID": "101",
//                   "NAME": "Test Inspection3",
//                   "IBLOCK_SECTION_ID": null,
//                   "CREATED_BY": "4",
//                   "BP_PUBLISHED": "Y",
//                   "CODE": null,
//                   "DETAIL_PICTURE": null,
//                   "PROPERTY_795": {
//                      "2127": "189"
//                   }
//                },
//                {
//                   "ID": "405",
//                   "IBLOCK_ID": "101",
//                   "NAME": "additional structure name",
//                   "IBLOCK_SECTION_ID": null,
//                   "CREATED_BY": "4",
//                   "BP_PUBLISHED": "Y",
//                   "CODE": null,
//                   "DETAIL_PICTURE": null,
//                   "PROPERTY_795": {
//                      "2135": "191"
//                   },
//                   "PROPERTY_797": {
//                      "2133": "399"
//                   }
//                }
//             ]
//             dispatch({ type: 'SET_INSPECTIONS', payload: inspections })

         }
      }
      getInspectionsFromB24()

   }, [isUserAuthorized]);



   useEffect(() => {

      if(flagNeedToRefreshInspectionsList == true)
         getFilteredInspectionsFromB24()

   }, [flagNeedToRefreshInspectionsList]);



   const getFilteredInspectionsFromB24 =  async () => {

      if(isUserAuthorized === true) {
         // getting inspection element from B24 List
         console.log("Getting inspections using filter")

         const managersIDs = [1, 4, 63]
         let filter = {}
         let filterForSecondaryRespInspections = {}

         if(managersIDs.indexOf(access_token_data.user_id) !== -1) {
            filter = {
               PROPERTY_795: 189, // в списке инстпекций показываем только по главным строениям
               NAME: '%' + searchText + '%',
            }
         }
         else {
            filter = {
               PROPERTY_795: 189, // в списке инстпекций показываем только по главным строениям
               NAME: '%' + searchText + '%',
               PROPERTY_1111: access_token_data.user_id,
            }
            filterForSecondaryRespInspections = {
               PROPERTY_795: 189, // в списке инстпекций показываем только по главным строениям
               NAME: '%' + searchText + '%',
               PROPERTY_1313: access_token_data.user_id // фильтра для поиска Inspetions, где тек. пользователь SecondaryRespPerson
            }
         }

         if(searchText.length == 0) {
            delete filter['NAME']
            filter['!NAME'] = '  '

            delete filterForSecondaryRespInspections['NAME']
            filterForSecondaryRespInspections['!NAME'] = '  '
         }




console.log('filter: ', filter)

         dispatch({ type: 'SET_LOADING' })

         const params = {
            'IBLOCK_TYPE_ID': 'lists',
            'IBLOCK_ID': '101',
            'FILTER': filter,
            'ELEMENT_ORDER': {
               'PROPERTY_1311': 'DESC',
            }
         };
         const inspections = await runB24apiMethod(access_token_data, "lists.element.get", params)

         const paramsSRP = {
            'IBLOCK_TYPE_ID': 'lists',
            'IBLOCK_ID': '101',
            'FILTER': filterForSecondaryRespInspections,
            'ELEMENT_ORDER': {
               'PROPERTY_1311': 'DESC'
            }
         };
         const inspectionsSRP = await runB24apiMethod(access_token_data, "lists.element.get", paramsSRP)

         // теперь объединим две полученные выборки (Inspection, где тек. пользователь главный ответственный и те, в который он SRP (second responsible person)
         const totalInspections = inspections.result.concat(inspectionsSRP.result)


console.log('lists.element.get (inspections) ', inspections);

         dispatch({ type: 'SET_INSPECTIONS', payload: totalInspections })
         //dispatch({ type: 'SET_INSPECTIONS', payload: inspections.result })
         dispatch({ type: 'REFRESH_INSPECTIONS_LIST' })

      }
   }

   const keyPress =  async (e) => {
      if(e.keyCode == 13){
         await getFilteredInspectionsFromB24(searchText)
      }
   }



   return (
      <Box sx={{ flexGrow: 1 }}>
         <AppBar position="static">
            <Toolbar>
{/*
               <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="open drawer"
                  sx={{ mr: 2 }}
               >
                  <MenuIcon />
               </IconButton>
*/}
{/*               <Typography
                  variant="h6"
                  noWrap
                  component="div"
                  sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
               >
                  ECS :: Inspections
               </Typography>*/}
               <Search>
{/*
                  <SearchIconWrapper>
                     <SearchIcon />
                  </SearchIconWrapper>
*/}
                  <StyledInputBase
                     placeholder="Inspections Search"
                     inputProps={{ 'aria-label': 'search' }}
                     value={searchText}
                     onChange={(event) => {
                        setSearchText(event.target.value)
                     }}
                     onKeyDown={keyPress}
                  />
               </Search>

               <IconButton type="button" sx={{ p: '10px' }} aria-label="search"
                  onClick={getFilteredInspectionsFromB24}
               >
                  <SearchIcon />
               </IconButton>


            </Toolbar>
         </AppBar>
      </Box>
   );
}