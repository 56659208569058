import { createContext, useReducer } from 'react'
import ECReducer from './ECReducer'

const ECContext = createContext()

export const InspectionsProvider = ({ children }) => {
  const initialState = {

      isDealWasCreated: false,

     isAutomaticSaving: false,

     flagNeedToRefreshInspectionsList: false,

     flagGeneralReadyToSave: false,
     flagJobsiteReadyToSave: false,
     flagElevationsReadyToSave: false,
     flagSidingReadyToSave: false,
     flagWindowsReadyToSave: false,
     flagGuttersReadyToSave: false,
     flagInteriorReadyToSave: false,
     flagRooffieldReafdyToSave: false,
     flagVentilationReadyToSave: false,
     flagTestSquareReadyToSave: false,
     flagRoofPhotosReadyToSave: false,
     flagSkylightsReadyToSave: false,

     flagNoPhotosReadyData: false,

     flagCleanAllControlsData: false,

     flagNeedToUpdatePhotosForGeneralBlock: false,
     flagNeedToUpdatePhotosForJobsiteBlock: false,
     flagNeedToUpdatePhotosForSidingBlock: false,
     flagNeedToUpdatePhotosForElevationsBlock: false,
     flagNeedToUpdatePhotosForWindowsBlock: false,
     flagNeedToUpdatePhotosForGuttersBlock: false,
     flagNeedToUpdatePhotosForInteriorBlock: false,
     flagNeedToUpdatePhotosForRoofFieldBlock: false,
     flagNeedToUpdatePhotosForSkyLightsBlock: false,
     flagNeedToUpdatePhotosForVentilationBlock: false,
     flagNeedToUpdatePhotosForTestSquareBlock: false,
     flagNeedToUpdatePhotosForRoofPhotosBlock: false,

     flagReloadDatafromInspectionAllData: '',
     flagReloadDataforLinkedDeal: '',
     flagReloadDataforNamesAndAddress: '',

     readyGENERALdata: {},
     readyJOBSITEdata: {},
     readyELEVATIONSdata: {},
     readySIDINGdata: {},
     readyWINDOWSdata: {},
     readyGUTTERSdata: {},
     readyINTERIORdata: {},
     readyROOFFIELDdata: {},
     readyVENTILATIONdata: {},
     readyTESTSQUAREdata: {},
     readyROOFPHOTOSdata: {},
     readySKYLIGHTSdata: {},

     inspectionAllData: {
        isNew: false,
        isAdditonalStructureInspection: false,
        inspectionId: '',
        photoFolderId: '',

//General Block
        photoOfFrontOfProperty: {DETAIL_URL: '', FILE_ID: '', ID: ''},

//Jobsite Block
        photosDoesTheDrivewayHaveExistingCracks: []

// ... Block

// ... Block


     },

     loading: false,
     isUserAuthorized: false,

     saveRequest: false,

     getNoPhotoDataRequest: false,

     inspections: [],
     editInspectionId: '',

     access_token_data: {
// for test purpose only!!!
//        access_token: ''
     },

     showCameraFor: {property: '', page: ''},
     showCamera: false,


     showInspectionDialog: false,
     inspectionGeneralBlockInfo: {},
     inspectionJobsiteBlockInfo: {},
     inspectionElevationsBlockInfo: {},
     inspectionElevationsSUBBlockInfo: {},
     inspectionRoofBlockInfo: {},
     inspectionDamageBlockInfo: {},
     inspectionRoofSUBBlockInfo: {},
     inspectionSidingBlockInfo: {},
     inspectionWindowsBlockInfo: {},
     inspectionGuttersBlockInfo: {},
     inspectionInteriorBlockInfo: {},
     inspectionPhotosBlockInfo: {},
     inspectionReportsBlockInfo: {}

  }

  const [state, dispatch] = useReducer(ECReducer, initialState)

  return (
    <ECContext.Provider
      value={{
        ...state,
        dispatch,
      }}
    >
      {children}
    </ECContext.Provider>
  )
}

export default ECContext
