import {
   Button, Drawer,
   FormControl,
   FormControlLabel,
   FormLabel, ImageList, ImageListItem, ListItemButton, ListItemIcon, Radio, RadioGroup, Stack,
   TextField
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {PhotoCamera} from "@mui/icons-material";
import Box from "@mui/material/Box";
import * as React from "react";
import {useContext, useEffect} from "react";
import ECContext from "../../context/inspections/ECContext";
import { Image } from 'mui-image'
import spinner from "../layout/assets/loading.gif";
import {getImageUrl, savePhotoIntoFolder} from "../../context/inspections/ECActions";
import GalleryIcon from "@mui/icons-material/CollectionsOutlined";
import reduce from "image-blob-reduce";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";


export default function Jobsite() {

   const { showCameraFor, flagReloadDatafromInspectionAllData, flagCleanAllControlsData, flagNeedToUpdatePhotosForJobsiteBlock, inspectionJobsiteBlockInfo, inspectionGeneralBlockInfo, flagNeedToUpdatePhotosForGeneralBlock, inspectionAllData, dispatch, showCamera, saveRequest, editInspectionId, inspections, access_token_data } = useContext(ECContext)
   const [show, setShow] = React.useState(false);
   const reduce = require('image-blob-reduce')();

   const [doesTheDrivewayHaveExistingCracks, setDoesTheDrivewayHaveExistingCracks] = React.useState('');
   const [photosDoesTheDrivewayHaveExistingCracks, setPhotosDoesTheDrivewayHaveExistingCracks] = React.useState([]);
   const [doesTheJobSideHaveRemoteAccess, setDoesTheJobSideHaveRemoteAccess] = React.useState('');
   const [photosDoesTheJobSideHaveRemoteAccess, setPhotosDoesTheJobSideHaveRemoteAccess] = React.useState([]);
   const [doesBuildingHaveAnyPreexistingImperfections, setDoesBuildingHaveAnyPreexistingImperfections] = React.useState('');
   const [photosDoesBuildingHaveAnyPreexistingImperfections, setPhotosDoesBuildingHaveAnyPreexistingImperfections] = React.useState([]);
   const [doesBuildingNeedDecking, setDoesBuildingNeedDecking] = React.useState('');
   const [photosDoesBuildingNeedDecking, setPhotosDoesBuildingNeedDecking] = React.useState([]);
   const [doesItHaveAdeck, setDoesItHaveAdeck] = React.useState('');
   const [photosDoesItHaveAdeck, setPhotosDoesItHaveAdeck] = React.useState([]);
   const [specialRequestByHomeowner, setSpecialRequestByHomeowner] = React.useState('');
   const [specialInstructionsForMaterialDeliveryDriver, setSpecialInstructionsForMaterialDeliveryDriver] = React.useState('');




   useEffect(() => {

      setShow(inspectionJobsiteBlockInfo.showBlock)
   }, [inspectionJobsiteBlockInfo]);

   useEffect(() => {
      setDoesTheDrivewayHaveExistingCracks('');
      setPhotosDoesTheDrivewayHaveExistingCracks([]);
      setDoesTheJobSideHaveRemoteAccess('');
      setPhotosDoesTheJobSideHaveRemoteAccess([]);
      setDoesBuildingHaveAnyPreexistingImperfections('');
      setPhotosDoesBuildingHaveAnyPreexistingImperfections([]);
      setDoesBuildingNeedDecking('');
      setPhotosDoesBuildingNeedDecking([]);
      setDoesItHaveAdeck('');
      setPhotosDoesItHaveAdeck([]);
      setSpecialRequestByHomeowner('');
      setSpecialInstructionsForMaterialDeliveryDriver('');
   }, [flagCleanAllControlsData]);

   useEffect(() => {


      if(flagNeedToUpdatePhotosForJobsiteBlock == true) {
         dispatch({ type: 'DROP_UPDATE_PHOTO_FOR_JOBSITE' })

         console.log("We're in Jobsite. Updating the photos after we shoot them. inspectionAllData:")
         console.log(inspectionAllData)
         console.log("photosDoesTheJobSideHaveRemoteAccess:")
         console.log(photosDoesTheJobSideHaveRemoteAccess)


         if(inspectionAllData.hasOwnProperty('photosDoesTheDrivewayHaveExistingCracks') && showCameraFor.property == 'photosDoesTheDrivewayHaveExistingCracks') {
            setPhotosDoesTheDrivewayHaveExistingCracks([
               ...inspectionAllData.photosDoesTheDrivewayHaveExistingCracks]
            )
         }
         if(inspectionAllData.hasOwnProperty('photosDoesTheJobSideHaveRemoteAccess') && showCameraFor.property == 'photosDoesTheJobSideHaveRemoteAccess') {
            setPhotosDoesTheJobSideHaveRemoteAccess([
               ...inspectionAllData.photosDoesTheJobSideHaveRemoteAccess]
            )
         }
         if(inspectionAllData.hasOwnProperty('photosDoesBuildingHaveAnyPreexistingImperfections') && showCameraFor.property == 'photosDoesBuildingHaveAnyPreexistingImperfections') {
            setPhotosDoesBuildingHaveAnyPreexistingImperfections([
               ...inspectionAllData.photosDoesBuildingHaveAnyPreexistingImperfections]
            )
         }
         if(inspectionAllData.hasOwnProperty('photosDoesBuildingNeedDecking') && showCameraFor.property == 'photosDoesBuildingNeedDecking') {
            setPhotosDoesBuildingNeedDecking([
               ...inspectionAllData.photosDoesBuildingNeedDecking]
            )
         }
         if(inspectionAllData.hasOwnProperty('photosDoesItHaveAdeck') && showCameraFor.property == 'photosDoesItHaveAdeck') {
            setPhotosDoesItHaveAdeck([
               ...inspectionAllData.photosDoesItHaveAdeck]
            )
         }



      }

   }, [flagNeedToUpdatePhotosForJobsiteBlock]);

   useEffect(() => {

                  const transformSavingData = (prop) => {

                     return prop.map((fileData, index) => {
                        if(fileData.IS_NEW == true) {
                           prop[index].IS_NEW = false
                           return fileData.FILE_ID
                        }
                     }).filter(x => x !== undefined)
                  }


      if(saveRequest === true) {

         let jobsiteBlockData = {

            PROPERTY_835: doesTheDrivewayHaveExistingCracks,
            PROPERTY_837: transformSavingData(photosDoesTheDrivewayHaveExistingCracks),
               // photosDoesTheDrivewayHaveExistingCracks.map((fileData, index) => {
               //    return fileData.FILE_ID
               // }),
            PROPERTY_839: doesTheJobSideHaveRemoteAccess,
            PROPERTY_841: transformSavingData(photosDoesTheJobSideHaveRemoteAccess),
               // photosDoesTheJobSideHaveRemoteAccess.map((fileData, index) => {
               //    return fileData.FILE_ID
               // }),

            PROPERTY_843: doesBuildingHaveAnyPreexistingImperfections,
            PROPERTY_845: transformSavingData(photosDoesBuildingHaveAnyPreexistingImperfections),
               // photosDoesBuildingHaveAnyPreexistingImperfections.map((fileData, index) => {
               //    return fileData.FILE_ID
               // }),
            PROPERTY_847: doesBuildingNeedDecking,
            PROPERTY_849: transformSavingData(photosDoesBuildingNeedDecking),
               // photosDoesBuildingNeedDecking.map((fileData, index) => {
               //    return fileData.FILE_ID
               // }),
            PROPERTY_851: doesItHaveAdeck,
            PROPERTY_853: transformSavingData(photosDoesItHaveAdeck),
               // photosDoesItHaveAdeck.map((fileData, index) => {
               //    return fileData.FILE_ID
               // }),
            PROPERTY_855: specialRequestByHomeowner,
            PROPERTY_857: specialInstructionsForMaterialDeliveryDriver,

            NO_PHOTOS_DATA: {
               "Does the Driveway Have Existing Cracks": doesTheDrivewayHaveExistingCracks,
               "Does the JobSide Have Remote Access": doesTheJobSideHaveRemoteAccess,
               "Does Building Have Any Preexisting Imperfections": doesBuildingHaveAnyPreexistingImperfections,
               "Does Building Need Decking": doesBuildingNeedDecking,
               "Does It Have a Deck": doesItHaveAdeck,
               "Special Request By Homeowner": specialRequestByHomeowner,
               "Special Instructions For Material Delivery Driver": specialInstructionsForMaterialDeliveryDriver
            }


         }

         dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA_WITH_JOBSITE_BLOCK', payload: jobsiteBlockData } )

console.log('Updationg jobsiteBlockData with:')
console.log(jobsiteBlockData)


         // triggering Save in InspectionEdit component
         dispatch({ type: 'SET_JOBSITE_READY_TO_SAVE_FLAG' })

      }
   }, [saveRequest]);

   useEffect(() => {

      if(!inspectionAllData.hasOwnProperty('PROPERTY_799' )) return;

      console.log('RELOADING data... in jobsite.jsx')
      console.log('current inspectionAllData:')
      console.log(inspectionAllData)



      setDoesTheDrivewayHaveExistingCracks(inspectionAllData.PROPERTY_835) //jobsite.Does the driveway have existing cracks/stains?
      const photosDoesTheDrivewayHaveExistingCracks = async () => {        //jobsite.Cracks/stains Photos

         if(inspectionAllData.hasOwnProperty('photosDoesTheDrivewayHaveExistingCracks' )) {
            if(inspectionAllData.photosDoesTheDrivewayHaveExistingCracks.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.photosDoesTheDrivewayHaveExistingCracks.length; i++) {

                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.photosDoesTheDrivewayHaveExistingCracks[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'FILE_ID': inspectionAllData.photosDoesTheDrivewayHaveExistingCracks[i].FILE_ID })
               }
               setPhotosDoesTheDrivewayHaveExistingCracks(images)
               dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                  payload: {...inspectionAllData,
                  'photosDoesTheDrivewayHaveExistingCracks': images
               }})
            }
         }
      }
      photosDoesTheDrivewayHaveExistingCracks()

      setDoesTheJobSideHaveRemoteAccess(inspectionAllData.PROPERTY_839) //jobsite.Does the job side have remote access?

      const photosDoesTheJobSideHaveRemoteAccess = async () => {        //jobsite.Remote access photos
         if(inspectionAllData.hasOwnProperty('photosDoesTheJobSideHaveRemoteAccess' )) {
            if(inspectionAllData.photosDoesTheJobSideHaveRemoteAccess.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.photosDoesTheJobSideHaveRemoteAccess.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.photosDoesTheJobSideHaveRemoteAccess[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'FILE_ID': inspectionAllData.photosDoesTheJobSideHaveRemoteAccess[i].FILE_ID})
               }
               setPhotosDoesTheJobSideHaveRemoteAccess(images)
               dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                  payload: {...inspectionAllData, 'photosDoesTheJobSideHaveRemoteAccess': images}})
            }
         }
      }
      photosDoesTheJobSideHaveRemoteAccess()

      setDoesBuildingHaveAnyPreexistingImperfections(inspectionAllData.PROPERTY_843) //jobsite.Does building have any pre-existing imperfections or damages (i.e. broken siding, light fixtures, fence, glass etc)

      const photosDoesBuildingHaveAnyPreexistingImperfections = async () => {        //jobsite.Pre-existing imperfections or damages (i.e. broken siding, light fixtures, fence, glass etc) Photos
         if(inspectionAllData.hasOwnProperty('photosDoesBuildingHaveAnyPreexistingImperfections' )) {
            if(inspectionAllData.photosDoesBuildingHaveAnyPreexistingImperfections.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.photosDoesBuildingHaveAnyPreexistingImperfections.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.photosDoesBuildingHaveAnyPreexistingImperfections[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'FILE_ID': inspectionAllData.photosDoesBuildingHaveAnyPreexistingImperfections[i].FILE_ID})
               }
               setPhotosDoesBuildingHaveAnyPreexistingImperfections(images)
               dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                  payload: {...inspectionAllData, 'photosDoesBuildingHaveAnyPreexistingImperfections': images}})

            }
         }
      }
      photosDoesBuildingHaveAnyPreexistingImperfections()

      setDoesBuildingNeedDecking(inspectionAllData.PROPERTY_847) //jobsite.Does building need decking? Add attic photo w/tape measure (required if Mpls or St.Paul)

      const photosDoesBuildingNeedDecking = async () => {        //jobsite.Attic photo w/tape measure (required if Mpls or St.Paul) Photos
         if(inspectionAllData.hasOwnProperty('photosDoesBuildingNeedDecking' )) {
            if(inspectionAllData.photosDoesBuildingNeedDecking.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.photosDoesBuildingNeedDecking.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.photosDoesBuildingNeedDecking[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'FILE_ID': inspectionAllData.photosDoesBuildingNeedDecking[i].FILE_ID})
               }
               setPhotosDoesBuildingNeedDecking(images)
               dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                  payload: {...inspectionAllData, 'photosDoesBuildingNeedDecking': images}})

            }
         }
      }
      photosDoesBuildingNeedDecking()

      setDoesItHaveAdeck(inspectionAllData.PROPERTY_851) //jobsite.Does it have a deck? Take photos of deck and railing


      const photosDoesItHaveAdeck = async () => {        //jobsite.Photos of deck and railing
         if(inspectionAllData.hasOwnProperty('photosDoesItHaveAdeck' )) {
            if(inspectionAllData.photosDoesItHaveAdeck.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.photosDoesItHaveAdeck.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.photosDoesItHaveAdeck[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'FILE_ID': inspectionAllData.photosDoesItHaveAdeck[i].FILE_ID})
               }
               setPhotosDoesItHaveAdeck(images)
               dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                  payload: {...inspectionAllData, 'photosDoesItHaveAdeck': images}})

            }
         }
      }
      photosDoesItHaveAdeck()

      setSpecialRequestByHomeowner(inspectionAllData.PROPERTY_855) //jobsite.Special Request by Homeowner
      setSpecialInstructionsForMaterialDeliveryDriver(inspectionAllData.PROPERTY_857) //jobsite.Special Instructions for Material Delivery Driver


   }, [flagReloadDatafromInspectionAllData]);



   const toBase64 = file => new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
   });


   const [drawerVar, setDrawerVar] = React.useState([])
   const [drawerVarName, setDrawerVarName] = React.useState('')
   const [drawerSetter, setDrawerSetter] = React.useState('')
   const [state, setState] = React.useState({top: false, left: false, bottom: false, right: false,});

   const toggleDrawer = (anchor, open, photosValues) => (event) => {
      if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
         return;
      }

      setDrawerVar(photosValues)
      setDrawerVarName(anchor)

      setState({ ...state, [anchor]: open });
   };

   const list = (drawerVar) => (
      <Box
         sx={{ width: '400px' }}
         role="presentation"
         onClick={toggleDrawer(drawerVarName, false, drawerVar)}
         onKeyDown={toggleDrawer(drawerVarName, false,drawerVar)}
      >

         <Stack direction="row" justifyContent="center" alignItems="center" padding={2}>
            <Button variant="text" onClick={toggleDrawer(drawerVarName, false, drawerVar)}>Back</Button>
         </Stack>


         <ImageList sx={{ width: '400px' }} cols={1} >
            {drawerVar.map((item) => (
               <ImageListItem key={item.DETAIL_URL}>
                  <img
                     src={`${item.DETAIL_URL}`}
                     srcSet={`${item.DETAIL_URL}`}
                     loading="lazy"
                  />

                  <Button
                     disableElevation={true}
                     value={item.FILE_ID}
                     onClick={ (event) => {
                        event.stopPropagation()

                        const updatedPhotos = drawerVar.filter( photo1 => photo1.FILE_ID != event.target.value )


console.log(`drawerVarName = ${drawerVarName}`)
console.log(`event.target.value = ${event.target.value}`)
console.log("drawerVar:")
console.log(drawerVar)
console.log(`updated photos:`)
console.log(updatedPhotos)


                        if(drawerVarName == "photosDoesTheDrivewayHaveExistingCracks") {
                           setPhotosDoesTheDrivewayHaveExistingCracks(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }
                        if(drawerVarName == "photosDoesTheJobSideHaveRemoteAccess") {
                           setPhotosDoesTheJobSideHaveRemoteAccess(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }
                        if(drawerVarName == "photosDoesBuildingHaveAnyPreexistingImperfections") {
                           setPhotosDoesBuildingHaveAnyPreexistingImperfections(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }
                        if(drawerVarName == "photosDoesBuildingNeedDecking") {
                           setPhotosDoesBuildingNeedDecking(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }
                        if(drawerVarName == "photosDoesItHaveAdeck") {
                           setPhotosDoesItHaveAdeck(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }




                        let tmp = inspectionAllData
                        const updatedPhotos2 = tmp[drawerVarName].filter( photo1 => photo1.FILE_ID != event.target.value )
                        tmp[drawerVarName] = updatedPhotos2

console.log(`tmp[drawerVarName]:`)
console.log(tmp[drawerVarName])


                        dispatch({ type: 'SET_INSPECTION_ALL_DATA', payload: tmp } )
                     }}>Delete
                  </Button>

                  <br/>
               </ImageListItem>
            ))}
         </ImageList>
      </Box>
   );




   return (

      <Box sx={{mx: 'auto', mt: 2, width: '100%', display: show === true ? 'flex' : 'none', flexDirection: 'column'}}>

         <Box sx={{display: 'flex', mt: 0, p: 2, gap: 1, width: '100%'}}>
            <FormControl>
               <FormLabel id="driveway-cracks-radio-buttons-group-label">Does the driveway have existing cracks/stains?</FormLabel>
               <RadioGroup
                  aria-labelledby="driveway-cracks--radio-buttons-group-label"
                  name="driveway-cracks-buttons-group"
                  value={doesTheDrivewayHaveExistingCracks}
                  onChange={(event) => {
                     setDoesTheDrivewayHaveExistingCracks(event.target.value)
                  }}
               >
                  <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
               </RadioGroup>
            </FormControl>

            <Stack direction="row" justifyContent="right" alignItems="right" padding={2} sx={{ml: 'auto', width: '200px'}}>

               <React.Fragment key={'right'} >
                     <Drawer
                        anchor={'right'}
                        open={state[drawerVarName]}
                        //open={state[drawerVarName]}
                        onClose={toggleDrawer(drawerVarName, false, drawerVar)}
                        sx={{zIndex: 9999}}
                     >
                        {list(drawerVar)}
                     </Drawer>
               </React.Fragment>


               <IconButton color="primary" aria-label="upload picture" component="label"
                  onClick={ () => {
                     dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'photosDoesTheDrivewayHaveExistingCracks', page: 'jobsite'} })
                  }}>
                  <PhotoCamera />
               </IconButton>
               <IconButton color="primary" aria-label="upload picture" component="label">
                  <input
                     type="file" accept="image/*" multiple hidden name="myImage"
                     onChange={(event) => {

                        console.log('event.target.files:')
                        console.log(event.target.files)

                        const files = event.target.files
                        let photos = []

                        //files.forEach((file) => {
                        for (let i = 0; i < files.length; i++) {
                           reduce.toBlob(files[i], { max: 900 }).then(blob => {
                              const wrapSaveImage = async (dataUri) => {
                                 //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                                 const wrapper = async () => {
                                    const base64 = await toBase64(dataUri);
                                    const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', '').replace('data:image/png;base64,', ''), generateUniqueName: true}
                                    const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                    dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, photosDoesTheDrivewayHaveExistingCracks: [...photosDoesTheDrivewayHaveExistingCracks, ...photos, savedPhotoData]} })
                                    //dispatch({ type: 'SET_UPDATE_PHOTO_FOR_JOBSITE' })

                                    const updatedPhotos = [...photos, savedPhotoData]
                                    setPhotosDoesTheDrivewayHaveExistingCracks([...photosDoesTheDrivewayHaveExistingCracks, ...updatedPhotos])

                                    photos.push(savedPhotoData)
                                 }
                                 wrapper()
                              }
                              wrapSaveImage(blob)
                           });
                        }


                     }}
                  />
                  <GalleryIcon />
               </IconButton>
            </Stack>

         </Box>


         <Stack direction="row" alignItems="center" spacing={1}>
            {photosDoesTheDrivewayHaveExistingCracks.map((image, index) => (
               <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('photosDoesTheDrivewayHaveExistingCracks', true, photosDoesTheDrivewayHaveExistingCracks)}/>
            ))}
         </Stack>



         <Box sx={{display: 'flex', mt: 0, p: 2, gap: 1, width: '100%'}}>
            <FormControl>
               <FormLabel id="remote-access-radio-buttons-group-label">Does the job side have remote access?</FormLabel>
               <RadioGroup
                  aria-labelledby="remote-access-radio-buttons-group-label"
                  name="remote-access-buttons-group"
                  value={doesTheJobSideHaveRemoteAccess}
                  onChange={(event) => {
                     setDoesTheJobSideHaveRemoteAccess(event.target.value)
                  }}

               >
                  <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
               </RadioGroup>
            </FormControl>

            <Stack direction="row" justifyContent="right" alignItems="right" padding={2} sx={{ml: 'auto', width: '200px'}}>
               <IconButton color="primary" aria-label="upload picture" component="label"
                           onClick={ () => {
                              dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'photosDoesTheJobSideHaveRemoteAccess', page: 'jobsite'} })
                           }}>
                  <PhotoCamera />
               </IconButton>
               <IconButton color="primary" aria-label="upload picture" component="label">
                  <input
                     type="file" accept="image/*" multiple hidden name="myImage"
                     onChange={(event) => {

                        console.log('event.target.files:')
                        console.log(event.target.files)

                        const files = event.target.files
                        let photos = []

                        //files.forEach((file) => {
                        for (let i = 0; i < files.length; i++) {
                           reduce.toBlob(files[i], { max: 900 }).then(blob => {
                              const wrapSaveImage = async (dataUri) => {
                                 //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                                 const wrapper = async () => {
                                    const base64 = await toBase64(dataUri);
                                    const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', '').replace('data:image/png;base64,', ''), generateUniqueName: true}
                                    const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                    dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, photosDoesTheJobSideHaveRemoteAccess: [...photosDoesTheJobSideHaveRemoteAccess, ...photos, savedPhotoData]} })
                                    //dispatch({ type: 'SET_UPDATE_PHOTO_FOR_JOBSITE' })

                                    const updatedPhotos = [...photos, savedPhotoData]
                                    setPhotosDoesTheJobSideHaveRemoteAccess([...photosDoesTheJobSideHaveRemoteAccess, ...updatedPhotos])
                                    photos.push(savedPhotoData)
                                 }
                                 wrapper()
                              }
                              wrapSaveImage(blob)
                           });
                        }
                     }}
                  />
                  <GalleryIcon />
               </IconButton>
            </Stack>
         </Box>

         <Stack direction="row" alignItems="center" spacing={1}>

            {/*https://www.npmjs.com/package/mui-image*/}
            {photosDoesTheJobSideHaveRemoteAccess.map((image, index) => (
               <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('photosDoesTheJobSideHaveRemoteAccess', true, photosDoesTheJobSideHaveRemoteAccess)}/>
            ))}

         </Stack>


         <Box sx={{display: 'flex', mt: 0, p: 2, gap: 1, width: '100%'}}>
            <FormControl>
               <FormLabel id="imperfections-radio-buttons-group-label">Does building have any pre-existing imperfections or damages (i.e. broken siding, light fixtures, fence, glass etc)</FormLabel>
               <RadioGroup
                  aria-labelledby="imperfections-radio-buttons-group-label"
                  name="imperfections-buttons-group"
                  value={doesBuildingHaveAnyPreexistingImperfections}
                  onChange={(event) => {
                     setDoesBuildingHaveAnyPreexistingImperfections(event.target.value)
                  }}

               >
                  <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
               </RadioGroup>
            </FormControl>

            <Stack direction="row" justifyContent="right" alignItems="right" padding={2} sx={{ml: 'auto', width: '200px'}}>
               <IconButton  color="primary" aria-label="upload picture" component="label"
                           onClick={ () => {
                              dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'photosDoesBuildingHaveAnyPreexistingImperfections', page: 'jobsite'} })
                           }}>
                  <PhotoCamera />
               </IconButton>
               <IconButton  color="primary" aria-label="upload picture" component="label">
                  <input
                     type="file" accept="image/*" multiple hidden name="myImage"
                     onChange={(event) => {

                        console.log('event.target.files:')
                        console.log(event.target.files)

                        const files = event.target.files
                        let photos = []

                        //files.forEach((file) => {
                        for (let i = 0; i < files.length; i++) {
                           reduce.toBlob(files[i], { max: 900 }).then(blob => {
                              const wrapSaveImage = async (dataUri) => {
                                 //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                                 const wrapper = async () => {
                                    const base64 = await toBase64(dataUri);
                                    const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', '').replace('data:image/png;base64,', ''), generateUniqueName: true}
                                    const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                    dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, photosDoesBuildingHaveAnyPreexistingImperfections: [...photosDoesBuildingHaveAnyPreexistingImperfections, ...photos, savedPhotoData]} })
                                    //dispatch({ type: 'SET_UPDATE_PHOTO_FOR_JOBSITE' })

                                    const updatedPhotos = [...photos, savedPhotoData]
                                    setPhotosDoesBuildingHaveAnyPreexistingImperfections([...photosDoesBuildingHaveAnyPreexistingImperfections, ...updatedPhotos])
                                    photos.push(savedPhotoData)
                                 }
                                 wrapper()
                              }
                              wrapSaveImage(blob)
                           });
                        }
                     }}
                  />
                  <GalleryIcon />
               </IconButton>
            </Stack>

         </Box>

         <Stack direction="row" alignItems="center" spacing={1}>
            {photosDoesBuildingHaveAnyPreexistingImperfections.map((image, index) => (
               <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('photosDoesBuildingHaveAnyPreexistingImperfections', true, photosDoesBuildingHaveAnyPreexistingImperfections)}/>
            ))}
         </Stack>



         <Box sx={{display: 'flex', mt: 0, p: 2, gap: 1, width: '100%'}}>
            <FormControl>
               <FormLabel id="need-decking-radio-buttons-group-label">Does building need decking? Add attic photo w/tape measure (required if Mpls or St.Paul)</FormLabel>
               <RadioGroup
                  aria-labelledby="need-decking-radio-buttons-group-label"
                  name="need-decking-buttons-group"
                  value={doesBuildingNeedDecking}
                  onChange={(event) => {
                     setDoesBuildingNeedDecking(event.target.value)
                  }}

               >
                  <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
               </RadioGroup>
            </FormControl>


            <Stack direction="row" justifyContent="right" alignItems="right" padding={2} sx={{ml: 'auto', width: '200px'}}>
               <IconButton color="primary" aria-label="upload picture" component="label"
                           onClick={ () => {
                              dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'photosDoesBuildingNeedDecking', page: 'jobsite'} })
                           }}>
                  <PhotoCamera />
               </IconButton>
               <IconButton color="primary" aria-label="upload picture" component="label">
                  <input
                     type="file" accept="image/*" multiple hidden name="myImage"
                     onChange={(event) => {

                        const files = event.target.files
                        let photos = []

                        //files.forEach((file) => {
                        for (let i = 0; i < files.length; i++) {
                           reduce.toBlob(files[i], { max: 900 }).then(blob => {
                              const wrapSaveImage = async (dataUri) => {
                                 //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                                 const wrapper = async () => {
                                    const base64 = await toBase64(dataUri);
                                    const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', '').replace('data:image/png;base64,', ''), generateUniqueName: true}
                                    const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                    dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, photosDoesBuildingNeedDecking: [...photosDoesBuildingNeedDecking, ...photos, savedPhotoData]} })
                                    //dispatch({ type: 'SET_UPDATE_PHOTO_FOR_JOBSITE' })

                                    const updatedPhotos = [...photos, savedPhotoData]
                                    setPhotosDoesBuildingNeedDecking([...photosDoesBuildingNeedDecking, ...updatedPhotos])
                                    photos.push(savedPhotoData)
                                 }
                                 wrapper()
                              }
                              wrapSaveImage(blob)
                           });
                        }


                     }}
                  />
                  <GalleryIcon />
               </IconButton>
            </Stack>

         </Box>

         <Stack direction="row" alignItems="center" spacing={1}>
            {photosDoesBuildingNeedDecking.map((image, index) => (
               <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('photosDoesBuildingNeedDecking', true, photosDoesBuildingNeedDecking)}/>
            ))}
         </Stack>




         <Box sx={{display: 'flex', mt: 0, p: 2, gap: 1, width: '100%'}}>
            <FormControl>
               <FormLabel id="have-a-deck-radio-buttons-group-label">Does it have a deck? Take photos of deck and railing</FormLabel>
               <RadioGroup
                  aria-labelledby="have-a-deck-radio-buttons-group-label"
                  name="have-a-deck-buttons-group"
                  value={doesItHaveAdeck}
                  onChange={(event) => {
                     setDoesItHaveAdeck(event.target.value)
                  }}

               >
                  <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
               </RadioGroup>
            </FormControl>

            <Stack direction="row" justifyContent="right" alignItems="right" padding={2} sx={{ml: 'auto', width: '200px'}}>
               <IconButton color="primary" aria-label="upload picture" component="label"
                           onClick={ () => {
                              dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'photosDoesItHaveAdeck', page: 'jobsite'} })
                           }}>
                  <PhotoCamera />
               </IconButton>
               <IconButton color="primary" aria-label="upload picture" component="label">
                  <input
                     type="file" accept="image/*" multiple hidden name="myImage"
                     onChange={(event) => {

                        const files = event.target.files
                        let photos = []

                        //files.forEach((file) => {
                        for (let i = 0; i < files.length; i++) {
                           reduce.toBlob(files[i], { max: 900 }).then(blob => {
                              const wrapSaveImage = async (dataUri) => {
                                 //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                                 const wrapper = async () => {
                                    const base64 = await toBase64(dataUri);
                                    const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', '').replace('data:image/png;base64,', ''), generateUniqueName: true}
                                    const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                    dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, photosDoesItHaveAdeck: [...photosDoesItHaveAdeck, ...photos, savedPhotoData]} })
                                    //dispatch({ type: 'SET_UPDATE_PHOTO_FOR_JOBSITE' })

                                    const updatedPhotos = [...photos, savedPhotoData]
                                    setPhotosDoesItHaveAdeck([...photosDoesItHaveAdeck, ...updatedPhotos])
                                    photos.push(savedPhotoData)
                                 }
                                 wrapper()
                              }
                              wrapSaveImage(blob)
                           });
                        }


                     }}
                  />
                  <GalleryIcon />
               </IconButton>
            </Stack>

         </Box>

         <Stack direction="row" alignItems="center" spacing={1}>
            {photosDoesItHaveAdeck.map((image, index) => (
               <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('photosDoesItHaveAdeck', true, photosDoesItHaveAdeck)}/>
            ))}
         </Stack>



         <Box sx={{display: 'flex', mt: 0, p: 2, gap: 1, width: '100%'}}>
            <TextField fullWidth id="homeowner-reqeust" value={specialRequestByHomeowner} label="Special Request by Homeowner" multiline rows={4}
                       onChange={(event) => {
                          setSpecialRequestByHomeowner(event.target.value)
                       }}
            />
         </Box>

         <Box sx={{display: 'flex', mt: 0, p: 2, gap: 1, width: '100%'}}>
            <TextField fullWidth id="driver-instructions" value={specialInstructionsForMaterialDeliveryDriver} label="Special Instructions for Material Delivery Driver" multiline rows={4}
                       onChange={(event) => {
                          setSpecialInstructionsForMaterialDeliveryDriver(event.target.value)
                       }}
            />
         </Box>


{/*
         <Stack direction="row" alignItems="center" spacing={2}>
            <CustomCamera />
         </Stack>
*/}



      </Box>

   );
}