import {
   Button, Checkbox, Drawer,
   FormControl,
   FormControlLabel, FormGroup,
   FormLabel, ImageList, ImageListItem, ListItemButton, ListItemIcon, Radio, RadioGroup, Stack,
   TextField
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {PhotoCamera} from "@mui/icons-material";
import Box from "@mui/material/Box";
import * as React from "react";
import {useContext, useEffect} from "react";
import ECContext from "../../context/inspections/ECContext";
import { Image } from 'mui-image'
import spinner from "../layout/assets/loading.gif";
import {getImageUrl, savePhotoIntoFolder} from "../../context/inspections/ECActions";
import GalleryIcon from "@mui/icons-material/CollectionsOutlined";
import reduce from "image-blob-reduce";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";


export default function Gutters() {

   const { showCameraFor, flagReloadDatafromInspectionAllData, flagCleanAllControlsData, flagNeedToUpdatePhotosForGuttersBlock, inspectionGuttersBlockInfo, inspectionAllData, dispatch, showCamera, saveRequest, editInspectionId, inspections, access_token_data } = useContext(ECContext)
   const [show, setShow] = React.useState(false);
   const reduce = require('image-blob-reduce')();


   const [gutterSpecialComments, setGutterSpecialComments] = React.useState('');
   const [guttersAdditionalDamaggToSupplementPhotos, setGuttersAdditionalDamaggToSupplementPhotos] = React.useState([]);
   const [gutterCoversPhotos, setGutterCoversPhotos] = React.useState([]);
   const [gutterSize, setGutterSize] = React.useState('');
   const [gutterSizePhotos, setGutterSizePhotos] = React.useState([]);
   const [guttersLeftUpperCovers, setGuttersLeftUpperCovers] = React.useState('');
   const [guttersLeftUpperApproved, setGuttersLeftUpperApproved] = React.useState('');
   const [guttersLeftUpper, setGuttersLeftUpper] = React.useState('');
   const [guttersLeft, setGuttersLeft] = React.useState('');
   const [guttersLeftCovers, setGuttersLeftCovers] = React.useState('');
   const [guttersLeftApproved, setGuttersLeftApproved] = React.useState('');
   const [guttersLeftPhotos, setGuttersLeftPhotos] = React.useState([]);
   const [guttersBackUpperCovers, setGuttersBackUpperCovers] = React.useState('');
   const [guttersBackUpperApproved, setGuttersBackUpperApproved] = React.useState('');
   const [guttersBackUpper, setGuttersBackUpper] = React.useState('');
   const [guttersBackPhotos, setGuttersBackPhotos] = React.useState([]);
   const [guttersBackCovers, setGuttersBackCovers] = React.useState('');
   const [guttersBackApproved, setGuttersBackApproved] = React.useState('');
   const [guttersBack, setGuttersBack] = React.useState('');
   const [guttersRightUpperCovers, setGuttersRightUpperCovers] = React.useState('');
   const [guttersRightUpperApproved, setGuttersRightUpperApproved] = React.useState('');
   const [guttersRightUpper, setGuttersRightUpper] = React.useState('');
   const [guttersRightPhotos, setGuttersRightPhotos] = React.useState([]);
   const [guttersRightCovers, setGuttersRightCovers] = React.useState('');
   const [guttersRightApproved, setGuttersRightApproved] = React.useState('');
   const [guttersRight, setGuttersRight] = React.useState('');
   const [guttersFrontUpperCovers, setGuttersFrontUpperCovers] = React.useState('');
   const [guttersFrontUpperApproved, setGuttersFrontUpperApproved] = React.useState('');
   const [guttersFrontUpper, setGuttersFrontUpper] = React.useState('');
   const [guttersFrontPhotos, setGuttersFrontPhotos] = React.useState([]);
   const [guttersFrontCovers, setGuttersFrontCovers] = React.useState('');
   const [guttersFrontApproved, setGuttersFrontApproved] = React.useState('');
   const [guttersFront, setGuttersFront] = React.useState('');

   const [guttersFrontUpperPhotos, setGuttersFrontUpperPhotos] = React.useState([]); //PROPERTY_1315
   const [guttersRightUpperPhotos, setGuttersRightUpperPhotos] = React.useState([]); //PROPERTY_1317
   const [guttersBackUpperPhotos, setGuttersBackUpperPhotos] = React.useState([]); //PROPERTY_1319
   const [guttersLeftUpperPhotos, setGuttersLeftUpperPhotos] = React.useState([]); //PROPERTY_1321
   const [downspoutsFrontUpperPhotos, setDownspoutsFrontUpperPhotos] = React.useState([]); //PROPERTY_1323
   const [downspoutsRightUpperPhotos, setDownspoutsRightUpperPhotos] = React.useState([]); //PROPERTY_1325
   const [downspoutsBackUpperPhotos, setDownspoutsBackUpperPhotos] = React.useState([]); //PROPERTY_1327
   const [downspoutsLeftUpperPhotos, setDownspoutsLeftUpperPhotos] = React.useState([]); //PROPERTY_1329


   const [downspoutsWidthPhotos, setDownspoutsWidthPhotos] = React.useState([]);
   const [downspoutsWidth, setDownspoutsWidth] = React.useState('');
   const [downspoutsLeftUpperApproved, setDownspoutsLeftUpperApproved] = React.useState('');
   const [downspoutsLeftUpper, setDownspoutsLeftUpper] = React.useState('');
   const [downspoutsLeftPhotos, setDownspoutsLeftPhotos] = React.useState([]);
   const [downspoutsLeftApproved, setDownspoutsLeftApproved] = React.useState('');
   const [downspoutsLeft, setDownspoutsLeft] = React.useState('');
   const [downspoutsBackUpperApproved, setDownspoutsBackUpperApproved] = React.useState('');
   const [downspoutsBackUpper, setDownspoutsBackUpper] = React.useState('');
   const [downspoutsBackPhotos, setDownspoutsBackPhotos] = React.useState([]);
   const [downspoutsBackApproved, setDownspoutsBackApproved] = React.useState('');
   const [downspoutsBack, setDownspoutsBack] = React.useState('');
   const [downspoutsRightUpperApproved, setDownspoutsRightUpperApproved] = React.useState('');
   const [downspoutsRightUpper, setDownspoutsRightUpper] = React.useState('');
   const [downspoutsRightPhotos, setDownspoutsRightPhotos] = React.useState([]);
   const [downspoutsRightApproved, setDownspoutsRightApproved] = React.useState('');
   const [downspoutsRight, setDownspoutsRight] = React.useState('');
   const [downspoutsFrontUpperApproved, setDownspoutsFrontUpperApproved] = React.useState('');
   const [downspoutsFrontUpper, setDownspoutsFrontUpper] = React.useState('');
   const [downspoutsFrontPhotos, setDownspoutsFrontPhotos] = React.useState([]);
   const [downspoutsFrontApproved, setDownspoutsFrontApproved] = React.useState('');
   const [downspoutsFront, setDownspoutsFront] = React.useState('');
   
   

   useEffect(() => {

      setShow(inspectionGuttersBlockInfo.showBlock)
   }, [inspectionGuttersBlockInfo]);

   useEffect(() => {

      setGutterSpecialComments('');
      setGuttersAdditionalDamaggToSupplementPhotos([]);
      setGutterCoversPhotos([]);
      setGutterSize('');
      setGutterSizePhotos([]);
      setGuttersLeftUpperCovers('');
      setGuttersLeftUpperApproved('');
      setGuttersLeftUpper('');
      setGuttersLeft('');
      setGuttersLeftCovers('');
      setGuttersLeftApproved('');
      setGuttersLeftPhotos([]);
      setGuttersBackUpperCovers('');
      setGuttersBackUpperApproved('');
      setGuttersBackUpper('');
      setGuttersBackPhotos([]);
      setGuttersBackCovers('');
      setGuttersBackApproved('');
      setGuttersBack('');
      setGuttersRightUpperCovers('');
      setGuttersRightUpperApproved('');
      setGuttersRightUpper('');
      setGuttersRightPhotos([]);
      setGuttersRightCovers('');
      setGuttersRightApproved('');
      setGuttersRight('');
      setGuttersFrontUpperCovers('');
      setGuttersFrontUpperApproved('');
      setGuttersFrontUpper('');
      setGuttersFrontPhotos([]);
      setGuttersFrontCovers('');
      setGuttersFrontApproved('');
      setGuttersFront('');
      setDownspoutsWidthPhotos([]);
      setDownspoutsWidth('');
      setDownspoutsLeftUpperApproved('');
      setDownspoutsLeftUpper('');
      setDownspoutsLeftPhotos([]);
      setDownspoutsLeftApproved('');
      setDownspoutsLeft('');
      setDownspoutsBackUpperApproved('');
      setDownspoutsBackUpper('');
      setDownspoutsBackPhotos([]);
      setDownspoutsBackApproved('');
      setDownspoutsBack('');
      setDownspoutsRightUpperApproved('');
      setDownspoutsRightUpper('');
      setDownspoutsRightPhotos([]);
      setDownspoutsRightApproved('');
      setDownspoutsRight('');
      setDownspoutsFrontUpperApproved('');
      setDownspoutsFrontUpper('');
      setDownspoutsFrontPhotos([]);
      setDownspoutsFrontApproved('');
      setDownspoutsFront('');

      setGuttersFrontUpperPhotos([]);
      setGuttersRightUpperPhotos([]);
      setGuttersBackUpperPhotos([]);
      setGuttersLeftUpperPhotos([]);
      setDownspoutsFrontUpperPhotos([]);
      setDownspoutsRightUpperPhotos([]);
      setDownspoutsBackUpperPhotos([]);
      setDownspoutsLeftUpperPhotos([]);

   }, [flagCleanAllControlsData]);

   useEffect(() => {


      if(flagNeedToUpdatePhotosForGuttersBlock == true) {
         dispatch({ type: 'DROP_UPDATE_PHOTO_FOR_GUTTERS' })

         if(inspectionAllData.hasOwnProperty('gutterSizePhotos') && showCameraFor.property == 'gutterSizePhotos') {
            setGutterSizePhotos([
               ...inspectionAllData.gutterSizePhotos]
            )
         }
         if(inspectionAllData.hasOwnProperty('guttersLeftPhotos') && showCameraFor.property == 'guttersLeftPhotos') {
            setGuttersLeftPhotos([
               ...inspectionAllData.guttersLeftPhotos]
            )
         }
         if(inspectionAllData.hasOwnProperty('guttersBackPhotos') && showCameraFor.property == 'guttersBackPhotos') {
            setGuttersBackPhotos([
               ...inspectionAllData.guttersBackPhotos]
            )
         }
         if(inspectionAllData.hasOwnProperty('guttersRightPhotos') && showCameraFor.property == 'guttersRightPhotos') {
            setGuttersRightPhotos([
               ...inspectionAllData.guttersRightPhotos]
            )
         }
         if(inspectionAllData.hasOwnProperty('guttersFrontPhotos') && showCameraFor.property == 'guttersFrontPhotos') {
            setGuttersFrontPhotos([
               ...inspectionAllData.guttersFrontPhotos]
            )
         }
         if(inspectionAllData.hasOwnProperty('downspoutsWidthPhotos') && showCameraFor.property == 'downspoutsWidthPhotos') {
            setDownspoutsWidthPhotos([
               ...inspectionAllData.downspoutsWidthPhotos]
            )
         }
         if(inspectionAllData.hasOwnProperty('downspoutsLeftPhotos') && showCameraFor.property == 'downspoutsLeftPhotos') {
            setDownspoutsLeftPhotos([
               ...inspectionAllData.downspoutsLeftPhotos]
            )
         }
         if(inspectionAllData.hasOwnProperty('downspoutsBackPhotos') && showCameraFor.property == 'downspoutsBackPhotos') {
            setDownspoutsBackPhotos([
               ...inspectionAllData.downspoutsBackPhotos]
            )
         }
         if(inspectionAllData.hasOwnProperty('downspoutsRightPhotos') && showCameraFor.property == 'downspoutsRightPhotos') {
            setDownspoutsRightPhotos([
               ...inspectionAllData.downspoutsRightPhotos]
            )
         }
         if(inspectionAllData.hasOwnProperty('downspoutsFrontPhotos') && showCameraFor.property == 'downspoutsFrontPhotos') {
            setDownspoutsFrontPhotos([
               ...inspectionAllData.downspoutsFrontPhotos]
            )
         }

         if(inspectionAllData.hasOwnProperty('guttersFrontUpperPhotos') && showCameraFor.property == 'guttersFrontUpperPhotos') {
            setGuttersFrontUpperPhotos([
               ...inspectionAllData.guttersFrontUpperPhotos]
            )
         }
         if(inspectionAllData.hasOwnProperty('guttersRightUpperPhotos') && showCameraFor.property == 'guttersRightUpperPhotos') {
            setGuttersRightUpperPhotos([
               ...inspectionAllData.guttersRightUpperPhotos]
            )
         }
         if(inspectionAllData.hasOwnProperty('guttersBackUpperPhotos') && showCameraFor.property == 'guttersBackUpperPhotos') {
            setGuttersBackUpperPhotos([
               ...inspectionAllData.guttersBackUpperPhotos]
            )
         }
         if(inspectionAllData.hasOwnProperty('guttersLeftUpperPhotos') && showCameraFor.property == 'guttersLeftUpperPhotos') {
            setGuttersLeftUpperPhotos([
               ...inspectionAllData.guttersLeftUpperPhotos]
            )
         }
         if(inspectionAllData.hasOwnProperty('downspoutsFrontUpperPhotos') && showCameraFor.property == 'downspoutsFrontUpperPhotos') {
            setDownspoutsFrontUpperPhotos([
               ...inspectionAllData.downspoutsFrontUpperPhotos]
            )
         }
         if(inspectionAllData.hasOwnProperty('downspoutsRightUpperPhotos') && showCameraFor.property == 'downspoutsRightUpperPhotos') {
            setDownspoutsRightUpperPhotos([
               ...inspectionAllData.downspoutsRightUpperPhotos]
            )
         }
         if(inspectionAllData.hasOwnProperty('downspoutsBackUpperPhotos') && showCameraFor.property == 'downspoutsBackUpperPhotos') {
            setDownspoutsBackUpperPhotos([
               ...inspectionAllData.downspoutsBackUpperPhotos]
            )
         }
         if(inspectionAllData.hasOwnProperty('downspoutsLeftUpperPhotos') && showCameraFor.property == 'downspoutsLeftUpperPhotos') {
            setDownspoutsLeftUpperPhotos([
               ...inspectionAllData.downspoutsLeftUpperPhotos]
            )
         }



      }

   }, [flagNeedToUpdatePhotosForGuttersBlock]);

   useEffect(() => {

                  const transformSavingData = (prop) => {

                     return prop.map((fileData, index) => {
                        if(fileData.IS_NEW == true) {
                           prop[index].IS_NEW = false
                           return fileData.FILE_ID
                        }
                     }).filter(x => x !== undefined)
                  }


      if(saveRequest === true) {

         let guttersBlockData = {


            PROPERTY_1255: gutterSpecialComments, //gutter.SpecialComments
            PROPERTY_1253: transformSavingData(guttersAdditionalDamaggToSupplementPhotos), //gutters.AdditionalDamaggToSupplement.Photos
            PROPERTY_1251: transformSavingData(gutterCoversPhotos), //gutter.Cover.Photos
            PROPERTY_1249: transformSavingData(gutterSizePhotos),//gutter.Size.Photos
            PROPERTY_1247: gutterSize, //gutter.Size
            PROPERTY_1199: guttersLeftUpperCovers, //gutters.LeftUpper.Covers
            PROPERTY_1197: guttersLeftUpperApproved, //gutters.LeftUpper.Approved
            PROPERTY_1195: guttersLeftUpper, //gutters.LeftUpper
            PROPERTY_1193: transformSavingData(guttersLeftPhotos),//gutters.Left.Photos
            PROPERTY_1191: guttersLeftCovers, //gutters.Left.Covers
            PROPERTY_1189: guttersLeftApproved, //gutters.Left.Approved
            PROPERTY_1187: guttersLeft, //gutters.Left
            PROPERTY_1185: guttersBackUpperCovers, //gutters.BackUpper.Covers
            PROPERTY_1183: guttersBackUpperApproved, //gutters.BackUpper.Approved
            PROPERTY_1181: guttersBackUpper, //gutters.BackUpper
            PROPERTY_1179: transformSavingData(guttersBackPhotos),//gutters.Back.Photos
            PROPERTY_1177: guttersBackCovers, //gutters.Back.Covers
            PROPERTY_1175: guttersBackApproved, //gutters.Back.Approved
            PROPERTY_1173: guttersBack, //gutters.Back
            PROPERTY_1171: guttersRightUpperCovers, //gutters.RightUpper.Covers
            PROPERTY_1169: guttersRightUpperApproved, //gutters.RightUpper.Approved
            PROPERTY_1167: guttersRightUpper, //gutters.RightUpper
            PROPERTY_1165: transformSavingData(guttersRightPhotos),//gutters.Right.Photos
            PROPERTY_1163: guttersRightCovers, //gutters.Right.Covers
            PROPERTY_1161: guttersRightApproved, //gutters.Right.Approved
            PROPERTY_1159: guttersRight, //gutters.Right
            PROPERTY_1157: guttersFrontUpperCovers, //gutters.FrontUpper.Covers
            PROPERTY_1155: guttersFrontUpperApproved, //gutters.FrontUpper.Approved
            PROPERTY_1153: guttersFrontUpper, //gutters.FrontUpper
            PROPERTY_1151: transformSavingData(guttersFrontPhotos),//gutters.Front.Photos
            PROPERTY_1149: guttersFrontCovers, //gutters.Front.Covers
            PROPERTY_1147: guttersFrontApproved, //gutters.Front.Approved
            PROPERTY_1145: guttersFront, //gutters.Front

            PROPERTY_1245: transformSavingData(downspoutsWidthPhotos),//downspouts.Width.Photos
            PROPERTY_1243: downspoutsWidth, //downspouts.Width
            PROPERTY_1241: downspoutsLeftUpperApproved, //downspouts.LeftUpper.Approved
            PROPERTY_1239: downspoutsLeftUpper, //downspouts.LeftUpper
            PROPERTY_1237: transformSavingData(downspoutsLeftPhotos),//downspouts.Left.Photos
            PROPERTY_1235: downspoutsLeftApproved, //downspouts.Left.Approved
            PROPERTY_1233: downspoutsLeft, //downspouts.Left
            PROPERTY_1231: downspoutsBackUpperApproved, //downspouts.BackUpper.Approved
            PROPERTY_1229: downspoutsBackUpper, //downspouts.BackUpper
            PROPERTY_1227: transformSavingData(downspoutsBackPhotos),//downspouts.Back.Photos
            PROPERTY_1223: downspoutsBackApproved, //downspouts.Back.Approved
            PROPERTY_1221: downspoutsBack, //downspouts.Back
            PROPERTY_1219: downspoutsRightUpperApproved, //downspouts.RightUpper.Approved
            PROPERTY_1217: downspoutsRightUpper, //downspouts.RightUpper
            PROPERTY_1215: transformSavingData(downspoutsRightPhotos),//downspouts.Right.Photos
            PROPERTY_1213: downspoutsRightApproved, //downspouts.Right.Approved
            PROPERTY_1211: downspoutsRight, //downspouts.Right
            PROPERTY_1209: downspoutsFrontUpperApproved, //downspouts.FrontUpper.Approved
            PROPERTY_1207: downspoutsFrontUpper, //downspouts.FrontUpper
            PROPERTY_1205: transformSavingData(downspoutsFrontPhotos),//downspouts.Front.Photos
            PROPERTY_1203: downspoutsFrontApproved, //downspouts.Front.Approved
            PROPERTY_1201: downspoutsFront, //downspouts.Front

            PROPERTY_1315: transformSavingData(guttersFrontUpperPhotos),
            PROPERTY_1317: transformSavingData(guttersRightUpperPhotos),
            PROPERTY_1319: transformSavingData(guttersBackUpperPhotos),
            PROPERTY_1321: transformSavingData(guttersLeftUpperPhotos),
            PROPERTY_1323: transformSavingData(downspoutsFrontUpperPhotos),
            PROPERTY_1325: transformSavingData(downspoutsRightUpperPhotos),
            PROPERTY_1327: transformSavingData(downspoutsBackUpperPhotos),
            PROPERTY_1329: transformSavingData(downspoutsLeftUpperPhotos),


            NO_PHOTOS_DATA: {
               "gutter.Front": guttersFront, //gutters.Front
               "gutter.Front.Approved": guttersFrontApproved, //gutters.Front.Approved
               "gutter.Front.Covers": guttersFrontCovers, //gutters.Front.Covers
               "gutter.FrontUpper": guttersFrontUpper, //gutters.FrontUpper
               "gutter.FrontUpper.Approved": guttersFrontUpperApproved, //gutters.FrontUpper.Approved
               "gutter.FrontUpper.Covers": guttersFrontUpperCovers, //gutters.FrontUpper.Covers
               "gutter.Right": guttersRight, //gutters.Right
               "gutter.Right.Approved": guttersRightApproved, //gutters.Right.Approved
               "gutter.Right.Covers": guttersRightCovers, //gutters.Right.Covers
               "gutter.RightUpper": guttersRightUpper, //gutters.RightUpper
               "gutter.RightUpper.Approved": guttersRightUpperApproved, //gutters.RightUpper.Approved
               "gutter.RightUpper.Covers": guttersRightUpperCovers, //gutters.RightUpper.Covers
               "gutter.Back": guttersBack, //gutters.Back
               "gutter.Back.Approved": guttersBackApproved, //gutters.Back.Approved
               "gutter.Back.Covers": guttersBackCovers, //gutters.Back.Covers
               "gutter.BackUpper": guttersBackUpper, //gutters.BackUpper
               "gutter.BackUpper.Approved": guttersBackUpperApproved, //gutters.BackUpper.Approved
               "gutter.BackUpper.Covers": guttersBackUpperCovers, //gutters.BackUpper.Covers
               "gutter.Left": guttersLeft, //gutters.Left
               "gutter.Left.Approved": guttersLeftApproved, //gutters.Left.Approved
               "gutter.Left.Covers": guttersLeftCovers, //gutters.Left.Covers
               "gutter.LeftUpper": guttersLeftUpper, //gutters.LeftUpper
               "gutter.LeftUpper.Approved": guttersLeftUpperApproved, //gutters.LeftUpper.Approved
               "gutter.LeftUpper.Covers": guttersLeftUpperCovers, //gutters.LeftUpper.Covers

               "downspouts.Front": downspoutsFront, //downspouts.Front
               "downspouts.Front.Approved": downspoutsFrontApproved, //downspouts.Front.Approved

               "downspouts.FrontUpper": downspoutsFrontUpper, //downspouts.FrontUpper
               "downspouts.FrontUpper.Approved": downspoutsFrontUpperApproved, //downspouts.FrontUpper.Approved
               "downspouts.Right": downspoutsRight, //downspouts.Right
               "downspouts.Right.Approved": downspoutsRightApproved, //downspouts.Right.Approved
               "downspouts.RightUpper": downspoutsRightUpper, //downspouts.RightUpper
               "downspouts.RightUpper.Approved": downspoutsRightUpperApproved, //downspouts.RightUpper.Approved
               "downspouts.Back": downspoutsBack, //downspouts.Back
               "downspouts.Back.Approved": downspoutsBackApproved, //downspouts.Back.Approved
               "downspouts.BackUpper": downspoutsBackUpper, //downspouts.BackUpper
               "downspouts.BackUpper.Approved": downspoutsBackUpperApproved, //downspouts.BackUpper.Approved
               "downspouts.Left": downspoutsLeft, //downspouts.Left
               "downspouts.Left.Approved": downspoutsLeftApproved, //downspouts.Left.Approved
               "downspouts.LeftUpper": downspoutsLeftUpper, //downspouts.LeftUpper
               "downspouts.LeftUpper.Approved": downspoutsLeftUpperApproved, //downspouts.LeftUpper.Approved

               "downspouts.Width": downspoutsWidth, //downspouts.Width
               "gutter.Size": gutterSize, //gutter.Size
               "gutter.Special Comments": gutterSpecialComments, //gutter.SpecialComments
















            }

         }

         dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA_WITH_GUTTERS_BLOCK', payload: guttersBlockData } )

console.log('Updationg guttersBlockData with:')
console.log(guttersBlockData)


         // triggering Save in InspectionEdit component
         dispatch({ type: 'SET_GUTTERS_READY_TO_SAVE_FLAG' })

      }
   }, [saveRequest]);

   useEffect(() => {

      //if(!inspectionAllData.hasOwnProperty('PROPERTY_799' )) return;
      //console.log('RELOADING data... in windows.jsx')
      // console.log('current inspectionAllData:')
      // console.log(inspectionAllData)

         setGutterSpecialComments(inspectionAllData.PROPERTY_1255) //gutter.SpecialComments
         const guttersAdditionalDamaggToSupplementPhotos = async () => {
            if(inspectionAllData.hasOwnProperty('guttersAdditionalDamaggToSupplementPhotos' )) {
               if(inspectionAllData.guttersAdditionalDamaggToSupplementPhotos.length > 0) {
                  let images = []
                  for (let i = 0; i < inspectionAllData.guttersAdditionalDamaggToSupplementPhotos.length; i++) {
                     const imageUrl = await getImageUrl(access_token_data, inspectionAllData.guttersAdditionalDamaggToSupplementPhotos[i].ID)
                     images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'FILE_ID': inspectionAllData.guttersAdditionalDamaggToSupplementPhotos[i].FILE_ID })
                  }
                  setGuttersAdditionalDamaggToSupplementPhotos(images)
                  dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                     payload: {...inspectionAllData,
                     'guttersAdditionalDamaggToSupplementPhotos': images
                  }})
               }
            }
         }
      guttersAdditionalDamaggToSupplementPhotos()

      const gutterCoversPhotos = async () => {
         if(inspectionAllData.hasOwnProperty('gutterCoversPhotos' )) {
            if(inspectionAllData.gutterCoversPhotos.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.gutterCoversPhotos.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.gutterCoversPhotos[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'FILE_ID': inspectionAllData.gutterCoversPhotos[i].FILE_ID })
               }
               setGutterCoversPhotos(images)
               dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                  payload: {...inspectionAllData,
                     'gutterCoversPhotos': images
                  }})
            }
         }
      }
      gutterCoversPhotos()

      const gutterSizePhotos = async () => {
         if(inspectionAllData.hasOwnProperty('gutterSizePhotos' )) {
            if(inspectionAllData.gutterSizePhotos.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.gutterSizePhotos.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.gutterSizePhotos[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'FILE_ID': inspectionAllData.gutterSizePhotos[i].FILE_ID })
               }
               setGutterSizePhotos(images)
               dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                  payload: {...inspectionAllData,
                     'gutterSizePhotos': images
                  }})
            }
         }
      }
      gutterSizePhotos()

         setGutterSize(inspectionAllData.PROPERTY_1247) //gutter.Size
         setGuttersLeftUpperCovers(inspectionAllData.PROPERTY_1199) //gutters.LeftUpper.Covers
         setGuttersLeftUpperApproved(inspectionAllData.PROPERTY_1197) //gutters.LeftUpper.Approved
         setGuttersLeftUpper(inspectionAllData.PROPERTY_1195) //gutters.LeftUpper


      const guttersLeftPhotos = async () => {
         if(inspectionAllData.hasOwnProperty('guttersLeftPhotos' )) {
            if(inspectionAllData.guttersLeftPhotos.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.guttersLeftPhotos.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.guttersLeftPhotos[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'FILE_ID': inspectionAllData.guttersLeftPhotos[i].FILE_ID })
               }
               setGuttersLeftPhotos(images)
               dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                  payload: {...inspectionAllData,
                     'guttersLeftPhotos': images
                  }})
            }
         }
      }
      guttersLeftPhotos()


         setGuttersLeftCovers(inspectionAllData.PROPERTY_1191) //gutters.Left.Covers
         setGuttersLeftApproved(inspectionAllData.PROPERTY_1189) //gutters.Left.Approved
         setGuttersLeft(inspectionAllData.PROPERTY_1187) //gutters.Left
         setGuttersBackUpperCovers(inspectionAllData.PROPERTY_1185) //gutters.BackUpper.Covers
         setGuttersBackUpperApproved(inspectionAllData.PROPERTY_1183) //gutters.BackUpper.Approved
         setGuttersBackUpper(inspectionAllData.PROPERTY_1181) //gutters.BackUpper

      const guttersBackPhotos = async () => {
         if(inspectionAllData.hasOwnProperty('guttersBackPhotos' )) {
            if(inspectionAllData.guttersBackPhotos.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.guttersBackPhotos.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.guttersBackPhotos[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'FILE_ID': inspectionAllData.guttersBackPhotos[i].FILE_ID })
               }
               setGuttersBackPhotos(images)
               dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                  payload: {...inspectionAllData,
                     'guttersBackPhotos': images
                  }})
            }
         }
      }
      guttersBackPhotos()

         setGuttersBackCovers(inspectionAllData.PROPERTY_1177) //gutters.Back.Covers
         setGuttersBackApproved(inspectionAllData.PROPERTY_1175) //gutters.Back.Approved
         setGuttersBack(inspectionAllData.PROPERTY_1173) //gutters.Back
         setGuttersRightUpperCovers(inspectionAllData.PROPERTY_1171) //gutters.RightUpper.Covers
         setGuttersRightUpperApproved(inspectionAllData.PROPERTY_1169) //gutters.RightUpper.Approved
         setGuttersRightUpper(inspectionAllData.PROPERTY_1167) //gutters.RightUpper


      const guttersRightPhotos = async () => {
         if(inspectionAllData.hasOwnProperty('guttersRightPhotos' )) {
            if(inspectionAllData.guttersRightPhotos.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.guttersRightPhotos.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.guttersRightPhotos[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'FILE_ID': inspectionAllData.guttersRightPhotos[i].FILE_ID })
               }
               setGuttersRightPhotos(images)
               dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                  payload: {...inspectionAllData,
                     'guttersRightPhotos': images
                  }})
            }
         }
      }
      guttersRightPhotos()

         setGuttersRightCovers(inspectionAllData.PROPERTY_1163) //gutters.Right.Covers
         setGuttersRightApproved(inspectionAllData.PROPERTY_1161) //gutters.Right.Approved
         setGuttersRight(inspectionAllData.PROPERTY_1159) //gutters.Right
         setGuttersFrontUpperCovers(inspectionAllData.PROPERTY_1157) //gutters.FrontUpper.Covers
         setGuttersFrontUpperApproved(inspectionAllData.PROPERTY_1155) //gutters.FrontUpper.Approved
         setGuttersFrontUpper(inspectionAllData.PROPERTY_1153) //gutters.FrontUpper

      const guttersFrontPhotos = async () => {
         if(inspectionAllData.hasOwnProperty('guttersFrontPhotos' )) {
            if(inspectionAllData.guttersFrontPhotos.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.guttersFrontPhotos.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.guttersFrontPhotos[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'FILE_ID': inspectionAllData.guttersFrontPhotos[i].FILE_ID })
               }
               setGuttersFrontPhotos(images)
               dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                  payload: {...inspectionAllData,
                     'guttersFrontPhotos': images
                  }})
            }
         }
      }
      guttersFrontPhotos()

         setGuttersFrontCovers(inspectionAllData.PROPERTY_1149) //gutters.Front.Covers
         setGuttersFrontApproved(inspectionAllData.PROPERTY_1147) //gutters.Front.Approved
         setGuttersFront(inspectionAllData.PROPERTY_1145) //gutters.Front

      const downspoutsWidthPhotos = async () => {
         if(inspectionAllData.hasOwnProperty('downspoutsWidthPhotos' )) {
            if(inspectionAllData.downspoutsWidthPhotos.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.downspoutsWidthPhotos.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.downspoutsWidthPhotos[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'FILE_ID': inspectionAllData.downspoutsWidthPhotos[i].FILE_ID })
               }
               setDownspoutsWidthPhotos(images)
               dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                  payload: {...inspectionAllData,
                     'downspoutsWidthPhotos': images
                  }})
            }
         }
      }
      downspoutsWidthPhotos()

         setDownspoutsWidth(inspectionAllData.PROPERTY_1243) //downspouts.Width
         setDownspoutsLeftUpperApproved(inspectionAllData.PROPERTY_1241) //downspouts.LeftUpper.Approved
         setDownspoutsLeftUpper(inspectionAllData.PROPERTY_1239) //downspouts.LeftUpper

      const downspoutsLeftPhotos = async () => {
         if(inspectionAllData.hasOwnProperty('downspoutsLeftPhotos' )) {
            if(inspectionAllData.downspoutsLeftPhotos.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.downspoutsLeftPhotos.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.downspoutsLeftPhotos[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'FILE_ID': inspectionAllData.downspoutsLeftPhotos[i].FILE_ID })
               }
               setDownspoutsLeftPhotos(images)
               dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                  payload: {...inspectionAllData,
                     'downspoutsLeftPhotos': images
                  }})
            }
         }
      }
      downspoutsLeftPhotos()

         setDownspoutsLeftApproved(inspectionAllData.PROPERTY_1235) //downspouts.Left.Approved
         setDownspoutsLeft(inspectionAllData.PROPERTY_1233) //downspouts.Left
         setDownspoutsBackUpperApproved(inspectionAllData.PROPERTY_1231) //downspouts.BackUpper.Approved
         setDownspoutsBackUpper(inspectionAllData.PROPERTY_1229) //downspouts.BackUpper

      const downspoutsBackPhotos = async () => {
         if(inspectionAllData.hasOwnProperty('downspoutsBackPhotos' )) {
            if(inspectionAllData.downspoutsBackPhotos.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.downspoutsBackPhotos.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.downspoutsBackPhotos[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'FILE_ID': inspectionAllData.downspoutsBackPhotos[i].FILE_ID })
               }
               setDownspoutsBackPhotos(images)
               dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                  payload: {...inspectionAllData,
                     'downspoutsBackPhotos': images
                  }})
            }
         }
      }
      downspoutsBackPhotos()

         setDownspoutsBackApproved(inspectionAllData.PROPERTY_1223) //downspouts.Back.Approved
         setDownspoutsBack(inspectionAllData.PROPERTY_1221) //downspouts.Back
         setDownspoutsRightUpperApproved(inspectionAllData.PROPERTY_1219) //downspouts.RightUpper.Approved
         setDownspoutsRightUpper(inspectionAllData.PROPERTY_1217) //downspouts.RightUpper

      const downspoutsRightPhotos = async () => {
         if(inspectionAllData.hasOwnProperty('downspoutsRightPhotos' )) {
            if(inspectionAllData.downspoutsRightPhotos.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.downspoutsRightPhotos.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.downspoutsRightPhotos[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'FILE_ID': inspectionAllData.downspoutsRightPhotos[i].FILE_ID })
               }
               setDownspoutsRightPhotos(images)
               dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                  payload: {...inspectionAllData,
                     'downspoutsRightPhotos': images
                  }})
            }
         }
      }
      downspoutsRightPhotos()

         setDownspoutsRightApproved(inspectionAllData.PROPERTY_1213) //downspouts.Right.Approved
         setDownspoutsRight(inspectionAllData.PROPERTY_1211) //downspouts.Right
         setDownspoutsFrontUpperApproved(inspectionAllData.PROPERTY_1209) //downspouts.FrontUpper.Approved
         setDownspoutsFrontUpper(inspectionAllData.PROPERTY_1207) //downspouts.FrontUpper

      const downspoutsFrontPhotos = async () => {
         if(inspectionAllData.hasOwnProperty('downspoutsFrontPhotos' )) {
            if(inspectionAllData.downspoutsFrontPhotos.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.downspoutsFrontPhotos.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.downspoutsFrontPhotos[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'FILE_ID': inspectionAllData.downspoutsFrontPhotos[i].FILE_ID })
               }
               setDownspoutsFrontPhotos(images)
               dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                  payload: {...inspectionAllData,
                     'downspoutsFrontPhotos': images
                  }})
            }
         }
      }
      downspoutsFrontPhotos()






      const guttersFrontUpperPhotos = async () => {
         if(inspectionAllData.hasOwnProperty('guttersFrontUpperPhotos' )) {
            if(inspectionAllData.guttersFrontUpperPhotos.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.guttersFrontUpperPhotos.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.guttersFrontUpperPhotos[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'FILE_ID': inspectionAllData.guttersFrontUpperPhotos[i].FILE_ID })
               }
               setGuttersFrontUpperPhotos(images)
               dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                  payload: {...inspectionAllData,
                     'guttersFrontUpperPhotos': images
                  }})
            }
         }
      }
      guttersFrontUpperPhotos()

      const guttersRightUpperPhotos = async () => {
         if(inspectionAllData.hasOwnProperty('guttersRightUpperPhotos' )) {
            if(inspectionAllData.guttersRightUpperPhotos.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.guttersRightUpperPhotos.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.guttersRightUpperPhotos[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'FILE_ID': inspectionAllData.guttersRightUpperPhotos[i].FILE_ID })
               }
               setGuttersRightUpperPhotos(images)
               dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                  payload: {...inspectionAllData,
                     'guttersRightUpperPhotos': images
                  }})
            }
         }
      }
      guttersRightUpperPhotos()

      const guttersBackUpperPhotos = async () => {
         if(inspectionAllData.hasOwnProperty('guttersBackUpperPhotos' )) {
            if(inspectionAllData.guttersBackUpperPhotos.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.guttersBackUpperPhotos.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.guttersBackUpperPhotos[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'FILE_ID': inspectionAllData.guttersBackUpperPhotos[i].FILE_ID })
               }
               setGuttersBackUpperPhotos(images)
               dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                  payload: {...inspectionAllData,
                     'guttersBackUpperPhotos': images
                  }})
            }
         }
      }
      guttersBackUpperPhotos()

      const guttersLeftUpperPhotos = async () => {
         if(inspectionAllData.hasOwnProperty('guttersLeftUpperPhotos' )) {
            if(inspectionAllData.guttersLeftUpperPhotos.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.guttersLeftUpperPhotos.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.guttersLeftUpperPhotos[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'FILE_ID': inspectionAllData.guttersLeftUpperPhotos[i].FILE_ID })
               }
               setGuttersLeftUpperPhotos(images)
               dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                  payload: {...inspectionAllData,
                     'guttersLeftUpperPhotos': images
                  }})
            }
         }
      }
      guttersLeftUpperPhotos()


      const downspoutsFrontUpperPhotos = async () => {
         if(inspectionAllData.hasOwnProperty('downspoutsFrontUpperPhotos' )) {
            if(inspectionAllData.downspoutsFrontUpperPhotos.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.downspoutsFrontUpperPhotos.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.downspoutsFrontUpperPhotos[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'FILE_ID': inspectionAllData.downspoutsFrontUpperPhotos[i].FILE_ID })
               }
               setDownspoutsFrontUpperPhotos(images)
               dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                  payload: {...inspectionAllData,
                     'downspoutsFrontUpperPhotos': images
                  }})
            }
         }
      }
      downspoutsFrontUpperPhotos()

      const downspoutsRightUpperPhotos = async () => {
         if(inspectionAllData.hasOwnProperty('downspoutsRightUpperPhotos' )) {
            if(inspectionAllData.downspoutsRightUpperPhotos.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.downspoutsRightUpperPhotos.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.downspoutsRightUpperPhotos[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'FILE_ID': inspectionAllData.downspoutsRightUpperPhotos[i].FILE_ID })
               }
               setDownspoutsRightUpperPhotos(images)
               dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                  payload: {...inspectionAllData,
                     'downspoutsRightUpperPhotos': images
                  }})
            }
         }
      }
      downspoutsRightUpperPhotos()

      const downspoutsBackUpperPhotos = async () => {
         if(inspectionAllData.hasOwnProperty('downspoutsBackUpperPhotos' )) {
            if(inspectionAllData.downspoutsBackUpperPhotos.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.downspoutsBackUpperPhotos.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.downspoutsBackUpperPhotos[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'FILE_ID': inspectionAllData.downspoutsBackUpperPhotos[i].FILE_ID })
               }
               setDownspoutsBackUpperPhotos(images)
               dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                  payload: {...inspectionAllData,
                     'downspoutsBackUpperPhotos': images
                  }})
            }
         }
      }
      downspoutsBackUpperPhotos()

      const downspoutsLeftUpperPhotos = async () => {
         if(inspectionAllData.hasOwnProperty('downspoutsLeftUpperPhotos' )) {
            if(inspectionAllData.downspoutsLeftUpperPhotos.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.downspoutsLeftUpperPhotos.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.downspoutsLeftUpperPhotos[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'FILE_ID': inspectionAllData.downspoutsLeftUpperPhotos[i].FILE_ID })
               }
               setDownspoutsLeftUpperPhotos(images)
               dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                  payload: {...inspectionAllData,
                     'downspoutsLeftUpperPhotos': images
                  }})
            }
         }
      }
      downspoutsLeftUpperPhotos()







      setDownspoutsFrontApproved(inspectionAllData.PROPERTY_1203) //downspouts.Front.Approved
         setDownspoutsFront(inspectionAllData.PROPERTY_1201) //downspouts.Front



   }, [flagReloadDatafromInspectionAllData]);



   const toBase64 = file => new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
   });


   const [drawerVar, setDrawerVar] = React.useState([])
   const [drawerVarName, setDrawerVarName] = React.useState('')
   const [drawerSetter, setDrawerSetter] = React.useState('')
   const [state, setState] = React.useState({top: false, left: false, bottom: false, right: false,});

   const toggleDrawer = (anchor, open, photosValues) => (event) => {
      if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
         return;
      }

      setDrawerVar(photosValues)
      setDrawerVarName(anchor)

      setState({ ...state, [anchor]: open });
   };

   const list = (drawerVar) => (
      <Box
         sx={{ width: '400px' }}
         role="presentation"
         onClick={toggleDrawer(drawerVarName, false, drawerVar)}
         onKeyDown={toggleDrawer(drawerVarName, false,drawerVar)}
      >

         <Stack direction="row" justifyContent="center" alignItems="center" padding={2}>
            <Button variant="text" onClick={toggleDrawer(drawerVarName, false, drawerVar)}>Back</Button>
         </Stack>


         <ImageList sx={{ width: '400px' }} cols={1} >
            {drawerVar.map((item) => (
               <ImageListItem key={item.DETAIL_URL}>
                  <img
                     src={`${item.DETAIL_URL}`}
                     srcSet={`${item.DETAIL_URL}`}
                     loading="lazy"
                  />

                  <Button
                     disableElevation={true}
                     value={item.FILE_ID}
                     onClick={ (event) => {
                        event.stopPropagation()

                        const updatedPhotos = drawerVar.filter( photo1 => photo1.FILE_ID != event.target.value )


// console.log(`drawerVarName = ${drawerVarName}`)
// console.log(`event.target.value = ${event.target.value}`)
// console.log("drawerVar:")
// console.log(drawerVar)
// console.log(`updated photos:`)
// console.log(updatedPhotos)


                        if(drawerVarName == "guttersAdditionalDamaggToSupplementPhotos") {
                           setGuttersAdditionalDamaggToSupplementPhotos(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }
                        if(drawerVarName == "gutterCoversPhotos") {
                           setGutterCoversPhotos(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }
                        if(drawerVarName == "gutterSizePhotos") {
                           setGutterSizePhotos(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }
                        if(drawerVarName == "guttersLeftPhotos") {
                           setGuttersLeftPhotos(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }
                        if(drawerVarName == "guttersBackPhotos") {
                           setGuttersLeftPhotos(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }
                        if(drawerVarName == "guttersRightPhotos") {
                           setGuttersRightPhotos(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }
                        if(drawerVarName == "guttersFrontPhotos") {
                           setGuttersFrontPhotos(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }
                        if(drawerVarName == "downspoutsWidthPhotos") {
                           setDownspoutsWidthPhotos(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }
                        if(drawerVarName == "downspoutsLeftPhotos") {
                           setDownspoutsLeftPhotos(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }
                        if(drawerVarName == "downspoutsBackPhotos") {
                           setDownspoutsBackPhotos(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }
                        if(drawerVarName == "downspoutsRightPhotos") {
                           setDownspoutsRightPhotos(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }
                        if(drawerVarName == "downspoutsFrontPhotos") {
                           setDownspoutsFrontPhotos(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }

                        if(drawerVarName == "guttersFrontUpperPhotos") {
                           setGuttersFrontUpperPhotos(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }
                        if(drawerVarName == "guttersRightUpperPhotos") {
                           setGuttersRightUpperPhotos(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }
                        if(drawerVarName == "guttersBackUpperPhotos") {
                           setGuttersBackUpperPhotos(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }
                        if(drawerVarName == "guttersLeftUpperPhotos") {
                           setGuttersLeftUpperPhotos(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }
                        if(drawerVarName == "downspoutsFrontUpperPhotos") {
                           setDownspoutsFrontUpperPhotos(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }
                        if(drawerVarName == "downspoutsRightUpperPhotos") {
                           setDownspoutsRightUpperPhotos(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }
                        if(drawerVarName == "downspoutsBackUpperPhotos") {
                           setDownspoutsBackUpperPhotos(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }
                        if(drawerVarName == "downspoutsLeftUpperPhotos") {
                           setDownspoutsLeftUpperPhotos(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }




                        // if(drawerVarName == "interiorPhotos") {
                        //    setInteriorPhotos(updatedPhotos)
                        //    setDrawerVar(updatedPhotos)
                        // }
                        // if(drawerVarName == "manufacturerMarkingsPhotos") {
                        //    setManufacturerMarkingsPhotos(updatedPhotos)
                        //    setDrawerVar(updatedPhotos)
                        // }
                        // if(drawerVarName == "closeUpOfWindowsDamagePhotos") {
                        //    setCloseUpOfWindowsDamagePhotos(updatedPhotos)
                        //    setDrawerVar(updatedPhotos)
                        // }
                        // if(drawerVarName == "closeUpOfWindowsDamagePhotos") {
                        //    setCloseUpOfWindowsDamagePhotos(updatedPhotos)
                        //    setDrawerVar(updatedPhotos)
                        // }
                        // if(drawerVarName == "elevationsPhotos") {
                        //    setElevationsPhotos(updatedPhotos)
                        //    setDrawerVar(updatedPhotos)
                        // }




                        let tmp = inspectionAllData
                        const updatedPhotos2 = tmp[drawerVarName].filter( photo1 => photo1.FILE_ID != event.target.value )
                        tmp[drawerVarName] = updatedPhotos2

// console.log(`tmp[drawerVarName]:`)
// console.log(tmp[drawerVarName])


                        dispatch({ type: 'SET_INSPECTION_ALL_DATA', payload: tmp } )
                     }}>Delete
                  </Button>

                  <br/>
               </ImageListItem>
            ))}
         </ImageList>
      </Box>
   );




   return (


      <Stack
         direction="column"
         justifyContent="left"
         alignItems="left"
         padding={2}
         spacing={1}
         sx={{ display: show === true ? 'flex' : 'none' }}
      >

         <Stack direction="row" justifyContent="left" alignItems="center" padding={0}>
            <React.Fragment key={'right'} ><Drawer anchor={'right'} open={state[drawerVarName]} onClose={toggleDrawer(drawerVarName, false, drawerVar)} sx={{zIndex: 9999}}>{list(drawerVar)}</Drawer></React.Fragment>
            <Typography sx={{my: 'auto'}}>Gutters</Typography>
         </Stack>
{/*Front*/}
         <Stack direction="row" justifyContent="flex-start" alignItems="center" padding={0}>
               <TextField sx={{width: '150px'}} id="gutters-front" label="Front" variant="outlined"
                          value={guttersFront}
                          onChange={(event) => {
                             setGuttersFront(event.target.value)
                          }}
               />
               <Stack direction="column" justifyContent="flex-start" alignItems="center" padding={1}>
                  <FormGroup>
                     <FormControlLabel control={<Checkbox checked={guttersFrontApproved} onChange={
                        (event) => {
                           setGuttersFrontApproved(event.target.checked);
                        }
                     }/>} label="Approved" />
                     <FormControlLabel control={<Checkbox checked={guttersFrontCovers} onChange={
                        (event) => {
                           setGuttersFrontCovers(event.target.checked);
                        }
                     }/>} label="Covers" />
                  </FormGroup>
               </Stack>
               <IconButton color="primary" aria-label="upload picture" component="label"
                           onClick={ () => {
                              dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'guttersFrontPhotos', page: 'gutters'} })
                           }}>
                  <PhotoCamera />
               </IconButton>
               <IconButton color="primary" aria-label="upload picture" component="label">
                  <input
                     type="file" accept="image/*" multiple hidden name="myImage"
                     onChange={(event) => {

                        const files = event.target.files
                        let photos = []

                        //files.forEach((file) => {
                        for (let i = 0; i < files.length; i++) {
                           reduce.toBlob(files[i], { max: 900 }).then(blob => {
                              const wrapSaveImage = async (dataUri) => {
                                 //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                                 const wrapper = async () => {
                                    const base64 = await toBase64(dataUri);
                                    const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', '').replace('data:image/png;base64,', ''), generateUniqueName: true}
                                    const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                    dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, guttersFrontPhotos: [...guttersFrontPhotos, ...photos, savedPhotoData]} })

                                    const updatedPhotos = [...photos, savedPhotoData]
                                    setGuttersFrontPhotos([...guttersFrontPhotos, ...updatedPhotos])
                                    photos.push(savedPhotoData)
                                 }
                                 wrapper()
                              }
                              wrapSaveImage(blob)
                           });
                        }
                     }}
                  />
                  <GalleryIcon />
               </IconButton>
         </Stack>
         <Stack direction="row" alignItems="center" spacing={1}>
            {guttersFrontPhotos.map((image, index) => (
               <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('guttersFrontPhotos', true, guttersFrontPhotos)}/>
            ))}
         </Stack>


{/*FrontUpper*/}
         <Stack direction="row" justifyContent="flex-start" alignItems="center" padding={0}>
            <TextField sx={{width: '150px'}} id="gutters-front-upper" label="Front-Upper" variant="outlined"
                       value={guttersFrontUpper}
                       onChange={(event) => {
                          setGuttersFrontUpper(event.target.value)
                       }}
            />
            <Stack direction="column" justifyContent="flex-start" alignItems="center" padding={1}>
               <FormGroup>
                  <FormControlLabel control={<Checkbox checked={guttersFrontUpperApproved} onChange={
                     (event) => {
                        setGuttersFrontUpperApproved(event.target.checked);
                     }
                  }/>} label="Approved" />
                  <FormControlLabel control={<Checkbox checked={guttersFrontUpperCovers} onChange={
                     (event) => {
                        setGuttersFrontUpperCovers(event.target.checked);
                     }
                  }/>} label="Covers" />
               </FormGroup>
            </Stack>
            <IconButton color="primary" aria-label="upload picture" component="label"
                        onClick={ () => {
                           dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'guttersFrontUpperPhotos', page: 'gutters'} })
                        }}>
               <PhotoCamera />
            </IconButton>
            <IconButton color="primary" aria-label="upload picture" component="label">
               <input
                  type="file" accept="image/*" multiple hidden name="myImage"
                  onChange={(event) => {

                     const files = event.target.files
                     let photos = []

                     //files.forEach((file) => {
                     for (let i = 0; i < files.length; i++) {
                        reduce.toBlob(files[i], { max: 900 }).then(blob => {
                           const wrapSaveImage = async (dataUri) => {
                              //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                              const wrapper = async () => {
                                 const base64 = await toBase64(dataUri);
                                 const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', '').replace('data:image/png;base64,', ''), generateUniqueName: true}
                                 const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                 dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, guttersFrontUpperPhotos: [...guttersFrontUpperPhotos, ...photos, savedPhotoData]} })

                                 const updatedPhotos = [...photos, savedPhotoData]
                                 setGuttersFrontUpperPhotos([...guttersFrontUpperPhotos, ...updatedPhotos])
                                 photos.push(savedPhotoData)
                              }
                              wrapper()
                           }
                           wrapSaveImage(blob)
                        });
                     }
                  }}
               />
               <GalleryIcon />
            </IconButton>
         </Stack>
         <Stack direction="row" alignItems="center" spacing={1}>
            {guttersFrontUpperPhotos.map((image, index) => (
               <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('guttersFrontUpperPhotos', true, guttersFrontUpperPhotos)}/>
            ))}
         </Stack>


{/*Right*/}
         <Stack direction="row" justifyContent="left" alignItems="center" padding={0}>
            <TextField sx={{width: '150px'}} id="gutters-front" label="Right" variant="outlined"
                       value={guttersRight}
                       onChange={(event) => {
                          setGuttersRight(event.target.value)
                       }}
            />
            <Stack direction="column" justifyContent="flex-start" alignItems="center" padding={1}>
               <FormGroup>
                  <FormControlLabel control={<Checkbox checked={guttersRightApproved} onChange={
                     (event) => {
                        setGuttersRightApproved(event.target.checked);
                     }
                  }/>} label="Approved" />
                  <FormControlLabel control={<Checkbox checked={guttersRightCovers} onChange={
                     (event) => {
                        setGuttersRightCovers(event.target.checked);
                     }
                  }/>} label="Covers" />
               </FormGroup>
            </Stack>
            <IconButton color="primary" aria-label="upload picture" component="label"
                        onClick={ () => {
                           dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'guttersRightPhotos', page: 'gutters'} })
                        }}>
               <PhotoCamera />
            </IconButton>
            <IconButton color="primary" aria-label="upload picture" component="label">
               <input
                  type="file" accept="image/*" multiple hidden name="myImage"
                  onChange={(event) => {

                     const files = event.target.files
                     let photos = []

                     //files.forEach((file) => {
                     for (let i = 0; i < files.length; i++) {
                        reduce.toBlob(files[i], { max: 900 }).then(blob => {
                           const wrapSaveImage = async (dataUri) => {
                              //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                              const wrapper = async () => {
                                 const base64 = await toBase64(dataUri);
                                 const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', '').replace('data:image/png;base64,', ''), generateUniqueName: true}
                                 const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                 dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, guttersRightPhotos: [...guttersRightPhotos, ...photos, savedPhotoData]} })

                                 const updatedPhotos = [...photos, savedPhotoData]
                                 setGuttersRightPhotos([...guttersRightPhotos, ...updatedPhotos])
                                 photos.push(savedPhotoData)
                              }
                              wrapper()
                           }
                           wrapSaveImage(blob)
                        });
                     }
                  }}
               />
               <GalleryIcon />
            </IconButton>
         </Stack>
         <Stack direction="row" alignItems="center" spacing={1}>
            {guttersRightPhotos.map((image, index) => (
               <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('guttersRightPhotos', true, guttersRightPhotos)}/>
            ))}
         </Stack>


{/*RightUpper*/}
         <Stack direction="row" justifyContent="left" alignItems="center" padding={0}>
            <TextField sx={{width: '150px'}} id="gutters-front-upper" label="Right-Upper" variant="outlined"
                       value={guttersRightUpper}
                       onChange={(event) => {
                          setGuttersRightUpper(event.target.value)
                       }}
            />
            <Stack direction="column" justifyContent="flex-start" alignItems="center" padding={1}>
               <FormGroup>
                  <FormControlLabel control={<Checkbox checked={guttersRightUpperApproved} onChange={
                     (event) => {
                        setGuttersRightUpperApproved(event.target.checked);
                     }
                  }/>} label="Approved" />
                  <FormControlLabel control={<Checkbox checked={guttersRightUpperCovers} onChange={
                     (event) => {
                        setGuttersRightUpperCovers(event.target.checked);
                     }
                  }/>} label="Covers" />
               </FormGroup>
            </Stack>
            <IconButton color="primary" aria-label="upload picture" component="label"
                        onClick={ () => {
                           dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'guttersRightUpperPhotos', page: 'gutters'} })
                        }}>
               <PhotoCamera />
            </IconButton>
            <IconButton color="primary" aria-label="upload picture" component="label">
               <input
                  type="file" accept="image/*" multiple hidden name="myImage"
                  onChange={(event) => {

                     const files = event.target.files
                     let photos = []

                     //files.forEach((file) => {
                     for (let i = 0; i < files.length; i++) {
                        reduce.toBlob(files[i], { max: 900 }).then(blob => {
                           const wrapSaveImage = async (dataUri) => {
                              //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                              const wrapper = async () => {
                                 const base64 = await toBase64(dataUri);
                                 const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', '').replace('data:image/png;base64,', ''), generateUniqueName: true}
                                 const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                 dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, guttersRightUpperPhotos: [...guttersRightUpperPhotos, ...photos, savedPhotoData]} })

                                 const updatedPhotos = [...photos, savedPhotoData]
                                 setGuttersRightUpperPhotos([...guttersRightUpperPhotos, ...updatedPhotos])
                                 photos.push(savedPhotoData)
                              }
                              wrapper()
                           }
                           wrapSaveImage(blob)
                        });
                     }
                  }}
               />
               <GalleryIcon />
            </IconButton>
         </Stack>
         <Stack direction="row" alignItems="center" spacing={1}>
            {guttersRightUpperPhotos.map((image, index) => (
               <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('guttersRightUpperPhotos', true, guttersRightUpperPhotos)}/>
            ))}
         </Stack>


{/*Back*/}
         <Stack direction="row" justifyContent="left" alignItems="center" padding={0}>
            <TextField sx={{width: '150px'}} id="gutters-front" label="Back" variant="outlined"
                       value={guttersBack}
                       onChange={(event) => {
                          setGuttersBack(event.target.value)
                       }}
            />
            <Stack direction="column" justifyContent="flex-start" alignItems="center" padding={1}>
               <FormGroup>
                  <FormControlLabel control={<Checkbox checked={guttersBackApproved} onChange={
                     (event) => {
                        setGuttersBackApproved(event.target.checked);
                     }
                  }/>} label="Approved" />
                  <FormControlLabel control={<Checkbox checked={guttersBackCovers} onChange={
                     (event) => {
                        setGuttersBackCovers(event.target.checked);
                     }
                  }/>} label="Covers" />
               </FormGroup>
            </Stack>
            <IconButton color="primary" aria-label="upload picture" component="label"
                        onClick={ () => {
                           dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'guttersBackPhotos', page: 'gutters'} })
                        }}>
               <PhotoCamera />
            </IconButton>
            <IconButton color="primary" aria-label="upload picture" component="label">
               <input
                  type="file" accept="image/*" multiple hidden name="myImage"
                  onChange={(event) => {

                     const files = event.target.files
                     let photos = []

                     //files.forEach((file) => {
                     for (let i = 0; i < files.length; i++) {
                        reduce.toBlob(files[i], { max: 900 }).then(blob => {
                           const wrapSaveImage = async (dataUri) => {
                              //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                              const wrapper = async () => {
                                 const base64 = await toBase64(dataUri);
                                 const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', '').replace('data:image/png;base64,', ''), generateUniqueName: true}
                                 const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                 dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, guttersBackPhotos: [...guttersBackPhotos, ...photos, savedPhotoData]} })

                                 const updatedPhotos = [...photos, savedPhotoData]
                                 setGuttersBackPhotos([...guttersBackPhotos, ...updatedPhotos])
                                 photos.push(savedPhotoData)
                              }
                              wrapper()
                           }
                           wrapSaveImage(blob)
                        });
                     }
                  }}
               />
               <GalleryIcon />
            </IconButton>
         </Stack>
         <Stack direction="row" alignItems="center" spacing={1}>
            {guttersBackPhotos.map((image, index) => (
               <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('guttersBackPhotos', true, guttersBackPhotos)}/>
            ))}
         </Stack>


{/*BackUpper*/}
         <Stack direction="row" justifyContent="left" alignItems="center" padding={0}>
            <TextField sx={{width: '150px'}} id="gutters-front-upper" label="BackUpper" variant="outlined"
                       value={guttersBackUpper}
                       onChange={(event) => {
                          setGuttersBackUpper(event.target.value)
                       }}
            />
            <Stack direction="column" justifyContent="flex-start" alignItems="center" padding={1}>
               <FormGroup>
                  <FormControlLabel control={<Checkbox checked={guttersBackUpperApproved} onChange={
                     (event) => {
                        setGuttersBackUpperApproved(event.target.checked);
                     }
                  }/>} label="Approved" />
                  <FormControlLabel control={<Checkbox checked={guttersBackUpperCovers} onChange={
                     (event) => {
                        setGuttersBackUpperCovers(event.target.checked);
                     }
                  }/>} label="Covers" />
               </FormGroup>
            </Stack>
            <IconButton color="primary" aria-label="upload picture" component="label"
                        onClick={ () => {
                           dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'guttersBackUpperPhotos', page: 'gutters'} })
                        }}>
               <PhotoCamera />
            </IconButton>
            <IconButton color="primary" aria-label="upload picture" component="label">
               <input
                  type="file" accept="image/*" multiple hidden name="myImage"
                  onChange={(event) => {

                     const files = event.target.files
                     let photos = []

                     //files.forEach((file) => {
                     for (let i = 0; i < files.length; i++) {
                        reduce.toBlob(files[i], { max: 900 }).then(blob => {
                           const wrapSaveImage = async (dataUri) => {
                              //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                              const wrapper = async () => {
                                 const base64 = await toBase64(dataUri);
                                 const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', '').replace('data:image/png;base64,', ''), generateUniqueName: true}
                                 const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                 dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, guttersBackUpperPhotos: [...guttersBackUpperPhotos, ...photos, savedPhotoData]} })

                                 const updatedPhotos = [...photos, savedPhotoData]
                                 setGuttersBackUpperPhotos([...guttersBackUpperPhotos, ...updatedPhotos])
                                 photos.push(savedPhotoData)
                              }
                              wrapper()
                           }
                           wrapSaveImage(blob)
                        });
                     }
                  }}
               />
               <GalleryIcon />
            </IconButton>
         </Stack>
         <Stack direction="row" alignItems="center" spacing={1}>
            {guttersBackUpperPhotos.map((image, index) => (
               <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('guttersBackUpperPhotos', true, guttersBackUpperPhotos)}/>
            ))}
         </Stack>


{/*Left*/}
         <Stack direction="row" justifyContent="left" alignItems="center" padding={0}>
            <TextField sx={{width: '150px'}} id="gutters-front" label="Left" variant="outlined"
                       value={guttersLeft}
                       onChange={(event) => {
                          setGuttersLeft(event.target.value)
                       }}
            />
            <Stack direction="column" justifyContent="flex-start" alignItems="center" padding={1}>
               <FormGroup>
                  <FormControlLabel control={<Checkbox checked={guttersLeftApproved} onChange={
                     (event) => {
                        setGuttersLeftApproved(event.target.checked);
                     }
                  }/>} label="Approved" />
                  <FormControlLabel control={<Checkbox checked={guttersLeftCovers} onChange={
                     (event) => {
                        setGuttersLeftCovers(event.target.checked);
                     }
                  }/>} label="Covers" />
               </FormGroup>
            </Stack>
            <IconButton color="primary" aria-label="upload picture" component="label"
                        onClick={ () => {
                           dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'guttersLeftPhotos', page: 'gutters'} })
                        }}>
               <PhotoCamera />
            </IconButton>
            <IconButton color="primary" aria-label="upload picture" component="label">
               <input
                  type="file" accept="image/*" multiple hidden name="myImage"
                  onChange={(event) => {

                     const files = event.target.files
                     let photos = []

                     //files.forEach((file) => {
                     for (let i = 0; i < files.length; i++) {
                        reduce.toBlob(files[i], { max: 900 }).then(blob => {
                           const wrapSaveImage = async (dataUri) => {
                              //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                              const wrapper = async () => {
                                 const base64 = await toBase64(dataUri);
                                 const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', '').replace('data:image/png;base64,', ''), generateUniqueName: true}
                                 const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                 dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, guttersLeftPhotos: [...guttersLeftPhotos, ...photos, savedPhotoData]} })

                                 const updatedPhotos = [...photos, savedPhotoData]
                                 setGuttersLeftPhotos([...guttersLeftPhotos, ...updatedPhotos])
                                 photos.push(savedPhotoData)
                              }
                              wrapper()
                           }
                           wrapSaveImage(blob)
                        });
                     }
                  }}
               />
               <GalleryIcon />
            </IconButton>
         </Stack>
         <Stack direction="row" alignItems="center" spacing={1}>
            {guttersLeftPhotos.map((image, index) => (
               <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('guttersLeftPhotos', true, guttersLeftPhotos)}/>
            ))}
         </Stack>


{/*LeftUpper*/}
         <Stack direction="row" justifyContent="left" alignItems="center" padding={0}>
            <TextField sx={{width: '150px'}} id="gutters-front-upper" label="LeftUpper" variant="outlined"
                       value={guttersLeftUpper}
                       onChange={(event) => {
                          setGuttersLeftUpper(event.target.value)
                       }}
            />
            <Stack direction="column" justifyContent="flex-start" alignItems="center" padding={1}>
               <FormGroup>
                  <FormControlLabel control={<Checkbox checked={guttersLeftUpperApproved} onChange={
                     (event) => {
                        setGuttersLeftUpperApproved(event.target.checked);
                     }
                  }/>} label="Approved" />
                  <FormControlLabel control={<Checkbox checked={guttersLeftUpperCovers} onChange={
                     (event) => {
                        setGuttersLeftUpperCovers(event.target.checked);
                     }
                  }/>} label="Covers" />
               </FormGroup>
            </Stack>
            <IconButton color="primary" aria-label="upload picture" component="label"
                        onClick={ () => {
                           dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'guttersLeftUpperPhotos', page: 'gutters'} })
                        }}>
               <PhotoCamera />
            </IconButton>
            <IconButton color="primary" aria-label="upload picture" component="label">
               <input
                  type="file" accept="image/*" multiple hidden name="myImage"
                  onChange={(event) => {

                     const files = event.target.files
                     let photos = []

                     //files.forEach((file) => {
                     for (let i = 0; i < files.length; i++) {
                        reduce.toBlob(files[i], { max: 900 }).then(blob => {
                           const wrapSaveImage = async (dataUri) => {
                              //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                              const wrapper = async () => {
                                 const base64 = await toBase64(dataUri);
                                 const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', '').replace('data:image/png;base64,', ''), generateUniqueName: true}
                                 const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                 dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, guttersLeftUpperPhotos: [...guttersLeftUpperPhotos, ...photos, savedPhotoData]} })

                                 const updatedPhotos = [...photos, savedPhotoData]
                                 setGuttersLeftUpperPhotos([...guttersLeftUpperPhotos, ...updatedPhotos])
                                 photos.push(savedPhotoData)
                              }
                              wrapper()
                           }
                           wrapSaveImage(blob)
                        });
                     }
                  }}
               />
               <GalleryIcon />
            </IconButton>
         </Stack>
         <Stack direction="row" alignItems="center" spacing={1}>
            {guttersLeftUpperPhotos.map((image, index) => (
               <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('guttersLeftUpperPhotos', true, guttersLeftUpperPhotos)}/>
            ))}
         </Stack>




{/*Downspouts ==========================================================================================================*/}

         <Stack direction="row" justifyContent="left" alignItems="left" padding={0}>
            <React.Fragment key={'right'} ><Drawer anchor={'right'} open={state[drawerVarName]} onClose={toggleDrawer(drawerVarName, false, drawerVar)} sx={{zIndex: 9999}}>{list(drawerVar)}</Drawer></React.Fragment>
            <Typography sx={{my: 'auto'}}>Downspouts</Typography>
         </Stack>

{/*Front*/}
         <Stack direction="row" justifyContent="left" alignItems="left" padding={0}>
            <TextField sx={{width: '150px'}} id="downspouts-front" label="Front" variant="outlined"
                       value={downspoutsFront}
                       onChange={(event) => {
                          setDownspoutsFront(event.target.value)
                       }}
            />
            <FormGroup>
               <FormControlLabel control={<Checkbox checked={downspoutsFrontApproved} onChange={
                  (event) => {
                     setDownspoutsFrontApproved(event.target.checked);
                  }
               }/>} label="Approved" />
            </FormGroup>
            <IconButton color="primary" aria-label="upload picture" component="label"
                        onClick={ () => {
                           dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'downspoutsFrontPhotos', page: 'gutters'} })
                        }}>
               <PhotoCamera />
            </IconButton>
            <IconButton color="primary" aria-label="upload picture" component="label">
               <input
                  type="file" accept="image/*" multiple hidden name="myImage"
                  onChange={(event) => {

                     const files = event.target.files
                     let photos = []

                     //files.forEach((file) => {
                     for (let i = 0; i < files.length; i++) {
                        reduce.toBlob(files[i], { max: 900 }).then(blob => {
                           const wrapSaveImage = async (dataUri) => {
                              //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                              const wrapper = async () => {
                                 const base64 = await toBase64(dataUri);
                                 const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', '').replace('data:image/png;base64,', ''), generateUniqueName: true}
                                 const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                 dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, downspoutsFrontPhotos: [...downspoutsFrontPhotos, ...photos, savedPhotoData]} })

                                 const updatedPhotos = [...photos, savedPhotoData]
                                 setDownspoutsFrontPhotos([...downspoutsFrontPhotos, ...updatedPhotos])
                                 photos.push(savedPhotoData)
                              }
                              wrapper()
                           }
                           wrapSaveImage(blob)
                        });
                     }
                  }}
               />
               <GalleryIcon />
            </IconButton>
         </Stack>
         <Stack direction="row" alignItems="center" spacing={1}>
            {downspoutsFrontPhotos.map((image, index) => (
               <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('downspoutsFrontPhotos', true, downspoutsFrontPhotos)}/>
            ))}
         </Stack>


{/*FrontUpper*/}
         <Stack direction="row" justifyContent="left" alignItems="left" padding={0}>
            <TextField sx={{width: '150px'}} id="downspouts-front-upper" label="Front-Upper" variant="outlined"
                       value={downspoutsFrontUpper}
                       onChange={(event) => {
                          setDownspoutsFrontUpper(event.target.value)
                       }}
            />
            <FormGroup>
               <FormControlLabel control={<Checkbox checked={downspoutsFrontUpperApproved} onChange={
                  (event) => {
                     setDownspoutsFrontUpperApproved(event.target.checked);
                  }
               }/>} label="Approved" />
            </FormGroup>
            <IconButton color="primary" aria-label="upload picture" component="label"
                        onClick={ () => {
                           dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'downspoutsFrontUpperPhotos', page: 'gutters'} })
                        }}>
               <PhotoCamera />
            </IconButton>
            <IconButton color="primary" aria-label="upload picture" component="label">
               <input
                  type="file" accept="image/*" multiple hidden name="myImage"
                  onChange={(event) => {

                     const files = event.target.files
                     let photos = []

                     //files.forEach((file) => {
                     for (let i = 0; i < files.length; i++) {
                        reduce.toBlob(files[i], { max: 900 }).then(blob => {
                           const wrapSaveImage = async (dataUri) => {
                              //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                              const wrapper = async () => {
                                 const base64 = await toBase64(dataUri);
                                 const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', '').replace('data:image/png;base64,', ''), generateUniqueName: true}
                                 const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                 dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, downspoutsFrontUpperPhotos: [...downspoutsFrontUpperPhotos, ...photos, savedPhotoData]} })

                                 const updatedPhotos = [...photos, savedPhotoData]
                                 setDownspoutsFrontUpperPhotos([...downspoutsFrontUpperPhotos, ...updatedPhotos])
                                 photos.push(savedPhotoData)
                              }
                              wrapper()
                           }
                           wrapSaveImage(blob)
                        });
                     }
                  }}
               />
               <GalleryIcon />
            </IconButton>
         </Stack>
         <Stack direction="row" alignItems="center" spacing={1}>
            {downspoutsFrontUpperPhotos.map((image, index) => (
               <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('downspoutsFrontUpperPhotos', true, downspoutsFrontUpperPhotos)}/>
            ))}
         </Stack>


{/*Right*/}
         <Stack direction="row" justifyContent="left" alignItems="left" padding={0}>
            <TextField sx={{width: '150px'}} id="downspouts-front" label="Right" variant="outlined"
                       value={downspoutsRight}
                       onChange={(event) => {
                          setDownspoutsRight(event.target.value)
                       }}
            />
            <FormGroup>
               <FormControlLabel control={<Checkbox checked={downspoutsRightApproved} onChange={
                  (event) => {
                     setDownspoutsRightApproved(event.target.checked);
                  }
               }/>} label="Approved" />
            </FormGroup>
            <IconButton color="primary" aria-label="upload picture" component="label"
                        onClick={ () => {
                           dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'downspoutsRightPhotos', page: 'gutters'} })
                        }}>
               <PhotoCamera />
            </IconButton>
            <IconButton color="primary" aria-label="upload picture" component="label">
               <input
                  type="file" accept="image/*" multiple hidden name="myImage"
                  onChange={(event) => {

                     const files = event.target.files
                     let photos = []

                     //files.forEach((file) => {
                     for (let i = 0; i < files.length; i++) {
                        reduce.toBlob(files[i], { max: 900 }).then(blob => {
                           const wrapSaveImage = async (dataUri) => {
                              //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                              const wrapper = async () => {
                                 const base64 = await toBase64(dataUri);
                                 const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', '').replace('data:image/png;base64,', ''), generateUniqueName: true}
                                 const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                 dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, downspoutsRightPhotos: [...downspoutsRightPhotos, ...photos, savedPhotoData]} })

                                 const updatedPhotos = [...photos, savedPhotoData]
                                 setDownspoutsRightPhotos([...downspoutsRightPhotos, ...updatedPhotos])
                                 photos.push(savedPhotoData)
                              }
                              wrapper()
                           }
                           wrapSaveImage(blob)
                        });
                     }
                  }}
               />
               <GalleryIcon />
            </IconButton>
         </Stack>
         <Stack direction="row" alignItems="center" spacing={1}>
            {downspoutsRightPhotos.map((image, index) => (
               <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('downspoutsRightPhotos', true, downspoutsRightPhotos)}/>
            ))}
         </Stack>


{/*RightUpper*/}
         <Stack direction="row" justifyContent="left" alignItems="left" padding={0}>
            <TextField sx={{width: '150px'}} id="downspouts-front-upper" label="Right-Upper" variant="outlined"
                       value={downspoutsRightUpper}
                       onChange={(event) => {
                          setDownspoutsRightUpper(event.target.value)
                       }}
            />
            <FormGroup>
               <FormControlLabel control={<Checkbox checked={downspoutsRightUpperApproved} onChange={
                  (event) => {
                     setDownspoutsRightUpperApproved(event.target.checked);
                  }
               }/>} label="Approved" />
            </FormGroup>
            <IconButton color="primary" aria-label="upload picture" component="label"
                        onClick={ () => {
                           dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'downspoutsRightUpperPhotos', page: 'gutters'} })
                        }}>
               <PhotoCamera />
            </IconButton>
            <IconButton color="primary" aria-label="upload picture" component="label">
               <input
                  type="file" accept="image/*" multiple hidden name="myImage"
                  onChange={(event) => {

                     const files = event.target.files
                     let photos = []

                     //files.forEach((file) => {
                     for (let i = 0; i < files.length; i++) {
                        reduce.toBlob(files[i], { max: 900 }).then(blob => {
                           const wrapSaveImage = async (dataUri) => {
                              //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                              const wrapper = async () => {
                                 const base64 = await toBase64(dataUri);
                                 const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', '').replace('data:image/png;base64,', ''), generateUniqueName: true}
                                 const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                 dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, downspoutsRightUpperPhotos: [...downspoutsRightUpperPhotos, ...photos, savedPhotoData]} })

                                 const updatedPhotos = [...photos, savedPhotoData]
                                 setDownspoutsRightUpperPhotos([...downspoutsRightUpperPhotos, ...updatedPhotos])
                                 photos.push(savedPhotoData)
                              }
                              wrapper()
                           }
                           wrapSaveImage(blob)
                        });
                     }
                  }}
               />
               <GalleryIcon />
            </IconButton>
         </Stack>
         <Stack direction="row" alignItems="center" spacing={1}>
            {downspoutsRightUpperPhotos.map((image, index) => (
               <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('downspoutsRightUpperPhotos', true, downspoutsRightUpperPhotos)}/>
            ))}
         </Stack>


{/*Back*/}
         <Stack direction="row" justifyContent="left" alignItems="left" padding={0}>
            <TextField sx={{width: '150px'}} id="downspouts-front" label="Back" variant="outlined"
                       value={downspoutsBack}
                       onChange={(event) => {
                          setDownspoutsBack(event.target.value)
                       }}
            />
            <FormGroup>
               <FormControlLabel control={<Checkbox checked={downspoutsBackApproved} onChange={
                  (event) => {
                     setDownspoutsBackApproved(event.target.checked);
                  }
               }/>} label="Approved" />
            </FormGroup>
            <IconButton color="primary" aria-label="upload picture" component="label"
                        onClick={ () => {
                           dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'downspoutsBackPhotos', page: 'gutters'} })
                        }}>
               <PhotoCamera />
            </IconButton>
            <IconButton color="primary" aria-label="upload picture" component="label">
               <input
                  type="file" accept="image/*" multiple hidden name="myImage"
                  onChange={(event) => {

                     const files = event.target.files
                     let photos = []

                     //files.forEach((file) => {
                     for (let i = 0; i < files.length; i++) {
                        reduce.toBlob(files[i], { max: 900 }).then(blob => {
                           const wrapSaveImage = async (dataUri) => {
                              //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                              const wrapper = async () => {
                                 const base64 = await toBase64(dataUri);
                                 const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', '').replace('data:image/png;base64,', ''), generateUniqueName: true}
                                 const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                 dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, downspoutsBackPhotos: [...downspoutsBackPhotos, ...photos, savedPhotoData]} })

                                 const updatedPhotos = [...photos, savedPhotoData]
                                 setDownspoutsBackPhotos([...downspoutsBackPhotos, ...updatedPhotos])
                                 photos.push(savedPhotoData)
                              }
                              wrapper()
                           }
                           wrapSaveImage(blob)
                        });
                     }
                  }}
               />
               <GalleryIcon />
            </IconButton>
         </Stack>
         <Stack direction="row" alignItems="center" spacing={1}>
            {downspoutsBackPhotos.map((image, index) => (
               <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('downspoutsBackPhotos', true, downspoutsBackPhotos)}/>
            ))}
         </Stack>


{/*BackUpper*/}
         <Stack direction="row" justifyContent="left" alignItems="left" padding={0}>
            <TextField sx={{width: '150px'}} id="downspouts-front-upper" label="BackUpper" variant="outlined"
                       value={downspoutsBackUpper}
                       onChange={(event) => {
                          setDownspoutsBackUpper(event.target.value)
                       }}
            />
            <FormGroup>
               <FormControlLabel control={<Checkbox checked={downspoutsBackUpperApproved} onChange={
                  (event) => {
                     setDownspoutsBackUpperApproved(event.target.checked);
                  }
               }/>} label="Approved" />
            </FormGroup>
            <IconButton color="primary" aria-label="upload picture" component="label"
                        onClick={ () => {
                           dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'downspoutsBackUpperPhotos', page: 'gutters'} })
                        }}>
               <PhotoCamera />
            </IconButton>
            <IconButton color="primary" aria-label="upload picture" component="label">
               <input
                  type="file" accept="image/*" multiple hidden name="myImage"
                  onChange={(event) => {

                     const files = event.target.files
                     let photos = []

                     //files.forEach((file) => {
                     for (let i = 0; i < files.length; i++) {
                        reduce.toBlob(files[i], { max: 900 }).then(blob => {
                           const wrapSaveImage = async (dataUri) => {
                              //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                              const wrapper = async () => {
                                 const base64 = await toBase64(dataUri);
                                 const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', '').replace('data:image/png;base64,', ''), generateUniqueName: true}
                                 const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                 dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, downspoutsBackUpperPhotos: [...downspoutsBackUpperPhotos, ...photos, savedPhotoData]} })

                                 const updatedPhotos = [...photos, savedPhotoData]
                                 setDownspoutsBackUpperPhotos([...downspoutsBackUpperPhotos, ...updatedPhotos])
                                 photos.push(savedPhotoData)
                              }
                              wrapper()
                           }
                           wrapSaveImage(blob)
                        });
                     }
                  }}
               />
               <GalleryIcon />
            </IconButton>
         </Stack>
         <Stack direction="row" alignItems="center" spacing={1}>
            {downspoutsBackUpperPhotos.map((image, index) => (
               <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('downspoutsBackUpperPhotos', true, downspoutsBackUpperPhotos)}/>
            ))}
         </Stack>


{/*Left*/}
         <Stack direction="row" justifyContent="left" alignItems="left" padding={0}>
            <TextField sx={{width: '150px'}} id="downspouts-front" label="Left" variant="outlined"
                       value={downspoutsLeft}
                       onChange={(event) => {
                          setDownspoutsLeft(event.target.value)
                       }}
            />
            <FormGroup>
               <FormControlLabel control={<Checkbox checked={downspoutsLeftApproved} onChange={
                  (event) => {
                     setDownspoutsLeftApproved(event.target.checked);
                  }
               }/>} label="Approved" />
            </FormGroup>
            <IconButton color="primary" aria-label="upload picture" component="label"
                        onClick={ () => {
                           dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'downspoutsLeftPhotos', page: 'gutters'} })
                        }}>
               <PhotoCamera />
            </IconButton>
            <IconButton color="primary" aria-label="upload picture" component="label">
               <input
                  type="file" accept="image/*" multiple hidden name="myImage"
                  onChange={(event) => {

                     const files = event.target.files
                     let photos = []

                     //files.forEach((file) => {
                     for (let i = 0; i < files.length; i++) {
                        reduce.toBlob(files[i], { max: 900 }).then(blob => {
                           const wrapSaveImage = async (dataUri) => {
                              //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                              const wrapper = async () => {
                                 const base64 = await toBase64(dataUri);
                                 const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', '').replace('data:image/png;base64,', ''), generateUniqueName: true}
                                 const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                 dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, downspoutsLeftPhotos: [...downspoutsLeftPhotos, ...photos, savedPhotoData]} })

                                 const updatedPhotos = [...photos, savedPhotoData]
                                 setDownspoutsLeftPhotos([...downspoutsLeftPhotos, ...updatedPhotos])
                                 photos.push(savedPhotoData)
                              }
                              wrapper()
                           }
                           wrapSaveImage(blob)
                        });
                     }
                  }}
               />
               <GalleryIcon />
            </IconButton>
         </Stack>
         <Stack direction="row" alignItems="center" spacing={1}>
            {downspoutsLeftPhotos.map((image, index) => (
               <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('downspoutsLeftPhotos', true, downspoutsLeftPhotos)}/>
            ))}
         </Stack>


{/*LeftUpper*/}
         <Stack direction="row" justifyContent="left" alignItems="left" padding={0}>
            <TextField sx={{width: '150px'}} id="downspouts-front-upper" label="LeftUpper" variant="outlined"
                       value={downspoutsLeftUpper}
                       onChange={(event) => {
                          setDownspoutsLeftUpper(event.target.value)
                       }}
            />
            <FormGroup>
               <FormControlLabel control={<Checkbox checked={downspoutsLeftUpperApproved} onChange={
                  (event) => {
                     setDownspoutsLeftUpperApproved(event.target.checked);
                  }
               }/>} label="Approved" />
            </FormGroup>
            <IconButton color="primary" aria-label="upload picture" component="label"
                        onClick={ () => {
                           dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'downspoutsLeftUpperPhotos', page: 'gutters'} })
                        }}>
               <PhotoCamera />
            </IconButton>
            <IconButton color="primary" aria-label="upload picture" component="label">
               <input
                  type="file" accept="image/*" multiple hidden name="myImage"
                  onChange={(event) => {

                     const files = event.target.files
                     let photos = []

                     //files.forEach((file) => {
                     for (let i = 0; i < files.length; i++) {
                        reduce.toBlob(files[i], { max: 900 }).then(blob => {
                           const wrapSaveImage = async (dataUri) => {
                              //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                              const wrapper = async () => {
                                 const base64 = await toBase64(dataUri);
                                 const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', '').replace('data:image/png;base64,', ''), generateUniqueName: true}
                                 const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                 dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, downspoutsLeftUpperPhotos: [...downspoutsLeftUpperPhotos, ...photos, savedPhotoData]} })

                                 const updatedPhotos = [...photos, savedPhotoData]
                                 setDownspoutsLeftUpperPhotos([...downspoutsLeftUpperPhotos, ...updatedPhotos])
                                 photos.push(savedPhotoData)
                              }
                              wrapper()
                           }
                           wrapSaveImage(blob)
                        });
                     }
                  }}
               />
               <GalleryIcon />
            </IconButton>
         </Stack>
         <Stack direction="row" alignItems="center" spacing={1}>
            {downspoutsLeftPhotos.map((image, index) => (
               <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('downspoutsLeftPhotos', true, downspoutsLeftPhotos)}/>
            ))}
         </Stack>


{/*footer ====*/}
         <Stack direction="row" alignItems="center" spacing={1}>
            <TextField fullWidth id="downspouts-front-upper" label="Downspouts Width (Tape Measure)" variant="outlined"
                       value={downspoutsWidth}
                       onChange={(event) => {
                          setDownspoutsWidth(event.target.value)
                       }}
            />
            <IconButton color="primary" aria-label="upload picture" component="label"
                        onClick={ () => {
                           dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'downspoutsWidthPhotos', page: 'gutters'} })
                        }}>
               <PhotoCamera />
            </IconButton>
            <IconButton color="primary" aria-label="upload picture" component="label">
               <input
                  type="file" accept="image/*" multiple hidden name="myImage"
                  onChange={(event) => {

                     const files = event.target.files
                     let photos = []

                     //files.forEach((file) => {
                     for (let i = 0; i < files.length; i++) {
                        reduce.toBlob(files[i], { max: 900 }).then(blob => {
                           const wrapSaveImage = async (dataUri) => {
                              //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                              const wrapper = async () => {
                                 const base64 = await toBase64(dataUri);
                                 const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', '').replace('data:image/png;base64,', ''), generateUniqueName: true}
                                 const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                 dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, downspoutsWidthPhotos: [...downspoutsWidthPhotos, ...photos, savedPhotoData]} })

                                 const updatedPhotos = [...photos, savedPhotoData]
                                 setDownspoutsWidthPhotos([...downspoutsWidthPhotos, ...updatedPhotos])
                                 photos.push(savedPhotoData)
                              }
                              wrapper()
                           }
                           wrapSaveImage(blob)
                        });
                     }
                  }}
               />
               <GalleryIcon />
            </IconButton>
            <Stack direction="row" alignItems="center" spacing={1}>
               {downspoutsWidthPhotos.map((image, index) => (
                  <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('downspoutsWidthPhotos', true, downspoutsWidthPhotos)}/>
               ))}
            </Stack>
         </Stack>

         <Stack direction="row" alignItems="center" spacing={1}>
            <TextField fullWidth id="downspouts-front-upper" label="Gutter Size (Tape Measure)" variant="outlined"
                       value={gutterSize}
                       onChange={(event) => {
                          setGutterSize(event.target.value)
                       }}
            />
            <IconButton color="primary" aria-label="upload picture" component="label"
                        onClick={ () => {
                           dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'gutterSizePhotos', page: 'gutters'} })
                        }}>
               <PhotoCamera />
            </IconButton>
            <IconButton color="primary" aria-label="upload picture" component="label">
               <input
                  type="file" accept="image/*" multiple hidden name="myImage"
                  onChange={(event) => {

                     const files = event.target.files
                     let photos = []

                     //files.forEach((file) => {
                     for (let i = 0; i < files.length; i++) {
                        reduce.toBlob(files[i], { max: 900 }).then(blob => {
                           const wrapSaveImage = async (dataUri) => {
                              //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                              const wrapper = async () => {
                                 const base64 = await toBase64(dataUri);
                                 const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', '').replace('data:image/png;base64,', ''), generateUniqueName: true}
                                 const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                 dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, gutterSizePhotos: [...gutterSizePhotos, ...photos, savedPhotoData]} })

                                 const updatedPhotos = [...photos, savedPhotoData]
                                 setGutterSizePhotos([...gutterSizePhotos, ...updatedPhotos])
                                 photos.push(savedPhotoData)
                              }
                              wrapper()
                           }
                           wrapSaveImage(blob)
                        });
                     }
                  }}
               />
               <GalleryIcon />
            </IconButton>
            <Stack direction="row" alignItems="center" spacing={1}>
               {gutterSizePhotos.map((image, index) => (
                  <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('gutterSizePhotos', true, gutterSizePhotos)}/>
               ))}
            </Stack>
         </Stack>

         <Stack direction="row" alignItems="center" spacing={1}>
            <Typography sx={{my: 'auto'}}>Gutter Covers Photos</Typography>
            <IconButton color="primary" aria-label="upload picture" component="label"
                        onClick={ () => {
                           dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'gutterCoversPhotos', page: 'gutters'} })
                        }}>
               <PhotoCamera />
            </IconButton>
            <IconButton color="primary" aria-label="upload picture" component="label">
               <input
                  type="file" accept="image/*" multiple hidden name="myImage"
                  onChange={(event) => {

                     const files = event.target.files
                     let photos = []

                     //files.forEach((file) => {
                     for (let i = 0; i < files.length; i++) {
                        reduce.toBlob(files[i], { max: 900 }).then(blob => {
                           const wrapSaveImage = async (dataUri) => {
                              //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                              const wrapper = async () => {
                                 const base64 = await toBase64(dataUri);
                                 const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', '').replace('data:image/png;base64,', ''), generateUniqueName: true}
                                 const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                 dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, gutterCoversPhotos: [...gutterCoversPhotos, ...photos, savedPhotoData]} })

                                 const updatedPhotos = [...photos, savedPhotoData]
                                 setGutterCoversPhotos([...gutterCoversPhotos, ...updatedPhotos])
                                 photos.push(savedPhotoData)
                              }
                              wrapper()
                           }
                           wrapSaveImage(blob)
                        });
                     }
                  }}
               />
               <GalleryIcon />
            </IconButton>
            <Stack direction="row" alignItems="center" spacing={1}>
               {gutterCoversPhotos.map((image, index) => (
                  <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('gutterCoversPhotos', true, gutterCoversPhotos)}/>
               ))}
            </Stack>
         </Stack>

         <Stack direction="row" alignItems="center" spacing={1}>
            <Typography sx={{my: 'auto'}}>Additional Damage to Supplement</Typography>
            <IconButton color="primary" aria-label="upload picture" component="label"
                        onClick={ () => {
                           dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'guttersAdditionalDamaggToSupplementPhotos', page: 'gutters'} })
                        }}>
               <PhotoCamera />
            </IconButton>
            <IconButton color="primary" aria-label="upload picture" component="label">
               <input
                  type="file" accept="image/*" multiple hidden name="myImage"
                  onChange={(event) => {

                     const files = event.target.files
                     let photos = []

                     //files.forEach((file) => {
                     for (let i = 0; i < files.length; i++) {
                        reduce.toBlob(files[i], { max: 900 }).then(blob => {
                           const wrapSaveImage = async (dataUri) => {
                              //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                              const wrapper = async () => {
                                 const base64 = await toBase64(dataUri);
                                 const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', '').replace('data:image/png;base64,', ''), generateUniqueName: true}
                                 const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                 dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, guttersAdditionalDamaggToSupplementPhotos: [...guttersAdditionalDamaggToSupplementPhotos, ...photos, savedPhotoData]} })

                                 const updatedPhotos = [...photos, savedPhotoData]
                                 setGuttersAdditionalDamaggToSupplementPhotos([...guttersAdditionalDamaggToSupplementPhotos, ...updatedPhotos])
                                 photos.push(savedPhotoData)
                              }
                              wrapper()
                           }
                           wrapSaveImage(blob)
                        });
                     }
                  }}
               />
               <GalleryIcon />
            </IconButton>
            <Stack direction="row" alignItems="center" spacing={1}>
               {guttersAdditionalDamaggToSupplementPhotos.map((image, index) => (
                  <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('guttersAdditionalDamaggToSupplementPhotos', true, guttersAdditionalDamaggToSupplementPhotos)}/>
               ))}
            </Stack>
         </Stack>

         <Stack direction="row" alignItems="center" spacing={1}>
            <TextField fullWidth id="downspouts-front-upper" label="Special Comments" variant="outlined"
                       value={gutterSpecialComments}
                       onChange={(event) => {
                          setGutterSpecialComments(event.target.value)
                       }}
                       rows={3}
                       multiline
            />
         </Stack>


      </Stack>
   );
}