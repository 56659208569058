import * as React from 'react';
import {
   Accordion, AccordionDetails,
   AccordionSummary, Autocomplete, Backdrop,
   Button, Checkbox,
   Dialog,
   FormControl, FormControlLabel, FormGroup, FormLabel,
   InputLabel,
   MenuItem, Radio, RadioGroup,
   Select,
   Slide, Stack, TextField
} from "@mui/material";
import Typography from "@mui/material/Typography";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import {useContext, useEffect} from "react";
import ECContext from "../context/inspections/ECContext";
import Box from "@mui/material/Box";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {PhotoCamera} from "@mui/icons-material";
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import AssistantDirectionIcon from '@mui/icons-material/AssistantDirection';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import General from "./inspection_blocks/general";
import Jobsite from "./inspection_blocks/jobsite";
import Elevations from "./inspection_blocks/elevations";
import Roof from "./inspection_blocks/roof";
import Siding from "./inspection_blocks/siding";
import Interior from "./inspection_blocks/interior";
import {
   getInspectionById,
   createNewInspectionAndPrepareData,
   updateInspection,
   createNewContact,
   createNewDeal,
   getLinkedInspections, updateDealWithSecondaryRespPerson, getDealData, getContactData,
} from "../context/inspections/ECActions";
import {useSnackbar} from "notistack";
import CustomCamera from "./tools/custom_camera";
import Photos from "./inspection_blocks/photos";
import CircularProgress from '@mui/material/CircularProgress';
import Reports from "./inspection_blocks/reports";
import Windows from "./inspection_blocks/windows";
import Gutters from "./inspection_blocks/gutters";
import RoofTestSquares from "./inspection_blocks/roof/roofTestSquares";


export default function InspectionEdit() {

   const { isDealWasCreated, flagGuttersReadyToSave, flagWindowsReadyToSave, readyWINDOWSdata, readyGUTTERSdata, isAutomaticSaving, photoDataToUpload, readySKYLIGHTSdata, flagSkylightsReadyToSave, readyROOFPHOTOSdata, flagRoofPhotosReadyToSave, readyTESTSQUAREdata, flagTestSquareReadyToSave, readyVENTILATIONdata, flagVentilationReadyToSave, readyROOFFIELDdata, flagRooffieldReadyToSave, readyINTERIORdata, flagInteriorReadyToSave, readySIDINGdata, flagSidingReadyToSave, readyELEVATIONSdata, flagElevationsReadyToSave, readyGENERALdata, readyJOBSITEdata, flagCleanAllControlsData, showInspectionDialog, flagGeneralReadyToSave, flagJobsiteReadyToSave, editInspectionId, dispatch, access_token_data, inspectionAllData } = useContext(ECContext)
   const [open, setOpen] = React.useState(false);
   const { enqueueSnackbar } = useSnackbar();

   const [section, setSection] = React.useState(10);
   const [structure, setStructure] = React.useState(10);
   const [structureOptions, setStructureOptions] = React.useState([{id: 20, label: '––– Add New ––––'} ]);
   //const [structureOptions, setStructureOptions] = React.useState([{id: 20, label: '––– Add New ––––'}, {id: 10, label: '1. Main Structure'}]);


   const [recordType, setRecordType] = React.useState(189); // 189: Inspection (main structure), 191: Additional Structure
   const [linkedMainStructure, setLinkedMainStructure] = React.useState('');

   const [showProgress, setShowProgress] = React.useState(false);
   const [firstAutoSaving, setFirstAutoSaving] = React.useState(false);


//SAVING BLOCK *********************************************************************************************************

   useEffect(() => {

// console.log("We're in InspectionEdit. SAVING.")
// console.log(`flagGeneralReadyToSave: ${flagGeneralReadyToSave}, flagJobsiteReadyToSave: ${flagJobsiteReadyToSave}
// flagInteriorReadyToSave: ${flagInteriorReadyToSave}`)


      if( flagGeneralReadyToSave == true
         && flagJobsiteReadyToSave == true
         && flagElevationsReadyToSave == true
         && flagSidingReadyToSave == true
         && flagWindowsReadyToSave == true
         && flagGuttersReadyToSave == true
         && flagInteriorReadyToSave == true
         && flagRooffieldReadyToSave == true
         && flagVentilationReadyToSave == true
         && flagTestSquareReadyToSave == true
         //&& flagRoofPhotosReadyToSave== true
         && flagSkylightsReadyToSave == true) {


         // т.к. мы попали сюда, значит все страницы заполнили свои данные и их можно брать и в Report (когда выбрано там No-Photos)
         dispatch({ type: 'SET_NOPHOTOS_READY_DATA' })

         const wrap = async () => {






//             console.log('>>> Saving allDataForJsonField:')
// console.log(allDataForJsonField)


            // дозаполним InsuranceAllData полями отсюда, из InspectionEdit
            let inspectionEditBlockData = {
               ...inspectionAllData,

               ...readyGENERALdata,
               ...readyJOBSITEdata,
               ...readyELEVATIONSdata,
               ...readySIDINGdata,
               ...readyWINDOWSdata,
               ...readyGUTTERSdata,
               ...readyINTERIORdata,
               ...readyROOFFIELDdata,
               ...readyVENTILATIONdata,
               ...readyTESTSQUAREdata,
               ...readyROOFPHOTOSdata,
               ...readySKYLIGHTSdata,

               PROPERTY_795: recordType,        //_Record Type
               PROPERTY_797: linkedMainStructure,//Linked main structure
            }

            // if(recordType == 191) // для строений название при обнолвении не перезаписываем (его уже дали праивльное при создании)
            //    delete inspectionEditBlockData.NAME
            if(recordType == 189) // для главного строения LinkStrucuture не нужен
               delete inspectionEditBlockData.PROPERTY_797




            console.log('inspectionEditBlockData: ', inspectionEditBlockData)
            console.log('', )
            console.log('', )
            console.log('', )
            console.log('', )


// Если Name, LastName and Address are empty и при этом УКАЗАН LinkeDeal, то подтскиваем информацию из этого Deal'а и заполняем наши  пустые поляё, после чего идем дальше все это дело сохранять
            let contactName = ''
            let contactLastName = ''
            let contactEmail = ''
            let contactPhone = ''
            let address = ''
            if(inspectionEditBlockData.PROPERTY_795 != 191        // Если это не Additional Structure (тогда никаких сдело не надо создавать)
               && inspectionEditBlockData.hasOwnProperty('PROPERTY_799') && inspectionEditBlockData.PROPERTY_799.length == 0     // name
               && (inspectionEditBlockData.hasOwnProperty('PROPERTY_801') && inspectionEditBlockData.PROPERTY_801.length == 0)   // lastname is not empty
               && (inspectionEditBlockData.hasOwnProperty('PROPERTY_803') && inspectionEditBlockData.PROPERTY_803.length == 0)   // address is not empty
               && inspectionEditBlockData.hasOwnProperty('PROPERTY_789') && inspectionEditBlockData.PROPERTY_789.length !== 0
            ) {

console.log('we are insid deal getting!')

               if(inspectionEditBlockData.PROPERTY_789 != null) {
                  const dealIdToGet = inspectionEditBlockData.PROPERTY_789.id;

                  const dealData = await getDealData(access_token_data, dealIdToGet)
console.log('dealData: ', dealData)

                  const contactId = dealData.CONTACT_ID
                  const contactData = await getContactData(access_token_data, contactId)
console.log('contactData: ', contactData)

                  contactName = contactData.NAME;
                  contactLastName = contactData.LAST_NAME;
                  if(contactData.EMAIL != undefined && contactData.EMAIL.length > 0)
                     contactEmail = contactData.EMAIL[0].VALUE;
                  if(contactData.PHONE != undefined && contactData.PHONE.length > 0)
                     contactPhone = contactData.PHONE[0].VALUE;
                  address = dealData.UF_CRM_1610540918.substring(0, dealData.UF_CRM_1610540918.indexOf('|'))

console.log(`name: ${contactName} lastName:${contactLastName} address:${address}`)

                  inspectionEditBlockData.PROPERTY_799 = contactName
                  inspectionEditBlockData.PROPERTY_801 = contactLastName
                  inspectionEditBlockData.PROPERTY_1109 = contactEmail
                  inspectionEditBlockData.PROPERTY_805 = contactPhone
                  inspectionEditBlockData.PROPERTY_803 = address

                  dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: inspectionEditBlockData } )
                  dispatch({ type: 'UPDATE_NAMES_AND_ADDRESS'} )
               }

            }







// Не даём сохранять, если Имя или Фамилия или Адрес пустые
console.log('firstAutoSaving: ',firstAutoSaving)
console.log('inspectionEditBlockData.PROPERTY_795: ',inspectionEditBlockData.PROPERTY_795)
console.log('inspectionEditBlockData.PROPERTY_799.length: ',inspectionEditBlockData.PROPERTY_799.length)
console.log('inspectionEditBlockData.PROPERTY_801.length: ',inspectionEditBlockData.PROPERTY_801.length)
console.log('inspectionEditBlockData.PROPERTY_803.length: ',inspectionEditBlockData.PROPERTY_803.length)

            if(!firstAutoSaving &&
               inspectionEditBlockData.PROPERTY_795 != 191        // Если это не Additional Structure (тогда никаких сдело не надо создавать)
               &&
               (
                  (inspectionEditBlockData.hasOwnProperty('PROPERTY_799') && inspectionEditBlockData.PROPERTY_799.length == 0)    //name
                  || (inspectionEditBlockData.hasOwnProperty('PROPERTY_801') && inspectionEditBlockData.PROPERTY_801.length == 0) //lastname is not empty
                  || (inspectionEditBlockData.hasOwnProperty('PROPERTY_803') && inspectionEditBlockData.PROPERTY_803.length == 0) //address is not empty
               )
            ) {
               enqueueSnackbar('Name, LastName and Address fields shouldn\'t be empty', 'error');
               setShowProgress(false)
               dispatch({ type: 'DROP_SAVE_REQUEST' })

               dispatch({ type: 'DROP_GENERAL_READY_TO_SAVE_FLAG' })
               dispatch({ type: 'DROP_JOBSITE_READY_TO_SAVE_FLAG' })
               dispatch({ type: 'DROP_ELEVATIONS_READY_TO_SAVE_FLAG' })
               dispatch({ type: 'DROP_SIDING_READY_TO_SAVE_FLAG' })
               dispatch({ type: 'DROP_WINDOWS_READY_TO_SAVE_FLAG' })
               dispatch({ type: 'DROP_GUTTERS_READY_TO_SAVE_FLAG' })
               dispatch({ type: 'DROP_INTERIOR_READY_TO_SAVE_FLAG' })
               dispatch({ type: 'DROP_ROOFFIELD_READY_TO_SAVE_FLAG' })
               dispatch({ type: 'DROP_VENTILATION_READY_TO_SAVE_FLAG' })
               dispatch({ type: 'DROP_ROOFPHOTOS_READY_TO_SAVE_FLAG' })
               dispatch({ type: 'DROP_SKYLIGHTS_READY_TO_SAVE_FLAG' })

               return;
            }


// If the deal has been already created and linked – just update Second Responsible Person
            if(inspectionEditBlockData.hasOwnProperty('PROPERTY_789') && inspectionEditBlockData.hasOwnProperty('PROPERTY_1313')) {

               if(inspectionEditBlockData.PROPERTY_789 != null) {
                  const dealIdToUpdate = inspectionEditBlockData.PROPERTY_789.id;
                  const secondaryRespPersonId = inspectionEditBlockData.PROPERTY_1313.id;

                  console.log('Updating the existing dealID: ', dealIdToUpdate)
                  console.log('with Secondary Responsible UserId: ', secondaryRespPersonId)

                  const updateDealParams = {
                     DEAL_ID: dealIdToUpdate,
                     UF_CRM_1616850979: `${inspectionEditBlockData.hasOwnProperty('PROPERTY_1313') ? inspectionEditBlockData.PROPERTY_1313.id : ''}`, //'Second responsible for the split deal' deal field
                     OBSERVER: [`${inspectionEditBlockData.hasOwnProperty('PROPERTY_1313') ? inspectionEditBlockData.PROPERTY_1313.id : ''}`]
                  }
                  console.log('with parameters: ', updateDealParams)
                  await updateDealWithSecondaryRespPerson(access_token_data, updateDealParams)
               }

            }








console.log('=============== CONDITIONS to create a New Deal===========')
console.log('inspectionEditBlockData.isNew', inspectionEditBlockData.isNew)
console.log('isDealWasCreated', isDealWasCreated)
console.log('inspectionEditBlockData.PROPERTY_789', inspectionEditBlockData.PROPERTY_789)
console.log('inspectionEditBlockData.PROPERTY_795', inspectionEditBlockData.PROPERTY_795)
console.log('inspectionEditBlockData.PROPERTY_799', inspectionEditBlockData.PROPERTY_799)
console.log('inspectionEditBlockData.PROPERTY_801', inspectionEditBlockData.PROPERTY_801)
console.log('inspectionEditBlockData.PROPERTY_803', inspectionEditBlockData.PROPERTY_803)
console.log('', )



// Conditions if we need to create a [NEW DEAL]
            // если в linkedDealId в GeneralBlock не выбран, и это новая Inspection, то надо создать новую сделку и подставить её ID в поле linkedDealId
            if(inspectionEditBlockData.isNew
               && isDealWasCreated == false
               && inspectionEditBlockData.PROPERTY_789.length == 0   // linkedDealId is empty
               && inspectionEditBlockData.PROPERTY_795 != 191        // Если это не Additional Structure (тогда никаких сдело не надо создавать)
               && (inspectionEditBlockData.hasOwnProperty('PROPERTY_799') && inspectionEditBlockData.PROPERTY_799.length > 0)
               && (inspectionEditBlockData.hasOwnProperty('PROPERTY_801') && inspectionEditBlockData.PROPERTY_801.length > 0) //lastname is not empty
               && (inspectionEditBlockData.hasOwnProperty('PROPERTY_803') && inspectionEditBlockData.PROPERTY_803.length > 0) //address is not empty
            ) {

console.log('Creating a NEW CONTACT (for the Deal)')

               const newContactParams = {
                  NAME: inspectionEditBlockData.PROPERTY_799,
                  LAST_NAME: inspectionEditBlockData.PROPERTY_801,
                  PHONE: inspectionEditBlockData.PROPERTY_805,
                  EMAIL: inspectionEditBlockData.PROPERTY_1109
               }


               const newContactId = await createNewContact(access_token_data, newContactParams)

console.log('Creating a NEW DEAL')
               const newDealTitle = `${inspectionEditBlockData.NAME} ${inspectionEditBlockData.hasOwnProperty('PROPERTY_801') ? inspectionEditBlockData.PROPERTY_801 : ''} ${inspectionEditBlockData.hasOwnProperty('PROPERTY_803') ? inspectionEditBlockData.PROPERTY_803 : ''}`
               const newDealParams = {
                  TITLE: newDealTitle,
                  CONTACT_ID: newContactId,
                  UF_CRM_1610540918: `${inspectionEditBlockData.hasOwnProperty('PROPERTY_803') ? inspectionEditBlockData.PROPERTY_803 : ''}`,
                  UF_CRM_1616850979: `${inspectionEditBlockData.hasOwnProperty('PROPERTY_1313') ? inspectionEditBlockData.PROPERTY_1313.id : ''}`, //'Second responsible for the split deal' deal field
                  OBSERVER: [`${inspectionEditBlockData.hasOwnProperty('PROPERTY_1313') ? inspectionEditBlockData.PROPERTY_1313.id : ''}`]
               }
console.log('with parameters: ', newDealParams)
               const newDealId = await createNewDeal(access_token_data, newDealParams)

               dispatch({ type: 'SET_IS_DEAL_WAS_CREATED'})

               inspectionEditBlockData.PROPERTY_789 = { id: newDealId, label: newDealTitle }  //_Linked Deal
               dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: inspectionEditBlockData } )
               dispatch({ type: 'UPDATE_LINKED_DEAL'} )

               readyGENERALdata.PROPERTY_789 = { id: newDealId, label: newDealTitle }  //_Linked Deal
            }





            const allDataForJsonField = {
               ...inspectionAllData,

               ...readyGENERALdata,
               ...readyJOBSITEdata,
               ...readyELEVATIONSdata,
               ...readySIDINGdata,
               ...readyWINDOWSdata,
               ...readyGUTTERSdata,
               ...readyINTERIORdata,
               ...readyROOFFIELDdata,
               ...readyVENTILATIONdata,
               ...readyTESTSQUAREdata,
               ...readyROOFPHOTOSdata,
               ...readySKYLIGHTSdata,
               PROPERTY_795: recordType,        //_Record Type
               PROPERTY_797: linkedMainStructure,//Linked main structure
               photoFolderId: inspectionAllData.photoFolderId
            }
            delete allDataForJsonField.PROPERTY_1107 // eдалим json (а то он каждый раз добавляляс :)

            console.log(' Data for JSON.stringifay while saving')
            console.log(allDataForJsonField)



            inspectionEditBlockData.PROPERTY_1107 = JSON.stringify(allDataForJsonField) //inspectionAllData

            // заполним дату создания для нового Inspection. Потому что этот долбанный механизм list.get не отдаёт это поле. Пидоры.
            // if(inspectionEditBlockData.isNew) {
            //    let tmpCreationDate = new Date()
            //    allDataForJsonField.PROPERTY_1311 = tmpCreationDate.toLocaleDateString("en-US")
            //    inspectionEditBlockData.PROPERTY_1311 = tmpCreationDate.toLocaleDateString("en-US")
            // }
            // else {
            //    if (inspectionAllData.hasOwnProperty('PROPERTY_1311') && inspectionAllData.PROPERTY_1311.length > 0) {
            //
            //       allDataForJsonField.PROPERTY_1311 = inspectionAllData.PROPERTY_1311
            //       inspectionEditBlockData.PROPERTY_1311 = inspectionAllData.PROPERTY_1311
            //    }
            //
            // }




            //dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: inspectionEditBlockData } )

            console.log(' >>> Updating Inspection with data: >>>')
            console.log(inspectionEditBlockData)

            const updateResult = await updateInspection(access_token_data, inspectionEditBlockData)


            if(updateResult.hasOwnProperty('error')) {
               const errorMessage = updateResult.error_description
               enqueueSnackbar(`Can't save: ${errorMessage}`, 'error');
            }
            else {


               // if(!isAutomaticSaving)
               //    enqueueSnackbar('Saved!', 'error');
            }
            dispatch({ type: 'DROP_SAVE_REQUEST' })

// если выше была ситуация, когда мы подтянули имена и адрес из сделки, то надо эти значения отправить в контролы
            if(contactName.length != 0) {
               // dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: inspectionEditBlockData } )
               // dispatch({ type: 'UPDATE_NAMES_AND_ADDRESS'} )
            }


            setShowProgress(false)
         }
         wrap().then(r => {});

         dispatch({ type: 'DROP_GENERAL_READY_TO_SAVE_FLAG' })
         dispatch({ type: 'DROP_JOBSITE_READY_TO_SAVE_FLAG' })
         dispatch({ type: 'DROP_ELEVATIONS_READY_TO_SAVE_FLAG' })
         dispatch({ type: 'DROP_SIDING_READY_TO_SAVE_FLAG' })
         dispatch({ type: 'DROP_WINDOWS_READY_TO_SAVE_FLAG' })
         dispatch({ type: 'DROP_GUTTERS_READY_TO_SAVE_FLAG' })
         dispatch({ type: 'DROP_INTERIOR_READY_TO_SAVE_FLAG' })
         dispatch({ type: 'DROP_ROOFFIELD_READY_TO_SAVE_FLAG' })
         dispatch({ type: 'DROP_VENTILATION_READY_TO_SAVE_FLAG' })
         dispatch({ type: 'DROP_ROOFPHOTOS_READY_TO_SAVE_FLAG' })
         dispatch({ type: 'DROP_SKYLIGHTS_READY_TO_SAVE_FLAG' })

      }


   }, [flagGeneralReadyToSave, flagJobsiteReadyToSave, flagElevationsReadyToSave, flagSidingReadyToSave,
      flagWindowsReadyToSave, flagGuttersReadyToSave, flagInteriorReadyToSave, flagRooffieldReadyToSave, flagVentilationReadyToSave, flagTestSquareReadyToSave,
      flagRoofPhotosReadyToSave, flagSkylightsReadyToSave]);








   useEffect(() => {
      setOpen(showInspectionDialog)

      if(showInspectionDialog) {

// если открываемый диалог – для НОВОЙ Inspection
         if(inspectionAllData.isNew === true) {

            setFirstAutoSaving(true)

            const createInspectionsAndFolder = async () => {

               const inspectionAndFolderIDs = await createNewInspectionAndPrepareData(access_token_data)

               dispatch({ type: 'SET_INSPECTION_ALL_DATA', payload: {...inspectionAllData, inspectionId: inspectionAndFolderIDs.newInspectionId, photoFolderId: inspectionAndFolderIDs.newInspectionFolderId}})
               dispatch({ type: 'SET_INSPECTION_GENERAL_DATA', payload: {showBlock: true} })

               setLinkedMainStructure(inspectionAndFolderIDs.newInspectionId)

               setStructureOptions([...structureOptions, {id: inspectionAndFolderIDs.newInspectionId, label: '1. Main Structure'}])
               setStructure(inspectionAndFolderIDs.newInspectionId)

               dispatch({ type: 'SAVE_REQUEST', payload: true })

            }
            createInspectionsAndFolder().then(r => {});

         }
         else {
// если открываемый диалог – для СУЩЕСТВУЮЩЕЙ Inspection
            dispatch({ type: 'SET_INSPECTION_GENERAL_DATA', payload: {inspectionId: editInspectionId, showBlock: true} })

            setLinkedMainStructure(editInspectionId)

// подгрузим связанные доп. строения (если таккие есть)
            const loadLinkedStructures = async () => {
               const linkedStructures = await getLinkedInspections(access_token_data, editInspectionId)

               let linked = [{id: 20, label: '––– Add New ––––'}, {id: editInspectionId, label: '1. Main Structure'}]
               linkedStructures.forEach( (linkedStructure) => {
                  linked.push({id: linkedStructure.ID, label: linkedStructure.NAME} )
               })
               setStructureOptions([...linked])

               setStructure(editInspectionId)

console.log('Linked structures for inpsectionId: ${editInspectionId}')
console.log(linkedStructures)
            }
            loadLinkedStructures()


// тут мы открываем существующий Inspection. Поэтому загрузим по нему данные из Б24 и скажем всем компонентам их подгрузить
            const wrap = async () => {
               const inspectionData = await getInspectionById(access_token_data, editInspectionId)

console.log('Inspection data loaded for editing:')
console.log(inspectionData)

               const loadedInspectionAllData = JSON.parse(Object.values(inspectionData.PROPERTY_1107)[0])
               loadedInspectionAllData.isNew = false
               loadedInspectionAllData.inspectionId = editInspectionId

               //dispatch({ type: 'SET_INSPECTION_ALL_DATA', payload: {...loadedInspectionAllData}})
               dispatch({ type: 'RELOAD_DATA', payload: {...loadedInspectionAllData}}) // говорим всем компоннетам взять данные из inspectilAllData
               //dispatch({ type: 'RELOAD_DATA', payload: {...inspectionData, ...loadedInspectionAllData}}) // говорим всем компоннетам взять данные из inspectilAllData


            }
            wrap()




         }


         setSection(10)
      }

   }, [showInspectionDialog]);



   const handleClose = () => {
      hideAllSections();

      setSection(10)
      setStructure(10)
      //setStructureOptions([{id: 20, label: '––– Add New ––––'}, {id: 10, label: '1. Main Structure'}])
      setRecordType(189) // 189: Inspection (main structure), 191: Additional Structure
      setLinkedMainStructure('')
      setStructureOptions([])


      //handleSave()

      dispatch({ type: 'SET_INSPECTION_ALL_DATA', payload: {}})
      dispatch({ type: 'CLEAN_CONTROLS_DATA'})
      dispatch({ type: 'SET_HIDE_INSPECTION_DIALOG' })
      dispatch({ type: 'REFRESH_INSPECTIONS_LIST' })
      dispatch({ type: 'DROP_IS_DEAL_WAS_CREATED' })
   };

   const handleSave = () => {

      setShowProgress(true)
      setFirstAutoSaving(false)
      dispatch({ type: 'SAVE_REQUEST' })

      //hideAllSections();
      //dispatch({ type: 'SET_HIDE_INSPECTION_DIALOG' })
   };


   const [expanded, setExpanded] = React.useState('panel_inspection');
   const [expanded2, setExpanded2] = React.useState('');
   const handleChange = (panel) => (event, newExpanded) => {
      setExpanded(newExpanded ? panel : false);
   };
   const handleChange2 = (panel) => (event, newExpanded) => {
      setExpanded2(newExpanded ? panel : false);
   };


   const hideAllSections = () => {

      dispatch({ type: 'SET_INSPECTION_GENERAL_DATA', payload: {inspectionId: '', showBlock: false} })
      dispatch({ type: 'SET_INSPECTION_JOBSITE_DATA', payload: {inspectionId: '', showBlock: false} })
      dispatch({ type: 'SET_INSPECTION_ELEVATIONS_DATA', payload: {inspectionId: '', showBlock: false} })
      dispatch({ type: 'SET_INSPECTION_ROOF_DATA', payload: {inspectionId: '', showBlock: false} })
      dispatch({ type: 'SET_INSPECTION_ROOF_SUB_DATA', payload: {inspectionId: '', elevationType: "", showBlock: false} })
      dispatch({ type: 'SET_INSPECTION_SIDING_DATA', payload: {inspectionId: '', showBlock: false} })
      dispatch({ type: 'SET_INSPECTION_WINDOWS_DATA', payload: {inspectionId: '', showBlock: false} })
      dispatch({ type: 'SET_INSPECTION_GUTTERS_DATA', payload: {inspectionId: '', showBlock: false} })
      dispatch({ type: 'SET_INSPECTION_INTERIOR_DATA', payload: {inspectionId: '', showBlock: false} })
      dispatch({ type: 'SET_INSPECTION_PHOTOS_DATA', payload: {inspectionId: '', showBlock: false} })
      dispatch({ type: 'SET_INSPECTION_REPORTS_DATA', payload: {inspectionId: '', showBlock: false} })
      dispatch({ type: 'SET_INSPECTION_DAMAGE_DATA', payload: {inspectionId: editInspectionId, elevationType: "testSquares", showBlock: false} })
   }


   const handleSectionChange = (event) => {

      const selectedSection = event.target.value
      setSection(selectedSection);

      hideAllSections()

      // turning on the selected section
      // eslint-disable-next-line default-case
      switch (selectedSection) {
         case 10:
            handleSave()
            dispatch({ type: 'SET_INSPECTION_GENERAL_DATA', payload: {inspectionId: editInspectionId, showBlock: true} })
            break
         case 20:
            handleSave()
            dispatch({ type: 'SET_INSPECTION_JOBSITE_DATA', payload: {inspectionId: editInspectionId, showBlock: true} })
            break
         case 30:
            handleSave()
            dispatch({ type: 'SET_INSPECTION_ELEVATIONS_DATA', payload: {inspectionId: editInspectionId, showBlock: true} })
            break
         case 40:
            handleSave()
            dispatch({ type: 'SET_INSPECTION_ROOF_DATA', payload: {inspectionId: editInspectionId, showBlock: true} })
            break
         case 50:
            handleSave()
            dispatch({ type: 'SET_INSPECTION_SIDING_DATA', payload: {inspectionId: editInspectionId, showBlock: true} })
            break
         case 55:
            handleSave()
            dispatch({ type: 'SET_INSPECTION_WINDOWS_DATA', payload: {inspectionId: editInspectionId, showBlock: true} })
            break
         case 56:
            handleSave()
            dispatch({ type: 'SET_INSPECTION_GUTTERS_DATA', payload: {inspectionId: editInspectionId, showBlock: true} })
            break
         case 60:
            handleSave()
            dispatch({ type: 'SET_INSPECTION_INTERIOR_DATA', payload: {inspectionId: editInspectionId, showBlock: true} })
            break
         case 65:
            handleSave()
            dispatch({ type: 'SET_INSPECTION_DAMAGE_DATA', payload: {inspectionId: editInspectionId, elevationType: "testSquares", showBlock: true} })
            break
         case 70:
            handleSave()
            dispatch({ type: 'SET_INSPECTION_PHOTOS_DATA', payload: {inspectionId: editInspectionId, showBlock: true} })
            break
         case 80:
            handleSave()
            dispatch({ type: 'SET_INSPECTION_REPORTS_DATA', payload: {inspectionId: editInspectionId, showBlock: true} })
            break

      }


   };

   const handleStructureChange = (event) => {

      const selectedSection = event.target.value
      setStructure(selectedSection);

console.log(`selectedSection: ${selectedSection}`)

      if(selectedSection == '20') { // Adding New Structure ============================================================

         let newStructName = prompt("Please enter new structure type", "Garage");

         if (newStructName == null)
            return;

         let label = ''
         //
         // dispatch({ type: 'SET_INSPECTION_ALL_DATA', payload: {}})
         // dispatch({ type: 'CLEAN_CONTROLS_DATA'})
         //

         setSection(10)

         const createInspectionsAndFolder = async (newStructName) => {

            // new Item for Combobox
            let newID = structureOptions.length + 10
            const newNumber = structureOptions.length
            label = `${newNumber}. ${newStructName}`

            const newAdditionStructureInspectionName = label + ' [' + Math.floor(new Date().getTime() / 1000) + ']'

            setRecordType(191) //Additional Structure



            const params = {
               NAME: newAdditionStructureInspectionName,
               PROPERTY_797: linkedMainStructure,              //_Linked Main structure
               //PROPERTY_797: linkedMainStructure,              //_Linked Main structure
               PROPERTY_795: 191,                               // 'Record Type' -> 'Additional Structure'

                  //...emptyStructure
            }

            const inspectionAndFolderIDs = await createNewInspectionAndPrepareData(access_token_data, params)

console.log('inspectioAllData after we added additionalStructure inspection:')
console.log({...inspectionAllData, inspectionId: inspectionAndFolderIDs.newInspectionId, photoFolderId: inspectionAndFolderIDs.newInspectionFolderId, isAdditonalStructureInspection: true})




            dispatch({ type: 'SET_INSPECTION_ALL_DATA', payload: {
               //...inspectionAllData,
               inspectionId: inspectionAndFolderIDs.newInspectionId,
               photoFolderId: inspectionAndFolderIDs.newInspectionFolderId,
               isAdditonalStructureInspection: true,
               PROPERTY_797: inspectionAllData.inspectionId,
               NAME: newAdditionStructureInspectionName,
               PROPERTY_795: 191,                               // 'Record Type' -> 'Additional Structure'

               //PROPERTY_1107: [] //inspectionAllData (for Json)
                  //...emptyStructure
            }
            })
            dispatch({ type: 'SET_INSPECTION_GENERAL_DATA', payload: {showBlock: true} })
            dispatch({ type: 'CLEAN_CONTROLS_DATA'})



            setStructureOptions([...structureOptions, {id: inspectionAndFolderIDs.newInspectionId, label: label, structureInspectionId: inspectionAndFolderIDs.newInspectionId }])
            setStructure(inspectionAndFolderIDs.newInspectionId)

// !!! сразу запишем созданную доп. постройку
            dispatch({ type: 'SAVE_REQUEST' })


         }
         createInspectionsAndFolder(newStructName).then(r => {});



      }

      if(selectedSection != '10' && selectedSection != '20') { // Loading existing  ============================================================


         const cur = structureOptions.filter( str => str.id == selectedSection )
         const main = structureOptions.filter( str => str.label == '1. Main Structure' )

//alert(`(cur[0].label: ${cur[0].label}`)

         if (cur[0].label != '1. Main Structure') {
            setRecordType(191) //Additional Structure
            setLinkedMainStructure(main[0].id)
         }
         else {
            setRecordType(189) //main structure
            setLinkedMainStructure('')
         }



         dispatch({ type: 'CLEAN_CONTROLS_DATA'})
         dispatch({ type: 'SET_INSPECTION_GENERAL_DATA', payload: {inspectionId: selectedSection, showBlock: true} })

         // тут мы открываем существующий Inspection. Поэтому загрузим по нему данные из Б24 и скажем всем компонентам их подгрузить

         const wrap = async (selectedSection) => {
            const inspectionData = await getInspectionById(access_token_data, selectedSection)

            console.log('Inspection data loaded for editing:')
            console.log(inspectionData)

            const loadedInspectionAllData = JSON.parse(Object.values(inspectionData.PROPERTY_1107)[0])
            inspectionData.isNew = false
            inspectionData.inspectionId = selectedSection

            dispatch({ type: 'SET_INSPECTION_ALL_DATA', payload: {...loadedInspectionAllData}})
            dispatch({ type: 'RELOAD_DATA', payload: {...loadedInspectionAllData}}) // говорим всем компоннетам взять данные из inspectilAllData
            //dispatch({ type: 'RELOAD_DATA', payload: {...inspectionData, ...loadedInspectionAllData}}) // говорим всем компоннетам взять данные из inspectilAllData

            setSection(10)

         }
         wrap(selectedSection)

      }


   };



   return (

         <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
         >
            {/*<AppBar sx={{ position: 'sticky', position: '-webkit-sticky', top: 0  }}>*/}
            <AppBar sx={{ position: 'sticky', top: 0  }}>
               <Toolbar>
                  <IconButton
                     edge="start"
                     color="inherit"
                     onClick={handleClose}
                     aria-label="close"
                  >
                     <CloseIcon />
                  </IconButton>
                  <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                     Inspection
                  </Typography>
                  <Button color="inherit" onClick={handleSave} sx={{ position: 'sticky' }} >
                     Save
                  </Button>
               </Toolbar>
            </AppBar>

            {/*<Box sx={{mx: 'auto', mt: '70px', width: '100%', display: 'flex',}}>*/}
            <Box sx={{mx: 'auto', mt: 2, width: '100%', display: 'flex',}}>
               <FormControl fullWidth>
                  <InputLabel id="structure-label">Structure</InputLabel>
                  <Select
                     labelId="structure-select-label"
                     id="structure-select"
                     value={structure}
                     label="Structure"
                     onChange={handleStructureChange}
                  >

                     {structureOptions.map((option, index) => (
                        <MenuItem value={option.id}>{option.label}</MenuItem>
                     ))}

                  </Select>
               </FormControl>
               <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Section</InputLabel>
                  <Select
                     labelId="demo-simple-select-label"
                     id="demo-simple-select"
                     value={section}
                     label="Section"
                     onChange={handleSectionChange}
                  >
                     <MenuItem value={10}>General</MenuItem>
                     <MenuItem value={65}>Initial Inspection Damages</MenuItem>
                     <MenuItem value={20}>Jobsite</MenuItem>
                     <MenuItem value={30}>Elevations</MenuItem>
                     <MenuItem value={40}>Roof</MenuItem>
                     <MenuItem value={50}>Siding</MenuItem>
                     <MenuItem value={55}>Windows</MenuItem>
                     <MenuItem value={56}>Gutters</MenuItem>
                     <MenuItem value={60}>Interior</MenuItem>
                     <MenuItem value={70}>Photos</MenuItem>
                     <MenuItem value={80}>Reports</MenuItem>
                  </Select>
               </FormControl>
            </Box>

            <General/>
            <Jobsite/>
            <Elevations/>
            <Roof/>
            <Siding/>
            <Windows/>
            <Gutters/>
            <Interior/>
            <Photos />
            <Reports />
            <RoofTestSquares />

            <Backdrop
               sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
               open={showProgress}
               //onClick={handleClose}
            >
               <CircularProgress color="inherit" />
            </Backdrop>


            <Stack direction="row" alignItems="center" spacing={2}>
               <CustomCamera />
            </Stack>


         </Dialog>



);
}