import {
   Checkbox,
   FormControlLabel, FormGroup, MenuItem,
   Stack, TextField
} from "@mui/material";
import * as React from "react";
import {useContext, useEffect} from "react";
import ECContext from "../../context/inspections/ECContext";
import {
   getUserInfo,
   proxySendReportToClient,
   proxyCreateReport,
   proxyGetPDFReport,
   getImageUrl,
   savePhotoIntoFolder,
   createNewContact, createNewDeal, updateInspection
} from "../../context/inspections/ECActions";
import LoadingButton from '@mui/lab/LoadingButton';
import Link from '@mui/material/Link';
import Box from "@mui/material/Box";
import {Image} from "mui-image";
import {gridColumnVisibilityModelSelector} from "@mui/x-data-grid";

export default function Reports() {

   const {flagNoPhotosReadyData,
      readyGENERALdata,
      readyJOBSITEdata,
      readyELEVATIONSdata,
      readySIDINGdata,
      readyWINDOWSdata,
      readyGUTTERSdata,
      readyINTERIORdata,
      readyROOFFIELDdata,
      readyVENTILATIONdata,
      readyTESTSQUAREdata,
      readySKYLIGHTSdata,
      editInspectionId, inspectionAllData, access_token_data, inspectionReportsBlockInfo, dispatch } = useContext(ECContext)
   const [show, setShow] = React.useState(false);

   const [jobsitesPhotos, setJobsitesPhotos] = React.useState([]);
   const [elevationsPhotos, setElevationsPhotos] = React.useState([]);
   const [roofFields, setRoofFields] = React.useState([]);
   const [ventilationsPhotos, setVentilationsPhotos] = React.useState([]);
   const [windowsPhotos, setWindowsPhotos] = React.useState([]);
   const [guttersPhotos, setGuttersPhotos] = React.useState([]);
   const [testSquaresPhotos, setTestSquaresPhotos] = React.useState([]);
   const [roofPhotos, setRoofPhotos] = React.useState([]);
   const [interiorPhotos, setInteriorPhotos] = React.useState([]);

   const [jobsitesPhotosLoaded, setJobsitesPhotosLoaded] = React.useState(false);
   const [elevationsPhotosLoaded, setElevationsPhotosLoaded] = React.useState(false);
   const [roofFieldsLoaded, setRoofFieldsLoaded] = React.useState(false);
   const [ventilationsPhotosLoaded, setVentilationsPhotosLoaded] = React.useState(false);
   const [windowsPhotosLoaded, setWindowsPhotosLoaded] = React.useState(false);
   const [guttersPhotosLoaded, setGuttersPhotosLoaded] = React.useState(false);
   const [testSquaresPhotosLoaded, setTestSquaresPhotosLoaded] = React.useState(false);
   const [roofPhotosLoaded, setRoofPhotosLoaded] = React.useState(false);
   const [interiorPhotosLoaded, setInteriorPhotosLoaded] = React.useState(false);


   const [loading, setLoading] = React.useState(false);
   const [loadingSendingReport, setLoadingSendingReport] = React.useState(false);
   const [pdfReportLink, setPdfReportLink] = React.useState(false);
   const [pdfLink, setPdfLink] = React.useState(false);
   const [clientEmail, setClientEmail] = React.useState('');

   const [generateReportDisabled, setGenerateReportDisabled] = React.useState(false);

   const [filterJobsite, setFilterJobsite] = React.useState(false);
   const [filterElevations, setFilterElevations] = React.useState(false);
   const [filterRoffields, setFilterRoffields] = React.useState(false);
   const [filterVentilations, setFilterVentilations] = React.useState(false);
   const [filterWindows, setFilterWindows] = React.useState(false);
   const [filterGutters, setFilterGutters] = React.useState(false);
   const [filterTestSquares, setFilterTestSquares] = React.useState(false);
   const [filterRoofPhotos, setFilterRoofPhotos] = React.useState(false);
   const [filterInterior, setFilterInterior] = React.useState(false);
   const [filterNoPhotos, setFilterNoPhotos] = React.useState(false);

   const [generatingNoPhotoReportInProgress, setGeneratingNoPhotoReportInProgress] = React.useState(false);



   function getDamageCausedBy(causedBy) {

      let outDamageCausedBy = ''

      if(causedBy.indexOf(215) != -1) outDamageCausedBy += ' Flooding'
      if(causedBy.indexOf(205) != -1) outDamageCausedBy += ' Hail'
      if(causedBy.indexOf(207) != -1) outDamageCausedBy += ' Wind'
      if(causedBy.indexOf(213) != -1) outDamageCausedBy += ' Fire'
      if(causedBy.indexOf(211) != -1) outDamageCausedBy += ' Tornado'
      if(causedBy.indexOf(209) != -1) outDamageCausedBy += ' Hurricane'

      return outDamageCausedBy
   }

   function getWeatherCondition(weatherId) {

      switch (weatherId) {
         case 197:
            return 'Sunny'
         case 199:
            return 'Overcast'
         case 201:
            return 'Wet'
         case 203:
            return 'Other'
     }
   }



   useEffect(() => {

      if(generatingNoPhotoReportInProgress !== true)
         return;

      setGeneratingNoPhotoReportInProgress(false);


      console.log("We're in Report NO-PHOTOS (before array test)")
/*
Здесь у нас все заполненные данные пришли! :)

   readyGENERALdata,
   readyJOBSITEdata,
   readyELEVATIONSdata,
   readySIDINGdata,
   readyWINDOWSdata,
   readyGUTTERSdata,
   readyINTERIORdata,
   readyROOFFIELDdata,
   readyVENTILATIONdata,
   readyTESTSQUAREdata,
   readySKYLIGHTSdata,
*/
         console.log("readyGENERALdata",readyGENERALdata.NO_PHOTOS_DATA)
         console.log("readyJOBSITEdata", readyJOBSITEdata.NO_PHOTOS_DATA)
         //console.log("readyELEVATIONSdata",readyELEVATIONSdata.NO_PHOTOS_DATA)
         console.log("readySIDINGdata",readySIDINGdata.NO_PHOTOS_DATA)
         console.log("readyWINDOWSdata",readyWINDOWSdata.NO_PHOTOS_DATA)
         console.log("readyGUTTERSdata",readyGUTTERSdata.NO_PHOTOS_DATA)
         console.log("readyINTERIORdata",readyINTERIORdata.NO_PHOTOS_DATA)
         console.log("readyROOFFIELDdata",readyROOFFIELDdata.NO_PHOTOS_DATA)
         console.log("readyVENTILATIONdata",readyVENTILATIONdata.NO_PHOTOS_DATA)
         console.log("readyTESTSQUAREdata",readyTESTSQUAREdata.NO_PHOTOS_DATA)
         console.log("readySKYLIGHTSdata",readySKYLIGHTSdata.NO_PHOTOS_DATA)


      let jobsite = ''
      let siding = ''
      let windows = ''
      let gutters = ''
      let rooffield = ''
      let ventilation = ''
      let damages = ''


      for (const property in readyJOBSITEdata.NO_PHOTOS_DATA) {
         if(readyJOBSITEdata.NO_PHOTOS_DATA[property] == undefined)
            continue;
         if(readyJOBSITEdata.NO_PHOTOS_DATA[property].length == 0)
            continue;
         jobsite += `${property}: [${readyJOBSITEdata.NO_PHOTOS_DATA[property]}]\n`
      }


      for (const property in readySIDINGdata.NO_PHOTOS_DATA) {
         if(readySIDINGdata.NO_PHOTOS_DATA[property] == undefined)
            continue;
         if(readySIDINGdata.NO_PHOTOS_DATA[property].length == 0)
            continue;
         siding += `${property}: [${readySIDINGdata.NO_PHOTOS_DATA[property]}]\n`
      }

      for (const property in readyWINDOWSdata.NO_PHOTOS_DATA) {
         if(readyWINDOWSdata.NO_PHOTOS_DATA[property] == undefined)
            continue;
         if(readyWINDOWSdata.NO_PHOTOS_DATA[property].length == 0)
            continue;
         windows += `${property}: [${readyWINDOWSdata.NO_PHOTOS_DATA[property]}]\n`
      }

      let tmpGutters = 'Gutters:\n\n'
      let tmpDownspouts = 'Downspouts:\n\n'
      for (const property in readyGUTTERSdata.NO_PHOTOS_DATA) {
         if(readyGUTTERSdata.NO_PHOTOS_DATA[property] == undefined)
            continue;
         if(readyGUTTERSdata.NO_PHOTOS_DATA[property].length == 0)
            continue;

         if(property.indexOf('gutter.') !== -1) {
            tmpGutters += `${property.replace('gutter.', '')}: [${readyGUTTERSdata.NO_PHOTOS_DATA[property]}]\n`
         }
         if(property.indexOf('downspouts.') !== -1) {
            tmpDownspouts += `${property.replace('downspouts.', '')}: [${readyGUTTERSdata.NO_PHOTOS_DATA[property]}]\n`
         }
      }
      gutters = tmpGutters + '\n' + tmpDownspouts



      for (const property in readyROOFFIELDdata.NO_PHOTOS_DATA) {
         if(readyROOFFIELDdata.NO_PHOTOS_DATA[property] == undefined)
            continue;
         if(readyROOFFIELDdata.NO_PHOTOS_DATA[property].length == 0)
            continue;
         rooffield += `${property}: [${readyROOFFIELDdata.NO_PHOTOS_DATA[property]}]\n`
      }

      for (const property in readyVENTILATIONdata.NO_PHOTOS_DATA) {
         if(readyVENTILATIONdata.NO_PHOTOS_DATA[property] == undefined)
            continue;
         if(readyVENTILATIONdata.NO_PHOTOS_DATA[property].length == 0)
            continue;
         ventilation += `${property}: [${readyVENTILATIONdata.NO_PHOTOS_DATA[property]}]\n`
      }

      for (const property in readyTESTSQUAREdata.NO_PHOTOS_DATA) {
         if(readyTESTSQUAREdata.NO_PHOTOS_DATA[property] == undefined)
            continue;
         if(readyTESTSQUAREdata.NO_PHOTOS_DATA[property].length == 0)
            continue;
         damages += `${property}: [${readyTESTSQUAREdata.NO_PHOTOS_DATA[property]}]\n`
      }

      let tableValues = []

      if(filterJobsite == true)
         tableValues.push( {Category: 'Jobsite', Values: jobsite} )
      if(filterRoffields == true)
         tableValues.push( {Category: 'Roof', Values: rooffield} )
      if(filterWindows == true)
         tableValues.push( {Category: 'Windows', Values: windows} )
      if(filterGutters == true)
         tableValues.push( {Category: 'Gutters', Values: gutters} )
      if(filterTestSquares == true)
         tableValues.push( {Category: 'Damages', Values: damages} )
      if(filterVentilations == true)
         tableValues.push( {Category: 'Ventilation', Values: ventilation } )



      /*      let tableValues = [
               {
                  Category: 'Jobsite',
                  Values: 'test for Jobsite',
               },
               {
                  Category: 'Siding',
                  Values: 'test for Jobsite',
               },
            ]*/




      let mapImage = '';
      if(inspectionAllData.hasOwnProperty('PROPERTY_803'))
         mapImage = `https://maps.googleapis.com/maps/api/staticmap?center=${encodeURI(inspectionAllData.PROPERTY_803)}&zoom=15&size=600x700&maptype=roadmap&markers=color:yellow%7Clabel:Structure%7C${encodeURI(inspectionAllData.PROPERTY_803)}&key=AIzaSyCCfbR4n0p3adblt5rgK7lKita1WvWNUOk`


      const wrap = async () => {

         const userInfo = await getUserInfo(access_token_data, access_token_data.user_id)
         const inspectorInfo = `${userInfo[0].NAME} ${userInfo[0].LAST_NAME} ${userInfo[0].EMAIL}`


         let dateOfLoss = ''
         const tmp = (inspectionAllData.PROPERTY_831 != undefined ) ? inspectionAllData.PROPERTY_831 : ''
         if(tmp.length > 0) {
            let tmpDateOfLoss = new Date(tmp)
            dateOfLoss = tmpDateOfLoss.toLocaleDateString("en-US")
         }
         else
            dateOfLoss = ''


         let params = {
            templateId: 65, // шаблона для отчёт 'No Photos'
            providerClassName: 'Bitrix\\DocumentGenerator\\DataProvider\\Rest',
            value: 1,
            values: {

               homeownerName: inspectionAllData.hasOwnProperty('PROPERTY_799') ? inspectionAllData.PROPERTY_799 : '',
               homeownerLastName: inspectionAllData.hasOwnProperty('PROPERTY_801') ? inspectionAllData.PROPERTY_801 : '',
               mainImage:
                  await (async () => {
                     if(inspectionAllData.hasOwnProperty('photoOfFrontOfProperty' )) {
                        const imageUrl = await getImageUrl(access_token_data, inspectionAllData.photoOfFrontOfProperty.ID)

                        return imageUrl.DOWNLOAD_URL
                     }
                  })(),
               clientAddress: inspectionAllData.hasOwnProperty('PROPERTY_803') ? inspectionAllData.PROPERTY_803 : '',
               inspectionId: editInspectionId,
               structureImage:
                  await (async () => {
                     if(inspectionAllData.hasOwnProperty('photoOfFrontOfProperty' )) {
                        const imageUrl = await getImageUrl(access_token_data, inspectionAllData.photoOfFrontOfProperty.ID)

                        return imageUrl.DOWNLOAD_URL
                     }
                  })(),
               mapImage: mapImage,
               //mapImage: 'https://inspections.blueocean24.ru/pdf_test_image.jpg',
               ageOfStructure: inspectionAllData.hasOwnProperty('PROPERTY_813') ? inspectionAllData.PROPERTY_813 : '',
               ageOfRoof: inspectionAllData.hasOwnProperty('PROPERTY_819') ? inspectionAllData.PROPERTY_819 : '',
               existingCracks: 'N',
               overheadWires: 'N',
               rooftopLoad: 'N',
               oilStains: 'N',
               narrowAccess: 'N',
               inspectionDateTime: inspectionAllData.hasOwnProperty('PROPERTY_1311') ? inspectionAllData.PROPERTY_1311 : '',
               //inspectionDateTime: '03/02/2023 01:20',
               inspectorInfo: inspectorInfo,
               homeownerPresent: 'Y',
               weatherConditions: getWeatherCondition(inspectionAllData.hasOwnProperty('PROPERTY_811') ? inspectionAllData.PROPERTY_811 : ''),
               //claimRecommendation: 'Claim file',
               insuranceCarrier: inspectionAllData.hasOwnProperty('PROPERTY_823') ? inspectionAllData.PROPERTY_823.label : '',
               damageCausedBy: getDamageCausedBy(inspectionAllData.hasOwnProperty('PROPERTY_829') ? inspectionAllData.PROPERTY_829 : ''),
               dateOfLoss: dateOfLoss,
               notes: inspectionAllData.hasOwnProperty('PROPERTY_821') ? inspectionAllData.PROPERTY_821 : '',

               Table: tableValues,
               TableItemCategory: 'Table.Item.Category',
               TableItemValues: 'Table.Item.Values',





            },
            fields: {
               Image: {TYPE: 'IMAGE'}, // тип поля - изображение
               mainImage: {TYPE: 'IMAGE'},
               structureImage: {TYPE: 'IMAGE'},
               mapImage: {TYPE: 'IMAGE'},

               Table: {
                  PROVIDER: 'Bitrix\\DocumentGenerator\\DataProvider\\ArrayDataProvider',
                  OPTIONS: {
                     ITEM_NAME: 'Item',
                     ITEM_PROVIDER: 'Bitrix\\DocumentGenerator\\DataProvider\\HashDataProvider',
                  },
               },


            }
         };


         console.log('params to generate No-Photos Report:')
         console.log(params)

         const result = await proxyCreateReport(access_token_data, params)
         const reportId = result.document.id

         if(isNaN(reportId)) {
            console.log('Error while receiving reportId (No-Photos block). Quitting.')
            setLoading(false)
            return;
         }

         setTimeout(async () => {
            params = {
               id: reportId
            }
            const result1 = await proxyGetPDFReport(access_token_data, params)
            const pdfDownloadUrl = result1.document.publicUrl;
            //const pdfDownloadUrl = result1.document.pdfUrlMachine;

            setPdfReportLink(pdfDownloadUrl)

            setPdfLink(true)
            setLoading(false)
            setGenerateReportDisabled(false)
         }, 3000);

      }
      wrap()









   }, [flagNoPhotosReadyData]);


   useEffect(() => {


// console.log('======== debug data (inside userEffect): =========')
// console.log('jobsitesPhotosLoaded',jobsitesPhotosLoaded)
// console.log('filterJobsite',filterJobsite)
// console.log('elevationsPhotosLoaded',elevationsPhotosLoaded)
// console.log('filterElevations',filterElevations)
// console.log('roofFieldsLoaded',roofFieldsLoaded)
// console.log('filterRoffields',filterRoffields)
// console.log('windowsPhotosLoaded',windowsPhotosLoaded)
// console.log('filterWindows',filterWindows)
// console.log('guttersPhotosLoaded',guttersPhotosLoaded)
// console.log('filterGutters',filterGutters)
// console.log('testSquaresPhotosLoaded',testSquaresPhotosLoaded)
// console.log('filterTestSquares',filterTestSquares)
// console.log('roofPhotosLoaded',roofPhotosLoaded)
// console.log('filterRoofPhotos',filterRoofPhotos)
// console.log('interiorPhotosLoaded',interiorPhotosLoaded)
// console.log('filterInterior',filterInterior)
// console.log('loading',loading)



      if( (jobsitesPhotosLoaded || !filterJobsite)
         && (elevationsPhotosLoaded || !filterElevations)
         && (roofFieldsLoaded || !filterRoffields)
//         && (ventilationsPhotosLoaded || !filterVentilations)
         && (windowsPhotosLoaded || !filterWindows)
         && (guttersPhotosLoaded || !filterGutters)
         && (testSquaresPhotosLoaded || !filterTestSquares)
         && (roofPhotosLoaded || !filterRoofPhotos)
         && (interiorPhotosLoaded || !filterInterior)
         && loading === true) {

console.log('We are inside useEffeft. Allf photos are PREPARED!')


         //Preparing Data for Gutteres and Windows blocks

         let windows = ''
         let gutters = ''


         for (const property in readyWINDOWSdata.NO_PHOTOS_DATA) {
            if(readyWINDOWSdata.NO_PHOTOS_DATA[property] == undefined)
               continue;
            if(readyWINDOWSdata.NO_PHOTOS_DATA[property].length == 0)
               continue;
            windows += `${property}: [${readyWINDOWSdata.NO_PHOTOS_DATA[property]}]\n`
         }

         let tmpGutters = 'Gutters:\n\n'
         let tmpDownspouts = 'Downspouts:\n\n'
         for (const property in readyGUTTERSdata.NO_PHOTOS_DATA) {
            if(readyGUTTERSdata.NO_PHOTOS_DATA[property] == undefined)
               continue;
            if(readyGUTTERSdata.NO_PHOTOS_DATA[property].length == 0)
               continue;

            if(property.indexOf('gutter.') !== -1) {
               tmpGutters += `${property.replace('gutter.', '')}: [${readyGUTTERSdata.NO_PHOTOS_DATA[property]}]\n`
            }
            if(property.indexOf('downspouts.') !== -1) {
               tmpDownspouts += `${property.replace('downspouts.', '')}: [${readyGUTTERSdata.NO_PHOTOS_DATA[property]}]\n`
            }
         }
         gutters = tmpGutters + '\n' + tmpDownspouts

         //== end preparing Gutters and Windows data block





         setGenerateReportDisabled(false)

         setJobsitesPhotosLoaded(false);
         setElevationsPhotosLoaded(false);
         setRoofFieldsLoaded(false);
         setVentilationsPhotosLoaded(false);
         setWindowsPhotosLoaded(false);
         setGuttersPhotosLoaded(false);
         setTestSquaresPhotosLoaded(false);
         setRoofPhotosLoaded(false);
         setInteriorPhotosLoaded(false);

         if ([...jobsitesPhotos, ...elevationsPhotos, ...roofFields, ...ventilationsPhotos, ...windowsPhotos, ...guttersPhotos, ...testSquaresPhotos, ...roofPhotos, ...interiorPhotos].length == 0) {
            setLoading(false)
            return;
         }

         let mapImage = '';
         if(inspectionAllData.hasOwnProperty('PROPERTY_803'))
            mapImage = `https://maps.googleapis.com/maps/api/staticmap?center=${encodeURI(inspectionAllData.PROPERTY_803)}&zoom=15&size=600x700&maptype=roadmap&markers=color:yellow%7Clabel:Structure%7C${encodeURI(inspectionAllData.PROPERTY_803)}&key=AIzaSyCCfbR4n0p3adblt5rgK7lKita1WvWNUOk`


         const wrap = async () => {

            const userInfo = await getUserInfo(access_token_data, access_token_data.user_id)
            const inspectorInfo = `${userInfo[0].NAME} ${userInfo[0].LAST_NAME} ${userInfo[0].EMAIL}`


            let dateOfLoss = ''
            const tmp = (inspectionAllData.PROPERTY_831 != undefined ) ? inspectionAllData.PROPERTY_831 : ''
            if(tmp.length > 0) {
               let tmpDateOfLoss = new Date(tmp)
               dateOfLoss = tmpDateOfLoss.toLocaleDateString("en-US")
            }
            else
               dateOfLoss = ''

           //

// console.log("INSPECTION_DATA:")
// console.log(inspectionAllData)

            let params = {
               templateId: 63,
               providerClassName: 'Bitrix\\DocumentGenerator\\DataProvider\\Rest',
               value: 1,
               values: {

                  homeownerName: inspectionAllData.hasOwnProperty('PROPERTY_799') ? inspectionAllData.PROPERTY_799 : '',
                  homeownerLastName: inspectionAllData.hasOwnProperty('PROPERTY_801') ? inspectionAllData.PROPERTY_801 : '',
                  mainImage:
                     await (async () => {
                        if(inspectionAllData.hasOwnProperty('photoOfFrontOfProperty' )) {
                           const imageUrl = await getImageUrl(access_token_data, inspectionAllData.photoOfFrontOfProperty.ID)

                           return imageUrl.DOWNLOAD_URL
                        }
                     })(),
                  clientAddress: inspectionAllData.hasOwnProperty('PROPERTY_803') ? inspectionAllData.PROPERTY_803 : '',
                  inspectionId: editInspectionId,
                  structureImage:
                     await (async () => {
                        if(inspectionAllData.hasOwnProperty('photoOfFrontOfProperty' )) {
                           const imageUrl = await getImageUrl(access_token_data, inspectionAllData.photoOfFrontOfProperty.ID)

                           return imageUrl.DOWNLOAD_URL
                        }
                     })(),
                  mapImage: mapImage,
                  //mapImage: 'https://inspections.blueocean24.ru/pdf_test_image.jpg',
                  ageOfStructure: inspectionAllData.hasOwnProperty('PROPERTY_813') ? inspectionAllData.PROPERTY_813 : '',
                  ageOfRoof: inspectionAllData.hasOwnProperty('PROPERTY_819') ? inspectionAllData.PROPERTY_819 : '',
                  existingCracks: 'N',
                  overheadWires: 'N',
                  rooftopLoad: 'N',
                  oilStains: 'N',
                  narrowAccess: 'N',
                  inspectionDateTime: inspectionAllData.hasOwnProperty('PROPERTY_1311') ? inspectionAllData.PROPERTY_1311 : '',
                  //inspectionDateTime: '03/02/2023 01:20',
                  inspectorInfo: inspectorInfo,
                  homeownerPresent: 'Y',
                  weatherConditions: getWeatherCondition(inspectionAllData.hasOwnProperty('PROPERTY_811') ? inspectionAllData.PROPERTY_811 : ''),
                  //claimRecommendation: 'Claim file',
                  insuranceCarrier: inspectionAllData.hasOwnProperty('PROPERTY_823') ? inspectionAllData.PROPERTY_823.label : '',
                  damageCausedBy: getDamageCausedBy(inspectionAllData.hasOwnProperty('PROPERTY_829') ? inspectionAllData.PROPERTY_829 : ''),
                  dateOfLoss: dateOfLoss,
                  notes: inspectionAllData.hasOwnProperty('PROPERTY_821') ? inspectionAllData.PROPERTY_821 : '',

                  Table: [...jobsitesPhotos],
                  Table1: [...elevationsPhotos],
                  Table2: [...windowsPhotos],
                  Table3: [...guttersPhotos],
                  Table4: [...roofFields],
                  Table5: [...testSquaresPhotos],
                  Table6: [...roofPhotos],
                  Table7: [...interiorPhotos],

                  // Table: [
                  //    {
                  //       ImageType: 'Roof',
                  //       Image1: 'https://inspections.blueocean24.ru/pdf_test_image.jpg',
                  //       Image2: 'https://inspections.blueocean24.ru/pdf_test_image.jpg'
                  //    },
                  // ],

                  TableItemJobType: 'Table.Item.JobType',
                  TableItemData: 'Table.Item.Data',
                  TableItemType: 'Table.Item.ImageType',
                  TableItemImage1: 'Table.Item.Image1',
                  TableItemImage2: 'Table.Item.Image2',
                  TableItemImgDesc1: 'Table.Item.Desc1',
                  TableItemImgDesc2: 'Table.Item.Desc2',

                  Table1ItemJobType: 'Table1.Item.JobType',
                  Table1ItemData: 'Table1.Item.Data',
                  Table1ItemType: 'Table1.Item.ImageType',
                  Table1ItemImage1: 'Table1.Item.Image1',
                  Table1ItemImage2: 'Table1.Item.Image2',
                  Table1ItemImgDesc1: 'Table1.Item.Desc1',
                  Table1ItemImgDesc2: 'Table1.Item.Desc2',


                  Table2ItemJobType: 'Table2.Item.JobType',
                  Table2ItemData: 'Table2.Item.Data',
                  Table2ItemType: 'Table2.Item.ImageType',
                  Table2ItemImage1: 'Table2.Item.Image1',
                  Table2ItemImage2: 'Table2.Item.Image2',
                  Table2ItemImgDesc1: 'Table2.Item.Desc1',
                  Table2ItemImgDesc2: 'Table2.Item.Desc2',


                  Table3ItemJobType: 'Table3.Item.JobType',
                  Table3ItemData: 'Table3.Item.Data',
                  Table3ItemType: 'Table3.Item.ImageType',
                  Table3ItemImage1: 'Table3.Item.Image1',
                  Table3ItemImage2: 'Table3.Item.Image2',
                  Table3ItemImgDesc1: 'Table3.Item.Desc1',
                  Table3ItemImgDesc2: 'Table3.Item.Desc2',


                  Table4ItemJobType: 'Table4.Item.JobType',
                  Table4ItemData: 'Table4.Item.Data',
                  Table4ItemType: 'Table4.Item.ImageType',
                  Table4ItemImage1: 'Table4.Item.Image1',
                  Table4ItemImage2: 'Table4.Item.Image2',
                  Table4ItemImgDesc1: 'Table4.Item.Desc1',
                  Table4ItemImgDesc2: 'Table4.Item.Desc2',


                  Table5ItemJobType: 'Table5.Item.JobType',
                  Table5ItemData: 'Table5.Item.Data',
                  Table5ItemType: 'Table5.Item.ImageType',
                  Table5ItemImage1: 'Table5.Item.Image1',
                  Table5ItemImage2: 'Table5.Item.Image2',
                  Table5ItemImgDesc1: 'Table5.Item.Desc1',
                  Table5ItemImgDesc2: 'Table5.Item.Desc2',


                  Table6ItemJobType: 'Table6.Item.JobType',
                  Table6ItemData: 'Table6.Item.Data',
                  Table6ItemType: 'Table6.Item.ImageType',
                  Table6ItemImage1: 'Table6.Item.Image1',
                  Table6ItemImage2: 'Table6.Item.Image2',
                  Table6ItemImgDesc1: 'Table6.Item.Desc1',
                  Table6ItemImgDesc2: 'Table6.Item.Desc2',


                  Table7ItemJobType: 'Table7.Item.JobType',
                  Table7ItemData: 'Table7.Item.Data',
                  Table7ItemType: 'Table7.Item.ImageType',
                  Table7ItemImage1: 'Table7.Item.Image1',
                  Table7ItemImage2: 'Table7.Item.Image2',
                  Table7ItemImgDesc1: 'Table7.Item.Desc1',
                  Table7ItemImgDesc2: 'Table7.Item.Desc2',



               },
               fields: {
                  Image: {TYPE: 'IMAGE'}, // тип поля - изображение
                  mainImage: {TYPE: 'IMAGE'},
                  structureImage: {TYPE: 'IMAGE'},
                  mapImage: {TYPE: 'IMAGE'},


                  // Table: {
                  //    PROVIDER: 'Bitrix\\DocumentGenerator\\DataProvider\\ArrayDataProvider',
                  //    OPTIONS: {
                  //       ITEM_NAME: 'Item',
                  //       ITEM_PROVIDER: 'Bitrix\\DocumentGenerator\\DataProvider\\HashDataProvider',
                  //    },
                  // },
                  // Table1ItemImage: {TYPE: 'IMAGE'},
                  // Table1ItemImage: {TYPE: 'IMAGE'},
                  //
                  // Table1: {
                  //    PROVIDER: 'Bitrix\\DocumentGenerator\\DataProvider\\ArrayDataProvider',
                  //    OPTIONS: {
                  //       ITEM_NAME: 'Item',
                  //       ITEM_PROVIDER: 'Bitrix\\DocumentGenerator\\DataProvider\\HashDataProvider',
                  //    },
                  // },
                  // Table1ItemImage1: {TYPE: 'IMAGE'},
                  // Table1ItemImage2: {TYPE: 'IMAGE'},
                  //
                  // Table2: {
                  //    PROVIDER: 'Bitrix\\DocumentGenerator\\DataProvider\\ArrayDataProvider',
                  //    OPTIONS: {
                  //       ITEM_NAME: 'Item',
                  //       ITEM_PROVIDER: 'Bitrix\\DocumentGenerator\\DataProvider\\HashDataProvider',
                  //    },
                  // },
                  // Table2ItemImage1: {TYPE: 'IMAGE'},
                  // Table2ItemImage2: {TYPE: 'IMAGE'},
                  //
                  // Table3: {
                  //    PROVIDER: 'Bitrix\\DocumentGenerator\\DataProvider\\ArrayDataProvider',
                  //    OPTIONS: {
                  //       ITEM_NAME: 'Item',
                  //       ITEM_PROVIDER: 'Bitrix\\DocumentGenerator\\DataProvider\\HashDataProvider',
                  //    },
                  // },
                  // Table3ItemImage1: {TYPE: 'IMAGE'},
                  // Table3ItemImage2: {TYPE: 'IMAGE'},
                  //
                  //
                  // Table4: {
                  //    PROVIDER: 'Bitrix\\DocumentGenerator\\DataProvider\\ArrayDataProvider',
                  //    OPTIONS: {
                  //       ITEM_NAME: 'Item',
                  //       ITEM_PROVIDER: 'Bitrix\\DocumentGenerator\\DataProvider\\HashDataProvider',
                  //    },
                  // },
                  // Table4ItemImage1: {TYPE: 'IMAGE'},
                  // Table4ItemImage2: {TYPE: 'IMAGE'},
                  //
                  // Table5: {
                  //    PROVIDER: 'Bitrix\\DocumentGenerator\\DataProvider\\ArrayDataProvider',
                  //    OPTIONS: {
                  //       ITEM_NAME: 'Item',
                  //       ITEM_PROVIDER: 'Bitrix\\DocumentGenerator\\DataProvider\\HashDataProvider',
                  //    },
                  // },
                  // Table5ItemImage1: {TYPE: 'IMAGE'},
                  // Table5ItemImage2: {TYPE: 'IMAGE'},
                  //
                  // Table6: {
                  //    PROVIDER: 'Bitrix\\DocumentGenerator\\DataProvider\\ArrayDataProvider',
                  //    OPTIONS: {
                  //       ITEM_NAME: 'Item',
                  //       ITEM_PROVIDER: 'Bitrix\\DocumentGenerator\\DataProvider\\HashDataProvider',
                  //    },
                  // },
                  // Table6ItemImage1: {TYPE: 'IMAGE'},
                  // Table6ItemImage2: {TYPE: 'IMAGE'},
                  //
                  // Table7: {
                  //    PROVIDER: 'Bitrix\\DocumentGenerator\\DataProvider\\ArrayDataProvider',
                  //    OPTIONS: {
                  //       ITEM_NAME: 'Item',
                  //       ITEM_PROVIDER: 'Bitrix\\DocumentGenerator\\DataProvider\\HashDataProvider',
                  //    },
                  // },
                  // Table7ItemImage1: {TYPE: 'IMAGE'},
                  // Table7ItemImage2: {TYPE: 'IMAGE'},

               }
            };


// заполним таблицы данными:
            let tableIndex = 0;
            const getTableName = (tableIndex) => {
               if (tableIndex == 0)
                  return 'Table';
               else
                  return `Table${tableIndex}`


            }

            if(jobsitesPhotos.length > 0) {
               params.values[getTableName(tableIndex)] = jobsitesPhotos
               params.fields[getTableName(tableIndex)] = {
                  PROVIDER: 'Bitrix\\DocumentGenerator\\DataProvider\\ArrayDataProvider',
                     OPTIONS: {
                     ITEM_NAME: 'Item',
                        ITEM_PROVIDER: 'Bitrix\\DocumentGenerator\\DataProvider\\HashDataProvider',
                  },
               }
               params.fields[getTableName(tableIndex)+'ItemImage1'] = {TYPE: 'IMAGE'}
               params.fields[getTableName(tableIndex)+'ItemImage2'] = {TYPE: 'IMAGE'}
               tableIndex++;
            }

            if(elevationsPhotos.length > 0) {
               params.values[getTableName(tableIndex)] = elevationsPhotos
               params.fields[getTableName(tableIndex)] = {
                  PROVIDER: 'Bitrix\\DocumentGenerator\\DataProvider\\ArrayDataProvider',
                  OPTIONS: {
                     ITEM_NAME: 'Item',
                     ITEM_PROVIDER: 'Bitrix\\DocumentGenerator\\DataProvider\\HashDataProvider',
                  },
               }
               params.fields[getTableName(tableIndex)+'ItemImage1'] = {TYPE: 'IMAGE'}
               params.fields[getTableName(tableIndex)+'ItemImage2'] = {TYPE: 'IMAGE'}
               tableIndex++;
            }
            if(windowsPhotos.length > 0) {
               windowsPhotos[0].Data = windows
               params.values[getTableName(tableIndex)] = windowsPhotos
               params.fields[getTableName(tableIndex)] = {
                  PROVIDER: 'Bitrix\\DocumentGenerator\\DataProvider\\ArrayDataProvider',
                  OPTIONS: {
                     ITEM_NAME: 'Item',
                     ITEM_PROVIDER: 'Bitrix\\DocumentGenerator\\DataProvider\\HashDataProvider',
                  },
               }
               params.fields[getTableName(tableIndex)+'ItemImage1'] = {TYPE: 'IMAGE'}
               params.fields[getTableName(tableIndex)+'ItemImage2'] = {TYPE: 'IMAGE'}
               tableIndex++;
            }
            if(guttersPhotos.length > 0) {
               guttersPhotos[0].Data = gutters
               params.values[getTableName(tableIndex)] = guttersPhotos
               params.fields[getTableName(tableIndex)] = {
                  PROVIDER: 'Bitrix\\DocumentGenerator\\DataProvider\\ArrayDataProvider',
                  OPTIONS: {
                     ITEM_NAME: 'Item',
                     ITEM_PROVIDER: 'Bitrix\\DocumentGenerator\\DataProvider\\HashDataProvider',
                  },
               }
               params.fields[getTableName(tableIndex)+'ItemImage1'] = {TYPE: 'IMAGE'}
               params.fields[getTableName(tableIndex)+'ItemImage2'] = {TYPE: 'IMAGE'}
               tableIndex++;
            }
            if(roofFields.length > 0) {
               params.values[getTableName(tableIndex)] = roofFields
               params.fields[getTableName(tableIndex)] = {
                  PROVIDER: 'Bitrix\\DocumentGenerator\\DataProvider\\ArrayDataProvider',
                  OPTIONS: {
                     ITEM_NAME: 'Item',
                     ITEM_PROVIDER: 'Bitrix\\DocumentGenerator\\DataProvider\\HashDataProvider',
                  },
               }
               params.fields[getTableName(tableIndex)+'ItemImage1'] = {TYPE: 'IMAGE'}
               params.fields[getTableName(tableIndex)+'ItemImage2'] = {TYPE: 'IMAGE'}
               tableIndex++;
            }
            if(testSquaresPhotos.length > 0) {
               params.values[getTableName(tableIndex)] = testSquaresPhotos
               params.fields[getTableName(tableIndex)] = {
                  PROVIDER: 'Bitrix\\DocumentGenerator\\DataProvider\\ArrayDataProvider',
                  OPTIONS: {
                     ITEM_NAME: 'Item',
                     ITEM_PROVIDER: 'Bitrix\\DocumentGenerator\\DataProvider\\HashDataProvider',
                  },
               }
               params.fields[getTableName(tableIndex)+'ItemImage1'] = {TYPE: 'IMAGE'}
               params.fields[getTableName(tableIndex)+'ItemImage2'] = {TYPE: 'IMAGE'}
               tableIndex++;
            }
            if(roofPhotos.length > 0) {
               params.values[getTableName(tableIndex)] = roofPhotos
               params.fields[getTableName(tableIndex)] = {
                  PROVIDER: 'Bitrix\\DocumentGenerator\\DataProvider\\ArrayDataProvider',
                  OPTIONS: {
                     ITEM_NAME: 'Item',
                     ITEM_PROVIDER: 'Bitrix\\DocumentGenerator\\DataProvider\\HashDataProvider',
                  },
               }
               params.fields[getTableName(tableIndex)+'ItemImage1'] = {TYPE: 'IMAGE'}
               params.fields[getTableName(tableIndex)+'ItemImage2'] = {TYPE: 'IMAGE'}
               tableIndex++;
            }
            if(interiorPhotos.length > 0) {
               params.values[getTableName(tableIndex)] = interiorPhotos
               params.fields[getTableName(tableIndex)] = {
                  PROVIDER: 'Bitrix\\DocumentGenerator\\DataProvider\\ArrayDataProvider',
                  OPTIONS: {
                     ITEM_NAME: 'Item',
                     ITEM_PROVIDER: 'Bitrix\\DocumentGenerator\\DataProvider\\HashDataProvider',
                  },
               }
               params.fields[getTableName(tableIndex)+'ItemImage1'] = {TYPE: 'IMAGE'}
               params.fields[getTableName(tableIndex)+'ItemImage2'] = {TYPE: 'IMAGE'}
               tableIndex++;
            }

// забьем остальные таблицы пустыми данными
            for (true; tableIndex < 8; tableIndex++) {

               params.values[getTableName(tableIndex)] = [{JobType: '', ImageType: '', Image1: '', Image2: ''}]
               params.fields[getTableName(tableIndex)] = {
                  PROVIDER: 'Bitrix\\DocumentGenerator\\DataProvider\\ArrayDataProvider',
                  OPTIONS: {
                     ITEM_NAME: 'Item',
                     ITEM_PROVIDER: 'Bitrix\\DocumentGenerator\\DataProvider\\HashDataProvider',
                  },
               }
               params.fields[getTableName(tableIndex)+'ItemImage1'] = {TYPE: 'IMAGE'}
               params.fields[getTableName(tableIndex)+'ItemImage2'] = {TYPE: 'IMAGE'}

            }






            console.log('params to generate Report:')
            console.log(params)

            const result = await proxyCreateReport(access_token_data, params)
            const reportId = result.document.id

            if(isNaN(reportId)) {
               console.log('Error while receiving reportId. Quitting.')
               setLoading(false)
               return;
            }



            setTimeout(async () => {

               params = {
                  id: reportId
               }
               const result1 = await proxyGetPDFReport(access_token_data, params)
               const pdfDownloadUrl = result1.document.publicUrl;
               //const pdfDownloadUrl = result1.document.pdfUrlMachine;

               setPdfReportLink(pdfDownloadUrl)

               setPdfLink(true)
               setLoading(false)
               setGenerateReportDisabled(false)

            }, 3000);

         }
         wrap()



      }

   },[
      jobsitesPhotos,
      elevationsPhotos,
      roofFields,
      ventilationsPhotos,
      windowsPhotos,
      guttersPhotos,
      testSquaresPhotos,
      roofPhotos,
      interiorPhotos,

      flagNoPhotosReadyData
   ])






   const collectJobsitePhotos = async () => {

      // if(jobsitesPhotos.length > 0)
      //    return;

      console.log(' we are inside start collectJobsitePhotos ')

      let images = []

      const photosDoesTheDrivewayHaveExistingCracks = async () => {        //jobsite.Cracks/stains Photos
         if(inspectionAllData.hasOwnProperty('photosDoesTheDrivewayHaveExistingCracks' )) {
            if(inspectionAllData.photosDoesTheDrivewayHaveExistingCracks.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.photosDoesTheDrivewayHaveExistingCracks.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.photosDoesTheDrivewayHaveExistingCracks[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'FILE_ID': inspectionAllData.photosDoesTheDrivewayHaveExistingCracks[i].FILE_ID, 'DESC': 'Cracks/stains' })
               }
               //setJobsitesPhotos([...jobsitesPhotos, ...images])
               return images

            }
         }
         return []
      }
      let tmpImages = await photosDoesTheDrivewayHaveExistingCracks();
      images = [...images, ...tmpImages]

      const photosDoesTheJobSideHaveRemoteAccess = async () => {        //jobsite.Remote access photos
         if(inspectionAllData.hasOwnProperty('photosDoesTheJobSideHaveRemoteAccess' )) {
            if(inspectionAllData.photosDoesTheJobSideHaveRemoteAccess.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.photosDoesTheJobSideHaveRemoteAccess.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.photosDoesTheJobSideHaveRemoteAccess[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'FILE_ID': inspectionAllData.photosDoesTheJobSideHaveRemoteAccess[i].FILE_ID, 'DESC': 'Remote access'})
               }
               //setJobsitesPhotos([...jobsitesPhotos, ...images])
               return images

            }
         }
         return []
      }

      tmpImages = await photosDoesTheJobSideHaveRemoteAccess()
      images = [...images, ...tmpImages]


      const photosDoesBuildingHaveAnyPreexistingImperfections = async () => {        //jobsite.Pre-existing imperfections or damages (i.e. broken siding, light fixtures, fence, glass etc) Photos
         if(inspectionAllData.hasOwnProperty('photosDoesBuildingHaveAnyPreexistingImperfections' )) {
            if(inspectionAllData.photosDoesBuildingHaveAnyPreexistingImperfections.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.photosDoesBuildingHaveAnyPreexistingImperfections.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.photosDoesBuildingHaveAnyPreexistingImperfections[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'FILE_ID': inspectionAllData.photosDoesBuildingHaveAnyPreexistingImperfections[i].FILE_ID, 'DESC': 'Pre-existing imperfections'})
               }
               //setJobsitesPhotos([...jobsitesPhotos, ...images])
               return images

            }
         }
         return []
      }
      tmpImages = await photosDoesBuildingHaveAnyPreexistingImperfections()
      images = [...images, ...tmpImages]


      const photosDoesBuildingNeedDecking = async () => {        //jobsite.Attic photo w/tape measure (required if Mpls or St.Paul) Photos
         if(inspectionAllData.hasOwnProperty('photosDoesBuildingNeedDecking' )) {
            if(inspectionAllData.photosDoesBuildingNeedDecking.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.photosDoesBuildingNeedDecking.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.photosDoesBuildingNeedDecking[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'FILE_ID': inspectionAllData.photosDoesBuildingNeedDecking[i].FILE_ID, 'DESC': 'Does Building Need Decking'})
               }
               //setJobsitesPhotos([...jobsitesPhotos, ...images])
               return images
            }
         }
         return []
      }
      tmpImages = await photosDoesBuildingNeedDecking()
      images = [...images, ...tmpImages]


      const photosDoesItHaveAdeck = async () => {        //jobsite.Photos of deck and railing
         if(inspectionAllData.hasOwnProperty('photosDoesItHaveAdeck' )) {
            if(inspectionAllData.photosDoesItHaveAdeck.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.photosDoesItHaveAdeck.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.photosDoesItHaveAdeck[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'FILE_ID': inspectionAllData.photosDoesItHaveAdeck[i].FILE_ID, 'DESC': 'Does It Have a Deck'})
               }
               //setJobsitesPhotos([...jobsitesPhotos, ...images])
               return images
            }
         }
         return []
      }
      tmpImages = await photosDoesItHaveAdeck()
      images = [...images, ...tmpImages]

      let readyImages = []
      for (let i = 0; i < images.length; i+=2) {
         if( (i % 2 == 0) && (i < (images.length)) ) {
            readyImages.push({
               JobType: (i == 0 ? 'Jobsite' : ''),
               ImageType: 'Jobsite',
               Image1: images[i].DETAIL_URL,
               Image2: images[i+1] != undefined ? images[i+1].DETAIL_URL : '',
               Desc1: images[i].DESC,
               Desc2: images[i+1] != undefined ? images[i+1].DESC : '',
            })
         }
      }


console.log('finishing Jobsite photos', readyImages)

      setJobsitesPhotos([...readyImages])
      setJobsitesPhotosLoaded(true)
   }

   const collectElevationsPhotos = async () => {

      // if(elevationsPhotos.length > 0)
      //    return;

      let images = []

      const frontLeft = async () => {
         if(inspectionAllData.hasOwnProperty('frontLeft' )) {
            if(inspectionAllData.frontLeft.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.frontLeft.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.frontLeft[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'TYPE': 'frontLeft','DESC': 'FrontLeft'})
               }
               return images
            }
         }
         return []
      }
      let tmpImages = await frontLeft()
      images = [...images, ...tmpImages]


      const front = async () => {
         if(inspectionAllData.hasOwnProperty('front' )) {
            if(inspectionAllData.front.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.front.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.front[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'TYPE': 'front', 'DESC': 'Front'})
               }
               return images
            }
         }
         return []
      }
      tmpImages = await front()
      images = [...images, ...tmpImages]


      const frontRight = async () => {
         if(inspectionAllData.hasOwnProperty('frontRight' )) {
            if(inspectionAllData.frontRight.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.frontRight.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.frontRight[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'TYPE': 'frontRight', 'DESC': 'FrontRight'})
               }
               return images
            }
         }
         return []
      }
      tmpImages = await frontRight()
      images = [...images, ...tmpImages]



      const right = async () => {
         if(inspectionAllData.hasOwnProperty('right' )) {
            if(inspectionAllData.right.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.right.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.right[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'TYPE': 'right', 'DESC': 'Right'})
               }
               return images
            }
         }
         return []
      }

      tmpImages = await right()
      images = [...images, ...tmpImages]


      const backRight = async () => {
         if(inspectionAllData.hasOwnProperty('backRight' )) {
            if(inspectionAllData.backRight.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.backRight.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.backRight[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'TYPE': 'backRight', 'DESC': 'BackRight'})
               }
               return images
            }
         }
         return []
      }
      tmpImages = await backRight()
      images = [...images, ...tmpImages]


      const back = async () => {
         if(inspectionAllData.hasOwnProperty('back' )) {
            if(inspectionAllData.back.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.back.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.back[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'TYPE': 'back', 'DESC': 'Back'})
               }
               return images
            }
         }
         return []
      }
      tmpImages = await back()
      images = [...images, ...tmpImages]


      const backLeft = async () => {
         if(inspectionAllData.hasOwnProperty('backLeft' )) {
            if(inspectionAllData.backLeft.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.backLeft.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.backLeft[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'TYPE': 'backLeft', 'DESC': 'BackLeft'})
               }
               return images
            }
         }
         return []
      }
      tmpImages = await backLeft()
      images = [...images, ...tmpImages]


      const left = async () => {
         if(inspectionAllData.hasOwnProperty('left' )) {
            if(inspectionAllData.left.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.left.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.left[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'TYPE': 'left', 'DESC': 'Left'})
               }
               return images
            }
         }
         return []
      }
      tmpImages = await left()
      images = [...images, ...tmpImages]



      let readyImages = []
      for (let i = 0; i < images.length; i+=2) {
         if( (i % 2 == 0) && (i < (images.length)) ) {
            readyImages.push({
               JobType: (i == 0 ? 'Elevation' : ''),
               ImageType: `Elevations [${images[i].TYPE}, ${images[i+1].TYPE}]`,
               Image1: images[i].DETAIL_URL,
               Image2: images[i+1] != undefined ? images[i+1].DETAIL_URL : '',
               Desc1: images[i].DESC,
               Desc2: images[i+1] != undefined ? images[i+1].DESC : '',
            })
         }
      }


console.log('finishing Elevations photos')
      // console.log('elevations photos: ')
      // console.log(images)


      setElevationsPhotos([...readyImages])
      setElevationsPhotosLoaded(true)
   }

   const collectRoof_RoofField_Photos = async () => {

      // if(roofFields.length > 0)
      //    return;

      let images = []




      const photosNumberOfStories = async () => {
         if(inspectionAllData.hasOwnProperty('photosNumberOfStories' ))
            if(inspectionAllData.photosNumberOfStories.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.photosNumberOfStories.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.photosNumberOfStories[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'DESC': 'Number of Stories'})
               }
               return images
               }
         return []
      }
      let tmpImages = await photosNumberOfStories()
      images = [...images, ...tmpImages]


      const pitchGauge = async () => {
         if(inspectionAllData.hasOwnProperty('pitchGauge' ))
            if(inspectionAllData.pitchGauge.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.pitchGauge.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.pitchGauge[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'DESC': 'Pitch Gauge'})
               }
               return images
            }
         return []
      }

      tmpImages = await pitchGauge()
      images = [...images, ...tmpImages]


      const photosInchesOfEaveOverhang = async () => {
         if(inspectionAllData.hasOwnProperty('photosInchesOfEaveOverhang' ))
            if(inspectionAllData.photosInchesOfEaveOverhang.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.photosInchesOfEaveOverhang.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.photosInchesOfEaveOverhang[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'DESC': 'Inches ff Eave Overhang'})
               }
               return images
            }
         return []
      }
      tmpImages = await photosInchesOfEaveOverhang()
      images = [...images, ...tmpImages]


      const photosIceWaterShield = async () => {
         if(inspectionAllData.hasOwnProperty('photosIceWaterShield' ))
            if(inspectionAllData.photosIceWaterShield.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.photosIceWaterShield.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.photosIceWaterShield[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'DESC': 'IceWater Shield'})
               }
               return images
            }
         return []
      }
      tmpImages = await photosIceWaterShield()
      images = [...images, ...tmpImages]


      const photosRedeckRequired = async () => {
         if(inspectionAllData.hasOwnProperty('photosRedeckRequired' ))
            if(inspectionAllData.photosRedeckRequired.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.photosRedeckRequired.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.photosRedeckRequired[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'DESC': 'Redeck Required'})
               }
               return images
            }
         return []
      }
      tmpImages = await photosRedeckRequired()
      images = [...images, ...tmpImages]



      const photosRidgeCapShingle = async () => {
         if(inspectionAllData.hasOwnProperty('photosRidgeCapShingle' ))
            if(inspectionAllData.photosRidgeCapShingle.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.photosRidgeCapShingle.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.photosRidgeCapShingle[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'DESC': 'Ridge Cap Shingle'})
               }
               return images
            }
         return []
      }
      tmpImages = await photosRidgeCapShingle()
      images = [...images, ...tmpImages]



      const photosEaveFlashing = async () => {
         if(inspectionAllData.hasOwnProperty('photosEaveFlashing' ))
            if(inspectionAllData.photosEaveFlashing.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.photosEaveFlashing.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.photosEaveFlashing[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'DESC': 'Eave Flashing'})
               }
               return images
            }
         return []
      }
      tmpImages = await photosEaveFlashing()
      images = [...images, ...tmpImages]



      const roofOverview = async () => {
         if(inspectionAllData.hasOwnProperty('roofOverview' ))
            if(inspectionAllData.roofOverview.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.roofOverview.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.roofOverview[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'DESC': 'Roof Overview'})
               }
               return images
            }
         return []
      }
      tmpImages = await roofOverview()
      images = [...images, ...tmpImages]



      const photosShingleLayers = async () => {
         if(inspectionAllData.hasOwnProperty('photosShingleLayers' ))
            if(inspectionAllData.photosShingleLayers.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.photosShingleLayers.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.photosShingleLayers[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'DESC': 'Shingle Layers'})
               }
               return images
            }
         return []
      }
      tmpImages = await photosShingleLayers()
      images = [...images, ...tmpImages]


      const roofLeftSlope = async () => {
         if(inspectionAllData.hasOwnProperty('roofLeftSlope' ))
            if(inspectionAllData.roofLeftSlope.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.roofLeftSlope.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.roofLeftSlope[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'DESC': 'Roof Left Slope'})
               }
               return images
            }
         return []
      }
      tmpImages = await roofLeftSlope()
      images = [...images, ...tmpImages]


      const roofRightSlope = async () => {
         if(inspectionAllData.hasOwnProperty('roofRightSlope' ))
            if(inspectionAllData.roofRightSlope.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.roofRightSlope.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.roofRightSlope[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'DESC': 'Roof Right Slope'})
               }
               return images
            }
         return []
      }
      tmpImages = await roofRightSlope()
      images = [...images, ...tmpImages]


      const roofRearSlope = async () => {
         if(inspectionAllData.hasOwnProperty('roofRearSlope' ))
            if(inspectionAllData.roofRearSlope.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.roofRearSlope.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.roofRearSlope[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'DESC': 'Roof Rear Slope'})
               }
               return images
            }
         return []
      }
      tmpImages = await roofRearSlope()
      images = [...images, ...tmpImages]


      const roofFrontSlope = async () => {
         if(inspectionAllData.hasOwnProperty('roofFrontSlope' ))
            if(inspectionAllData.roofFrontSlope.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.roofFrontSlope.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.roofFrontSlope[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'DESC': 'Roof Front Slope'})
               }
               return images
            }
         return []
      }
      tmpImages = await roofFrontSlope()
      images = [...images, ...tmpImages]



      const starterShingle = async () => {
         if(inspectionAllData.hasOwnProperty('starterShingle' ))
            if(inspectionAllData.starterShingle.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.starterShingle.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.starterShingle[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'DESC': 'Starter Shingle'})
               }
               return images
            }
         return []
      }
      tmpImages = await starterShingle()
      images = [...images, ...tmpImages]

      const fieldRoofPhotos = async () => {
         if(inspectionAllData.hasOwnProperty('fieldRoofPhotos' ))
            if(inspectionAllData.fieldRoofPhotos.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.fieldRoofPhotos.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.fieldRoofPhotos[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'DESC': 'Roof'})
               }
               return images
            }
         return []
      }
      tmpImages = await fieldRoofPhotos()
      images = [...images, ...tmpImages]



      let readyImages = []
      for (let i = 0; i < images.length; i+=2) {
         if( (i % 2 == 0) && (i < (images.length)) ) {
            readyImages.push({
               JobType: (i == 0 ? 'RoofFields' : ''),
               ImageType: 'Rooffields',
               Image1: images[i].DETAIL_URL,
               Image2: images[i+1] != undefined ? images[i+1].DETAIL_URL : '',
               Desc1: images[i].DESC,
               Desc2: images[i+1] != undefined ? images[i+1].DESC : '',
            })
         }
      }



// this is VENTS block

      images = []
      const furriceCap = async () => {
         if(inspectionAllData.hasOwnProperty('furriceCap' )) {
            if (inspectionAllData.furriceCap.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.furriceCap.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.furriceCap[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'DESC': 'Furrice Cap'})
               }
               return images
            }
         }
         return []
      }
      tmpImages = await furriceCap()
      images = [...images, ...tmpImages]


      const splitBoot = async () => {
         if(inspectionAllData.hasOwnProperty('splitBoot' )) {
            if(inspectionAllData.splitBoot.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.splitBoot.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.splitBoot[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'DESC': 'Split Boot'})
               }
               return images
            }
         }
         return []
      }
      tmpImages = await splitBoot()
      images = [...images, ...tmpImages]


      const turtleVents = async () => {
         if(inspectionAllData.hasOwnProperty('turtleVents' )) {
            if(inspectionAllData.turtleVents.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.turtleVents.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.turtleVents[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'DESC': 'Turtle Vents'})
               }
               return images
            }
         }
         return []
      }
      tmpImages = await turtleVents()
      images = [...images, ...tmpImages]

      for (let i = 0; i < images.length; i+=2) {
         if( (i % 2 == 0) && (i < (images.length)) ) {
            readyImages.push({
               JobType: (i == 0 ? 'Ventilations' : ''),
               ImageType: 'Ventilations',
               Image1: images[i].DETAIL_URL,
               Image2: images[i+1] != undefined ? images[i+1].DETAIL_URL : '',
               Desc1: images[i].DESC,
               Desc2: images[i+1] != undefined ? images[i+1].DESC : '',
            })
         }
      }

      // for (let i = 0; i < images.length; i+=2) {
      //    if( (i % 2 == 0) && (i < (images.length)) ) {
      //       readyImages.push({
      //          JobType: (i == 0 ? 'Ventilations' : ''),
      //          ImageType: 'Ventilations',
      //          Image1: images[i].DETAIL_URL,
      //          Image2: images[i+1] != undefined ? images[i+1].DETAIL_URL : '',
      //          Desc1: images[i].DESC,
      //          Desc2: images[i+1] != undefined ? images[i+1].DESC : '',
      //       })
      //    }
      // }

      // for (let i = 0; i < images.length; i+=2) {
      //    if( (i % 2 == 0) && (i < (images.length)) ) {
      //       readyImages.push({
      //          ImageType: 'Rooffields',
      //          Image1: images[i].DETAIL_URL,
      //          Image2: images[i+1] != undefined ? images[i+1].DETAIL_URL : '',
      //          Desc1: images[i].DESC,
      //          Desc2: images[i+1] != undefined ? images[i+1].DESC : '',
      //       })
      //    }
      // }
// Ends of VENTS Block












      console.log('roofFields photos: ')
      console.log(images)

      setRoofFields(readyImages)
      setRoofFieldsLoaded(true)

   }

   const collectVentilationsPhotos = async () => {

      // if(ventilationsPhotos.length > 0)
      //    return;

      let images = []




      const furriceCap = async () => {
         if(inspectionAllData.hasOwnProperty('furriceCap' )) {
            if (inspectionAllData.furriceCap.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.furriceCap.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.furriceCap[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'DESC': 'Furrice Cap'})
               }
               return images
            }
         }
         return []
      }
      let tmpImages = await furriceCap()
      images = [...images, ...tmpImages]


      const splitBoot = async () => {
         if(inspectionAllData.hasOwnProperty('splitBoot' )) {
            if(inspectionAllData.splitBoot.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.splitBoot.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.splitBoot[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'DESC': 'Split Boot'})
               }
               return images
            }
         }
         return []
      }
      tmpImages = await splitBoot()
      images = [...images, ...tmpImages]


      const turtleVents = async () => {
         if(inspectionAllData.hasOwnProperty('turtleVents' )) {
            if(inspectionAllData.turtleVents.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.turtleVents.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.turtleVents[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'DESC': 'Turtle Vents'})
               }
               return images
            }
         }
         return []
      }
      tmpImages = await turtleVents()
      images = [...images, ...tmpImages]

      let readyImages = []
      for (let i = 0; i < images.length; i+=2) {
         if( (i % 2 == 0) && (i < (images.length)) ) {
            readyImages.push({
               JobType: (i == 0 ? 'Ventilations' : ''),
               ImageType: 'Ventilations',
               Image1: images[i].DETAIL_URL,
               Image2: images[i+1] != undefined ? images[i+1].DETAIL_URL : '',
               Desc1: images[i].DESC,
               Desc2: images[i+1] != undefined ? images[i+1].DESC : '',
            })
         }
      }

console.log('finishing Ventilations photos')

      setVentilationsPhotos(readyImages)
      setVentilationsPhotosLoaded(true)
   }

   const collectWindowsPhotos = async () => {

      // if(windowsPhotos.length > 0)
      //    return;

      let images = []

      const coveringsPhotos = async () => {
         if(inspectionAllData.hasOwnProperty('coveringsPhotos' )) {
            if (inspectionAllData.coveringsPhotos.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.coveringsPhotos.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.coveringsPhotos[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'DESC': 'Coverings'})
               }
               return images
            }
         }
         return []
      }
      let tmpImages = await coveringsPhotos()
      images = [...images, ...tmpImages]

      const interiorPhotos = async () => {
         if(inspectionAllData.hasOwnProperty('interiorPhotos' )) {
            if (inspectionAllData.interiorPhotos.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.interiorPhotos.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.interiorPhotos[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'DESC': 'Interior'})
               }
               return images
            }
         }
         return []
      }
      tmpImages = await interiorPhotos()
      images = [...images, ...tmpImages]

      const manufacturerMarkingsPhotos = async () => {
         if(inspectionAllData.hasOwnProperty('manufacturerMarkingsPhotos' )) {
            if (inspectionAllData.manufacturerMarkingsPhotos.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.manufacturerMarkingsPhotos.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.manufacturerMarkingsPhotos[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'DESC': 'Manufacturer Markings'})
               }
               return images
            }
         }
         return []
      }
      tmpImages = await manufacturerMarkingsPhotos()
      images = [...images, ...tmpImages]


      const closeUpOfWindowsDamagePhotos = async () => {
         if(inspectionAllData.hasOwnProperty('closeUpOfWindowsDamagePhotos' )) {
            if (inspectionAllData.closeUpOfWindowsDamagePhotos.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.closeUpOfWindowsDamagePhotos.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.closeUpOfWindowsDamagePhotos[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'DESC': 'CloseUp of Windows Damage'})
               }
               return images
            }
         }
         return []
      }
      tmpImages = await closeUpOfWindowsDamagePhotos()
      images = [...images, ...tmpImages]

      const elevationsPhotos = async () => {
         if(inspectionAllData.hasOwnProperty('elevationsPhotos' )) {
            if (inspectionAllData.elevationsPhotos.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.elevationsPhotos.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.elevationsPhotos[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'DESC': 'Elevations'})
               }
               return images
            }
         }
         return []
      }
      tmpImages = await elevationsPhotos()
      images = [...images, ...tmpImages]

      let readyImages = []
      for (let i = 0; i < images.length; i+=2) {
         if( (i % 2 == 0) && (i < (images.length)) ) {
            readyImages.push({
               JobType: (i == 0 ? 'Windows' : ''),
               ImageType: 'Windows',
               Image1: images[i].DETAIL_URL,
               Image2: images[i+1] != undefined ? images[i+1].DETAIL_URL : '',
               Desc1: images[i].DESC,
               Desc2: images[i+1] != undefined ? images[i+1].DESC : '',
            })
         }
      }



      console.log('finishing Windows photos')

      setWindowsPhotos(readyImages)
      setWindowsPhotosLoaded(true)
   }

   const collectGuttersPhotos = async () => {

      // if(guttersPhotos.length > 0)
      //    return;

      let images = []

      const guttersFrontPhotos = async () => {
         if(inspectionAllData.hasOwnProperty('guttersFrontPhotos' )) {
            if (inspectionAllData.guttersFrontPhotos.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.guttersFrontPhotos.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.guttersFrontPhotos[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'DESC': 'Gutters Front'})
               }
               return images
            }
         }
         return []
      }
      tmpImages = await guttersFrontPhotos()
      images = [...images, ...tmpImages]

      const guttersFrontUpperPhotos = async () => {
         if(inspectionAllData.hasOwnProperty('guttersFrontUpperPhotos' )) {
            if (inspectionAllData.guttersFrontUpperPhotos.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.guttersFrontUpperPhotos.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.guttersFrontUpperPhotos[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'DESC': 'Gutters Front-Upper'})
               }
               return images
            }
         }
         return []
      }
      tmpImages = await guttersFrontUpperPhotos()
      images = [...images, ...tmpImages]


      const guttersRightPhotos = async () => {
         if(inspectionAllData.hasOwnProperty('guttersRightPhotos' )) {
            if (inspectionAllData.guttersRightPhotos.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.guttersRightPhotos.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.guttersRightPhotos[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'DESC': 'Gutters Right'})
               }
               return images
            }
         }
         return []
      }
      tmpImages = await guttersRightPhotos()
      images = [...images, ...tmpImages]

      const guttersRightUpperPhotos = async () => {
         if(inspectionAllData.hasOwnProperty('guttersRightUpperPhotos' )) {
            if (inspectionAllData.guttersRightUpperPhotos.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.guttersRightUpperPhotos.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.guttersRightUpperPhotos[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'DESC': 'Gutters Right-Upper'})
               }
               return images
            }
         }
         return []
      }
      tmpImages = await guttersRightUpperPhotos()
      images = [...images, ...tmpImages]

      const guttersBackPhotos = async () => {
         if(inspectionAllData.hasOwnProperty('guttersBackPhotos' )) {
            if (inspectionAllData.guttersBackPhotos.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.guttersBackPhotos.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.guttersBackPhotos[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'DESC': 'Gutters Back'})
               }
               return images
            }
         }
         return []
      }
      tmpImages = await guttersBackPhotos()
      images = [...images, ...tmpImages]

      const guttersBackUpperPhotos = async () => {
         if(inspectionAllData.hasOwnProperty('guttersBackUpperPhotos' )) {
            if (inspectionAllData.guttersBackUpperPhotos.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.guttersBackUpperPhotos.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.guttersBackUpperPhotos[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'DESC': 'Gutters Back-Upper'})
               }
               return images
            }
         }
         return []
      }
      tmpImages = await guttersBackUpperPhotos()
      images = [...images, ...tmpImages]

      const guttersLeftPhotos = async () => {
         if(inspectionAllData.hasOwnProperty('guttersLeftPhotos' )) {
            if (inspectionAllData.guttersLeftPhotos.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.guttersLeftPhotos.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.guttersLeftPhotos[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'DESC': 'Gutters Left'})
               }
               return images
            }
         }
         return []
      }
      tmpImages = await guttersLeftPhotos()
      images = [...images, ...tmpImages]

      const guttersLeftUpperPhotos = async () => {
         if(inspectionAllData.hasOwnProperty('guttersLeftUpperPhotos' )) {
            if (inspectionAllData.guttersLeftUpperPhotos.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.guttersLeftUpperPhotos.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.guttersLeftUpperPhotos[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'DESC': 'Gutters Left-Upper'})
               }
               return images
            }
         }
         return []
      }
      tmpImages = await guttersLeftUpperPhotos()
      images = [...images, ...tmpImages]


      const downspoutsFrontPhotos = async () => {
         if(inspectionAllData.hasOwnProperty('downspoutsFrontPhotos' )) {
            if (inspectionAllData.downspoutsFrontPhotos.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.downspoutsFrontPhotos.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.downspoutsFrontPhotos[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'DESC': 'Downspouts Front'})
               }
               return images
            }
         }
         return []
      }
      tmpImages = await downspoutsFrontPhotos()
      images = [...images, ...tmpImages]

      const downspoutsFrontUpperPhotos = async () => {
         if(inspectionAllData.hasOwnProperty('downspoutsFrontUpperPhotos' )) {
            if (inspectionAllData.downspoutsFrontUpperPhotos.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.downspoutsFrontUpperPhotos.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.downspoutsFrontUpperPhotos[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'DESC': 'Downspouts Front-Upper'})
               }
               return images
            }
         }
         return []
      }
      tmpImages = await downspoutsFrontUpperPhotos()
      images = [...images, ...tmpImages]


      const downspoutsRightPhotos = async () => {
         if(inspectionAllData.hasOwnProperty('downspoutsRightPhotos' )) {
            if (inspectionAllData.downspoutsRightPhotos.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.downspoutsRightPhotos.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.downspoutsRightPhotos[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'DESC': 'Downspouts Right'})
               }
               return images
            }
         }
         return []
      }
      tmpImages = await downspoutsRightPhotos()
      images = [...images, ...tmpImages]

      const downspoutsRightUpperPhotos = async () => {
         if(inspectionAllData.hasOwnProperty('downspoutsRightUpperPhotos' )) {
            if (inspectionAllData.downspoutsRightUpperPhotos.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.downspoutsRightUpperPhotos.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.downspoutsRightUpperPhotos[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'DESC': 'Downspouts Right-Upper'})
               }
               return images
            }
         }
         return []
      }
      tmpImages = await downspoutsRightUpperPhotos()
      images = [...images, ...tmpImages]

      const downspoutsBackPhotos = async () => {
         if(inspectionAllData.hasOwnProperty('downspoutsBackPhotos' )) {
            if (inspectionAllData.downspoutsBackPhotos.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.downspoutsBackPhotos.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.downspoutsBackPhotos[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'DESC': 'Downspouts Back'})
               }
               return images
            }
         }
         return []
      }
      tmpImages = await downspoutsBackPhotos()
      images = [...images, ...tmpImages]

      const downspoutsBackUpperPhotos = async () => {
         if(inspectionAllData.hasOwnProperty('downspoutsBackUpperPhotos' )) {
            if (inspectionAllData.downspoutsBackUpperPhotos.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.downspoutsBackUpperPhotos.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.downspoutsBackUpperPhotos[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'DESC': 'Downspouts Back-Upper'})
               }
               return images
            }
         }
         return []
      }
      tmpImages = await downspoutsBackUpperPhotos()
      images = [...images, ...tmpImages]

      const downspoutsLeftPhotos = async () => {
         if(inspectionAllData.hasOwnProperty('downspoutsLeftPhotos' )) {
            if (inspectionAllData.downspoutsLeftPhotos.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.downspoutsLeftPhotos.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.downspoutsLeftPhotos[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'DESC': 'Downspouts Left'})
               }
               return images
            }
         }
         return []
      }
      tmpImages = await downspoutsLeftPhotos()
      images = [...images, ...tmpImages]

      const downspoutsLeftUpperPhotos = async () => {
         if(inspectionAllData.hasOwnProperty('downspoutsLeftUpperPhotos' )) {
            if (inspectionAllData.downspoutsLeftUpperPhotos.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.downspoutsLeftUpperPhotos.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.downspoutsLeftUpperPhotos[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'DESC': 'Downspouts Left-Upper'})
               }
               return images
            }
         }
         return []
      }
      tmpImages = await downspoutsLeftUpperPhotos()
      images = [...images, ...tmpImages]


      const downspoutsWidthPhotos = async () => {
         if(inspectionAllData.hasOwnProperty('downspoutsWidthPhotos' )) {
            if (inspectionAllData.downspoutsWidthPhotos.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.downspoutsWidthPhotos.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.downspoutsWidthPhotos[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'DESC': 'Downspouts Width'})
               }
               return images
            }
         }
         return []
      }
      tmpImages = await downspoutsWidthPhotos()
      images = [...images, ...tmpImages]



      const gutterSizePhotos = async () => {
         if(inspectionAllData.hasOwnProperty('gutterSizePhotos' )) {
            if (inspectionAllData.gutterSizePhotos.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.gutterSizePhotos.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.gutterSizePhotos[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'DESC': 'Gutter Size'})
               }
               return images
            }
         }
         return []
      }
      tmpImages = await gutterSizePhotos()
      images = [...images, ...tmpImages]


      const gutterCoversPhotos = async () => {
         if(inspectionAllData.hasOwnProperty('gutterCoversPhotos' )) {
            if (inspectionAllData.gutterCoversPhotos.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.gutterCoversPhotos.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.gutterCoversPhotos[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'DESC': 'Gutter Covers'})
               }
               return images
            }
         }
         return []
      }
      tmpImages = await gutterCoversPhotos()
      images = [...images, ...tmpImages]


      const guttersAdditionalDamaggToSupplementPhotos = async () => {
         if(inspectionAllData.hasOwnProperty('guttersAdditionalDamaggToSupplementPhotos' )) {
            if (inspectionAllData.guttersAdditionalDamaggToSupplementPhotos.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.guttersAdditionalDamaggToSupplementPhotos.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.guttersAdditionalDamaggToSupplementPhotos[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'DESC': 'Gutters AdditionalDamage To Supplement'})
               }
               return images
            }
         }
         return []
      }
      let tmpImages = await guttersAdditionalDamaggToSupplementPhotos()
      images = [...images, ...tmpImages]








/*

      guttersFrontUpperPhotos
      guttersRightUpperPhotos
      guttersBackUpperPhotos
      guttersLeftUpperPhotos
      downspoutsFrontUpperPhotos
      downspoutsRightUpperPhotos
      downspoutsBackUpperPhotos
      downspoutsLeftUpperPhotos

*/















      let readyImages = []
      for (let i = 0; i < images.length; i+=2) {
         if( (i % 2 == 0) && (i < (images.length)) ) {
            readyImages.push({
               JobType: (i == 0 ? 'Gutters' : ''),
               ImageType: 'Gutters',
               Image1: images[i].DETAIL_URL,
               Image2: images[i+1] != undefined ? images[i+1].DETAIL_URL : '',
               Desc1: images[i].DESC,
               Desc2: images[i+1] != undefined ? images[i+1].DESC : '',
            })
         }
      }

      console.log('finishing Gutters photos')

      setGuttersPhotos(readyImages)
      setGuttersPhotosLoaded(true)
   }

   const collectTestSquaresPhotos = async () => {

      // if(ventilationsPhotos.length > 0)
      //    return;

      let images = []



      const closeUp2Left = async () => {
         if(inspectionAllData.hasOwnProperty('closeUp2Left' )) {
            if(inspectionAllData.closeUp2Left.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.closeUp2Left.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.closeUp2Left[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'DESC': 'CloseUp2Left'})
               }
               return images
            }
         }
         return []
      }
      let tmpImages = await closeUp2Left()
      images = [...images, ...tmpImages]


      const closeUp1Left = async () => {
         if(inspectionAllData.hasOwnProperty('closeUp1Left' )) {
            if(inspectionAllData.closeUp1Left.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.closeUp1Left.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.closeUp1Left[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'DESC': 'CloseUp1Left'})
               }
               return images
            }
         }
         return []
      }
      tmpImages = await closeUp1Left()
      images = [...images, ...tmpImages]


      const damageLeft = async () => {
         if(inspectionAllData.hasOwnProperty('damageLeft' )) {
            if(inspectionAllData.damageLeft.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.damageLeft.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.damageLeft[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'DESC': 'Left'})
               }
               return images
            }
         }
         return []
      }

      tmpImages = await damageLeft()
      images = [...images, ...tmpImages]


      const overallTestSquareLeft = async () => {
         if(inspectionAllData.hasOwnProperty('overallTestSquareLeft' )) {
            if(inspectionAllData.overallTestSquareLeft.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.overallTestSquareLeft.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.overallTestSquareLeft[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'DESC': 'Overall TestSquare Left'})
               }
               return images
            }
         }
         return []
      }

      tmpImages = await overallTestSquareLeft()
      images = [...images, ...tmpImages]


      const closeUp2Back = async () => {
         if(inspectionAllData.hasOwnProperty('closeUp2Back' )) {
            if(inspectionAllData.closeUp2Back.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.closeUp2Back.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.closeUp2Back[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'DESC': 'CloseUp2Back'})
               }
               return images
            }
         }
         return []
      }

      tmpImages = await closeUp2Back()
      images = [...images, ...tmpImages]


      const closeUp1Back = async () => {
         if(inspectionAllData.hasOwnProperty('closeUp1Back' )) {
            if(inspectionAllData.closeUp1Back.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.closeUp1Back.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.closeUp1Back[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'DESC': 'CloseUp1Back'})
               }
               return images
            }
         }
         return []
      }

      tmpImages = await closeUp1Back()
      images = [...images, ...tmpImages]


      const damageBack = async () => {
         if(inspectionAllData.hasOwnProperty('damageBack' )) {
            if(inspectionAllData.damageBack.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.damageBack.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.damageBack[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'DESC': 'Back'})
               }
               return images
            }
         }
         return []
      }

      tmpImages = await damageBack()
      images = [...images, ...tmpImages]


      const overallTestSquareBack = async () => {
         if(inspectionAllData.hasOwnProperty('overallTestSquareBack' )) {
            if(inspectionAllData.overallTestSquareBack.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.overallTestSquareBack.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.overallTestSquareBack[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'DESC': 'Overall TestSquare Back'})
               }
               return images
            }
         }
         return []
      }

      tmpImages = await overallTestSquareBack()
      images = [...images, ...tmpImages]


      const closeUp2Right = async () => {
         if(inspectionAllData.hasOwnProperty('closeUp2Right' )) {
            if(inspectionAllData.closeUp2Right.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.closeUp2Right.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.closeUp2Right[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'DESC': 'CloseUp2Right'})
               }
               return images
            }
         }
         return []
      }

      tmpImages = await closeUp2Right()
      images = [...images, ...tmpImages]


      const closeUp1Right = async () => {
         if(inspectionAllData.hasOwnProperty('closeUp1Right' )) {
            if(inspectionAllData.closeUp1Right.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.closeUp1Right.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.closeUp1Right[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'DESC': 'CloseUp1Right'})
               }
               return images
            }
         }
         return []
      }

      tmpImages = await closeUp1Right()
      images = [...images, ...tmpImages]


      const damageRight = async () => {
         if(inspectionAllData.hasOwnProperty('damageRight' )) {
            if(inspectionAllData.damageRight.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.damageRight.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.damageRight[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'DESC': 'Right'})
               }
               return images
            }
         }
         return []
      }

      tmpImages = await damageRight()
      images = [...images, ...tmpImages]


      const overallTestSquareRight = async () => {
         if(inspectionAllData.hasOwnProperty('overallTestSquareRight' )) {
            if(inspectionAllData.overallTestSquareRight.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.overallTestSquareRight.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.overallTestSquareRight[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'DESC': 'Overall TestSquare Right'})
               }
               return images
            }
         }
         return []
      }

      tmpImages = await overallTestSquareRight()
      images = [...images, ...tmpImages]


      const closeUp2Front = async () => {
         if(inspectionAllData.hasOwnProperty('closeUp2Front' )) {
            if(inspectionAllData.closeUp2Front.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.closeUp2Front.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.closeUp2Front[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'DESC': 'CloseUp2Front'})
               }
               return images
            }
         }
         return []
      }

      tmpImages = await closeUp2Front()
      images = [...images, ...tmpImages]


      const closeUp1Front = async () => {
         if(inspectionAllData.hasOwnProperty('closeUp1Front' )) {
            if(inspectionAllData.closeUp1Front.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.closeUp1Front.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.closeUp1Front[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'DESC': 'CloseUp1Front'})
               }
               return images
            }
         }
         return []
      }
      tmpImages = await closeUp1Front()
      images = [...images, ...tmpImages]


      const damageFront = async () => {
         if(inspectionAllData.hasOwnProperty('damageFront' )) {
            if(inspectionAllData.damageFront.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.damageFront.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.damageFront[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'DESC': 'Front'})
               }
               return images
            }
         }
         return []
      }
      tmpImages = await damageFront()
      images = [...images, ...tmpImages]

      const overallTestSquareFront = async () => {
         if(inspectionAllData.hasOwnProperty('overallTestSquareFront' )) {
            if(inspectionAllData.overallTestSquareFront.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.overallTestSquareFront.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.overallTestSquareFront[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'DESC': 'Overall TestSquare Front'})
               }
               return images
            }
         }
         return []
      }
      tmpImages = await overallTestSquareFront()
      images = [...images, ...tmpImages]






      const damageRoof = async () => {
         if(inspectionAllData.hasOwnProperty('damageRoof' )) {
            if(inspectionAllData.damageRoof.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.damageRoof.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.damageRoof[i].ID)
                  images.push({'FILE_ID': imageUrl.FILE_ID, 'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'TYPE': 'Roof', 'DESC': 'Roof'})
               }
               return images
            }
         }
         return []
      }
      tmpImages =  await damageRoof()
      images = [...images, ...tmpImages]

      const damageSiding = async () => {
         if(inspectionAllData.hasOwnProperty('damageSiding' )) {
            if(inspectionAllData.damageSiding.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.damageSiding.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.damageSiding[i].ID)
                  images.push({'FILE_ID': imageUrl.FILE_ID, 'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'TYPE': 'Siding', 'DESC': 'Siding'})
               }
               return images
            }
         }
         return []
      }
      tmpImages =  await damageSiding()
      images = [...images, ...tmpImages]

      const damageGutterDownSpouts = async () => {
         if(inspectionAllData.hasOwnProperty('damageGutterDownSpouts' )) {
            if(inspectionAllData.damageGutterDownSpouts.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.damageGutterDownSpouts.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.damageGutterDownSpouts[i].ID)
                  images.push({'FILE_ID': imageUrl.FILE_ID, 'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'TYPE': 'Gutter/DownSpouts', 'DESC': 'Gutter/DownSpouts'})
               }
               return images
            }
         }
         return []
      }
      tmpImages =  await damageGutterDownSpouts()
      images = [...images, ...tmpImages]


      const damageWindows = async () => {
         if(inspectionAllData.hasOwnProperty('damageWindows' )) {
            if(inspectionAllData.damageWindows.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.damageWindows.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.damageWindows[i].ID)
                  images.push({'FILE_ID': imageUrl.FILE_ID, 'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'TYPE': 'Windows', 'DESC': 'Windows'})
               }
               return images
            }
         }
         return []
      }
      tmpImages =  await damageWindows()
      images = [...images, ...tmpImages]

      const damageMetals = async () => {
         if(inspectionAllData.hasOwnProperty('damageMetals' )) {
            if(inspectionAllData.damageMetals.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.damageMetals.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.damageMetals[i].ID)
                  images.push({'FILE_ID': imageUrl.FILE_ID, 'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'TYPE': 'Metals', 'DESC': 'Metals'})
               }
               return images
            }
         }
         return []
      }
      tmpImages =  await damageMetals()
      images = [...images, ...tmpImages]









      let readyImages = []
      for (let i = 0; i < images.length; i+=2) {
         if( (i % 2 == 0) && (i < (images.length)) ) {




            readyImages.push({
               JobType: (i == 0 ? 'Damages' : ''),
               ImageType: `${images[i].TYPE}, ${images[i+1] != undefined ? images[i+1].TYPE : ''}`,
               Image1: images[i].DETAIL_URL,
               Image2: images[i+1] != undefined ? images[i+1].DETAIL_URL : '',
               Desc1: images[i].DESC,
               Desc2: images[i+1] != undefined ? images[i+1].DESC : '',
            })
         }
      }

      console.log('finishing TestSquares photos')

      setTestSquaresPhotos(readyImages)
      setTestSquaresPhotosLoaded(true)
   }

   const collectRoofPhotos = async () => {

      // if(roofPhotos.length > 0)
      //    return;

      let images = []



      const roofPhotos1 = async () => {
         if(inspectionAllData.hasOwnProperty('roofPhotos' )) {
            if(inspectionAllData.roofPhotos.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.roofPhotos.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.roofPhotos[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'DESC': 'Roof Photo'})
               }
               return images
            }
         }
         return []
      }

      let tmpImages = await roofPhotos1()
      images = [...images, ...tmpImages]

      let readyImages = []
      for (let i = 0; i < images.length; i+=2) {
         if( (i % 2 == 0) && (i < (images.length)) ) {
            readyImages.push({
               JobType: (i == 0 ? 'Roof Photos' : ''),
               ImageType: 'Roof Photos',
               Image1: images[i].DETAIL_URL,
               Image2: images[i+1] != undefined ? images[i+1].DETAIL_URL : '',
               Desc1: images[i].DESC,
               Desc2: images[i+1] != undefined ? images[i+1].DESC : '',
            })
         }
      }

console.log('finishing Roof photos')

      setRoofPhotos(readyImages)
      setRoofPhotosLoaded(true)
   }

   const collectInteriorPhotos = async () => {

      // if(interiorPhotos.length > 0)
      //    return;

      let images = []

      const other = async () => {
         if(inspectionAllData.hasOwnProperty('other' ))
            if(inspectionAllData.other.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.other.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.other[i].ID)
                  images.push({'ID': imageUrl.ID, 'FILE_ID': imageUrl.FILE_ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'DESC': 'Other'})
               }
               return images
            }
         return []
      }

      let tmpImages = await other()
      images = [...images, ...tmpImages]


      const attic = async () => {
         if(inspectionAllData.hasOwnProperty('attic' ))
            if(inspectionAllData.attic.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.attic.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.attic[i].ID)
                  images.push({'FILE_ID': imageUrl.FILE_ID, 'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'DESC': 'Attic'})
               }
               return images
            }
         return []
      }

      tmpImages = await attic()
      images = [...images, ...tmpImages]


      const garage = async () => {
         if(inspectionAllData.hasOwnProperty('garage' ))
            if(inspectionAllData.garage.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.garage.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.garage[i].ID)
                  images.push({'FILE_ID': imageUrl.FILE_ID, 'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'DESC': 'Garage'})
               }
               return images
            }
         return []
      }

      tmpImages = await garage()
      images = [...images, ...tmpImages]


      const bedroom6 = async () => {
         if(inspectionAllData.hasOwnProperty('bedroom6' ))
            if(inspectionAllData.bedroom6.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.bedroom6.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.bedroom6[i].ID)
                  images.push({'FILE_ID': imageUrl.FILE_ID, 'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'DESC': 'Bedroom6'})
               }
               return images
            }
         return []
      }

      tmpImages = await bedroom6()
      images = [...images, ...tmpImages]


      const bedroom5 = async () => {
         if(inspectionAllData.hasOwnProperty('bedroom5' ))
            if(inspectionAllData.bedroom5.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.bedroom5.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.bedroom5[i].ID)
                  images.push({'FILE_ID': imageUrl.FILE_ID, 'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'DESC': 'Bedroom5'})
               }
               return images
            }
         return []
      }

      tmpImages = await bedroom5()
      images = [...images, ...tmpImages]



      const bedroom4 = async () => {
         if(inspectionAllData.hasOwnProperty('bedroom4' ))
            if(inspectionAllData.bedroom4.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.bedroom4.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.bedroom4[i].ID)
                  images.push({'FILE_ID': imageUrl.FILE_ID, 'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'DESC': 'Bedroom4'})
               }
               return images
            }
         return []
      }

      tmpImages = await bedroom4()
      images = [...images, ...tmpImages]


      const bedroom3 = async () => {
         if(inspectionAllData.hasOwnProperty('bedroom3' ))
            if(inspectionAllData.bedroom3.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.bedroom3.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.bedroom3[i].ID)
                  images.push({'FILE_ID': imageUrl.FILE_ID, 'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'DESC': 'Bedroom3'})
               }
               return images
            }
         return []
      }

      tmpImages = await bedroom3()
      images = [...images, ...tmpImages]


      const bedroom2 = async () => {
         if(inspectionAllData.hasOwnProperty('bedroom2' ))
            if(inspectionAllData.bedroom2.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.bedroom2.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.bedroom2[i].ID)
                  images.push({'FILE_ID': imageUrl.FILE_ID, 'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'DESC': 'Bedroom2'})
               }
               return images
            }
         return []
      }

      tmpImages = await bedroom2()
      images = [...images, ...tmpImages]


      const bedroom1 = async () => {
         if(inspectionAllData.hasOwnProperty('bedroom1' ))
            if(inspectionAllData.bedroom1.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.bedroom1.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.bedroom1[i].ID)
                  images.push({'FILE_ID': imageUrl.FILE_ID, 'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'DESC': 'Bedroom1'})
               }
               return images
            }
         return []
      }

      tmpImages = await bedroom1()
      images = [...images, ...tmpImages]


      const bathroom4 = async () => {
         if(inspectionAllData.hasOwnProperty('bathroom4' ))
            if(inspectionAllData.bathroom4.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.bathroom4.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.bathroom4[i].ID)
                  images.push({'FILE_ID': imageUrl.FILE_ID, 'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'DESC': 'Bathroom4'})
               }
               return images
            }
         return []
      }

      tmpImages = await bathroom4()
      images = [...images, ...tmpImages]


      const bathroom3 = async () => {
         if(inspectionAllData.hasOwnProperty('bathroom3' ))
            if(inspectionAllData.bathroom3.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.bathroom3.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.bathroom3[i].ID)
                  images.push({'FILE_ID': imageUrl.FILE_ID, 'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'DESC': 'Bathroom3'})
               }
               return images
            }
         return []
      }

      tmpImages = await bathroom3()
      images = [...images, ...tmpImages]


      const bathroom2 = async () => {
         if(inspectionAllData.hasOwnProperty('bathroom2' ))
            if(inspectionAllData.bathroom2.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.bathroom2.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.bathroom2[i].ID)
                  images.push({'FILE_ID': imageUrl.FILE_ID, 'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'DESC': 'Bathroom2'})
               }
               return images
            }
         return []
      }

      tmpImages = await bathroom2()
      images = [...images, ...tmpImages]


      const bathroom1 = async () => {
         if(inspectionAllData.hasOwnProperty('bathroom1' ))
            if(inspectionAllData.bathroom1.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.bathroom1.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.bathroom1[i].ID)
                  images.push({'FILE_ID': imageUrl.FILE_ID, 'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'DESC': 'Bathroom1'})
               }
               return images
            }
         return []
      }

      tmpImages = await bathroom1()
      images = [...images, ...tmpImages]


      const office = async () => {
         if(inspectionAllData.hasOwnProperty('office' ))
            if(inspectionAllData.office.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.office.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.office[i].ID)
                  images.push({'FILE_ID': imageUrl.FILE_ID, 'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'DESC': 'Office'})
               }
               return images
            }
         return []
      }

      tmpImages = await office()
      images = [...images, ...tmpImages]


      const hallway3 = async () => {
         if(inspectionAllData.hasOwnProperty('hallway3' ))
            if(inspectionAllData.hallway3.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.hallway3.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.hallway3[i].ID)
                  images.push({'FILE_ID': imageUrl.FILE_ID, 'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'DESC': 'Hallway3'})
               }
               return images
            }
         return []
      }

      tmpImages = await hallway3()
      images = [...images, ...tmpImages]


      const hallway2 = async () => {
         if(inspectionAllData.hasOwnProperty('hallway2' ))
            if(inspectionAllData.hallway2.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.hallway2.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.hallway2[i].ID)
                  images.push({'FILE_ID': imageUrl.FILE_ID, 'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'DESC': 'Hallway2'})
               }
               return images
            }
         return []
      }

      tmpImages = await hallway2()
      images = [...images, ...tmpImages]


      const hallway1 = async () => {
         if(inspectionAllData.hasOwnProperty('hallway1' ))
            if(inspectionAllData.hallway1.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.hallway1.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.hallway1[i].ID)
                  images.push({'FILE_ID': imageUrl.FILE_ID, 'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'DESC': 'Hallway1'})
               }
               return images
            }
         return []
      }

      tmpImages = await hallway1()
      images = [...images, ...tmpImages]


      const den = async () => {
         if(inspectionAllData.hasOwnProperty('den' ))
            if(inspectionAllData.den.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.den.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.den[i].ID)
                  images.push({'FILE_ID': imageUrl.FILE_ID, 'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'DESC': 'Den'})
               }
               return images
            }
         return []
      }

      tmpImages = await den()
      images = [...images, ...tmpImages]



      const kitchen = async () => {
         if(inspectionAllData.hasOwnProperty('kitchen' ))
            if(inspectionAllData.kitchen.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.kitchen.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.kitchen[i].ID)
                  images.push({'FILE_ID': imageUrl.FILE_ID, 'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'DESC': 'Kitchen'})
               }
               return images
            }
         return []
      }

      tmpImages = await kitchen()
      images = [...images, ...tmpImages]


      const diningRoom = async () => {
         if(inspectionAllData.hasOwnProperty('diningRoom' ))
            if(inspectionAllData.diningRoom.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.diningRoom.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.diningRoom[i].ID)
                  images.push({'FILE_ID': imageUrl.FILE_ID, 'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'DESC': 'DiningRoom'})
               }
               return images
            }
         return []
      }

      tmpImages = await diningRoom()
      images = [...images, ...tmpImages]


      const bonusRoom = async () => {
         if(inspectionAllData.hasOwnProperty('bonusRoom' ))
            if(inspectionAllData.bonusRoom.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.bonusRoom.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.bonusRoom[i].ID)
                  images.push({'FILE_ID': imageUrl.FILE_ID, 'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'DESC': 'BonusRoom'})
               }
               return images
            }
         return []
      }

      tmpImages = await bonusRoom()
      images = [...images, ...tmpImages]


      const familyRoom = async () => {
         if(inspectionAllData.hasOwnProperty('familyRoom' ))
            if(inspectionAllData.familyRoom.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.familyRoom.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.familyRoom[i].ID)
                  images.push({'FILE_ID': imageUrl.FILE_ID, 'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'DESC': 'FamilyRoom'})
               }
               return images
            }
         return []
      }

      tmpImages = await familyRoom()
      images = [...images, ...tmpImages]


      const livingRoom = async () => {
         if(inspectionAllData.hasOwnProperty('livingRoom' ))
            if(inspectionAllData.livingRoom.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.livingRoom.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.livingRoom[i].ID)
                  images.push({'FILE_ID': imageUrl.FILE_ID, 'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'DESC': 'LivingRoom'})
               }
               return images
            }
         return []
      }

      tmpImages = await livingRoom()
      images = [...images, ...tmpImages]


      let readyImages = []
      for (let i = 0; i < images.length; i+=2) {
         if( (i % 2 == 0) && (i < (images.length)) ) {
            readyImages.push({
               JobType: (i == 0 ? 'Interior' : ''),
               ImageType: 'Interior Photos',
               Image1: images[i].DETAIL_URL,
               Image2: images[i+1] != undefined ? images[i+1].DETAIL_URL : '',
               Desc1: images[i].DESC,
               Desc2: images[i+1] != undefined ? images[i+1].DESC : '',
            })
         }
      }

console.log('finishing Interior photos')


      setInteriorPhotos(readyImages)
      setInteriorPhotosLoaded(true)
   }




   useEffect(() => {

      if (inspectionReportsBlockInfo.showBlock == true) {



         if(inspectionAllData.hasOwnProperty('PROPERTY_1109'))
            setClientEmail(inspectionAllData.PROPERTY_1109)


      }

      setShow(inspectionReportsBlockInfo.showBlock)
   }, [inspectionReportsBlockInfo]);



   const generateReport = async () => {

      if(filterNoPhotos == true) {
         setGeneratingNoPhotoReportInProgress(true)

         setPdfLink(false)
         setLoading(true)

         dispatch({ type: 'SAVE_REQUEST'})
         return; // и выходим, т.к. все данные в случае 'No Photos' придут к нам сами выше в useEffect от flagNoPhotosReadyData
      }

      setPdfLink(false)
      setLoading(true)

// в отчёте с фото тоже собираем все данные со страницы, т.к. будем выводить блоки с данными для Gutters и Windows
      dispatch({ type: 'SAVE_REQUEST'})

      setJobsitesPhotos([]);
      setElevationsPhotos([]);
      setRoofFields([]);
      setVentilationsPhotos([]);
      setWindowsPhotos([]);
      setGuttersPhotos([]);
      setTestSquaresPhotos([]);
      setRoofPhotos([]);
      setInteriorPhotos([]);


      if(filterJobsite)
         collectJobsitePhotos()
      if(filterElevations)
         collectElevationsPhotos()
      if(filterRoffields) {
//         setFilterVentilations(true)
         collectRoof_RoofField_Photos()
      }
      // if(filterVentilations) {}
      //    collectVentilationsPhotos()

      if(filterWindows)
         collectWindowsPhotos()
      if(filterGutters)
         collectGuttersPhotos()
      if(filterTestSquares)
         collectTestSquaresPhotos()
      if(filterRoofPhotos)
         collectRoofPhotos()
      if(filterInterior)
         collectInteriorPhotos()



   }

   const sendReportToClient = async () => {

      setLoadingSendingReport(true)

      const params = {
         pdfLink: pdfReportLink,
         userId: access_token_data.user_id,
         clientEmail: clientEmail
      }
      const result = await proxySendReportToClient(access_token_data, params)

      setLoadingSendingReport(false)

   }




   return (

      <Stack
         direction="column"
         justifyContent="left"
         alignItems="left"
         padding={2}
         spacing={1}
         sx={{ display: show === true ? 'flex' : 'none' }}
      >
         <Stack direction="column" justifyContent="center" alignItems="center" spacing={1}>

            <FormGroup>
               <FormControlLabel control={<Checkbox checked={filterJobsite} onChange={
                  (event) => {
                     setFilterJobsite(event.target.checked);
                  }
               }/>} label="Jobsite" />
               <FormControlLabel control={<Checkbox checked={filterElevations} onChange={
                  (event) => {
                     setFilterElevations(event.target.checked);
                  }

               }/>} label="Elevations" />
               <FormControlLabel control={<Checkbox checked={filterRoffields} onChange={
                  (event) => {
                     setFilterRoffields(event.target.checked);
                     setFilterVentilations(event.target.checked)
                  }

               }/>} label="Roof" />
{/*
               <FormControlLabel control={<Checkbox checked={filterVentilations} onChange={
                  (event) => {
                     setFilterVentilations(event.target.checked);
                  }

               }/>} label="Ventilations" />
*/}
               <FormControlLabel control={<Checkbox checked={filterWindows} onChange={
                  (event) => {
                     setFilterWindows(event.target.checked);
                  }

               }/>} label="Windows" />
               <FormControlLabel control={<Checkbox checked={filterGutters} onChange={
                  (event) => {
                     setFilterGutters(event.target.checked);
                  }

               }/>} label="Gutters" />
               <FormControlLabel control={<Checkbox checked={filterTestSquares} onChange={
                  (event) => {
                     setFilterTestSquares(event.target.checked);
                  }

               }/>} label="Initial Inspection Damages" />
{/*
               <FormControlLabel control={<Checkbox checked={filterRoofPhotos} onChange={
                  (event) => {
                     setFilterRoofPhotos(event.target.checked);
                  }

               }/>} label="RoofPhotos" />
*/}
               <FormControlLabel control={<Checkbox checked={filterInterior} onChange={
                  (event) => {
                     setFilterInterior(event.target.checked);
                  }

               }/>} label="Interior" />

               <FormControlLabel control={<Checkbox checked={filterNoPhotos} onChange={
                  (event) => {
                     setFilterNoPhotos(event.target.checked);
                  }

               }/>} label="No-photos" />

            </FormGroup>
         </Stack>


         {/*<Typography>Iterior</Typography>*/}
         <Stack direction="column" justifyContent="center" alignItems="center" spacing={1}>

            {/*<Button variant="outlined" onClick={generateReport}>Generate Report</Button>*/}

            <LoadingButton
               size="small"
               disabled={generateReportDisabled}
               onClick={generateReport}
               loading={loading}
               loadingIndicator="Generating…"
               variant="outlined"
            >
               <span>Generate Report</span>
            </LoadingButton>

            <br/>


            <Box sx={{display: pdfLink == false ? 'none' : 'flex'}}>
               <Link href={pdfReportLink} target="_blank">Download created Report</Link>
            </Box>

            <br/>

            <Box sx={{display: pdfLink == false ? 'none' : 'flex'}}>
               <LoadingButton
                  size="small"
                  onClick={sendReportToClient}
                  loading={loadingSendingReport}
                  loadingIndicator="Sending…"
                  variant="outlined"
               >
                  <span>Send to Client ({clientEmail})</span>
               </LoadingButton>
            </Box>

         </Stack>


      </Stack>

   );
}