import {
   Accordion,
   AccordionDetails,
   AccordionSummary, Autocomplete,
   Button, Checkbox, Drawer,
   FormControl,
   FormControlLabel,
   FormGroup, FormLabel, ImageList, ImageListItem, InputLabel, MenuItem, Radio, RadioGroup, Select,
   Stack, TextField
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import {PhotoCamera} from "@mui/icons-material";
import Box from "@mui/material/Box";
import AssistantDirectionIcon from "@mui/icons-material/AssistantDirection";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import * as React from "react";
import {useContext, useEffect} from "react";
import ECContext from "../../../context/inspections/ECContext";
import AddIcon from "@mui/icons-material/Add";
import PropTypes from "prop-types";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
   GridRowModes,
   DataGrid,
   GridToolbarContainer,
   GridActionsCellItem,
} from '@mui/x-data-grid';

import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CloseIcon from '@mui/icons-material/Close';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import {getImageUrl, savePhotoIntoFolder} from "../../../context/inspections/ECActions";
import GalleryIcon from "@mui/icons-material/CollectionsOutlined";
import {Image} from "mui-image";




export default function RoofSkyLights() {

   const reduce = require('image-blob-reduce')();

   const { showCameraFor, access_token_data, flagNeedToUpdatePhotosForSkyLightsBlock, inspectionAllData, flagReloadDatafromInspectionAllData, saveRequest, flagCleanAllControlsData, inspectionRoofSUBBlockInfo, dispatch } = useContext(ECContext)
   const [show, setShow] = React.useState(false);

   const [isNew, setIsNew] = React.useState(false);
   const [selectedId, setSelectedId] = React.useState('');
   const [length, setLength] = React.useState('');
   const [width, setWidth] = React.useState('');
   const [flashing, setFlashing] = React.useState('');
   const [type, setType] = React.useState('');
   const [mount, setMount] = React.useState('');
   const [blinds, setBlinds] = React.useState('');
   const [quantity, setQuantity] = React.useState('');
   const [comment, setComment] = React.useState('');

   const [skyLightsPhotos, setSkyLightsPhotos] = React.useState([]);


   const [rows, setRows] = React.useState([]);





   useEffect(() => {

      if(inspectionRoofSUBBlockInfo.elevationType === "skylights")
         setShow(inspectionRoofSUBBlockInfo.showBlock)
      else
         setShow(false)


   }, [inspectionRoofSUBBlockInfo]);

   useEffect(() => {

      setRows([]);
      setSkyLightsPhotos([])

   }, [flagCleanAllControlsData]);

   useEffect(() => {

      if(flagNeedToUpdatePhotosForSkyLightsBlock == true) {
         dispatch({ type: 'DROP_UPDATE_PHOTO_FOR_SKYLIGHTS' })

         console.log("We're in SkyLights. Updating the photos after we shoot them. inspectionAllData:")
         console.log(inspectionAllData)

         if(inspectionAllData.hasOwnProperty('skyLightsPhotos') && showCameraFor.property == 'skyLightsPhotos') {
            setSkyLightsPhotos([...inspectionAllData.skyLightsPhotos])
         }

      }

   }, [flagNeedToUpdatePhotosForSkyLightsBlock]);

   useEffect(() => {

      const transformSavingData = (prop) => {

         return prop.map((fileData, index) => {
            if(fileData.IS_NEW == true) {
               prop[index].IS_NEW = false
               return fileData.FILE_ID
            }
         }).filter(x => x !== undefined)
      }

      if(saveRequest === true) {

         let skyLightsBlockData = {

            PROPERTY_979: JSON.stringify(rows),               //roof.SkyLights (table data. Json)
            PROPERTY_1299: transformSavingData(skyLightsPhotos),

            NO_PHOTOS_DATA: {
               "SkyLights (table data. Json)": JSON.stringify(rows),               //roof.SkyLights (table data. Json)
            }

         }

         dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA_WITH_SKYLIGHTS_BLOCK', payload: skyLightsBlockData } )

         // triggering Save in InspectionEdit component
         dispatch({ type: 'SET_SKYLIGHTS_READY_TO_SAVE_FLAG' })

      }
   }, [saveRequest]);

   useEffect(() => {

      if(!inspectionAllData.hasOwnProperty('PROPERTY_799' )) return;

      console.log('RELOADING data... in roofSkyLights.jsx')

      setRows(JSON.parse(inspectionAllData.PROPERTY_979))


      const skyLightsPhotos = async () => {
         if(inspectionAllData.hasOwnProperty('skyLightsPhotos' )) {
            if(inspectionAllData.skyLightsPhotos.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.skyLightsPhotos.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.skyLightsPhotos[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'FILE_ID': inspectionAllData.skyLightsPhotos[i].FILE_ID })
               }
               setSkyLightsPhotos(images)
               dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                  payload: {...inspectionAllData,
                     'skyLightsPhotos': images
                  }})
            }
         }
      }
      skyLightsPhotos()


   }, [flagReloadDatafromInspectionAllData]);



   const [drawerVar, setDrawerVar] = React.useState([])
   const [drawerVarName, setDrawerVarName] = React.useState('')
   const [drawerSetter, setDrawerSetter] = React.useState('')
   const [state, setState] = React.useState({top: false, left: false, bottom: false, right: false,});

   const toggleDrawer = (anchor, open, photosValues) => (event) => {
      if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
         return;
      }



      setDrawerVar(photosValues)
      setDrawerVarName(anchor)

      setState({ ...state, [anchor]: open });
   };

   const list = (drawerVar) => (
      <Box
         sx={{ width: '400px' }}
         role="presentation"
         onClick={toggleDrawer(drawerVarName, false, drawerVar)}
         onKeyDown={toggleDrawer(drawerVarName, false,drawerVar)}
      >

         <Stack direction="row" justifyContent="center" alignItems="center" padding={2}>
            <Button variant="text" onClick={toggleDrawer(drawerVarName, false, drawerVar)}>Back</Button>
         </Stack>


         <ImageList sx={{ width: '400px' }} cols={1} >
            {drawerVar.map((item) => (
               <ImageListItem key={item.DETAIL_URL}>
                  <img
                     src={`${item.DETAIL_URL}`}
                     srcSet={`${item.DETAIL_URL}`}
                     loading="lazy"
                  />

                  <Button
                     disableElevation={true}
                     value={item.FILE_ID}
                     onClick={ (event) => {
                        event.stopPropagation()

                        const updatedPhotos = drawerVar.filter( photo1 => photo1.FILE_ID != event.target.value )


                        if(drawerVarName == "skyLightsPhotos") {
                           setSkyLightsPhotos(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }

                        let tmp = inspectionAllData
                        const updatedPhotos2 = tmp[drawerVarName].filter( photo1 => photo1.FILE_ID != event.target.value )
                        tmp[drawerVarName] = updatedPhotos2

                        dispatch({ type: 'SET_INSPECTION_ALL_DATA', payload: tmp } )
                     }}>Delete
                  </Button>

                  <br/>
               </ImageListItem>
            ))}
         </ImageList>
      </Box>
   );



   const toBase64 = file => new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
   });





   const initialRows = [
/*
      {
         id: 1,
         size: '32x100',
         type: "Fixed",
         mount: 'Curb',
         blinds: 'None',
         quantity: 5
      }
*/
   ];

   function EditToolbar(props) {
      const { setRows, setRowModesModel } = props;

      const handleOpenNewRowClick = () => {


         setIsNew(true);
         setSelectedId('');
         setLength('');
         setWidth('');
         setFlashing('');
         setType('');
         setMount('');
         setBlinds('');
         setQuantity('');
         setComment('');

         setOpen(true);


/*
         const id = 999
         setRows((oldRows) => [...oldRows, { id, name: '', age: '', isNew: true }]);
         setRowModesModel((oldModel) => ({
            ...oldModel,
            [id]: { mode: GridRowModes.Edit, fieldToFocus: 'name' },
         }));
*/
      };

      return (
         <GridToolbarContainer>
            <Button color="primary" startIcon={<AddIcon />} onClick={handleOpenNewRowClick}>
               Add
            </Button>
         </GridToolbarContainer>
      );
   }

   EditToolbar.propTypes = {
      setRowModesModel: PropTypes.func.isRequired,
      setRows: PropTypes.func.isRequired,
   };


   const [rowModesModel, setRowModesModel] = React.useState({});

   const handleRowEditStart = (params, event) => {
      event.defaultMuiPrevented = true;
   };

   const handleRowEditStop = (params, event) => {
      event.defaultMuiPrevented = true;
   };

   const handleEditClick = (id) => () => {

console.log(`handleEditClick. id: ${id}`)

      setSelectedId(id)
      setIsNew(false);

      const currentRowData = rows.filter((row) => row.id == id)[0]

console.log(`currentRowData:`)
console.log(currentRowData)


      setSelectedId(id);
      setIsNew(false)
      setLength(currentRowData.length);
      setWidth(currentRowData.width);
      setFlashing(currentRowData.flashing);
      setType(currentRowData.type);
      setMount(currentRowData.mount);
      setBlinds(currentRowData.blinds);
      setQuantity(currentRowData.quantity);
      setComment(currentRowData.comment);


      setOpen(true);


      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
   };

   const handleSaveClick = (id) => () => {
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
   };

   const handleDeleteClick = (id) => () => {
      setRows(rows.filter((row) => row.id !== id));
   };

   const handleCancelClick = (id) => () => {
      setRowModesModel({
         ...rowModesModel,
         [id]: { mode: GridRowModes.View, ignoreModifications: true },
      });

      const editedRow = rows.find((row) => row.id === id);
      if (editedRow.isNew) {
         setRows(rows.filter((row) => row.id !== id));
      }
   };

   const processRowUpdate = (newRow) => {
      const updatedRow = { ...newRow, isNew: false };
      setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
      return updatedRow;
   };

   const columns = [
      { field: 'length', headerName: 'length', width: 70, editable: false },
      { field: 'width', headerName: 'width', width: 70, editable: false },
      { field: 'flashing', headerName: 'flashing', width: 70, editable: false },
      { field: 'type', headerName: 'type', width: 70, editable: false },
      { field: 'mount', headerName: 'mount', width: 70, editable: false },
      { field: 'blinds', headerName: 'blinds', width: 70, editable: false },
      { field: 'quantity', headerName: 'quantity', width: 70, editable: false },
      { field: 'comment', headerName: 'comment', width: 70, editable: false },

/*
      { field: 'size', headerName: 'Size', width: 70, editable: true },
      { field: 'type', headerName: 'Type', width: 70, editable: true },
      { field: 'mount', headerName: 'Mnt.', width: 70,editable: true },
      { field: 'blinds', headerName: 'Blinds', width: 70,editable: true },
      { field: 'quantity', headerName: 'Qty', width: 70, editable: true },
*/
      {
         field: 'actions',
         type: 'actions',
         headerName: '',
         width: 100,
         cellClassName: 'actions',
         getActions: ({ id }) => {

/*            const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

            if (isInEditMode) {
               return [
                  <GridActionsCellItem
                     icon={<SaveIcon />}
                     label="Сохранить"
                     onClick={handleSaveClick(id)}
                  />,
                  <GridActionsCellItem
                     icon={<CancelIcon />}
                     label="Отмена"
                     className="textPrimary"
                     onClick={handleCancelClick(id)}
                     color="inherit"
                  />,
               ];
            }*/

            return [
               <GridActionsCellItem
                  icon={<EditIcon />}
                  label="Edit"
                  className="textPrimary"
                  onClick={handleEditClick(id)}
                  color="inherit"
               />,
               <GridActionsCellItem
                  icon={<DeleteIcon />}
                  label="Delete"
                  onClick={handleDeleteClick(id)}
                  color="inherit"
               />,
            ];
         },
      },
   ];

   const [open, setOpen] = React.useState(false);
   const handleClickOpen = () => {
      setOpen(true);
   };
   const handleClose = () => {
      setOpen(false);
   };

   const handleSaveNewRow = () => {

      setOpen(false);


      const newRow = {
         id: Number(rows.length) + 1,
         length: length,
         width: width,
         flashing: flashing,
         type: type,
         mount: mount,
         blinds: blinds,
         quantity: quantity,
         comment: comment
      }

      if(isNew)
         setRows([...rows, newRow])
      else {
         const rowsWithoutCur = rows.filter((row) => row.id !== selectedId)
         setRows([...rowsWithoutCur, newRow])

      }

   };







   return (

      <>

         <React.Fragment key={'right'} >
            <Drawer
               anchor={'right'}
               open={state[drawerVarName]}
               //open={state[drawerVarName]}
               onClose={toggleDrawer(drawerVarName, false, drawerVar)}
               sx={{zIndex: 9999}}
            >
               {list(drawerVar)}
            </Drawer>
         </React.Fragment>


         <Box sx={{mx: 'auto', mt: 2, width: '100%', display: show === true ? 'flex' : 'none', flexDirection: 'column'}}>


            <Box
               sx={{
                  height: 250,
                  width: '100%',
                  '& .actions': {
                     color: 'text.secondary',
                  },
                  '& .textPrimary': {
                     color: 'text.primary',
                  },
               }}
            >
               <DataGrid
                  autoHeight
                  rows={rows}
                  columns={columns}
                  editMode="row"
                  rowModesModel={rowModesModel}
                  onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
                  onRowEditStart={handleRowEditStart}
                  onRowEditStop={handleRowEditStop}
                  processRowUpdate={processRowUpdate}
                  components={{
                     Toolbar: EditToolbar,
                  }}
                  componentsProps={{
                     toolbar: { setRows, setRowModesModel },
                  }}
                  experimentalFeatures={{ newEditingApi: true }}

                  hideFooterPagination
                  hideFooterSelectedRowCount
                  hideFooter={true}
               />
            </Box>



            <Stack direction="row" justifyContent="left" alignItems="left" padding={0}>
               <Typography>Photos</Typography>

               <Stack direction="row" justifyContent="right" alignItems="right" padding={2} sx={{ml: 'auto', width: '200px'}}>
                  <IconButton color="primary" aria-label="upload picture" component="label"
                              onClick={ () => {
                                 dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'skyLightsPhotos', page: 'skyLights'} })
                              }}>
                     <PhotoCamera />
                  </IconButton>
                  <IconButton color="primary" aria-label="upload picture" component="label">
                     <input
                        type="file" accept="image/*" multiple hidden name="myImage"
                        onChange={(event) => {

                           console.log('event.target.files:')
                           console.log(event.target.files)

                           const files = event.target.files
                           let photos = []

                           //files.forEach((file) => {
                           for (let i = 0; i < files.length; i++) {
                              reduce.toBlob(files[i], { max: 900 }).then(blob => {
                                 const wrapSaveImage = async (dataUri) => {
                                    //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                                    const wrapper = async () => {
                                       const base64 = await toBase64(dataUri);
                                       const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', ''), generateUniqueName: true}
                                       const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                       dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, skyLightsPhotos: [...skyLightsPhotos, ...photos, savedPhotoData]} })
                                       //dispatch({ type: 'SET_UPDATE_PHOTO_FOR_JOBSITE' })

                                       const updatedPhotos = [...photos, savedPhotoData]
                                       setSkyLightsPhotos([...skyLightsPhotos, ...updatedPhotos])
                                       photos.push(savedPhotoData)
                                    }
                                    wrapper()
                                 }
                                 wrapSaveImage(blob)
                              });
                           }


                        }}
                     />
                     <GalleryIcon />
                  </IconButton>
               </Stack>

            </Stack>
            <Stack direction="row" alignItems="center" spacing={1}>
               {skyLightsPhotos.map((image, index) => (
                  <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('skyLightsPhotos', true, skyLightsPhotos)}/>
               ))}
            </Stack>


         </Box>




{/*
         <Box sx={{mx: 'auto', mt: 2, width: '100%', display: show === true ? 'flex' : 'none', flexDirection: 'column'}}>
            Roof SkyLights

         </Box>
*/}




         <div>
            <Dialog
               fullScreen
               open={open}
               onClose={handleClose}
            >
               <AppBar sx={{ position: 'relative' }}>
                  <Toolbar>
                     <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                     >
                        <CloseIcon />
                     </IconButton>
                     <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Add/Edit SkyLight
                     </Typography>
                     <Button autoFocus color="inherit" onClick={handleSaveNewRow}>
                        save
                     </Button>
                  </Toolbar>
               </AppBar>


               <Stack
                  direction="column"
                  justifyContent="left"
                  alignItems="left"
                  padding={2}
                  spacing={1}
               >

                  <Stack direction="row" alignItems="center" spacing={2}>
                     <TextField sx={{width: "250px"}} id="other" label="Length (in.)" variant="outlined"
                                value={length}
                                onChange={(event) => {
                                   setLength(event.target.value)
                                }}
                     />
                     <TextField sx={{width: "250px"}} id="other" label="Width (in.)" variant="outlined"
                                value={width}
                                onChange={(event) => {
                                   setWidth(event.target.value)
                                }}
                     />
                  </Stack>

                  <Stack direction="row" alignItems="center" spacing={2}>
                     <FormControl>
                        <FormLabel id="flashing-radio-buttons-group-label">Flashing</FormLabel>
                        <RadioGroup
                           row
                           aria-labelledby="flashing-radio-buttons-group-label"
                           name="flashing-buttons-group"
                           value={flashing}
                           onChange={(event) => {
                              setFlashing(event.target.value)
                           }}

                        >
                           <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                           <FormControlLabel value="Flashing Only" control={<Radio />} label="Flashing Only" />
                           <FormControlLabel value="No" control={<Radio />} label="No" />
                        </RadioGroup>
                     </FormControl>
                  </Stack>

                  <Stack direction="row" alignItems="center" spacing={2}>
                     <FormControl>
                        <FormLabel id="type-buttons-group-label">Type</FormLabel>
                        <RadioGroup
                           row
                           aria-labelledby="type-radio-buttons-group-label"
                           name="flashing-buttons-group"
                           value={type}
                           onChange={(event) => {
                              setType(event.target.value)
                           }}
                        >
                           <FormControlLabel value="Electric" control={<Radio />} label="Electric" />
                           <FormControlLabel value="Fixed" control={<Radio />} label="Fixed" />
                           <FormControlLabel value="Manual" control={<Radio />} label="Manual" />
                           <FormControlLabel value="Solar" control={<Radio />} label="Solar" />
                        </RadioGroup>
                     </FormControl>
                  </Stack>

                  <Stack direction="row" alignItems="center" spacing={2}>
                     <FormControl>
                        <FormLabel id="mount-buttons-group-label">Mount</FormLabel>
                        <RadioGroup
                           row
                           aria-labelledby="mount-radio-buttons-group-label"
                           name="mount-buttons-group"
                           value={mount}
                           onChange={(event) => {
                              setMount(event.target.value)
                           }}
                        >
                           <FormControlLabel value="Curb" control={<Radio />} label="Curb" />
                           <FormControlLabel value="Deck" control={<Radio />} label="Deck" />
                        </RadioGroup>
                     </FormControl>
                  </Stack>

                  <Stack direction="row" alignItems="center" spacing={2}>
                     <FormControl>
                        <FormLabel id="mount-buttons-group-label">Blinds</FormLabel>
                        <RadioGroup
                           row
                           aria-labelledby="mount-radio-buttons-group-label"
                           name="mount-buttons-group"
                           value={blinds}
                           onChange={(event) => {
                              setBlinds(event.target.value)
                           }}
                        >
                           <FormControlLabel value="None" control={<Radio />} label="None" />
                           <FormControlLabel value="Electric" control={<Radio />} label="Electric" />
                           <FormControlLabel value="Manual" control={<Radio />} label="Manual" />
                           <FormControlLabel value="Solar" control={<Radio />} label="Solar" />
                        </RadioGroup>
                     </FormControl>
                  </Stack>

                  <Stack direction="row" alignItems="center" spacing={2}>
                     <Button variant="text" onClick={() => setQuantity(Number(quantity)-1) }><RemoveCircleOutlineIcon/></Button>
                     <TextField sx={{width: "250px"}} id="other" label="Quantity" variant="outlined"
                                value={quantity}
                                onChange={(event) => {
                                   setQuantity(event.target.value)
                                }}
                     />
                     <Button variant="text" onClick={() => setQuantity(Number(quantity)+1) }><AddCircleOutlineIcon/></Button>
                  </Stack>

                  <TextField sx={{width: "100%"}} id="other" label="Comment" variant="outlined" multiline rows={4}
                             value={comment}
                             onChange={(event) => {
                                setComment(event.target.value)
                             }}
                  />




               </Stack>

            </Dialog>


         </div>

      </>

   );
}