import React, {useContext, useEffect, useState} from 'react';
import FilerobotImageEditor, {
	TABS,
	TOOLS,
} from 'react-filerobot-image-editor';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import {getCurrentImgDataFunction} from "react-filerobot-image-editor";

import './styles/editor.css';





export default function ImageEditor(props) {

	const [isImgEditorShown, setIsImgEditorShown] = useState(false);

	useEffect(() => {

		// let element = document.getElementsByClassName("FIE_image-tool-button")
		// if(element.length > 0)
		// 	element[0].style.display  = "none";

		//const res = document.querySelector('.FIE_image-tool-button') != null ? document.querySelector('.FIE_image-tool-button').style.display = 'none' : '';
		// document.querySelector('.FIE_ellipse-tool-button').style.display = 'none';
		// document.querySelector('.FIE_polygon-tool-button').style.display = 'none';
		// document.querySelector('.FIE_line-tool-button').style.display = 'none';
		// document.querySelector('.FIE_arrow-tool-button').style.display = 'none';

	}, []);


	const openImgEditor = () => {
		setIsImgEditorShown(true);
	};

	const closeImgEditor = () => {
		setIsImgEditorShown(false);
	};

	const getImageData = (data) => {

		console.log('imageData:')
		console.log(data)


	};



	return (
		<div /*sx={{'z-index': '2000199999' !important}}*/>


			<Dialog
				open={props.imageEditorOpen}
				onClose={props.handleClose}
				aria-labelledby="signModalTitle"
				aria-describedby="signModalDescription"
				fullScreen
			>
				<DialogTitle id="dialog"></DialogTitle>
				<DialogContent sx={{p: 0}}>
					<DialogContentText id="imageEditoModal_ECS"></DialogContentText>


					{/*<button onClick={openImgEditor}>Open image editor</button>*/}
					{props.imageEditorOpen && (
						<FilerobotImageEditor

							source={props.source}
							//source="https://scaleflex.airstore.io/demo/stephen-walker-unsplash.jpg"
							onSave={(editedImageObject, designState) =>

									//console.log('saved', editedImageObject, designState)
									props.handleSave(editedImageObject)

							}
							onBeforeSave={(savedImageData) => {

								return false;
								//console.log('Yeah, baby')

								// const editedImage = getCurrentImgDataFunction()
								// const imageData = editedImage?.current?.({}).imageData;
								// console.log(imageData?.imageBase64);


								//console.log(FilerobotImageEditor.getCurrentImgData())

								//getImageData()



							}}


							//getCurrentImgDataFnRef={getImageData}
							//getCurrentImgDataFnRef = {}

							defaultSavedImageName={'updatedImage'}
							defaultSavedImageType={'jpeg'}
							closeAfterSave={true}
							onClose={props.handleClose}

							annotationsCommon={{
								//fill: '#00000000',
								//fill: '#00000000',
								fill: '#ff0000',
								// stroke: '#ff0000',
								// strokeWidth: 4,
//								opacity: 0.2,
							}}
							Text={{
								text: ' '

							}}
							Rotate={{ angle: 90, componentType: 'slider' }}
							Pen={{
								strokeWidth: 3,
								tension: 1,
								lineCap: 'round'
							}}
/*							Crop={{
								presetsItems: [
									{
										titleKey: 'classicTv',
										descriptionKey: '4:3',
										ratio: 4 / 3,
										// icon: CropClassicTv, // optional, CropClassicTv is a React Function component. Possible (React Function component, string or HTML Element)
									},
									{
										titleKey: 'cinemascope',
										descriptionKey: '21:9',
										ratio: 21 / 9,
										// icon: CropCinemaScope, // optional, CropCinemaScope is a React Function component.  Possible (React Function component, string or HTML Element)
									},
								],

							}}*/
							tabsIds={[TABS.ANNOTATE]} // or {['Adjust', 'Annotate', 'Watermark']}
							defaultTabId={TABS.ANNOTATE} // or 'Annotate'
							defaultToolId={TOOLS.TEXT} // or 'Text'
						/>
					)}



				</DialogContent>
				<DialogActions>
{/*
					<Button raised onClick={props.handleClose}>
						Continue
					</Button>
*/}
				</DialogActions>
			</Dialog>


		</div>


	);


}