import {
   Accordion,
   AccordionDetails,
   AccordionSummary, Autocomplete,
   Button, Checkbox, Drawer,
   FormControl,
   FormControlLabel,
   FormGroup, FormLabel, ImageList, ImageListItem, InputLabel, MenuItem, Radio, RadioGroup, Select,
   Stack, TextField
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import {PhotoCamera} from "@mui/icons-material";
import Box from "@mui/material/Box";
import AssistantDirectionIcon from "@mui/icons-material/AssistantDirection";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import * as React from "react";
import {useContext, useEffect} from "react";
import ECContext from "../../context/inspections/ECContext";
import reduce from "image-blob-reduce";
import {getImageUrl, savePhotoIntoFolder} from "../../context/inspections/ECActions";
import GalleryIcon from "@mui/icons-material/CollectionsOutlined";
import {Image} from "mui-image";

export default function Interior() {

   const { showCameraFor, flagReloadDatafromInspectionAllData, inspectionAllData, saveRequest, access_token_data, flagNeedToUpdatePhotosForInteriorBlock, flagCleanAllControlsData, inspectionInteriorBlockInfo, dispatch } = useContext(ECContext)
   const [show, setShow] = React.useState(false);
   const reduce = require('image-blob-reduce')();

   const [livingRoom, setLivingRoom] = React.useState([]);
   const [familyRoom, setFamilyRoom] = React.useState([]);
   const [bonusRoom, setBonusRoom] = React.useState([]);
   const [diningRoom, setDiningRoom] = React.useState([]);
   const [kitchen, setKitchen] = React.useState([]);
   const [den, setDen] = React.useState([]);
   const [hallway1, setHallway1] = React.useState([]);
   const [hallway2, setHallway2] = React.useState([]);
   const [hallway3, setHallway3] = React.useState([]);
   const [office, setOffice] = React.useState([]);
   const [bathroom1, setBathroom1] = React.useState([]);
   const [bathroom2, setBathroom2] = React.useState([]);
   const [bathroom3, setBathroom3] = React.useState([]);
   const [bathroom4, setBathroom4] = React.useState([]);
   const [bedroom1, setBedroom1] = React.useState([]);
   const [bedroom2, setBedroom2] = React.useState([]);
   const [bedroom3, setBedroom3] = React.useState([]);
   const [bedroom4, setBedroom4] = React.useState([]);
   const [bedroom5, setBedroom5] = React.useState([]);
   const [bedroom6, setBedroom6] = React.useState([]);
   const [garage, setGarage] = React.useState([]);
   const [attic, setAttic] = React.useState([]);
   const [other, setOther] = React.useState([]);

   useEffect(() => {

      setShow(inspectionInteriorBlockInfo.showBlock)
   }, [inspectionInteriorBlockInfo]);

   useEffect(() => {
      setLivingRoom([]);
      setFamilyRoom([]);
      setBonusRoom([]);
      setDiningRoom([]);
      setKitchen([]);
      setDen([]);
      setHallway1([]);
      setHallway2([]);
      setHallway3([]);
      setOffice([]);
      setBathroom1([]);
      setBathroom2([]);
      setBathroom3([]);
      setBathroom4([]);
      setBedroom1([]);
      setBedroom2([]);
      setBedroom3([]);
      setBedroom4([]);
      setBedroom5([]);
      setBedroom6([]);
      setGarage([]);
      setAttic([]);
      setOther([]);
   }, [flagCleanAllControlsData]);

   useEffect(() => {

      if(flagNeedToUpdatePhotosForInteriorBlock == true) {
         dispatch({ type: 'DROP_UPDATE_PHOTO_FOR_INTERIOR' })

         if(inspectionAllData.hasOwnProperty('livingRoom') && showCameraFor.property == 'livingRoom') {
            setLivingRoom([...inspectionAllData.livingRoom])
         }
         if(inspectionAllData.hasOwnProperty('familyRoom') && showCameraFor.property == 'familyRoom') {
            setFamilyRoom([...inspectionAllData.familyRoom])
         }

         if(inspectionAllData.hasOwnProperty('bonusRoom') && showCameraFor.property == 'bonusRoom') {
            setBonusRoom([...inspectionAllData.bonusRoom])
         }
         if(inspectionAllData.hasOwnProperty('diningRoom') && showCameraFor.property == 'diningRoom') {
            setDiningRoom([...inspectionAllData.diningRoom])
         }
         if(inspectionAllData.hasOwnProperty('kitchen') && showCameraFor.property == 'kitchen') {
            setKitchen([...inspectionAllData.kitchen])
         }
         if(inspectionAllData.hasOwnProperty('den') && showCameraFor.property == 'den') {
            setDen([...inspectionAllData.den])
         }
         if(inspectionAllData.hasOwnProperty('hallway1') && showCameraFor.property == 'hallway1') {
            setHallway1([...inspectionAllData.hallway1])
         }
         if(inspectionAllData.hasOwnProperty('hallway2') && showCameraFor.property == 'hallway2') {
            setHallway2([...inspectionAllData.hallway2])
         }
         if(inspectionAllData.hasOwnProperty('hallway3') && showCameraFor.property == 'hallway3') {
            setHallway3([...inspectionAllData.hallway3])
         }
         if(inspectionAllData.hasOwnProperty('office') && showCameraFor.property == 'office') {
            setOffice([...inspectionAllData.office])
         }
         if(inspectionAllData.hasOwnProperty('bathroom1') && showCameraFor.property == 'bathroom1') {
            setBathroom1([...inspectionAllData.bathroom1])
         }
         if(inspectionAllData.hasOwnProperty('bathroom2') && showCameraFor.property == 'bathroom2') {
            setBathroom2([...inspectionAllData.bathroom2])
         }
         if(inspectionAllData.hasOwnProperty('bathroom3') && showCameraFor.property == 'bathroom3') {
            setBathroom3([...inspectionAllData.bathroom3])
         }
         if(inspectionAllData.hasOwnProperty('bathroom4') && showCameraFor.property == 'bathroom4') {
            setBathroom4([...inspectionAllData.bathroom4])
         }
         if(inspectionAllData.hasOwnProperty('bedroom1') && showCameraFor.property == 'bedroom1') {
            setBedroom1([...inspectionAllData.bedroom1])
         }
         if(inspectionAllData.hasOwnProperty('bedroom6') && showCameraFor.property == 'bedroom6') {
            setBedroom6([...inspectionAllData.bedroom6])
         }
         if(inspectionAllData.hasOwnProperty('bedroom2') && showCameraFor.property == 'bedroom2') {
            setBedroom2([...inspectionAllData.bedroom2])
         }
         if(inspectionAllData.hasOwnProperty('bedroom3') && showCameraFor.property == 'bedroom3') {
            setBedroom3([...inspectionAllData.bedroom3])
         }
         if(inspectionAllData.hasOwnProperty('bedroom4') && showCameraFor.property == 'bedroom4') {
            setBedroom4([...inspectionAllData.bedroom4])
         }
         if(inspectionAllData.hasOwnProperty('bedroom5') && showCameraFor.property == 'bedroom5') {
            setBedroom5([...inspectionAllData.bedroom5])
         }
         if(inspectionAllData.hasOwnProperty('garage') && showCameraFor.property == 'garage') {
            setGarage([...inspectionAllData.garage])
         }
         if(inspectionAllData.hasOwnProperty('other') && showCameraFor.property == 'other') {
            setOther([...inspectionAllData.other])
         }
         if(inspectionAllData.hasOwnProperty('attic') && showCameraFor.property == 'attic') {
            setAttic([...inspectionAllData.attic])
         }

      }

   }, [flagNeedToUpdatePhotosForInteriorBlock]);

   useEffect(() => {


      const transformSavingData = (prop) => {

         return prop.map((fileData, index) => {
            if(fileData.IS_NEW == true) {
               prop[index].IS_NEW = false
               return fileData.FILE_ID
            }
         }).filter(x => x !== undefined)
      }


      if(/*inspectionGeneralBlockInfo.showBlock*/ /*&& saveRequest === true*/ saveRequest === true) {
         //dispatch({ type: 'DROP_SAVE_REQUEST' })

         let interiorBlockData = {


            PROPERTY_1099: transformSavingData(other),                                           //Interior.Other
               // inspectionAllData.hasOwnProperty('other') ?
               //    inspectionAllData.other.map((fileData, index) => {
               //          return fileData.FILE_ID
               //       }
               //    ) : '',
            PROPERTY_1097: transformSavingData(attic),                                              //Interior.Attic
               // inspectionAllData.hasOwnProperty('attic') ?
               //    inspectionAllData.attic.map((fileData, index) => {
               //          return fileData.FILE_ID
               //       }
               //    ) : '',
            PROPERTY_1095: transformSavingData(garage), //Interior.Garage
               // inspectionAllData.hasOwnProperty('garage') ?
               //    inspectionAllData.garage.map((fileData, index) => {
               //          return fileData.FILE_ID
               //       }
               //    ) : '',
            PROPERTY_1093: transformSavingData(bedroom6),                                           //Interior.Bedroom 6
               // inspectionAllData.hasOwnProperty('bedroom6') ?
               //    inspectionAllData.bedroom6.map((fileData, index) => {
               //          return fileData.FILE_ID
               //       }
               //    ) : '',
            PROPERTY_1091: transformSavingData(bedroom5),                                           //Interior.Bedroom 5
               // inspectionAllData.hasOwnProperty('bedroom5') ?
               //    inspectionAllData.bedroom5.map((fileData, index) => {
               //          return fileData.FILE_ID
               //       }
               //    ) : '',
            PROPERTY_1089: transformSavingData(bedroom4),                                           //Interior.Bedroom 4
               // inspectionAllData.hasOwnProperty('bedroom4') ?
               //    inspectionAllData.bedroom4.map((fileData, index) => {
               //          return fileData.FILE_ID
               //       }
               //    ) : '',
            PROPERTY_1087: transformSavingData(bedroom3),                                           //Interior.Bedroom 3
               // inspectionAllData.hasOwnProperty('bedroom3') ?
               //    inspectionAllData.bedroom3.map((fileData, index) => {
               //          return fileData.FILE_ID
               //       }
               //    ) : '',
            PROPERTY_1085: transformSavingData(bedroom2),                                           //Interior.Bedroom 2
               // inspectionAllData.hasOwnProperty('bedroom2') ?
               //    inspectionAllData.bedroom2.map((fileData, index) => {
               //          return fileData.FILE_ID
               //       }
               //    ) : '',
            PROPERTY_1083: transformSavingData(bedroom1),                                           //Interior.Bedroom 1
               // inspectionAllData.hasOwnProperty('bedroom1') ?
               //    inspectionAllData.bedroom1.map((fileData, index) => {
               //          return fileData.FILE_ID
               //       }
               //    ) : '',
            PROPERTY_1081: transformSavingData(bathroom4),                                           //Interior.Bathroom 4
               // inspectionAllData.hasOwnProperty('bathroom4') ?
               //    inspectionAllData.bathroom4((fileData, index) => {
               //          return fileData.FILE_ID
               //       }
               //    ) : '',
            PROPERTY_1079: transformSavingData(bathroom3),                                           //Interior.Bathroom 3
               // inspectionAllData.hasOwnProperty('bathroom3') ?
               //    inspectionAllData.bathroom3.map((fileData, index) => {
               //          return fileData.FILE_ID
               //       }
               //    ) : '',
            PROPERTY_1077: transformSavingData(bathroom2),                                           //Interior.Bathroom 2
               // inspectionAllData.hasOwnProperty('bathroom2') ?
               //    inspectionAllData.bathroom2.map((fileData, index) => {
               //          return fileData.FILE_ID
               //       }
               //    ) : '',
            PROPERTY_1075: transformSavingData(bathroom1),                                           //Interior.Bathroom 1
               // inspectionAllData.hasOwnProperty('bathroom1') ?
               //    inspectionAllData.bathroom1.map((fileData, index) => {
               //          return fileData.FILE_ID
               //       }
               //    ) : '',
            PROPERTY_1073: transformSavingData(office),                                           //Interior.Office
               // inspectionAllData.hasOwnProperty('office') ?
               //    inspectionAllData.office.map((fileData, index) => {
               //          return fileData.FILE_ID
               //       }
               //    ) : '',
            PROPERTY_1071: transformSavingData(hallway3),                                           //Interior.Hall Way 3
               // inspectionAllData.hasOwnProperty('hallway3') ?
               //    inspectionAllData.hallway3.map((fileData, index) => {
               //          return fileData.FILE_ID
               //       }
               //    ) : '',
            PROPERTY_1069: transformSavingData(hallway2),                                           //Interior.Hall Way 2
               // inspectionAllData.hasOwnProperty('hallway2') ?
               //    inspectionAllData.hallway2.map((fileData, index) => {
               //          return fileData.FILE_ID
               //       }
               //    ) : '',
            PROPERTY_1067: transformSavingData(hallway1),                                           //Interior.Hall Way 1
               // inspectionAllData.hasOwnProperty('hallway1') ?
               //    inspectionAllData.hallway1.map((fileData, index) => {
               //          return fileData.FILE_ID
               //       }
               //    ) : '',
            PROPERTY_1065: transformSavingData(den),                                           //Interior.Den
               // inspectionAllData.hasOwnProperty('den') ?
               //    inspectionAllData.den.map((fileData, index) => {
               //          return fileData.FILE_ID
               //       }
               //    ) : '',
            PROPERTY_1063: transformSavingData(kitchen),                                           //Interior.Kitchen
               // inspectionAllData.hasOwnProperty('kitchen') ?
               //    inspectionAllData.kitchen.map((fileData, index) => {
               //          return fileData.FILE_ID
               //       }
               //    ) : '',
            PROPERTY_1061: transformSavingData(diningRoom),                                           //Interior.Dining Room
               // inspectionAllData.hasOwnProperty('diningRoom') ?
               //    inspectionAllData.diningRoom.map((fileData, index) => {
               //          return fileData.FILE_ID
               //       }
               //    ) : '',
            PROPERTY_1059:  transformSavingData(bonusRoom),                                          //Interior.Bonus Room
               // inspectionAllData.hasOwnProperty('bonusRoom') ?
               //    inspectionAllData.bonusRoom.map((fileData, index) => {
               //          return fileData.FILE_ID
               //       }
               //    ) : '',
            PROPERTY_1057: transformSavingData(familyRoom),                                           //Interior.Family Room
               // inspectionAllData.hasOwnProperty('familyRoom') ?
               //    inspectionAllData.familyRoom.map((fileData, index) => {
               //          return fileData.FILE_ID
               //       }
               //    ) : '',
            PROPERTY_1055: transformSavingData(livingRoom),                                           //Interior.Living Room
               // inspectionAllData.hasOwnProperty('livingRoom') ?
               //    inspectionAllData.livingRoom.map((fileData, index) => {
               //          return fileData.FILE_ID
               //       }
               //    ) : '',

            NO_PHOTOS_DATA: {}

         }

         dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA_WITH_INTERIOR_BLOCK', payload: interiorBlockData } )

         // triggering Save in InspectionEdit component
         dispatch({ type: 'SET_INTERIOR_READY_TO_SAVE_FLAG' })

      }
   }, [saveRequest]);

   useEffect(() => {

      if(!inspectionAllData.hasOwnProperty('PROPERTY_799' )) return;

      console.log('RELOADING data... in interior.jsx')

      const other = async () => {
         if(inspectionAllData.hasOwnProperty('other' ))
            if(inspectionAllData.other.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.other.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.other[i].ID)
                  images.push({'ID': imageUrl.ID, 'FILE_ID': imageUrl.FILE_ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               setOther(images)
               dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                  payload: {...inspectionAllData,
                     'other': images
                  }})

            }
      }
      other()

      const attic = async () => {
         if(inspectionAllData.hasOwnProperty('attic' ))
            if(inspectionAllData.attic.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.attic.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.attic[i].ID)
                  images.push({'FILE_ID': imageUrl.FILE_ID, 'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               setAttic(images)
               dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                  payload: {...inspectionAllData,
                     'attic': images
                  }})

            }
      }
      attic()

      const garage = async () => {
         if(inspectionAllData.hasOwnProperty('garage' ))
            if(inspectionAllData.garage.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.garage.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.garage[i].ID)
                  images.push({'FILE_ID': imageUrl.FILE_ID, 'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               setGarage(images)
               dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                  payload: {...inspectionAllData,
                     'garage': images
                  }})

            }
      }
      garage()

      const bedroom6 = async () => {
         if(inspectionAllData.hasOwnProperty('bedroom6' ))
            if(inspectionAllData.bedroom6.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.bedroom6.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.bedroom6[i].ID)
                  images.push({'FILE_ID': imageUrl.FILE_ID, 'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               setBedroom6(images)
               dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                  payload: {...inspectionAllData,
                     'bedroom6': images
                  }})

            }
      }
      bedroom6()

      const bedroom5 = async () => {
         if(inspectionAllData.hasOwnProperty('bedroom5' ))
            if(inspectionAllData.bedroom5.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.bedroom5.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.bedroom5[i].ID)
                  images.push({'FILE_ID': imageUrl.FILE_ID, 'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               setBedroom5(images)
               dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                  payload: {...inspectionAllData,
                     'bedroom5': images
                  }})

            }
      }
      bedroom5()


      const bedroom4 = async () => {
         if(inspectionAllData.hasOwnProperty('bedroom4' ))
            if(inspectionAllData.bedroom4.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.bedroom4.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.bedroom4[i].ID)
                  images.push({'FILE_ID': imageUrl.FILE_ID, 'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               setBedroom4(images)
               dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                  payload: {...inspectionAllData,
                     'bedroom4': images
                  }})

            }
      }
      bedroom4()

      const bedroom3 = async () => {
         if(inspectionAllData.hasOwnProperty('bedroom3' ))
            if(inspectionAllData.bedroom3.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.bedroom3.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.bedroom3[i].ID)
                  images.push({'FILE_ID': imageUrl.FILE_ID, 'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               setBedroom3(images)
               dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                  payload: {...inspectionAllData,
                     'bedroom3': images
                  }})

            }
      }
      bedroom3()

      const bedroom2 = async () => {
         if(inspectionAllData.hasOwnProperty('bedroom2' ))
            if(inspectionAllData.bedroom2.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.bedroom2.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.bedroom2[i].ID)
                  images.push({'FILE_ID': imageUrl.FILE_ID, 'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               setBedroom2(images)
               dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                  payload: {...inspectionAllData,
                     'bedroom2': images
                  }})

            }
      }
      bedroom2()

      const bedroom1 = async () => {
         if(inspectionAllData.hasOwnProperty('bedroom1' ))
            if(inspectionAllData.bedroom1.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.bedroom1.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.bedroom1[i].ID)
                  images.push({'FILE_ID': imageUrl.FILE_ID, 'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               setBedroom1(images)
               dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                  payload: {...inspectionAllData,
                     'bedroom1': images
                  }})

            }
      }
      bedroom1()

      const bathroom4 = async () => {
         if(inspectionAllData.hasOwnProperty('bathroom4' ))
            if(inspectionAllData.bathroom4.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.bathroom4.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.bathroom4[i].ID)
                  images.push({'FILE_ID': imageUrl.FILE_ID, 'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               setBathroom4(images)
               dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                  payload: {...inspectionAllData,
                     'bathroom4': images
                  }})

            }
      }
      bathroom4()

      const bathroom3 = async () => {
         if(inspectionAllData.hasOwnProperty('bathroom3' ))
            if(inspectionAllData.bathroom3.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.bathroom3.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.bathroom3[i].ID)
                  images.push({'FILE_ID': imageUrl.FILE_ID, 'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               setBathroom3(images)
               dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                  payload: {...inspectionAllData,
                     'bathroom3': images
                  }})

            }
      }
      bathroom3()

      const bathroom2 = async () => {
         if(inspectionAllData.hasOwnProperty('bathroom2' ))
            if(inspectionAllData.bathroom2.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.bathroom2.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.bathroom2[i].ID)
                  images.push({'FILE_ID': imageUrl.FILE_ID, 'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               setBathroom2(images)
               dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                  payload: {...inspectionAllData,
                     'bathroom2': images
                  }})

            }
      }
      bathroom2()

      const bathroom1 = async () => {
         if(inspectionAllData.hasOwnProperty('bathroom1' ))
            if(inspectionAllData.bathroom1.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.bathroom1.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.bathroom1[i].ID)
                  images.push({'FILE_ID': imageUrl.FILE_ID, 'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               setBathroom1(images)
               dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                  payload: {...inspectionAllData,
                     'bathroom1': images
                  }})

            }
      }
      bathroom1()

      const office = async () => {
         if(inspectionAllData.hasOwnProperty('office' ))
            if(inspectionAllData.office.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.office.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.office[i].ID)
                  images.push({'FILE_ID': imageUrl.FILE_ID, 'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               setOffice(images)
               dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                  payload: {...inspectionAllData,
                     'office': images
                  }})

            }
      }
      office()

      const hallway3 = async () => {
         if(inspectionAllData.hasOwnProperty('hallway3' ))
            if(inspectionAllData.hallway3.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.hallway3.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.hallway3[i].ID)
                  images.push({'FILE_ID': imageUrl.FILE_ID, 'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               setHallway3(images)
               dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                  payload: {...inspectionAllData,
                     'hallway3': images
                  }})

            }
      }
      hallway3()

      const hallway2 = async () => {
         if(inspectionAllData.hasOwnProperty('hallway2' ))
            if(inspectionAllData.hallway2.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.hallway2.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.hallway2[i].ID)
                  images.push({'FILE_ID': imageUrl.FILE_ID, 'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               setHallway2(images)
               dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                  payload: {...inspectionAllData,
                     'hallway2': images
                  }})

            }
      }
      hallway2()

      const hallway1 = async () => {
         if(inspectionAllData.hasOwnProperty('hallway1' ))
            if(inspectionAllData.hallway1.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.hallway1.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.hallway1[i].ID)
                  images.push({'FILE_ID': imageUrl.FILE_ID, 'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               setHallway1(images)
               dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                  payload: {...inspectionAllData,
                     'hallway1': images
                  }})

            }
      }
      hallway1()

      const den = async () => {
         if(inspectionAllData.hasOwnProperty('den' ))
            if(inspectionAllData.den.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.den.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.den[i].ID)
                  images.push({'FILE_ID': imageUrl.FILE_ID, 'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               setDen(images)
               dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                  payload: {...inspectionAllData,
                     'den': images
                  }})

            }
      }
      den()


      const kitchen = async () => {
         if(inspectionAllData.hasOwnProperty('kitchen' ))
            if(inspectionAllData.kitchen.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.kitchen.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.kitchen[i].ID)
                  images.push({'FILE_ID': imageUrl.FILE_ID, 'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               setKitchen(images)
               dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                  payload: {...inspectionAllData,
                     'kitchen': images
                  }})

            }
      }
      kitchen()

      const diningRoom = async () => {
         if(inspectionAllData.hasOwnProperty('diningRoom' ))
            if(inspectionAllData.diningRoom.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.diningRoom.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.diningRoom[i].ID)
                  images.push({'FILE_ID': imageUrl.FILE_ID, 'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               setDiningRoom(images)
               dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                  payload: {...inspectionAllData,
                     'diningRoom': images
                  }})

            }
      }
      diningRoom()

      const bonusRoom = async () => {
         if(inspectionAllData.hasOwnProperty('bonusRoom' ))
            if(inspectionAllData.bonusRoom.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.bonusRoom.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.bonusRoom[i].ID)
                  images.push({'FILE_ID': imageUrl.FILE_ID, 'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               setBonusRoom(images)
               dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                  payload: {...inspectionAllData,
                     'bonusRoom': images
                  }})

            }
      }
      bonusRoom()

      const familyRoom = async () => {
         if(inspectionAllData.hasOwnProperty('familyRoom' ))
            if(inspectionAllData.familyRoom.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.familyRoom.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.familyRoom[i].ID)
                  images.push({'FILE_ID': imageUrl.FILE_ID, 'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               setFamilyRoom(images)
               dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                  payload: {...inspectionAllData,
                     'familyRoom': images
                  }})

            }
      }
      familyRoom()

      const livingRoom = async () => {
         if(inspectionAllData.hasOwnProperty('livingRoom' ))
            if(inspectionAllData.livingRoom.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.livingRoom.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.livingRoom[i].ID)
                  images.push({'FILE_ID': imageUrl.FILE_ID, 'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               setLivingRoom(images)
               dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                  payload: {...inspectionAllData,
                     'livingRoom': images
                  }})

            }
      }
      livingRoom()



   }, [flagReloadDatafromInspectionAllData]);




   const [drawerVar, setDrawerVar] = React.useState([])
   const [drawerVarName, setDrawerVarName] = React.useState('')
   const [drawerSetter, setDrawerSetter] = React.useState('')
   const [state, setState] = React.useState({top: false, left: false, bottom: false, right: false,});

   const toggleDrawer = (anchor, open, photosValues) => (event) => {
      if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
         return;
      }

      setDrawerVar(photosValues)
      setDrawerVarName(anchor)

      setState({ ...state, [anchor]: open });
   };

   const list = (drawerVar) => (
      <Box
         sx={{ width: '400px' }}
         role="presentation"
         onClick={toggleDrawer(drawerVarName, false, drawerVar)}
         onKeyDown={toggleDrawer(drawerVarName, false,drawerVar)}
      >

         <Stack direction="row" justifyContent="center" alignItems="center" padding={2}>
            <Button variant="text" onClick={toggleDrawer(drawerVarName, false, drawerVar)}>Back</Button>
         </Stack>

         <ImageList sx={{ width: '400px' }} cols={1} >
            {drawerVar.map((item) => (
               <ImageListItem key={item.DETAIL_URL}>
                  <img
                     src={`${item.DETAIL_URL}`}
                     srcSet={`${item.DETAIL_URL}`}
                     loading="lazy"
                  />

                  <Button
                     disableElevation={true}
                     value={item.FILE_ID}
                     onClick={ (event) => {
                        event.stopPropagation()

                        const updatedPhotos = drawerVar.filter( photo1 => photo1.FILE_ID != event.target.value )


                        console.log(`drawerVarName = ${drawerVarName}`)
                        console.log(`event.target.value = ${event.target.value}`)
                        console.log("drawerVar:")
                        console.log(drawerVar)
                        console.log(`updated photos:`)
                        console.log(updatedPhotos)


                        if(drawerVarName == "livingRoom") {
                           setLivingRoom(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }
                        if(drawerVarName == "familyRoom") {
                           setFamilyRoom(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }
                        if(drawerVarName == "bonusRoom") {
                           setBonusRoom(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }
                        if(drawerVarName == "diningRoom") {
                           setDiningRoom(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }
                        if(drawerVarName == "kitchen") {
                           setKitchen(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }
                        if(drawerVarName == "den") {
                           setDen(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }
                        if(drawerVarName == "hallway1") {
                           setHallway1(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }
                        if(drawerVarName == "hallway2") {
                           setHallway2(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }
                        if(drawerVarName == "hallway3") {
                           setHallway3(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }
                        if(drawerVarName == "office") {
                           setOffice(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }
                        if(drawerVarName == "bathroom1") {
                           setBathroom1(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }
                        if(drawerVarName == "bathroom2") {
                           setBathroom2(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }
                        if(drawerVarName == "bathroom3") {
                           setBathroom3(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }
                        if(drawerVarName == "bathroom4") {
                           setBathroom4(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }
                        if(drawerVarName == "bedroom1") {
                           setBedroom1(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }
                        if(drawerVarName == "bedroom2") {
                           setBedroom2(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }
                        if(drawerVarName == "bedroom3") {
                           setBedroom3(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }
                        if(drawerVarName == "bedroom4") {
                           setBedroom4(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }
                        if(drawerVarName == "bedroom5") {
                           setBedroom5(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }
                        if(drawerVarName == "bedroom6") {
                           setBedroom6(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }
                        if(drawerVarName == "garage") {
                           setGarage(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }
                        if(drawerVarName == "attic") {
                           setAttic(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }
                        if(drawerVarName == "other") {
                           setOther(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }




                        let tmp = inspectionAllData
                        const updatedPhotos2 = tmp[drawerVarName].filter( photo1 => photo1.FILE_ID != event.target.value )
                        tmp[drawerVarName] = updatedPhotos2

                        console.log(`tmp[drawerVarName]:`)
                        console.log(tmp[drawerVarName])


                        dispatch({ type: 'SET_INSPECTION_ALL_DATA', payload: tmp } )
                     }}>Delete
                  </Button>

                  <br/>
               </ImageListItem>
            ))}
         </ImageList>
      </Box>
   );




   const toBase64 = file => new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
   });

   return (

      <Stack
         direction="column"
         justifyContent="left"
         alignItems="left"
         padding={2}
         spacing={1}
         sx={{ display: show === true ? 'flex' : 'none' }}
      >

         <Stack direction="row" justifyContent="left" alignItems="left" padding={0}>


            <React.Fragment key={'right'} >
               <Drawer
                  anchor={'right'}
                  open={state[drawerVarName]}
                  //open={state[drawerVarName]}
                  onClose={toggleDrawer(drawerVarName, false, drawerVar)}
                  sx={{zIndex: 9999}}
               >
                  {list(drawerVar)}
               </Drawer>
            </React.Fragment>

            <Typography sx={{my: 'auto'}}>Living Room</Typography>
            <IconButton color="primary" aria-label="upload picture" component="label"
                        onClick={ () => {
                           dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'livingRoom', page: 'interior'} })
                        }}>
               <PhotoCamera />
            </IconButton>
            <IconButton color="primary" aria-label="upload picture" component="label">
               <input
                  type="file" accept="image/*" multiple hidden name="myImage"
                  onChange={(event) => {

                     const files = event.target.files
                     let photos = []

                     //files.forEach((file) => {
                     for (let i = 0; i < files.length; i++) {
                        reduce.toBlob(files[i], { max: 900 }).then(blob => {
                           const wrapSaveImage = async (dataUri) => {
                              //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                              const wrapper = async () => {
                                 const base64 = await toBase64(dataUri);
                                 const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', '').replace('data:image/png;base64,', ''), generateUniqueName: true}
                                 const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                 dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, livingRoom: [...livingRoom, ...photos, savedPhotoData]} })

                                 const updatedPhotos = [...photos, savedPhotoData]
                                 setLivingRoom([...livingRoom, ...updatedPhotos])
                                 photos.push(savedPhotoData)
                              }
                              wrapper()
                           }
                           wrapSaveImage(blob)
                        });
                     }


                  }}
               />
               <GalleryIcon />
            </IconButton>
         </Stack>

         <Stack direction="row" alignItems="center" spacing={1}>
            {livingRoom.map((image, index) => (
               <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('livingRoom', true, livingRoom)}/>
            ))}
         </Stack>



         <Stack direction="row" justifyContent="left" alignItems="left" padding={0}>
            <Typography sx={{my: 'auto'}}>Family Room</Typography>
            <IconButton color="primary" aria-label="upload picture" component="label"
                        onClick={ () => {
                           dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'familyRoom', page: 'interior'} })
                        }}>
               <PhotoCamera />
            </IconButton>
            <IconButton color="primary" aria-label="upload picture" component="label">
               <input
                  type="file" accept="image/*" multiple hidden name="myImage"
                  onChange={(event) => {

                     const files = event.target.files
                     let photos = []

                     //files.forEach((file) => {
                     for (let i = 0; i < files.length; i++) {
                        reduce.toBlob(files[i], { max: 900 }).then(blob => {
                           const wrapSaveImage = async (dataUri) => {
                              //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                              const wrapper = async () => {
                                 const base64 = await toBase64(dataUri);
                                 const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', '').replace('data:image/png;base64,', ''), generateUniqueName: true}
                                 const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                 dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, familyRoom: [...familyRoom, ...photos, savedPhotoData]} })

                                 const updatedPhotos = [...photos, savedPhotoData]
                                 setFamilyRoom([...familyRoom, ...updatedPhotos])
                                 photos.push(savedPhotoData)
                              }
                              wrapper()
                           }
                           wrapSaveImage(blob)
                        });
                     }


                  }}
               />
               <GalleryIcon />
            </IconButton>
         </Stack>

         <Stack direction="row" alignItems="center" spacing={1}>
            {familyRoom.map((image, index) => (
               <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('familyRoom', true, familyRoom)}/>
            ))}
         </Stack>




         <Stack direction="row" justifyContent="left" alignItems="left" padding={0}>
            <Typography sx={{my: 'auto'}}>Bonus Room</Typography>
            <IconButton color="primary" aria-label="upload picture" component="label"
                        onClick={ () => {
                           dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'bonusRoom', page: 'interior'} })
                        }}>
               <PhotoCamera />
            </IconButton>
            <IconButton color="primary" aria-label="upload picture" component="label">
               <input
                  type="file" accept="image/*" multiple hidden name="myImage"
                  onChange={(event) => {

                     const files = event.target.files
                     let photos = []

                     //files.forEach((file) => {
                     for (let i = 0; i < files.length; i++) {
                        reduce.toBlob(files[i], { max: 900 }).then(blob => {
                           const wrapSaveImage = async (dataUri) => {
                              //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                              const wrapper = async () => {
                                 const base64 = await toBase64(dataUri);
                                 const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', '').replace('data:image/png;base64,', ''), generateUniqueName: true}
                                 const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                 dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, bonusRoom: [...bonusRoom, ...photos, savedPhotoData]} })

                                 const updatedPhotos = [...photos, savedPhotoData]
                                 setBonusRoom([...bonusRoom, ...updatedPhotos])
                                 photos.push(savedPhotoData)
                              }
                              wrapper()
                           }
                           wrapSaveImage(blob)
                        });
                     }


                  }}
               />
               <GalleryIcon />
            </IconButton>
         </Stack>

         <Stack direction="row" alignItems="center" spacing={1}>
            {bonusRoom.map((image, index) => (
               <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('bonusRoom', true, bonusRoom)}/>
            ))}
         </Stack>



         <Stack direction="row" justifyContent="left" alignItems="left" padding={0}>
            <Typography sx={{my: 'auto'}}>Dining Room</Typography>
            <IconButton color="primary" aria-label="upload picture" component="label"
                        onClick={ () => {
                           dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'diningRoom', page: 'interior'} })
                        }}>
               <PhotoCamera />
            </IconButton>
            <IconButton color="primary" aria-label="upload picture" component="label">
               <input
                  type="file" accept="image/*" multiple hidden name="myImage"
                  onChange={(event) => {

                     const files = event.target.files
                     let photos = []

                     //files.forEach((file) => {
                     for (let i = 0; i < files.length; i++) {
                        reduce.toBlob(files[i], { max: 900 }).then(blob => {
                           const wrapSaveImage = async (dataUri) => {
                              //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                              const wrapper = async () => {
                                 const base64 = await toBase64(dataUri);
                                 const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', '').replace('data:image/png;base64,', ''), generateUniqueName: true}
                                 const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                 dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, diningRoom: [...diningRoom, ...photos, savedPhotoData]} })

                                 const updatedPhotos = [...photos, savedPhotoData]
                                 setDiningRoom([...diningRoom, ...updatedPhotos])
                                 photos.push(savedPhotoData)
                              }
                              wrapper()
                           }
                           wrapSaveImage(blob)
                        });
                     }


                  }}
               />
               <GalleryIcon />
            </IconButton>
         </Stack>

         <Stack direction="row" alignItems="center" spacing={1}>
            {diningRoom.map((image, index) => (
               <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('diningRoom', true, diningRoom)}/>
            ))}
         </Stack>



         <Stack direction="row" justifyContent="left" alignItems="left" padding={0}>
            <Typography sx={{my: 'auto'}}>Kitchen</Typography>
            <IconButton color="primary" aria-label="upload picture" component="label"
                        onClick={ () => {
                           dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'kitchen', page: 'interior'} })
                        }}>
               <PhotoCamera />
            </IconButton>
            <IconButton color="primary" aria-label="upload picture" component="label">
               <input
                  type="file" accept="image/*" multiple hidden name="myImage"
                  onChange={(event) => {

                     const files = event.target.files
                     let photos = []

                     //files.forEach((file) => {
                     for (let i = 0; i < files.length; i++) {
                        reduce.toBlob(files[i], { max: 900 }).then(blob => {
                           const wrapSaveImage = async (dataUri) => {
                              //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                              const wrapper = async () => {
                                 const base64 = await toBase64(dataUri);
                                 const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', '').replace('data:image/png;base64,', ''), generateUniqueName: true}
                                 const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                 dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, kitchen: [...kitchen, ...photos, savedPhotoData]} })

                                 const updatedPhotos = [...photos, savedPhotoData]
                                 setKitchen([...kitchen, ...updatedPhotos])
                                 photos.push(savedPhotoData)
                              }
                              wrapper()
                           }
                           wrapSaveImage(blob)
                        });
                     }


                  }}
               />
               <GalleryIcon />
            </IconButton>
         </Stack>

         <Stack direction="row" alignItems="center" spacing={1}>
            {kitchen.map((image, index) => (
               <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('kitchen', true, kitchen)}/>
            ))}
         </Stack>



         <Stack direction="row" justifyContent="left" alignItems="left" padding={0}>
            <Typography sx={{my: 'auto'}}>Den</Typography>
            <IconButton color="primary" aria-label="upload picture" component="label"
                        onClick={ () => {
                           dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'den', page: 'interior'} })
                        }}>
               <PhotoCamera />
            </IconButton>
            <IconButton color="primary" aria-label="upload picture" component="label">
               <input
                  type="file" accept="image/*" multiple hidden name="myImage"
                  onChange={(event) => {

                     const files = event.target.files
                     let photos = []

                     //files.forEach((file) => {
                     for (let i = 0; i < files.length; i++) {
                        reduce.toBlob(files[i], { max: 900 }).then(blob => {
                           const wrapSaveImage = async (dataUri) => {
                              //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                              const wrapper = async () => {
                                 const base64 = await toBase64(dataUri);
                                 const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', '').replace('data:image/png;base64,', ''), generateUniqueName: true}
                                 const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                 dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, den: [...den, ...photos, savedPhotoData]} })

                                 const updatedPhotos = [...photos, savedPhotoData]
                                 setDen([...den, ...updatedPhotos])
                                 photos.push(savedPhotoData)
                              }
                              wrapper()
                           }
                           wrapSaveImage(blob)
                        });
                     }


                  }}
               />
               <GalleryIcon />
            </IconButton>
         </Stack>

         <Stack direction="row" alignItems="center" spacing={1}>
            {den.map((image, index) => (
               <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('den', true, den)}/>
            ))}
         </Stack>



         <Stack direction="row" justifyContent="left" alignItems="left" padding={0}>
            <Typography sx={{my: 'auto'}}>Hallway 1</Typography>
            <IconButton color="primary" aria-label="upload picture" component="label"
                        onClick={ () => {
                           dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'hallway1', page: 'interior'} })
                        }}>
               <PhotoCamera />
            </IconButton>
            <IconButton color="primary" aria-label="upload picture" component="label">
               <input
                  type="file" accept="image/*" multiple hidden name="myImage"
                  onChange={(event) => {

                     const files = event.target.files
                     let photos = []

                     //files.forEach((file) => {
                     for (let i = 0; i < files.length; i++) {
                        reduce.toBlob(files[i], { max: 900 }).then(blob => {
                           const wrapSaveImage = async (dataUri) => {
                              //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                              const wrapper = async () => {
                                 const base64 = await toBase64(dataUri);
                                 const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', '').replace('data:image/png;base64,', ''), generateUniqueName: true}
                                 const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                 dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, hallway1: [...hallway1, ...photos, savedPhotoData]} })

                                 const updatedPhotos = [...photos, savedPhotoData]
                                 setHallway1([...hallway1, ...updatedPhotos])
                                 photos.push(savedPhotoData)
                              }
                              wrapper()
                           }
                           wrapSaveImage(blob)
                        });
                     }


                  }}
               />
               <GalleryIcon />
            </IconButton>
         </Stack>

         <Stack direction="row" alignItems="center" spacing={1}>
            {hallway1.map((image, index) => (
               <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('hallway1', true, hallway1)}/>
            ))}
         </Stack>



         <Stack direction="row" justifyContent="left" alignItems="left" padding={0}>
            <Typography sx={{my: 'auto'}}>Hallway 2</Typography>
            <IconButton color="primary" aria-label="upload picture" component="label"
                        onClick={ () => {
                           dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'hallway2', page: 'interior'} })
                        }}>
               <PhotoCamera />
            </IconButton>
            <IconButton color="primary" aria-label="upload picture" component="label">
               <input
                  type="file" accept="image/*" multiple hidden name="myImage"
                  onChange={(event) => {

                     const files = event.target.files
                     let photos = []

                     //files.forEach((file) => {
                     for (let i = 0; i < files.length; i++) {
                        reduce.toBlob(files[i], { max: 900 }).then(blob => {
                           const wrapSaveImage = async (dataUri) => {
                              //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                              const wrapper = async () => {
                                 const base64 = await toBase64(dataUri);
                                 const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', '').replace('data:image/png;base64,', ''), generateUniqueName: true}
                                 const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                 dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, hallway2: [...hallway2, ...photos, savedPhotoData]} })

                                 const updatedPhotos = [...photos, savedPhotoData]
                                 setHallway2([...hallway2, ...updatedPhotos])
                                 photos.push(savedPhotoData)
                              }
                              wrapper()
                           }
                           wrapSaveImage(blob)
                        });
                     }


                  }}
               />
               <GalleryIcon />
            </IconButton>
         </Stack>

         <Stack direction="row" alignItems="center" spacing={1}>
            {hallway2.map((image, index) => (
               <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('hallway2', true, hallway2)}/>
            ))}
         </Stack>



         <Stack direction="row" justifyContent="left" alignItems="left" padding={0}>
            <Typography sx={{my: 'auto'}}>Hallway 3</Typography>
            <IconButton color="primary" aria-label="upload picture" component="label"
                        onClick={ () => {
                           dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'hallway3', page: 'interior'} })
                        }}>
               <PhotoCamera />
            </IconButton>
            <IconButton color="primary" aria-label="upload picture" component="label">
               <input
                  type="file" accept="image/*" multiple hidden name="myImage"
                  onChange={(event) => {

                     const files = event.target.files
                     let photos = []

                     //files.forEach((file) => {
                     for (let i = 0; i < files.length; i++) {
                        reduce.toBlob(files[i], { max: 900 }).then(blob => {
                           const wrapSaveImage = async (dataUri) => {
                              //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                              const wrapper = async () => {
                                 const base64 = await toBase64(dataUri);
                                 const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', '').replace('data:image/png;base64,', ''), generateUniqueName: true}
                                 const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                 dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, hallway3: [...hallway3, ...photos, savedPhotoData]} })

                                 const updatedPhotos = [...photos, savedPhotoData]
                                 setHallway3([...hallway3, ...updatedPhotos])
                                 photos.push(savedPhotoData)
                              }
                              wrapper()
                           }
                           wrapSaveImage(blob)
                        });
                     }


                  }}
               />
               <GalleryIcon />
            </IconButton>
         </Stack>

         <Stack direction="row" alignItems="center" spacing={1}>
            {hallway3.map((image, index) => (
               <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('hallway3', true, hallway3)}/>
            ))}
         </Stack>


         <Stack direction="row" justifyContent="left" alignItems="left" padding={0}>
            <Typography sx={{my: 'auto'}}>Office</Typography>
            <IconButton color="primary" aria-label="upload picture" component="label"
                        onClick={ () => {
                           dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'office', page: 'interior'} })
                        }}>
               <PhotoCamera />
            </IconButton>
            <IconButton color="primary" aria-label="upload picture" component="label">
               <input
                  type="file" accept="image/*" multiple hidden name="myImage"
                  onChange={(event) => {

                     const files = event.target.files
                     let photos = []

                     //files.forEach((file) => {
                     for (let i = 0; i < files.length; i++) {
                        reduce.toBlob(files[i], { max: 900 }).then(blob => {
                           const wrapSaveImage = async (dataUri) => {
                              //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                              const wrapper = async () => {
                                 const base64 = await toBase64(dataUri);
                                 const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', '').replace('data:image/png;base64,', ''), generateUniqueName: true}
                                 const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                 dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, office: [...office, ...photos, savedPhotoData]} })

                                 const updatedPhotos = [...photos, savedPhotoData]
                                 setOffice([...office, ...updatedPhotos])
                                 photos.push(savedPhotoData)
                              }
                              wrapper()
                           }
                           wrapSaveImage(blob)
                        });
                     }


                  }}
               />
               <GalleryIcon />
            </IconButton>
         </Stack>

         <Stack direction="row" alignItems="center" spacing={1}>
            {office.map((image, index) => (
               <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('office', true, office)}/>
            ))}
         </Stack>



         <Stack direction="row" justifyContent="left" alignItems="left" padding={0}>
            <Typography sx={{my: 'auto'}}>Bathroom 1</Typography>
            <IconButton color="primary" aria-label="upload picture" component="label"
                        onClick={ () => {
                           dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'bathroom1', page: 'interior'} })
                        }}>
               <PhotoCamera />
            </IconButton>
            <IconButton color="primary" aria-label="upload picture" component="label">
               <input
                  type="file" accept="image/*" multiple hidden name="myImage"
                  onChange={(event) => {

                     const files = event.target.files
                     let photos = []

                     //files.forEach((file) => {
                     for (let i = 0; i < files.length; i++) {
                        reduce.toBlob(files[i], { max: 900 }).then(blob => {
                           const wrapSaveImage = async (dataUri) => {
                              //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                              const wrapper = async () => {
                                 const base64 = await toBase64(dataUri);
                                 const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', '').replace('data:image/png;base64,', ''), generateUniqueName: true}
                                 const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                 dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, bathroom1: [...bathroom1, ...photos, savedPhotoData]} })

                                 const updatedPhotos = [...photos, savedPhotoData]
                                 setBathroom1([...bathroom1, ...updatedPhotos])
                                 photos.push(savedPhotoData)
                              }
                              wrapper()
                           }
                           wrapSaveImage(blob)
                        });
                     }


                  }}
               />
               <GalleryIcon />
            </IconButton>
         </Stack>
         <Stack direction="row" alignItems="center" spacing={1}>
            {bathroom1.map((image, index) => (
               <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('bathroom1', true, bathroom1)}/>
            ))}
         </Stack>


         <Stack direction="row" justifyContent="left" alignItems="left" padding={0}>
            <Typography sx={{my: 'auto'}}>Bathroom 2</Typography>
            <IconButton color="primary" aria-label="upload picture" component="label"
                        onClick={ () => {
                           dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'bathroom2', page: 'interior'} })
                        }}>
               <PhotoCamera />
            </IconButton>
            <IconButton color="primary" aria-label="upload picture" component="label">
               <input
                  type="file" accept="image/*" multiple hidden name="myImage"
                  onChange={(event) => {

                     const files = event.target.files
                     let photos = []

                     //files.forEach((file) => {
                     for (let i = 0; i < files.length; i++) {
                        reduce.toBlob(files[i], { max: 900 }).then(blob => {
                           const wrapSaveImage = async (dataUri) => {
                              //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                              const wrapper = async () => {
                                 const base64 = await toBase64(dataUri);
                                 const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', '').replace('data:image/png;base64,', ''), generateUniqueName: true}
                                 const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                 dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, bathroom2: [...bathroom2, ...photos, savedPhotoData]} })

                                 const updatedPhotos = [...photos, savedPhotoData]
                                 setBathroom2([...bathroom2, ...updatedPhotos])
                                 photos.push(savedPhotoData)
                              }
                              wrapper()
                           }
                           wrapSaveImage(blob)
                        });
                     }


                  }}
               />
               <GalleryIcon />
            </IconButton>
         </Stack>
         <Stack direction="row" alignItems="center" spacing={1}>
            {bathroom2.map((image, index) => (
               <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('bathroom2', true, bathroom2)}/>
            ))}
         </Stack>


         <Stack direction="row" justifyContent="left" alignItems="left" padding={0}>
            <Typography sx={{my: 'auto'}}>Bathroom 3</Typography>
            <IconButton color="primary" aria-label="upload picture" component="label"
                        onClick={ () => {
                           dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'bathroom3', page: 'interior'} })
                        }}>
               <PhotoCamera />
            </IconButton>
            <IconButton color="primary" aria-label="upload picture" component="label">
               <input
                  type="file" accept="image/*" multiple hidden name="myImage"
                  onChange={(event) => {

                     const files = event.target.files
                     let photos = []

                     //files.forEach((file) => {
                     for (let i = 0; i < files.length; i++) {
                        reduce.toBlob(files[i], { max: 900 }).then(blob => {
                           const wrapSaveImage = async (dataUri) => {
                              //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                              const wrapper = async () => {
                                 const base64 = await toBase64(dataUri);
                                 const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', '').replace('data:image/png;base64,', ''), generateUniqueName: true}
                                 const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                 dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, bathroom3: [...bathroom3, ...photos, savedPhotoData]} })

                                 const updatedPhotos = [...photos, savedPhotoData]
                                 setBathroom3([...bathroom3, ...updatedPhotos])
                                 photos.push(savedPhotoData)
                              }
                              wrapper()
                           }
                           wrapSaveImage(blob)
                        });
                     }


                  }}
               />
               <GalleryIcon />
            </IconButton>
         </Stack>

         <Stack direction="row" alignItems="center" spacing={1}>
            {bathroom3.map((image, index) => (
               <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('bathroom3', true, bathroom3)}/>
            ))}
         </Stack>


         <Stack direction="row" justifyContent="left" alignItems="left" padding={0}>
            <Typography sx={{my: 'auto'}}>Bathroom 4</Typography>
            <IconButton color="primary" aria-label="upload picture" component="label"
                        onClick={ () => {
                           dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'bathroom4', page: 'interior'} })
                        }}>
               <PhotoCamera />
            </IconButton>
            <IconButton color="primary" aria-label="upload picture" component="label">
               <input
                  type="file" accept="image/*" multiple hidden name="myImage"
                  onChange={(event) => {

                     const files = event.target.files
                     let photos = []

                     //files.forEach((file) => {
                     for (let i = 0; i < files.length; i++) {
                        reduce.toBlob(files[i], { max: 900 }).then(blob => {
                           const wrapSaveImage = async (dataUri) => {
                              //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                              const wrapper = async () => {
                                 const base64 = await toBase64(dataUri);
                                 const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', '').replace('data:image/png;base64,', ''), generateUniqueName: true}
                                 const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                 dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, bathroom4: [...bathroom4, ...photos, savedPhotoData]} })

                                 const updatedPhotos = [...photos, savedPhotoData]
                                 setBathroom4([...bathroom4, ...updatedPhotos])
                                 photos.push(savedPhotoData)
                              }
                              wrapper()
                           }
                           wrapSaveImage(blob)
                        });
                     }


                  }}
               />
               <GalleryIcon />
            </IconButton>
         </Stack>

         <Stack direction="row" alignItems="center" spacing={1}>
            {bathroom4.map((image, index) => (
               <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('bathroom4', true, bathroom4)}/>
            ))}
         </Stack>


         <Stack direction="row" justifyContent="left" alignItems="left" padding={0}>
            <Typography sx={{my: 'auto'}}>Bedroom 1</Typography>
            <IconButton color="primary" aria-label="upload picture" component="label"
                        onClick={ () => {
                           dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'bedroom1', page: 'interior'} })
                        }}>
               <PhotoCamera />
            </IconButton>
            <IconButton color="primary" aria-label="upload picture" component="label">
               <input
                  type="file" accept="image/*" multiple hidden name="myImage"
                  onChange={(event) => {

                     const files = event.target.files
                     let photos = []

                     //files.forEach((file) => {
                     for (let i = 0; i < files.length; i++) {
                        reduce.toBlob(files[i], { max: 900 }).then(blob => {
                           const wrapSaveImage = async (dataUri) => {
                              //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                              const wrapper = async () => {
                                 const base64 = await toBase64(dataUri);
                                 const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', '').replace('data:image/png;base64,', ''), generateUniqueName: true}
                                 const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                 dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, bedroom1: [...bedroom1, ...photos, savedPhotoData]} })

                                 const updatedPhotos = [...photos, savedPhotoData]
                                 setBedroom1([...bedroom1, ...updatedPhotos])
                                 photos.push(savedPhotoData)
                              }
                              wrapper()
                           }
                           wrapSaveImage(blob)
                        });
                     }


                  }}
               />
               <GalleryIcon />
            </IconButton>
         </Stack>

         <Stack direction="row" alignItems="center" spacing={1}>
            {bedroom1.map((image, index) => (
               <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('bedroom1', true, bedroom1)}/>
            ))}
         </Stack>



         <Stack direction="row" justifyContent="left" alignItems="left" padding={0}>
            <Typography sx={{my: 'auto'}}>Bedroom 2</Typography>
            <IconButton color="primary" aria-label="upload picture" component="label"
                        onClick={ () => {
                           dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'bedroom2', page: 'interior'} })
                        }}>
               <PhotoCamera />
            </IconButton>
            <IconButton color="primary" aria-label="upload picture" component="label">
               <input
                  type="file" accept="image/*" multiple hidden name="myImage"
                  onChange={(event) => {

                     const files = event.target.files
                     let photos = []

                     //files.forEach((file) => {
                     for (let i = 0; i < files.length; i++) {
                        reduce.toBlob(files[i], { max: 900 }).then(blob => {
                           const wrapSaveImage = async (dataUri) => {
                              //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                              const wrapper = async () => {
                                 const base64 = await toBase64(dataUri);
                                 const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', '').replace('data:image/png;base64,', ''), generateUniqueName: true}
                                 const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                 dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, bedroom2: [...bedroom2, ...photos, savedPhotoData]} })

                                 const updatedPhotos = [...photos, savedPhotoData]
                                 setBedroom2([...bedroom2, ...updatedPhotos])
                                 photos.push(savedPhotoData)
                              }
                              wrapper()
                           }
                           wrapSaveImage(blob)
                        });
                     }


                  }}
               />
               <GalleryIcon />
            </IconButton>
         </Stack>

         <Stack direction="row" alignItems="center" spacing={1}>
            {bedroom2.map((image, index) => (
               <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('bedroom2', true, bedroom2)}/>
            ))}
         </Stack>


         <Stack direction="row" justifyContent="left" alignItems="left" padding={0}>
            <Typography sx={{my: 'auto'}}>Bedroom 3</Typography>
            <IconButton color="primary" aria-label="upload picture" component="label"
                        onClick={ () => {
                           dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'bedroom3', page: 'interior'} })
                        }}>
               <PhotoCamera />
            </IconButton>
            <IconButton color="primary" aria-label="upload picture" component="label">
               <input
                  type="file" accept="image/*" multiple hidden name="myImage"
                  onChange={(event) => {

                     const files = event.target.files
                     let photos = []

                     //files.forEach((file) => {
                     for (let i = 0; i < files.length; i++) {
                        reduce.toBlob(files[i], { max: 900 }).then(blob => {
                           const wrapSaveImage = async (dataUri) => {
                              //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                              const wrapper = async () => {
                                 const base64 = await toBase64(dataUri);
                                 const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', '').replace('data:image/png;base64,', ''), generateUniqueName: true}
                                 const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                 dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, bedroom3: [...bedroom3, ...photos, savedPhotoData]} })

                                 const updatedPhotos = [...photos, savedPhotoData]
                                 setBedroom3([...bedroom3, ...updatedPhotos])
                                 photos.push(savedPhotoData)
                              }
                              wrapper()
                           }
                           wrapSaveImage(blob)
                        });
                     }


                  }}
               />
               <GalleryIcon />
            </IconButton>
         </Stack>

         <Stack direction="row" alignItems="center" spacing={1}>
            {bedroom3.map((image, index) => (
               <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('bedroom3', true, bedroom3)}/>
            ))}
         </Stack>


         <Stack direction="row" justifyContent="left" alignItems="left" padding={0}>
            <Typography sx={{my: 'auto'}}>Bedroom 4</Typography>
            <IconButton color="primary" aria-label="upload picture" component="label"
                        onClick={ () => {
                           dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'bedroom4', page: 'interior'} })
                        }}>
               <PhotoCamera />
            </IconButton>
            <IconButton color="primary" aria-label="upload picture" component="label">
               <input
                  type="file" accept="image/*" multiple hidden name="myImage"
                  onChange={(event) => {

                     const files = event.target.files
                     let photos = []

                     //files.forEach((file) => {
                     for (let i = 0; i < files.length; i++) {
                        reduce.toBlob(files[i], { max: 900 }).then(blob => {
                           const wrapSaveImage = async (dataUri) => {
                              //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                              const wrapper = async () => {
                                 const base64 = await toBase64(dataUri);
                                 const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', '').replace('data:image/png;base64,', ''), generateUniqueName: true}
                                 const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                 dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, bedroom4: [...bedroom4, ...photos, savedPhotoData]} })

                                 const updatedPhotos = [...photos, savedPhotoData]
                                 setBedroom4([...bedroom4, ...updatedPhotos])
                                 photos.push(savedPhotoData)
                              }
                              wrapper()
                           }
                           wrapSaveImage(blob)
                        });
                     }


                  }}
               />
               <GalleryIcon />
            </IconButton>
         </Stack>
         <Stack direction="row" alignItems="center" spacing={1}>
            {bedroom4.map((image, index) => (
               <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('bedroom4', true, bedroom4)}/>
            ))}
         </Stack>



         <Stack direction="row" justifyContent="left" alignItems="left" padding={0}>
            <Typography sx={{my: 'auto'}}>Bedroom 5</Typography>
            <IconButton color="primary" aria-label="upload picture" component="label"
                        onClick={ () => {
                           dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'bedroom5', page: 'interior'} })
                        }}>
               <PhotoCamera />
            </IconButton>
            <IconButton color="primary" aria-label="upload picture" component="label">
               <input
                  type="file" accept="image/*" multiple hidden name="myImage"
                  onChange={(event) => {

                     const files = event.target.files
                     let photos = []

                     //files.forEach((file) => {
                     for (let i = 0; i < files.length; i++) {
                        reduce.toBlob(files[i], { max: 900 }).then(blob => {
                           const wrapSaveImage = async (dataUri) => {
                              //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                              const wrapper = async () => {
                                 const base64 = await toBase64(dataUri);
                                 const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', '').replace('data:image/png;base64,', ''), generateUniqueName: true}
                                 const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                 dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, bedroom5: [...bedroom5, ...photos, savedPhotoData]} })

                                 const updatedPhotos = [...photos, savedPhotoData]
                                 setBedroom5([...bedroom5, ...updatedPhotos])
                                 photos.push(savedPhotoData)
                              }
                              wrapper()
                           }
                           wrapSaveImage(blob)
                        });
                     }


                  }}
               />
               <GalleryIcon />
            </IconButton>
         </Stack>

         <Stack direction="row" alignItems="center" spacing={1}>
            {bedroom5.map((image, index) => (
               <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('bedroom5', true, bedroom5)}/>
            ))}
         </Stack>



         <Stack direction="row" justifyContent="left" alignItems="left" padding={0}>
            <Typography sx={{my: 'auto'}}>Bedroom 6</Typography>
            <IconButton color="primary" aria-label="upload picture" component="label"
                        onClick={ () => {
                           dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'bedroom6', page: 'interior'} })
                        }}>
               <PhotoCamera />
            </IconButton>
            <IconButton color="primary" aria-label="upload picture" component="label">
               <input
                  type="file" accept="image/*" multiple hidden name="myImage"
                  onChange={(event) => {

                     const files = event.target.files
                     let photos = []

                     //files.forEach((file) => {
                     for (let i = 0; i < files.length; i++) {
                        reduce.toBlob(files[i], { max: 900 }).then(blob => {
                           const wrapSaveImage = async (dataUri) => {
                              //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                              const wrapper = async () => {
                                 const base64 = await toBase64(dataUri);
                                 const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', '').replace('data:image/png;base64,', ''), generateUniqueName: true}
                                 const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                 dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, bedroom6: [...bedroom6, ...photos, savedPhotoData]} })

                                 const updatedPhotos = [...photos, savedPhotoData]
                                 setBedroom6([...bedroom6, ...updatedPhotos])
                                 photos.push(savedPhotoData)
                              }
                              wrapper()
                           }
                           wrapSaveImage(blob)
                        });
                     }


                  }}
               />
               <GalleryIcon />
            </IconButton>
         </Stack>

         <Stack direction="row" alignItems="center" spacing={1}>
            {bedroom6.map((image, index) => (
               <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('bedroom6', true, bedroom6)}/>
            ))}
         </Stack>


         <Stack direction="row" justifyContent="left" alignItems="left" padding={0}>
            <Typography sx={{my: 'auto'}}>Garage</Typography>
            <IconButton color="primary" aria-label="upload picture" component="label"
                        onClick={ () => {
                           dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'garage', page: 'interior'} })
                        }}>
               <PhotoCamera />
            </IconButton>
            <IconButton color="primary" aria-label="upload picture" component="label">
               <input
                  type="file" accept="image/*" multiple hidden name="myImage"
                  onChange={(event) => {

                     const files = event.target.files
                     let photos = []

                     //files.forEach((file) => {
                     for (let i = 0; i < files.length; i++) {
                        reduce.toBlob(files[i], { max: 900 }).then(blob => {
                           const wrapSaveImage = async (dataUri) => {
                              //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                              const wrapper = async () => {
                                 const base64 = await toBase64(dataUri);
                                 const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', '').replace('data:image/png;base64,', ''), generateUniqueName: true}
                                 const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                 dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, garage: [...garage, ...photos, savedPhotoData]} })

                                 const updatedPhotos = [...photos, savedPhotoData]
                                 setGarage([...garage, ...updatedPhotos])
                                 photos.push(savedPhotoData)
                              }
                              wrapper()
                           }
                           wrapSaveImage(blob)
                        });
                     }


                  }}
               />
               <GalleryIcon />
            </IconButton>
         </Stack>

         <Stack direction="row" alignItems="center" spacing={1}>
            {garage.map((image, index) => (
               <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('garage', true, garage)}/>
            ))}
         </Stack>



         <Stack direction="row" justifyContent="left" alignItems="left" padding={0}>
            <Typography sx={{my: 'auto'}}>Attic</Typography>
            <IconButton color="primary" aria-label="upload picture" component="label"
                        onClick={ () => {
                           dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'attic', page: 'interior'} })
                        }}>
               <PhotoCamera />
            </IconButton>
            <IconButton color="primary" aria-label="upload picture" component="label">
               <input
                  type="file" accept="image/*" multiple hidden name="myImage"
                  onChange={(event) => {

                     const files = event.target.files
                     let photos = []

                     //files.forEach((file) => {
                     for (let i = 0; i < files.length; i++) {
                        reduce.toBlob(files[i], { max: 900 }).then(blob => {
                           const wrapSaveImage = async (dataUri) => {
                              //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                              const wrapper = async () => {
                                 const base64 = await toBase64(dataUri);
                                 const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', '').replace('data:image/png;base64,', ''), generateUniqueName: true}
                                 const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                 dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, attic: [...attic, ...photos, savedPhotoData]} })

                                 const updatedPhotos = [...photos, savedPhotoData]
                                 setAttic([...attic, ...updatedPhotos])
                                 photos.push(savedPhotoData)
                              }
                              wrapper()
                           }
                           wrapSaveImage(blob)
                        });
                     }


                  }}
               />
               <GalleryIcon />
            </IconButton>
         </Stack>
         <Stack direction="row" alignItems="center" spacing={1}>
            {attic.map((image, index) => (
               <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('attic', true, attic)}/>
            ))}
         </Stack>


         <Stack direction="row" justifyContent="left" alignItems="left" padding={0}>
            <Typography sx={{my: 'auto'}}>Other</Typography>
            <IconButton color="primary" aria-label="upload picture" component="label"
                        onClick={ () => {
                           dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'other', page: 'interior'} })
                        }}>
               <PhotoCamera />
            </IconButton>
            <IconButton color="primary" aria-label="upload picture" component="label">
               <input
                  type="file" accept="image/*" multiple hidden name="myImage"
                  onChange={(event) => {

                     const files = event.target.files
                     let photos = []

                     //files.forEach((file) => {
                     for (let i = 0; i < files.length; i++) {
                        reduce.toBlob(files[i], { max: 900 }).then(blob => {
                           const wrapSaveImage = async (dataUri) => {
                              //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                              const wrapper = async () => {
                                 const base64 = await toBase64(dataUri);
                                 const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', '').replace('data:image/png;base64,', ''), generateUniqueName: true}
                                 const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                 dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, other: [...other, ...photos, savedPhotoData]} })

                                 const updatedPhotos = [...photos, savedPhotoData]
                                 setOther([...other, ...updatedPhotos])
                                 photos.push(savedPhotoData)
                              }
                              wrapper()
                           }
                           wrapSaveImage(blob)
                        });
                     }


                  }}
               />
               <GalleryIcon />
            </IconButton>
         </Stack>

         <Stack direction="row" alignItems="center" spacing={1}>
            {other.map((image, index) => (
               <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('other', true, other)}/>
            ))}
         </Stack>



      </Stack>

   );
}