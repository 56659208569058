import * as React from 'react';

import SearchAppBar from "../components/SearchAppBar";
import InspectionsList from "../components/InspectionsList";
import AddNewIinspectionFAB from "../components/AddNewIinspectionFAB";


function Home() {


   return (
      <>
         <SearchAppBar/>
         <InspectionsList/>


         <AddNewIinspectionFAB/>
      </>
  )
}

export default Home


