import {
   Accordion,
   AccordionDetails,
   AccordionSummary, Autocomplete,
   Button, Checkbox, Drawer,
   FormControl,
   FormControlLabel,
   FormGroup, FormLabel, ImageList, ImageListItem, InputLabel, MenuItem, Radio, RadioGroup, Select,
   Stack, TextField
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import {PhotoCamera} from "@mui/icons-material";
import Box from "@mui/material/Box";
import AssistantDirectionIcon from "@mui/icons-material/AssistantDirection";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import * as React from "react";
import {useContext, useEffect} from "react";
import ECContext from "../../../context/inspections/ECContext";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import {getImageUrl, savePhotoIntoFolder} from "../../../context/inspections/ECActions";
import GalleryIcon from "@mui/icons-material/CollectionsOutlined";
import {Image} from "mui-image";

export default function RoofVentilation() {

   const { showCameraFor, flagReloadDatafromInspectionAllData, flagNeedToUpdatePhotosForVentilationBlock, inspectionAllData, access_token_data, saveRequest, flagCleanAllControlsData, inspectionRoofSUBBlockInfo, dispatch } = useContext(ECContext)
   const [show, setShow] = React.useState(true);
   //const [show, setShow] = React.useState(false);
   const reduce = require('image-blob-reduce')();


   const [ridgeVent, setRidgeVent] = React.useState('');
   const [ridgeVentLF, setRidgeVentLF] = React.useState('');
   const [turtleVents, setTurtleVents] = React.useState([]);
   const [vents50sqln, setVents50sqln] = React.useState('');
   const [otherVents, setOtherVents] = React.useState('');
   const [gableVents, setGableVents] = React.useState('');
   const [powerVents, setPowerVents] = React.useState('');
   const [turbinesVents, setTurbinesVents] = React.useState('');
   const [smallVents, setSmallVents] = React.useState('');
   const [largeVents, setLargeVents] = React.useState('');
   const [vents3N1, setVents3N1] = React.useState('');
   const [leadTopVents, setLeadTopVents] = React.useState('');
   const [vents2inch, setVents2inch] = React.useState('');
   const [vents3inch, setVents3inch] = React.useState('');
   const [vents4inch, setVents4inch] = React.useState('');
   const [splitBoot, setSplitBoot] = React.useState([]);
   const [furriceCap, setFurriceCap] = React.useState([]);
   const [satelliteDish, setSatelliteDish] = React.useState([]);
   const [rainDiverter, setRainDiverter] = React.useState([]);

   // useEffect(() => {
   //
   //    if(inspectionRoofSUBBlockInfo.elevationType == "ventilation")
   //       setShow(inspectionRoofSUBBlockInfo.showBlock)
   //    else
   //       setShow(false)
   //
   //
   // }, [inspectionRoofSUBBlockInfo]);

   useEffect(() => {
      setRidgeVent('');
      setRidgeVentLF('');
      setTurtleVents([]);
      setVents50sqln('');
      setOtherVents('');
      setGableVents('');
      setPowerVents('');
      setTurbinesVents('');
      setSmallVents('');
      setLargeVents('');
      setVents3N1('');
      setLeadTopVents('');
      setVents2inch('');
      setVents3inch('');
      setVents4inch('');
      setSplitBoot([]);
      setFurriceCap([]);
      setSatelliteDish([]);
      setRainDiverter([]);

   }, [flagCleanAllControlsData]);

   useEffect(() => {

      if(flagNeedToUpdatePhotosForVentilationBlock == true) {
         dispatch({ type: 'DROP_UPDATE_PHOTO_FOR_VENTILATION' })

         console.log("We're in Ventilation. Updating the photos after we shoot them. inspectionAllData:")
         console.log(inspectionAllData)

         if(inspectionAllData.hasOwnProperty('turtleVents') && showCameraFor.property == 'turtleVents') {
            setTurtleVents([...inspectionAllData.turtleVents])
         }
         if(inspectionAllData.hasOwnProperty('splitBoot') && showCameraFor.property == 'splitBoot') {
            setSplitBoot([...inspectionAllData.splitBoot])
         }
         if(inspectionAllData.hasOwnProperty('rurriceCap') && showCameraFor.property == 'rurriceCap') {
            setFurriceCap([...inspectionAllData.rurriceCap])
         }
         if(inspectionAllData.hasOwnProperty('satelliteDish') && showCameraFor.property == 'satelliteDish') {
            setSatelliteDish([...inspectionAllData.satelliteDish])
         }
         if(inspectionAllData.hasOwnProperty('rainDiverter') && showCameraFor.property == 'rainDiverter') {
            setRainDiverter([...inspectionAllData.rainDiverter])
         }

      }

   }, [flagNeedToUpdatePhotosForVentilationBlock]);

   useEffect(() => {

      if(saveRequest === true) {

               const transformSavingData = (prop) => {

                  return prop.map((fileData, index) => {
                     if(fileData.IS_NEW == true) {
                        prop[index].IS_NEW = false
                        return fileData.FILE_ID
                     }
                  }).filter(x => x !== undefined)
               }

         let ventilationBlockData = {

            PROPERTY_977: transformSavingData(furriceCap),                             //roof.Ventilation.Furrice Cap
               // inspectionAllData.hasOwnProperty('furriceCap') ?
               //    inspectionAllData.furriceCap.map((fileData, index) => {
               //          return fileData.FILE_ID
               //       }
               //    ) : '',
            PROPERTY_975: transformSavingData(splitBoot),                            //roof.Ventilation.Split Boot
               // inspectionAllData.hasOwnProperty('splitBoot') ?
               //    inspectionAllData.splitBoot.map((fileData, index) => {
               //          return fileData.FILE_ID
               //       }
               //    ) : '',
            PROPERTY_973: vents4inch,                 //roof.Ventilation.Turtle Vents.4"
            PROPERTY_971: vents3inch,                 //roof.Ventilation.Turtle Vents.3"
            PROPERTY_969: vents2inch,                 //roof.Ventilation.Turtle Vents.2"
            PROPERTY_967: leadTopVents,               //roof.Ventilation.Turtle Vents.Lead Top
            PROPERTY_965: vents3N1,                   //roof.Ventilation.Turtle Vents.3-N-1
            PROPERTY_963: largeVents,                 //roof.Ventilation.Turtle Vents.Large
            PROPERTY_961: smallVents,                 //roof.Ventilation.Turtle Vents.Small
            PROPERTY_959: turbinesVents,              //roof.Ventilation.Turtle Vents.Turbines
            PROPERTY_957: powerVents,                 //roof.Ventilation.Turtle Vents.Power Vents
            PROPERTY_955: gableVents,                 //roof.Ventilation.Turtle Vents.Gable Vents
            PROPERTY_953: otherVents,                 //roof.Ventilation.Turtle Vents.Other
            PROPERTY_951: vents50sqln,                //roof.Ventilation.Turtle Vents.50 sqln (750)
            PROPERTY_949: transformSavingData(turtleVents),                            //roof.Ventilation.Turtle Vents.Photos
               // inspectionAllData.hasOwnProperty('turtleVents') ?
               //    inspectionAllData.turtleVents.map((fileData, index) => {
               //          return fileData.FILE_ID
               //       }
               //    ) : '',
            PROPERTY_947: ridgeVent,                  //roof.Ventilation.Ridge Vent
            PROPERTY_1333: transformSavingData(satelliteDish),
            PROPERTY_1335:transformSavingData(rainDiverter),


            NO_PHOTOS_DATA: {
               "Turtle Vents.4\"": vents4inch,                 //roof.Ventilation.Turtle Vents.4"
               "Turtle Vents.3\"": vents3inch,                 //roof.Ventilation.Turtle Vents.3"
               "Turtle Vents.2\"": vents2inch,                 //roof.Ventilation.Turtle Vents.2"
               "Turtle Vents.Lead Top": leadTopVents,               //roof.Ventilation.Turtle Vents.Lead Top
               "Turtle Vents.3-N-1": vents3N1,                   //roof.Ventilation.Turtle Vents.3-N-1
               "Turtle Vents.Large": largeVents,                 //roof.Ventilation.Turtle Vents.Large
               "Turtle Vents.Small": smallVents,                 //roof.Ventilation.Turtle Vents.Small
               "Turtle Vents.Turbines": turbinesVents,              //roof.Ventilation.Turtle Vents.Turbines
               "Turtle Vents.Power Vents": powerVents,                 //roof.Ventilation.Turtle Vents.Power Vents
               "Turtle Vents.Gable Vents": gableVents,                 //roof.Ventilation.Turtle Vents.Gable Vents
               "Turtle Vents.Other": otherVents,                 //roof.Ventilation.Turtle Vents.Other
               "Turtle Vents.50 sqln (750)": vents50sqln,                //roof.Ventilation.Turtle Vents.50 sqln (750)
               "Ventilation.Ridge Vent": ridgeVent,                  //roof.Ventilation.Ridge Vent

            }

         }

         dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA_WITH_VENTILATION_BLOCK', payload: ventilationBlockData } )

         // triggering Save in InspectionEdit component
         dispatch({ type: 'SET_VENTILATION_READY_TO_SAVE_FLAG' })

      }
   }, [saveRequest]);

   useEffect(() => {

      if(!inspectionAllData.hasOwnProperty('PROPERTY_799' )) return;

      console.log('RELOADING data... in roofVentilation.jsx')

      setVents4inch(inspectionAllData.PROPERTY_973)
      setVents3inch(inspectionAllData.PROPERTY_971)
      setVents2inch(inspectionAllData.PROPERTY_969)
      setLeadTopVents(inspectionAllData.PROPERTY_967)
      setVents3N1(inspectionAllData.PROPERTY_965)
      setLargeVents(inspectionAllData.PROPERTY_963)
      setSmallVents(inspectionAllData.PROPERTY_961)
      setTurbinesVents(inspectionAllData.PROPERTY_959)
      setPowerVents(inspectionAllData.PROPERTY_957)
      setGableVents(inspectionAllData.PROPERTY_955)
      setOtherVents(inspectionAllData.PROPERTY_953)
      setVents50sqln(inspectionAllData.PROPERTY_951)
      setRidgeVent(inspectionAllData.PROPERTY_947)

      const furriceCap = async () => {
         if(inspectionAllData.hasOwnProperty('furriceCap' )) {
            if(inspectionAllData.furriceCap.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.furriceCap.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.furriceCap[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'FILE_ID': imageUrl.FILE_ID })
               }
               setFurriceCap(images)
               dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                  payload: {...inspectionAllData,
                     'furriceCap': images
                  }})

            }
         }
      }
      furriceCap()

      const splitBoot = async () => {
         if(inspectionAllData.hasOwnProperty('splitBoot' )) {
            if(inspectionAllData.splitBoot.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.splitBoot.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.splitBoot[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'FILE_ID': imageUrl.FILE_ID })
               }
               setSplitBoot(images)
               dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                  payload: {...inspectionAllData,
                     'splitBoot': images
                  }})

            }
         }
      }
      splitBoot()

      const turtleVents = async () => {
         if(inspectionAllData.hasOwnProperty('turtleVents' )) {
            if(inspectionAllData.turtleVents.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.turtleVents.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.turtleVents[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'FILE_ID': imageUrl.FILE_ID })
               }
               setTurtleVents(images)
               dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                  payload: {...inspectionAllData,
                     'turtleVents': images
                  }})

            }
         }
      }
      turtleVents()

      const satelliteDish = async () => {
         if(inspectionAllData.hasOwnProperty('satelliteDish' )) {
            if(inspectionAllData.satelliteDish.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.satelliteDish.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.satelliteDish[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'FILE_ID': imageUrl.FILE_ID })
               }
               setSatelliteDish(images)
               dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                  payload: {...inspectionAllData,
                     'satelliteDish': images
                  }})

            }
         }
      }
      satelliteDish()

      const rainDiverter = async () => {
         if(inspectionAllData.hasOwnProperty('rainDiverter' )) {
            if(inspectionAllData.rainDiverter.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.rainDiverter.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.rainDiverter[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'FILE_ID': imageUrl.FILE_ID })
               }
               setRainDiverter(images)
               dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                  payload: {...inspectionAllData,
                     'rainDiverter': images
                  }})

            }
         }
      }
      rainDiverter()


   }, [flagReloadDatafromInspectionAllData]);



   const [drawerVar, setDrawerVar] = React.useState([])
   const [drawerVarName, setDrawerVarName] = React.useState('')
   const [drawerSetter, setDrawerSetter] = React.useState('')
   const [state, setState] = React.useState({top: false, left: false, bottom: false, right: false,});

   const toggleDrawer = (anchor, open, photosValues) => (event) => {
      if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
         return;
      }

      setDrawerVar(photosValues)
      setDrawerVarName(anchor)

      setState({ ...state, [anchor]: open });
   };

   const list = (drawerVar) => (
      <Box
         sx={{ width: '400px' }}
         role="presentation"
         onClick={toggleDrawer(drawerVarName, false, drawerVar)}
         onKeyDown={toggleDrawer(drawerVarName, false,drawerVar)}
      >

         <Stack direction="row" justifyContent="center" alignItems="center" padding={2}>
            <Button variant="text" onClick={toggleDrawer(drawerVarName, false, drawerVar)}>Back</Button>
         </Stack>


         <ImageList sx={{ width: '400px' }} cols={1} >
            {drawerVar.map((item) => (
               <ImageListItem key={item.DETAIL_URL}>
                  <img
                     src={`${item.DETAIL_URL}`}
                     srcSet={`${item.DETAIL_URL}`}
                     loading="lazy"
                  />

                  <Button
                     disableElevation={true}
                     value={item.FILE_ID}
                     onClick={ (event) => {
                        event.stopPropagation()

                        const updatedPhotos = drawerVar.filter( photo1 => photo1.FILE_ID != event.target.value )


                        console.log(`drawerVarName = ${drawerVarName}`)
                        console.log(`event.target.value = ${event.target.value}`)
                        console.log("drawerVar:")
                        console.log(drawerVar)
                        console.log(`updated photos:`)
                        console.log(updatedPhotos)


                        if(drawerVarName == "turtleVents") {
                           setTurtleVents(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }
                        if(drawerVarName == "splitBoot") {
                           setSplitBoot(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }
                        if(drawerVarName == "furriceCap") {
                           setFurriceCap(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }
                        if(drawerVarName == "satelliteDish") {
                           setSatelliteDish(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }
                        if(drawerVarName == "rainDiverter") {
                           setRainDiverter(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }





                        let tmp = inspectionAllData
                        const updatedPhotos2 = tmp[drawerVarName].filter( photo1 => photo1.FILE_ID != event.target.value )
                        tmp[drawerVarName] = updatedPhotos2

                        console.log(`tmp[drawerVarName]:`)
                        console.log(tmp[drawerVarName])


                        dispatch({ type: 'SET_INSPECTION_ALL_DATA', payload: tmp } )
                     }}>Delete
                  </Button>

                  <br/>
               </ImageListItem>
            ))}
         </ImageList>
      </Box>
   );





   const toBase64 = file => new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
   });

   return (

      <>
         <Box sx={{mx: 'auto', mt: 2, width: '100%', display: show === true ? 'flex' : 'none', flexDirection: 'column'}}>

            <React.Fragment key={'right'} >
               <Drawer
                  anchor={'right'}
                  open={state[drawerVarName]}
                  //open={state[drawerVarName]}
                  onClose={toggleDrawer(drawerVarName, false, drawerVar)}
                  sx={{zIndex: 9999}}
               >
                  {list(drawerVar)}
               </Drawer>
            </React.Fragment>

            <Stack
               direction="column"
               justifyContent="left"
               alignItems="left"
               padding={2}
            >

               <Stack direction="row" justifyContent="left" alignItems="left" padding={0} >
                  <FormControl>
                     <FormLabel id="ridge-vent-radio-buttons-group-label">Ridge Vent</FormLabel>
                     <RadioGroup
                        row
                        aria-labelledby="ridge-vent-radio-buttons-group-label"
                        name="ridge-vent-buttons-group"
                        value={ridgeVent}
                        onChange={(event) => {
                           setRidgeVent(event.target.value)
                        }}
                     >
                        <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                        <FormControlLabel value="no" control={<Radio />} label="No" />
                     </RadioGroup>
                  </FormControl>
               </Stack>

               <Stack direction="row" justifyContent="left" alignItems="left"  spacing={2}>
                  <Button variant="text" onClick={() => setRidgeVentLF(Number(ridgeVentLF)-1) }><RemoveCircleOutlineIcon/></Button>
                  <TextField sx={{width: "250px"}} id="other" label="# of Ridge Vent LF" variant="outlined"
                             value={ridgeVentLF}
                             onChange={(event) => {
                                setRidgeVentLF(event.target.value)
                             }}
                  />
                  <Button variant="text" onClick={() => setRidgeVentLF(Number(ridgeVentLF)+1) }><AddCircleOutlineIcon/></Button>
               </Stack>

               <Stack direction="row" justifyContent="left" alignItems="left" padding={1}>
                  <Typography>Vents</Typography>

                  <Stack direction="row" justifyContent="right" alignItems="right" padding={2} sx={{ml: 'auto', width: '200px'}}>
                     <IconButton color="primary" aria-label="upload picture" component="label"
                                 onClick={ () => {
                                    dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'turtleVents', page: 'ventilation'} })
                                 }}>
                        <PhotoCamera />
                     </IconButton>
                     <IconButton color="primary" aria-label="upload picture" component="label">
                        <input
                           type="file" accept="image/*" multiple hidden name="myImage"
                           onChange={(event) => {

                              console.log('event.target.files:')
                              console.log(event.target.files)

                              const files = event.target.files
                              let photos = []

                              //files.forEach((file) => {
                              for (let i = 0; i < files.length; i++) {
                                 reduce.toBlob(files[i], { max: 900 }).then(blob => {
                                    const wrapSaveImage = async (dataUri) => {
                                       //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                                       const wrapper = async () => {
                                          const base64 = await toBase64(dataUri);
                                          const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', ''), generateUniqueName: true}
                                          const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                          dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, turtleVents: [...turtleVents, ...photos, savedPhotoData]} })
                                          //dispatch({ type: 'SET_UPDATE_PHOTO_FOR_JOBSITE' })

                                          const updatedPhotos = [...photos, savedPhotoData]
                                          setTurtleVents([...turtleVents, ...updatedPhotos])
                                          photos.push(savedPhotoData)
                                       }
                                       wrapper()
                                    }
                                    wrapSaveImage(blob)
                                 });
                              }


                           }}
                        />
                        <GalleryIcon />
                     </IconButton>
                  </Stack>

               </Stack>

               <Stack direction="row" alignItems="center" spacing={1}>
                  {turtleVents.map((image, index) => (
                     <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('turtleVents', true, turtleVents)}/>
                  ))}
               </Stack>






{/*
               <Stack direction="row" justifyContent="left" alignItems="left" padding={1}>
                  <Typography>Satellite Dish</Typography>

                  <Stack direction="row" justifyContent="right" alignItems="right" padding={2} sx={{ml: 'auto', width: '200px'}}>
                     <IconButton color="primary" aria-label="upload picture" component="label"
                                 onClick={ () => {
                                    dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'satelliteDish', page: 'ventilation'} })
                                 }}>
                        <PhotoCamera />
                     </IconButton>
                     <IconButton color="primary" aria-label="upload picture" component="label">
                        <input
                           type="file" accept="image/*" multiple hidden name="myImage"
                           onChange={(event) => {

                              console.log('event.target.files:')
                              console.log(event.target.files)

                              const files = event.target.files
                              let photos = []

                              //files.forEach((file) => {
                              for (let i = 0; i < files.length; i++) {
                                 reduce.toBlob(files[i], { max: 900 }).then(blob => {
                                    const wrapSaveImage = async (dataUri) => {
                                       //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                                       const wrapper = async () => {
                                          const base64 = await toBase64(dataUri);
                                          const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', ''), generateUniqueName: true}
                                          const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                          dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, satelliteDish: [...satelliteDish, ...photos, savedPhotoData]} })
                                          //dispatch({ type: 'SET_UPDATE_PHOTO_FOR_JOBSITE' })

                                          const updatedPhotos = [...photos, savedPhotoData]
                                          setSatelliteDish([...satelliteDish, ...updatedPhotos])
                                          photos.push(savedPhotoData)
                                       }
                                       wrapper()
                                    }
                                    wrapSaveImage(blob)
                                 });
                              }


                           }}
                        />
                        <GalleryIcon />
                     </IconButton>
                  </Stack>

               </Stack>
               <Stack direction="row" alignItems="center" spacing={1}>
                  {satelliteDish.map((image, index) => (
                     <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('satelliteDish', true, satelliteDish)}/>
                  ))}
               </Stack>

               <Stack direction="row" justifyContent="left" alignItems="left" padding={1}>
                  <Typography>Rain Diverter</Typography>

                  <Stack direction="row" justifyContent="right" alignItems="right" padding={2} sx={{ml: 'auto', width: '200px'}}>
                     <IconButton color="primary" aria-label="upload picture" component="label"
                                 onClick={ () => {
                                    dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'rainDiverter', page: 'ventilation'} })
                                 }}>
                        <PhotoCamera />
                     </IconButton>
                     <IconButton color="primary" aria-label="upload picture" component="label">
                        <input
                           type="file" accept="image/*" multiple hidden name="myImage"
                           onChange={(event) => {

                              console.log('event.target.files:')
                              console.log(event.target.files)

                              const files = event.target.files
                              let photos = []

                              //files.forEach((file) => {
                              for (let i = 0; i < files.length; i++) {
                                 reduce.toBlob(files[i], { max: 900 }).then(blob => {
                                    const wrapSaveImage = async (dataUri) => {
                                       //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                                       const wrapper = async () => {
                                          const base64 = await toBase64(dataUri);
                                          const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', ''), generateUniqueName: true}
                                          const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                          dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, rainDiverter: [...rainDiverter, ...photos, savedPhotoData]} })
                                          //dispatch({ type: 'SET_UPDATE_PHOTO_FOR_JOBSITE' })

                                          const updatedPhotos = [...photos, savedPhotoData]
                                          setRainDiverter([...rainDiverter, ...updatedPhotos])
                                          photos.push(savedPhotoData)
                                       }
                                       wrapper()
                                    }
                                    wrapSaveImage(blob)
                                 });
                              }


                           }}
                        />
                        <GalleryIcon />
                     </IconButton>
                  </Stack>

               </Stack>
               <Stack direction="row" alignItems="center" spacing={1}>
                  {rainDiverter.map((image, index) => (
                     <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('rainDiverter', true, rainDiverter)}/>
                  ))}
               </Stack>
*/}


               <Stack direction="row" alignItems="center" spacing={2}>
                  <Typography sx={{my: 'auto'}}>Satellite Dish</Typography>
                  <IconButton color="primary" aria-label="upload picture" component="label"
                              onClick={ () => {
                                 dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'satelliteDish', page: 'ventilation'} })
                              }}>
                     <PhotoCamera />
                  </IconButton>
                  <IconButton color="primary" aria-label="upload picture" component="label">
                     <input
                        type="file" accept="image/*" multiple hidden name="myImage"
                        onChange={(event) => {

                           const files = event.target.files
                           let photos = []

                           //files.forEach((file) => {
                           for (let i = 0; i < files.length; i++) {
                              reduce.toBlob(files[i], { max: 900 }).then(blob => {
                                 const wrapSaveImage = async (dataUri) => {
                                    //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                                    const wrapper = async () => {
                                       const base64 = await toBase64(dataUri);
                                       const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', '').replace('data:image/png;base64,', ''), generateUniqueName: true}
                                       const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                       dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, satelliteDish: [...satelliteDish, ...photos, savedPhotoData]} })

                                       const updatedPhotos = [...photos, savedPhotoData]
                                       setSatelliteDish([...satelliteDish, ...updatedPhotos])
                                       photos.push(savedPhotoData)
                                    }
                                    wrapper()
                                 }
                                 wrapSaveImage(blob)
                              });
                           }
                        }}
                     />
                     <GalleryIcon />
                  </IconButton>
               </Stack>
               <Stack direction="row" alignItems="center" spacing={1}>
                  {satelliteDish.map((image, index) => (
                     <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('satelliteDish', true, satelliteDish)}/>
                  ))}
               </Stack>

               <Stack direction="row" alignItems="center" spacing={2}>
                  <Typography sx={{my: 'auto'}}>Rain Diverter</Typography>
                  <IconButton color="primary" aria-label="upload picture" component="label"
                              onClick={ () => {
                                 dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'rainDiverter', page: 'ventilation'} })
                              }}>
                     <PhotoCamera />
                  </IconButton>
                  <IconButton color="primary" aria-label="upload picture" component="label">
                     <input
                        type="file" accept="image/*" multiple hidden name="myImage"
                        onChange={(event) => {

                           const files = event.target.files
                           let photos = []

                           //files.forEach((file) => {
                           for (let i = 0; i < files.length; i++) {
                              reduce.toBlob(files[i], { max: 900 }).then(blob => {
                                 const wrapSaveImage = async (dataUri) => {
                                    //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                                    const wrapper = async () => {
                                       const base64 = await toBase64(dataUri);
                                       const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', '').replace('data:image/png;base64,', ''), generateUniqueName: true}
                                       const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                       dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, rainDiverter: [...rainDiverter, ...photos, savedPhotoData]} })

                                       const updatedPhotos = [...photos, savedPhotoData]
                                       setRainDiverter([...rainDiverter, ...updatedPhotos])
                                       photos.push(savedPhotoData)
                                    }
                                    wrapper()
                                 }
                                 wrapSaveImage(blob)
                              });
                           }
                        }}
                     />
                     <GalleryIcon />
                  </IconButton>
               </Stack>
               <Stack direction="row" alignItems="center" spacing={1}>
                  {rainDiverter.map((image, index) => (
                     <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('rainDiverter', true, rainDiverter)}/>
                  ))}
               </Stack>












               <Stack direction="row" alignItems="center" spacing={2}>
                  <Button variant="text" onClick={() => setVents50sqln(Number(vents50sqln)-1) }><RemoveCircleOutlineIcon/></Button>
                  <TextField sx={{width: "250px"}} id="other" label="Turtle Vents" variant="outlined"
                             value={vents50sqln}
                             onChange={(event) => {
                                setVents50sqln(event.target.value)
                             }}
                  />
{/*
                  <TextField sx={{width: "250px"}} id="other" label="50 sqln (750)" variant="outlined"
                             value={vents50sqln}
                             onChange={(event) => {
                                setVents50sqln(event.target.value)
                             }}
                  />
*/}
                  <Button variant="text" onClick={() => setVents50sqln(Number(vents50sqln)+1) }><AddCircleOutlineIcon/></Button>
               </Stack>

               <Stack direction="row" alignItems="center" spacing={2}>
                  <Button variant="text" onClick={() => setGableVents(Number(gableVents)-1) }><RemoveCircleOutlineIcon/></Button>
                  <TextField sx={{width: "250px"}} id="other" label="Gable Vents" variant="outlined"
                             value={gableVents}
                             onChange={(event) => {
                                setGableVents(event.target.value)
                             }}
                  />
                  <Button variant="text" onClick={() => setGableVents(Number(gableVents)+1) }><AddCircleOutlineIcon/></Button>
               </Stack>

               <Stack direction="row" alignItems="center" spacing={2}>
                  <Button variant="text" onClick={() => setPowerVents(Number(powerVents)-1) }><RemoveCircleOutlineIcon/></Button>
                  <TextField sx={{width: "250px"}} id="other" label="Power Vents" variant="outlined"
                             value={powerVents}
                             onChange={(event) => {
                                setPowerVents(event.target.value)
                             }}
                  />
                  <Button variant="text" onClick={() => setPowerVents(Number(powerVents)+1) }><AddCircleOutlineIcon/></Button>
               </Stack>

               <Stack direction="row" alignItems="center" spacing={2}>
                  <Button variant="text" onClick={() => setTurbinesVents(Number(turbinesVents)-1) }><RemoveCircleOutlineIcon/></Button>
                  <TextField sx={{width: "250px"}} id="other" label="Turbines" variant="outlined"
                             value={turbinesVents}
                             onChange={(event) => {
                                setTurbinesVents(event.target.value)
                             }}
                  />
                  <Button variant="text" onClick={() => setTurbinesVents(Number(turbinesVents)+1) }><AddCircleOutlineIcon/></Button>
               </Stack>

               <Stack direction="row" justifyContent="left" alignItems="left"  spacing={2}>
                  <Button variant="text" onClick={() => setSmallVents(Number(smallVents)-1) }><RemoveCircleOutlineIcon/></Button>
                  <TextField sx={{width: "250px"}} id="other" label="Small Exhaust" variant="outlined"
                             value={smallVents}
                             onChange={(event) => {
                                setSmallVents(event.target.value)
                             }}
                  />
                  <Button variant="text" onClick={() => setSmallVents(Number(smallVents)+1) }><AddCircleOutlineIcon/></Button>
               </Stack>

               <Stack direction="row" alignItems="center" spacing={2}>
                  <Button variant="text" onClick={() => setLargeVents(Number(largeVents)-1) }><RemoveCircleOutlineIcon/></Button>
                  <TextField sx={{width: "250px"}} id="other" label="Large Exhaust" variant="outlined"
                             value={largeVents}
                             onChange={(event) => {
                                setLargeVents(event.target.value)
                             }}
                  />
                  <Button variant="text" onClick={() => setLargeVents(Number(largeVents)+1) }><AddCircleOutlineIcon/></Button>
               </Stack>

               <Stack direction="row" justifyContent="left" alignItems="left"  spacing={2}>
                  <Button variant="text" onClick={() => setVents3N1(Number(vents3N1)-1) }><RemoveCircleOutlineIcon/></Button>
                  <TextField sx={{width: "250px"}} id="other" label="3 - N - 1" variant="outlined"
                             value={vents3N1}
                             onChange={(event) => {
                                setVents3N1(event.target.value)
                             }}
                  />
                  <Button variant="text" onClick={() => setVents3N1(Number(vents3N1)+1) }><AddCircleOutlineIcon/></Button>
               </Stack>

               <Stack direction="row" alignItems="center" spacing={2}>
                  <Button variant="text" onClick={() => setLeadTopVents(Number(leadTopVents)-1) }><RemoveCircleOutlineIcon/></Button>
                  <TextField sx={{width: "250px"}} id="other" label='Lead Top' variant="outlined"
                             value={leadTopVents}
                             onChange={(event) => {
                                setLeadTopVents(event.target.value)
                             }}
                  />
                  <Button variant="text" onClick={() => setLeadTopVents(Number(leadTopVents)+1) }><AddCircleOutlineIcon/></Button>
               </Stack>

               <Stack direction="row" alignItems="center" spacing={2}>
                  <Button variant="text" onClick={() => setVents2inch(Number(vents2inch)-1) }><RemoveCircleOutlineIcon/></Button>
                  <TextField sx={{width: "250px"}} id="other" label='2"' variant="outlined"
                             value={vents2inch}
                             onChange={(event) => {
                                setVents2inch(event.target.value)
                             }}
                  />
                  <Button variant="text" onClick={() => setVents2inch(Number(vents2inch)+1) }><AddCircleOutlineIcon/></Button>
               </Stack>

               <Stack direction="row" alignItems="center" spacing={2}>
                  <Button variant="text" onClick={() => setVents3inch(Number(vents3inch)-1) }><RemoveCircleOutlineIcon/></Button>
                  <TextField sx={{width: "250px"}} id="other" label='3"' variant="outlined"
                             value={vents3inch}
                             onChange={(event) => {
                                setVents3inch(event.target.value)
                             }}
                  />
                  <Button variant="text" onClick={() => setVents3inch(Number(vents3inch)+1) }><AddCircleOutlineIcon/></Button>
               </Stack>

               <Stack direction="row" alignItems="center" spacing={2}>
                  <Button variant="text" onClick={() => setVents4inch(Number(vents4inch)-1) }><RemoveCircleOutlineIcon/></Button>
                  <TextField sx={{width: "250px"}} id="other" label='4"' variant="outlined"
                             value={vents4inch}
                             onChange={(event) => {
                                setVents4inch(event.target.value)
                             }}
                  />
                  <Button variant="text" onClick={() => setVents4inch(Number(vents4inch)+1) }><AddCircleOutlineIcon/></Button>
               </Stack>

               <Stack direction="row" alignItems="center" spacing={2}>
                  <Button variant="text" onClick={() => setOtherVents(Number(otherVents)-1) }><RemoveCircleOutlineIcon/></Button>
                  <TextField sx={{width: "250px"}} id="other" label="Other" variant="outlined"
                             value={otherVents}
                             onChange={(event) => {
                                setOtherVents(event.target.value)
                             }}
                  />
                  <Button variant="text" onClick={() => setOtherVents(Number(otherVents)+1) }><AddCircleOutlineIcon/></Button>
               </Stack>


               <Stack direction="row" justifyContent="left" alignItems="left" padding={1}>
                  <Typography variant={"h6"} >Split Boot</Typography>
                  <Stack direction="row" justifyContent="right" alignItems="right" padding={2} sx={{ml: 'auto', width: '200px'}}>
                     <IconButton color="primary" aria-label="upload picture" component="label"
                                 onClick={ () => {
                                    dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'splitBoot', page: 'ventilation'} })
                                 }}>
                        <PhotoCamera />
                     </IconButton>
                     <IconButton color="primary" aria-label="upload picture" component="label">
                        <input
                           type="file" accept="image/*" multiple hidden name="myImage"
                           onChange={(event) => {

                              console.log('event.target.files:')
                              console.log(event.target.files)

                              const files = event.target.files
                              let photos = []

                              //files.forEach((file) => {
                              for (let i = 0; i < files.length; i++) {
                                 reduce.toBlob(files[i], { max: 900 }).then(blob => {
                                    const wrapSaveImage = async (dataUri) => {
                                       //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                                       const wrapper = async () => {
                                          const base64 = await toBase64(dataUri);
                                          const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', ''), generateUniqueName: true}
                                          const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                          dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, splitBoot: [...splitBoot, ...photos, savedPhotoData]} })
                                          //dispatch({ type: 'SET_UPDATE_PHOTO_FOR_JOBSITE' })

                                          const updatedPhotos = [...photos, savedPhotoData]
                                          setSplitBoot([...splitBoot, ...updatedPhotos])
                                          photos.push(savedPhotoData)
                                       }
                                       wrapper()
                                    }
                                    wrapSaveImage(blob)
                                 });
                              }


                           }}
                        />
                        <GalleryIcon />
                     </IconButton>
                  </Stack>

               </Stack>

               <Stack direction="row" alignItems="center" spacing={1}>
                  {splitBoot.map((image, index) => (
                     <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('splitBoot', true, splitBoot)}/>
                  ))}
               </Stack>



               <Stack direction="row" justifyContent="left" alignItems="left" padding={1}>
                  <Typography>Furnice Cap</Typography>
                  <Stack direction="row" justifyContent="right" alignItems="right" padding={2} sx={{ml: 'auto', width: '200px'}}>
                     <IconButton color="primary" aria-label="upload picture" component="label"
                                 onClick={ () => {
                                    dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'furriceCap', page: 'ventilation'} })
                                 }}>
                        <PhotoCamera />
                     </IconButton>
                     <IconButton color="primary" aria-label="upload picture" component="label">
                        <input
                           type="file" accept="image/*" multiple hidden name="myImage"
                           onChange={(event) => {

                              console.log('event.target.files:')
                              console.log(event.target.files)

                              const files = event.target.files
                              let photos = []

                              //files.forEach((file) => {
                              for (let i = 0; i < files.length; i++) {
                                 reduce.toBlob(files[i], { max: 900 }).then(blob => {
                                    const wrapSaveImage = async (dataUri) => {
                                       //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                                       const wrapper = async () => {
                                          const base64 = await toBase64(dataUri);
                                          const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', ''), generateUniqueName: true}
                                          const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                          dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, furriceCap: [...furriceCap, ...photos, savedPhotoData]} })
                                          //dispatch({ type: 'SET_UPDATE_PHOTO_FOR_JOBSITE' })

                                          const updatedPhotos = [...photos, savedPhotoData]
                                          setFurriceCap([...furriceCap, ...updatedPhotos])
                                          photos.push(savedPhotoData)
                                       }
                                       wrapper()
                                    }
                                    wrapSaveImage(blob)
                                 });
                              }


                           }}
                        />
                        <GalleryIcon />
                     </IconButton>
                  </Stack>

               </Stack>

               <Stack direction="row" alignItems="center" spacing={1}>
                  {furriceCap.map((image, index) => (
                     <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('furriceCap', true, furriceCap)}/>
                  ))}
               </Stack>






            </Stack>





         </Box>
      </>

   );
}