import {
   FormControl,
   InputLabel, MenuItem, Select
} from "@mui/material";
import Box from "@mui/material/Box";
import * as React from "react";
import {useContext, useEffect} from "react";
import ECContext from "../../context/inspections/ECContext";
import ElevationFrontLeft from "./elevations/elevationFrontLeft";
import ElevationFront from "./elevations/elevationFront";
import ElevationFrontRight from "./elevations/elevationFrontRight";
import ElevationRight from "./elevations/elevationRight";
import ElevationBackRight from "./elevations/elevationBackRight";
import ElevationBack from "./elevations/elevationBack";
import ElevationBackLeft from "./elevations/elevationBackLeft";
import ElevationLeft from "./elevations/elevationLeft";
import RoofField from "./roof/roofField";
import RoofVentilation from "./roof/roofVentilation";
import RoofSkyLights from "./roof/roofSkyLights";
import RoofTestSquares from "./roof/roofTestSquares";
import RoofPhotos from "./roof/roofPhotos";
import RoofMeasurements from "./roof/roofMeasurements";

export default function Roof() {

   const { inspectionRoofBlockInfo, dispatch } = useContext(ECContext)
   const [show, setShow] = React.useState(false);
   const [roofSection, setRoofSection] = React.useState(10);


   useEffect(() => {

      setShow(inspectionRoofBlockInfo.showBlock)
      dispatch({ type: 'SET_INSPECTION_ROOF_SUB_DATA', payload: {inspectionId: 99, elevationType: "roofField", showBlock: true} })

   }, [inspectionRoofBlockInfo]);


   const hideAllElevationSubSections = () => {

      dispatch({ type: 'SET_INSPECTION_ROOF_SUB_DATA', payload: {inspectionId: 99, elevationType: "", showBlock: false} })
   }


   const handleRoofSectionChange = (event) => {

      const selectedRoofSection = event.target.value

      setRoofSection(selectedRoofSection);

console.log("handleRoofSectionChange! :")
console.log(`selectedRoofSection: ${selectedRoofSection}`)


      hideAllElevationSubSections()

      // turning on the selected elevation sub_section
      switch (selectedRoofSection) {
         case 5:
            dispatch({ type: 'SET_INSPECTION_ROOF_SUB_DATA', payload: {inspectionId: 99, elevationType: "measurements", showBlock: true} })
            break
         case 10:
            dispatch({ type: 'SET_INSPECTION_ROOF_SUB_DATA', payload: {inspectionId: 99, elevationType: "roofField", showBlock: true} })
            break
         case 20:
            dispatch({ type: 'SET_INSPECTION_ROOF_SUB_DATA', payload: {inspectionId: 99, elevationType: "ventilation", showBlock: true} })
            break
         case 30:
            dispatch({ type: 'SET_INSPECTION_ROOF_SUB_DATA', payload: {inspectionId: 99, elevationType: "skylights", showBlock: true} })
            break
         case 40:
            dispatch({ type: 'SET_INSPECTION_ROOF_SUB_DATA', payload: {inspectionId: 99, elevationType: "testSquares", showBlock: true} })
            break
         case 50:
            dispatch({ type: 'SET_INSPECTION_ROOF_SUB_DATA', payload: {inspectionId: 99, elevationType: "photos", showBlock: true} })
            break
         default:
            dispatch({ type: 'SET_INSPECTION_ROOF_SUB_DATA', payload: {inspectionId: 99, elevationType: "deck", showBlock: true} })
      }
   }



   return (
      <>

      <Box sx={{ minWidth: 120, display: show === true ? 'flex' : 'none', mt: 2, p: 0, gap: 0, mx: 'auto', width: '100%' }}>
         <FormControl fullWidth variant="filled">
            <InputLabel id="roof-section-select-label">Roof Sections</InputLabel>
            <Select
               labelId="roof-section-select-label"
               id="roof-section-select"
               value={roofSection}
               label="Roof sections"
               onChange={handleRoofSectionChange}
            >
               {/*<MenuItem value={5}>Measurements</MenuItem>*/}
               <MenuItem value={10}>Roof Field</MenuItem>
{/*               <MenuItem value={20}>Ventilation</MenuItem>*/}
               <MenuItem value={30}>Sky Lights</MenuItem>
{/*               <MenuItem value={40}>Test Squares</MenuItem>*/}
{/*               <MenuItem value={50}>Photos</MenuItem>*/}


            </Select>
         </FormControl>
      </Box>

      <Box sx={{mx: 'auto', mt: 2, width: '100%', display: show === true ? 'flex' : 'none', flexDirection: 'column'}}>

         <RoofField/>
         {/*<RoofVentilation/>*/}
         <RoofSkyLights/>
         {/*<RoofTestSquares/>*/}
         {/*<RoofPhotos/>*/}



      </Box>

      </>
   );
}