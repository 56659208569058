import {
   Accordion,
   AccordionDetails,
   AccordionSummary, Autocomplete,
   Button, Checkbox, Drawer,
   FormControl,
   FormControlLabel,
   FormGroup, FormLabel, ImageList, ImageListItem, InputLabel, MenuItem, Radio, RadioGroup, Select,
   Stack, TextField
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import {PhotoCamera} from "@mui/icons-material";
import Box from "@mui/material/Box";
import AssistantDirectionIcon from "@mui/icons-material/AssistantDirection";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import * as React from "react";
import {useContext, useEffect} from "react";
import ECContext from "../../context/inspections/ECContext";
import reduce from "image-blob-reduce";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import {getImageUrl, savePhotoIntoFolder} from "../../context/inspections/ECActions";
import GalleryIcon from "@mui/icons-material/CollectionsOutlined";
import {Image} from "mui-image";

export default function Siding() {

   const { showCameraFor, flagNeedToUpdatePhotosForSidingBlock, access_token_data, inspectionAllData, flagReloadDatafromInspectionAllData, flagCleanAllControlsData, saveRequest, inspectionSidingBlockInfo, dispatch } = useContext(ECContext)
   const [show, setShow] = React.useState(false);
   const reduce = require('image-blob-reduce')();

   const [sidingType, setSidingType] = React.useState('');
   const [sidingTypePhotos, setSidingTypePhotos] = React.useState([]);
   const [sidingProfile, setSidingProfile] = React.useState('');
   const [manufacturer, setManufacturer] = React.useState('');
   const [color, setColor] = React.useState('');
   const [profileSize, setProfileSize] = React.useState('');
   const [sickNess, setSickNess] = React.useState('');
   const [reveal, setReveal] = React.useState('');
   const [lengthOfPanels, setLengthOfPanels] = React.useState('');

   const [sidingProfilePhotos, setSidingProfilePhotos] = React.useState([]);
   const [sidingAccessoriesPhotos, setSidingAccessoriesPhotos] = React.useState([]);

   const [sidingAccessoriesOutlets, setSidingAccessoriesOutlets] = React.useState('');
   const [sidingAccessoriesLightFixtures, setSidingAccessoriesLightFixtures] = React.useState('');
   const [sidingAccessoriesPowerMastMeter, setSidingAccessoriesPowerMastMeter] = React.useState('');
   const [sidingAccessoriesACcableSpitos, setSidingAccessoriesACcableSpitos] = React.useState('');
   const [sidingAccessoriesDryerVents, setSidingAccessoriesDryerVents] = React.useState('');
   const [sidingAccessoriesDeck, setSidingAccessoriesDeck] = React.useState('');
   const [sidingAccessoriesFlashing, setSidingAccessoriesFlashing] = React.useState('');
   const [sidingAccessoriesTreeBushObstruction, setSidingAccessoriesTreeBushObstruction] = React.useState('');
   const [sidingAccessoriesFanFoldInsualtion, setSidingAccessoriesFanFoldInsualtion] = React.useState('');
   const [sidingNotes, setSidingNotes] = React.useState('');



   useEffect(() => {

      setShow(inspectionSidingBlockInfo.showBlock)
   }, [inspectionSidingBlockInfo]);

   useEffect(() => {
      setSidingType('');
      setSidingTypePhotos([]);
      setSidingProfile('');
      setManufacturer('');
      setColor('');
      setProfileSize('');
      setSickNess('');
      setReveal('');
      setLengthOfPanels('');
      setSidingAccessoriesOutlets('');
      setSidingAccessoriesLightFixtures('');
      setSidingAccessoriesPowerMastMeter('');
      setSidingAccessoriesACcableSpitos('');
      setSidingAccessoriesDryerVents('');
      setSidingAccessoriesDeck('');
      setSidingAccessoriesFlashing('');
      setSidingAccessoriesTreeBushObstruction('');
      setSidingAccessoriesFanFoldInsualtion('');
      setSidingNotes('');
   }, [flagCleanAllControlsData]);

   useEffect(() => {


      if(flagNeedToUpdatePhotosForSidingBlock == true) {
         dispatch({ type: 'DROP_UPDATE_PHOTO_FOR_SIDING' })

         if(inspectionAllData.hasOwnProperty('sidingTypePhotos') && showCameraFor.property == 'sidingTypePhotos') {
            setSidingTypePhotos([
               ...inspectionAllData.sidingTypePhotos]
            )
         }
         if(inspectionAllData.hasOwnProperty('sidingProfilePhotos') && showCameraFor.property == 'sidingProfilePhotos') {
            setSidingProfilePhotos([
               ...inspectionAllData.sidingProfilePhotos]
            )
         }
         if(inspectionAllData.hasOwnProperty('sidingAccessoriesPhotos') && showCameraFor.property == 'sidingAccessoriesPhotos') {
            setSidingAccessoriesPhotos([
               ...inspectionAllData.sidingAccessoriesPhotos]
            )
         }



      }

   }, [flagNeedToUpdatePhotosForSidingBlock]);

   useEffect(() => {

      if(saveRequest === true) {
         //dispatch({ type: 'DROP_SAVE_REQUEST' })

         const transformSavingData = (prop) => {

            return prop.map((fileData, index) => {
               if(fileData.IS_NEW == true) {
                  prop[index].IS_NEW = false
                  return fileData.FILE_ID
               }
            }).filter(x => x !== undefined)
         }


         let sidingBlockData = {

            PROPERTY_1053: lengthOfPanels,   //Siding.Length of Panels
            PROPERTY_1051: reveal,           //Siding.Reveal
            PROPERTY_1049: sickNess,         //Siding.Sickness
            PROPERTY_1047: profileSize,      //Siding.Profile Size
            PROPERTY_1045: color,            //Siding.Color
            PROPERTY_1043: manufacturer,     //Siding.Manufacturer
            PROPERTY_1041: sidingProfile,    //Siding.Sidng Profile
            PROPERTY_1039: sidingType,       //Siding.Siding Type
            PROPERTY_1257: transformSavingData(sidingTypePhotos),
            PROPERTY_1259: transformSavingData(sidingProfilePhotos),                   // +Siding.Siding Profile.Photos
            PROPERTY_1261: transformSavingData(sidingAccessoriesPhotos),                   // +Siding.Accessories.Photos
            PROPERTY_1263: sidingAccessoriesOutlets,                   //Siding.Accessories.Outlets
            PROPERTY_1265: sidingAccessoriesLightFixtures,                   //Siding.Accessories.Light fixtures
            PROPERTY_1267: sidingAccessoriesPowerMastMeter,                   //Siding.Accessories.Power mast/meter
            PROPERTY_1269: sidingAccessoriesACcableSpitos,                   //Siding.Accessories.AC/Cable/Spitos
            PROPERTY_1271: sidingAccessoriesDryerVents,                   //Siding.Accessories.Dryer vent(s)
            PROPERTY_1273: sidingAccessoriesDeck,                   //Siding.Accessories.Deck
            PROPERTY_1275: sidingAccessoriesFlashing,                   //Siding.Accessories.Flashing
            PROPERTY_1277: sidingAccessoriesTreeBushObstruction,                   //Siding.Accessories.Tree/bush obstruction
            PROPERTY_1279: sidingAccessoriesFanFoldInsualtion,                   //Siding.Accessories.Fan fold insualtion
            PROPERTY_1331: sidingNotes, //Siding.Notes

            NO_PHOTOS_DATA: {
               "Length of Panels": lengthOfPanels,   //Siding.Length of Panels
               "Reveal": reveal,           //Siding.Reveal
               "Sickness": sickNess,         //Siding.Sickness
               "Profile Size": profileSize,      //Siding.Profile Size
               "Color": color,            //Siding.Color
               "Manufacturer": manufacturer,     //Siding.Manufacturer
               "Sidng Profile": sidingProfile,    //Siding.Sidng Profile
               "Siding Type": sidingType,       //Siding.Siding Type
               "Accessories.Outlets": sidingAccessoriesOutlets,                   //Siding.Accessories.Outlets
               "Accessories.Light fixtures": sidingAccessoriesLightFixtures,                   //Siding.Accessories.Light fixtures
               "Accessories.Power mast/meter": sidingAccessoriesPowerMastMeter,                   //Siding.Accessories.Power mast/meter
               "Accessories.AC/Cable/Spitos": sidingAccessoriesACcableSpitos,                   //Siding.Accessories.AC/Cable/Spitos
               "Accessories.Dryer vent(s)": sidingAccessoriesDryerVents,                   //Siding.Accessories.Dryer vent(s)
               "Accessories.Deck": sidingAccessoriesDeck,                   //Siding.Accessories.Deck
               "Accessories.Flashing": sidingAccessoriesFlashing,                   //Siding.Accessories.Flashing
               "Accessories.Tree/bush obstruction": sidingAccessoriesTreeBushObstruction,                   //Siding.Accessories.Tree/bush obstruction
               "Accessories.Fan fold insualtion": sidingAccessoriesFanFoldInsualtion                   //Siding.Accessories.Fan fold insualtion
            }

         }

         dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA_WITH_SIDING_BLOCK', payload: sidingBlockData } )

         // triggering Save in InspectionEdit component
         dispatch({ type: 'SET_SIDING_READY_TO_SAVE_FLAG' })

      }
   }, [saveRequest]);

   useEffect(() => {

      if(!inspectionAllData.hasOwnProperty('PROPERTY_799' )) return;

      console.log('RELOADING data... in siding.jsx')

      setLengthOfPanels(inspectionAllData.PROPERTY_1053)
      setLengthOfPanels(inspectionAllData.PROPERTY_1053)
      setReveal(inspectionAllData.PROPERTY_1051)
      setSickNess(inspectionAllData.PROPERTY_1049)
      setProfileSize(inspectionAllData.PROPERTY_1047)
      setColor(inspectionAllData.PROPERTY_1045)
      setManufacturer(inspectionAllData.PROPERTY_1043)
      setSidingProfile(inspectionAllData.PROPERTY_1041)
      setSidingType(inspectionAllData.PROPERTY_1039)

      setSidingAccessoriesOutlets(inspectionAllData.PROPERTY_1263)
      setSidingAccessoriesLightFixtures(inspectionAllData.PROPERTY_1265)
      setSidingAccessoriesPowerMastMeter(inspectionAllData.PROPERTY_1267)
      setSidingAccessoriesACcableSpitos(inspectionAllData.PROPERTY_1269)
      setSidingAccessoriesDryerVents(inspectionAllData.PROPERTY_1271)
      setSidingAccessoriesDeck(inspectionAllData.PROPERTY_1273)
      setSidingAccessoriesFlashing(inspectionAllData.PROPERTY_1275)
      setSidingAccessoriesTreeBushObstruction(inspectionAllData.PROPERTY_1277)
      setSidingAccessoriesFanFoldInsualtion(inspectionAllData.PROPERTY_1279)
      setSidingNotes(inspectionAllData.PROPERTY_1331)

      const sidingTypePhotos = async () => {
         if(inspectionAllData.hasOwnProperty('sidingTypePhotos' )) {
            if(inspectionAllData.sidingTypePhotos.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.sidingTypePhotos.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.sidingTypePhotos[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'FILE_ID': inspectionAllData.sidingTypePhotos[i].FILE_ID })
               }
               setSidingTypePhotos(images)
               dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                  payload: {...inspectionAllData,
                     'sidingTypePhotos': images
                  }})
            }
         }
      }
      sidingTypePhotos()

      const sidingProfilePhotos = async () => {
         if(inspectionAllData.hasOwnProperty('sidingProfilePhotos' )) {
            if(inspectionAllData.sidingProfilePhotos.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.sidingProfilePhotos.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.sidingProfilePhotos[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'FILE_ID': inspectionAllData.sidingProfilePhotos[i].FILE_ID })
               }
               setSidingProfilePhotos(images)
               dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                  payload: {...inspectionAllData,
                     'sidingProfilePhotos': images
                  }})
            }
         }
      }
      sidingProfilePhotos()

      const sidingAccessoriesPhotos = async () => {
         if(inspectionAllData.hasOwnProperty('sidingAccessoriesPhotos' )) {
            if(inspectionAllData.sidingAccessoriesPhotos.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.sidingAccessoriesPhotos.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.sidingAccessoriesPhotos[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'FILE_ID': inspectionAllData.sidingAccessoriesPhotos[i].FILE_ID })
               }
               setSidingAccessoriesPhotos(images)
               dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                  payload: {...inspectionAllData,
                     'sidingAccessoriesPhotos': images
                  }})
            }
         }
      }
      sidingAccessoriesPhotos()


   }, [flagReloadDatafromInspectionAllData]);



   const toBase64 = file => new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
   });


   const [drawerVar, setDrawerVar] = React.useState([])
   const [drawerVarName, setDrawerVarName] = React.useState('')
   const [drawerSetter, setDrawerSetter] = React.useState('')
   const [state, setState] = React.useState({top: false, left: false, bottom: false, right: false,});

   const toggleDrawer = (anchor, open, photosValues) => (event) => {
      if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
         return;
      }

      setDrawerVar(photosValues)
      setDrawerVarName(anchor)

      setState({ ...state, [anchor]: open });
   };

   const list = (drawerVar) => (
      <Box
         sx={{ width: '400px' }}
         role="presentation"
         onClick={toggleDrawer(drawerVarName, false, drawerVar)}
         onKeyDown={toggleDrawer(drawerVarName, false,drawerVar)}
      >

         <Stack direction="row" justifyContent="center" alignItems="center" padding={2}>
            <Button variant="text" onClick={toggleDrawer(drawerVarName, false, drawerVar)}>Back</Button>
         </Stack>


         <ImageList sx={{ width: '400px' }} cols={1} >
            {drawerVar.map((item) => (
               <ImageListItem key={item.DETAIL_URL}>
                  <img
                     src={`${item.DETAIL_URL}`}
                     srcSet={`${item.DETAIL_URL}`}
                     loading="lazy"
                  />

                  <Button
                     disableElevation={true}
                     value={item.FILE_ID}
                     onClick={ (event) => {
                        event.stopPropagation()

                        const updatedPhotos = drawerVar.filter( photo1 => photo1.FILE_ID != event.target.value )


                        if(drawerVarName == "sidingTypePhotos") {
                           setSidingTypePhotos(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }
                        if(drawerVarName == "sidingProfilePhotos") {
                           setSidingProfilePhotos(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }
                        if(drawerVarName == "sidingAccessoriesPhotos") {
                           setSidingAccessoriesPhotos(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }


                        let tmp = inspectionAllData
                        const updatedPhotos2 = tmp[drawerVarName].filter( photo1 => photo1.FILE_ID != event.target.value )
                        tmp[drawerVarName] = updatedPhotos2

                        dispatch({ type: 'SET_INSPECTION_ALL_DATA', payload: tmp } )
                     }}>Delete
                  </Button>

                  <br/>
               </ImageListItem>
            ))}
         </ImageList>
      </Box>
   );


   return (

      <Stack
         direction="column"
         justifyContent="left"
         alignItems="left"
         padding={2}
         spacing={1}
         sx={{ display: show === true ? 'flex' : 'none' }}
      >

         <Stack direction="row" alignItems="center" spacing={2}>
            <React.Fragment key={'right'} ><Drawer anchor={'right'} open={state[drawerVarName]} onClose={toggleDrawer(drawerVarName, false, drawerVar)} sx={{zIndex: 9999}}>{list(drawerVar)}</Drawer></React.Fragment>
            <FormControl>
               <FormLabel id="siding-type-radio-buttons-group-label">Siding Type</FormLabel>
               <RadioGroup
                  row
                  aria-labelledby="siding-type-radio-buttons-group-label"
                  name="siding-type-buttons-group"
                  value={sidingType}
                  onChange={(event) => {
                     setSidingType(event.target.value)
                  }}
               >
                  <FormControlLabel value="261" control={<Radio />} label="Vinyl" />
                  <FormControlLabel value="263" control={<Radio />} label="Steel" />
                  <FormControlLabel value="265" control={<Radio />} label="Wood" />
                  <FormControlLabel value="267" control={<Radio />} label="Aluminum" />
                  <FormControlLabel value="269" control={<Radio />} label="Fiber Cement / Hardie" />
                  <FormControlLabel value="271" control={<Radio />} label="Wood Compsite" />
                  <FormControlLabel value="327" control={<Radio />} label="LP Smart Side" />
               </RadioGroup>
            </FormControl>
            <IconButton color="primary" aria-label="upload picture" component="label"
                        onClick={ () => {
                           dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'sidingTypePhotos', page: 'siding'} })
                        }}>
               <PhotoCamera />
            </IconButton>
            <IconButton color="primary" aria-label="upload picture" component="label">
               <input
                  type="file" accept="image/*" multiple hidden name="myImage"
                  onChange={(event) => {

                     const files = event.target.files
                     let photos = []

                     //files.forEach((file) => {
                     for (let i = 0; i < files.length; i++) {
                        reduce.toBlob(files[i], { max: 900 }).then(blob => {
                           const wrapSaveImage = async (dataUri) => {
                              //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                              const wrapper = async () => {
                                 const base64 = await toBase64(dataUri);
                                 const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', '').replace('data:image/png;base64,', ''), generateUniqueName: true}
                                 const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                 dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, sidingTypePhotos: [...sidingTypePhotos, ...photos, savedPhotoData]} })

                                 const updatedPhotos = [...photos, savedPhotoData]
                                 setSidingTypePhotos([...sidingTypePhotos, ...updatedPhotos])
                                 photos.push(savedPhotoData)
                              }
                              wrapper()
                           }
                           wrapSaveImage(blob)
                        });
                     }
                  }}
               />
               <GalleryIcon />
            </IconButton>
         </Stack>
         <Stack direction="row" alignItems="center" spacing={1}>
            {sidingTypePhotos.map((image, index) => (
               <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('sidingTypePhotos', true, sidingTypePhotos)}/>
            ))}
         </Stack>


         <Stack direction="row" alignItems="center" spacing={2}>
            <FormControl>
               <FormLabel id="siding-type-radio-buttons-group-label">Siding Profile</FormLabel>
               <RadioGroup
                  row
                  aria-labelledby="siding-profile-radio-buttons-group-label"
                  name="siding-profile-buttons-group"
                  value={sidingProfile}
                  onChange={(event) => {
                     setSidingProfile(event.target.value)
                  }}
               >
                  <FormControlLabel value="271" control={<Radio />} label="Board&Batten" />
                  <FormControlLabel value="275" control={<Radio />} label="Shake" />
                  <FormControlLabel value="277" control={<Radio />} label="Dutch Lap" />
                  <FormControlLabel value="279" control={<Radio />} label="Traditional / Lap" />
               </RadioGroup>
            </FormControl>
            <IconButton color="primary" aria-label="upload picture" component="label"
                        onClick={ () => {
                           dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'sidingProfilePhotos', page: 'siding'} })
                        }}>
               <PhotoCamera />
            </IconButton>
            <IconButton color="primary" aria-label="upload picture" component="label">
               <input
                  type="file" accept="image/*" multiple hidden name="myImage"
                  onChange={(event) => {

                     const files = event.target.files
                     let photos = []

                     //files.forEach((file) => {
                     for (let i = 0; i < files.length; i++) {
                        reduce.toBlob(files[i], { max: 900 }).then(blob => {
                           const wrapSaveImage = async (dataUri) => {
                              //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                              const wrapper = async () => {
                                 const base64 = await toBase64(dataUri);
                                 const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', '').replace('data:image/png;base64,', ''), generateUniqueName: true}
                                 const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                 dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, sidingProfilePhotos: [...sidingProfilePhotos, ...photos, savedPhotoData]} })

                                 const updatedPhotos = [...photos, savedPhotoData]
                                 setSidingProfilePhotos([...sidingProfilePhotos, ...updatedPhotos])
                                 photos.push(savedPhotoData)
                              }
                              wrapper()
                           }
                           wrapSaveImage(blob)
                        });
                     }
                  }}
               />
               <GalleryIcon />
            </IconButton>
         </Stack>
         <Stack direction="row" alignItems="center" spacing={1}>
            {sidingProfilePhotos.map((image, index) => (
               <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('sidingProfilePhotos', true, sidingProfilePhotos)}/>
            ))}
         </Stack>


         <TextField sx={{width: "100%"}} id="manufacturer" label="Manufacturer" variant="outlined"
            value={manufacturer}
            onChange={(event) => {
              setManufacturer(event.target.value)
            }}
         />
         <TextField sx={{width: "100%"}} id="color" label="Color" variant="outlined"
            value={color}
            onChange={(event) => {
              setColor(event.target.value)
            }}
         />

         <Stack direction="row" alignItems="center" spacing={2}>
            <Button variant="text" onClick={() => setProfileSize(Number(profileSize)-1) } ><RemoveCircleOutlineIcon/></Button>
            <TextField sx={{width: "250px"}} id="other" label="Profile Size" variant="outlined"
                       value={profileSize}
                       onChange={(event) => {
                          setProfileSize(event.target.value)
                       }}
            />
            <Button variant="text" onClick={() => setProfileSize(Number(profileSize)+1) } ><AddCircleOutlineIcon/></Button>
         </Stack>

         <Stack direction="row" alignItems="center" spacing={2}>
            <Button variant="text" onClick={() => setSickNess(Number(sickNess)-1) }><RemoveCircleOutlineIcon/></Button>
            <TextField sx={{width: "250px"}} id="other" label="Thickness" variant="outlined"
                       value={sickNess}
                       onChange={(event) => {
                          setSickNess(event.target.value)
                       }}
            />
            <Button variant="text" onClick={() => setSickNess(Number(sickNess)+1) } ><AddCircleOutlineIcon/></Button>
         </Stack>

         <Stack direction="row" alignItems="center" spacing={2}>
            <Button variant="text" onClick={() => setReveal(Number(reveal)-1) } ><RemoveCircleOutlineIcon/></Button>
            <TextField sx={{width: "250px"}} id="other" label="Reveal" variant="outlined"
                       value={reveal}
                       onChange={(event) => {
                          setReveal(event.target.value)
                       }}
            />
            <Button variant="text" onClick={() => setReveal(Number(reveal)+1) } ><AddCircleOutlineIcon/></Button>
         </Stack>

         <Stack direction="row" alignItems="center" spacing={2}>
            <Button variant="text" onClick={() => setLengthOfPanels(Number(lengthOfPanels)-1) } ><RemoveCircleOutlineIcon/></Button>
            <TextField sx={{width: "250px"}} id="other" label="Length of Panels" variant="outlined"
                       value={lengthOfPanels}
                       onChange={(event) => {
                          setLengthOfPanels(event.target.value)
                       }}
            />
            <Button variant="text" onClick={() => setLengthOfPanels(Number(lengthOfPanels)+1) }><AddCircleOutlineIcon/></Button>
         </Stack>

         <Stack direction="row" alignItems="center" spacing={2}>
            <Typography sx={{my: 'auto'}}>Accessories</Typography>
            <IconButton color="primary" aria-label="upload picture" component="label"
                        onClick={ () => {
                           dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'sidingAccessoriesPhotos', page: 'siding'} })
                        }}>
               <PhotoCamera />
            </IconButton>
            <IconButton color="primary" aria-label="upload picture" component="label">
               <input
                  type="file" accept="image/*" multiple hidden name="myImage"
                  onChange={(event) => {

                     const files = event.target.files
                     let photos = []

                     //files.forEach((file) => {
                     for (let i = 0; i < files.length; i++) {
                        reduce.toBlob(files[i], { max: 900 }).then(blob => {
                           const wrapSaveImage = async (dataUri) => {
                              //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                              const wrapper = async () => {
                                 const base64 = await toBase64(dataUri);
                                 const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', '').replace('data:image/png;base64,', ''), generateUniqueName: true}
                                 const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                 dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, sidingAccessoriesPhotos: [...sidingAccessoriesPhotos, ...photos, savedPhotoData]} })

                                 const updatedPhotos = [...photos, savedPhotoData]
                                 setSidingAccessoriesPhotos([...sidingAccessoriesPhotos, ...updatedPhotos])
                                 photos.push(savedPhotoData)
                              }
                              wrapper()
                           }
                           wrapSaveImage(blob)
                        });
                     }
                  }}
               />
               <GalleryIcon />
            </IconButton>
         </Stack>
         <Stack direction="row" alignItems="center" spacing={1}>
            {sidingAccessoriesPhotos.map((image, index) => (
               <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('sidingAccessoriesPhotos', true, sidingAccessoriesPhotos)}/>
            ))}
         </Stack>

         <Stack direction="row" alignItems="center" spacing={2}>
            <TextField sx={{width: "250px"}} id="other" label="Outlets" variant="outlined"
                       value={sidingAccessoriesOutlets}
                       onChange={(event) => {
                          setSidingAccessoriesOutlets(event.target.value)
                       }}
            />
         </Stack>
         <Stack direction="row" alignItems="center" spacing={2}>
            <TextField sx={{width: "250px"}} id="other" label="Light fixtures" variant="outlined"
                       value={sidingAccessoriesLightFixtures}
                       onChange={(event) => {
                          setSidingAccessoriesLightFixtures(event.target.value)
                       }}
            />
         </Stack>
         <Stack direction="row" alignItems="center" spacing={2}>
            <TextField sx={{width: "250px"}} id="other" label="Power mast/meter" variant="outlined"
                       value={sidingAccessoriesPowerMastMeter}
                       onChange={(event) => {
                          setSidingAccessoriesPowerMastMeter(event.target.value)
                       }}
            />
         </Stack>
         <Stack direction="row" alignItems="center" spacing={2}>
            <TextField sx={{width: "250px"}} id="other" label="AC/Cable/Spigot" variant="outlined"
                       value={sidingAccessoriesACcableSpitos}
                       onChange={(event) => {
                          setSidingAccessoriesACcableSpitos(event.target.value)
                       }}
            />
         </Stack>
         <Stack direction="row" alignItems="center" spacing={2}>
            <TextField sx={{width: "250px"}} id="other" label="Dryer vent(s)" variant="outlined"
                       value={sidingAccessoriesDryerVents}
                       onChange={(event) => {
                          setSidingAccessoriesDryerVents(event.target.value)
                       }}
            />
         </Stack>
         <Stack direction="row" alignItems="center" spacing={2}>
            <TextField sx={{width: "250px"}} id="other" label="Deck" variant="outlined"
                       value={sidingAccessoriesDeck}
                       onChange={(event) => {
                          setSidingAccessoriesDeck(event.target.value)
                       }}
            />
         </Stack>
         <Stack direction="row" alignItems="center" spacing={2}>
            <TextField sx={{width: "250px"}} id="other" label="Flashing" variant="outlined"
                       value={sidingAccessoriesFlashing}
                       onChange={(event) => {
                          setSidingAccessoriesFlashing(event.target.value)
                       }}
            />
         </Stack>
         <Stack direction="row" alignItems="center" spacing={2}>
            <TextField sx={{width: "250px"}} id="other" label="Tree/bush obstruction" variant="outlined"
                       value={sidingAccessoriesTreeBushObstruction}
                       onChange={(event) => {
                          setSidingAccessoriesTreeBushObstruction(event.target.value)
                       }}
            />
         </Stack>
         <Stack direction="row" alignItems="center" spacing={2}>
            <TextField sx={{width: "250px"}} id="other" label="Fan fold insualtion" variant="outlined"
                       value={sidingAccessoriesFanFoldInsualtion}
                       onChange={(event) => {
                          setSidingAccessoriesFanFoldInsualtion(event.target.value)
                       }}
            />
         </Stack>
         <Stack direction="row" alignItems="center" spacing={2}>
            <TextField sx={{width: "250px"}} id="other" label="Notes" variant="outlined"
                       value={sidingNotes}
                       onChange={(event) => {
                          setSidingNotes(event.target.value)
                       }}
            />
         </Stack>


      </Stack>

   );
}