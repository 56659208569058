import {
   Button, Checkbox, Drawer,
   FormControl,
   FormControlLabel, FormGroup,
   FormLabel, ImageList, ImageListItem, ListItemButton, ListItemIcon, Radio, RadioGroup, Stack,
   TextField
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {PhotoCamera} from "@mui/icons-material";
import Box from "@mui/material/Box";
import * as React from "react";
import {useContext, useEffect} from "react";
import ECContext from "../../context/inspections/ECContext";
import { Image } from 'mui-image'
import spinner from "../layout/assets/loading.gif";
import {getImageUrl, savePhotoIntoFolder} from "../../context/inspections/ECActions";
import GalleryIcon from "@mui/icons-material/CollectionsOutlined";
import reduce from "image-blob-reduce";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";


export default function Windows() {

   const { showCameraFor, flagReloadDatafromInspectionAllData, flagCleanAllControlsData, flagNeedToUpdatePhotosForWindowsBlock, inspectionWindowsBlockInfo, inspectionAllData, dispatch, showCamera, saveRequest, editInspectionId, inspections, access_token_data } = useContext(ECContext)
   const [show, setShow] = React.useState(false);
   const reduce = require('image-blob-reduce')();



   const [additionalNotes, setAdditionalNotes] = React.useState('');
   const [coverings, setCoverings] = React.useState('');
   const [coveringsPhotos, setCoveringsPhotos] = React.useState([]);
   const [interior, setInterior] = React.useState('');
   const [interiorPhotos, setInteriorPhotos] = React.useState([]);
   const [style, setStyle] = React.useState('');
   const [exterior, setExterior] = React.useState('');
   const [measurementsSash, setMeasurementsSash] = React.useState('');
   const [measurementsFullFrame, setMeasurementsFullFrame] = React.useState('');
   const [manufacturerMarkings, setManufacturerMarkings] = React.useState('');
   const [manufacturerMarkingsPhotos, setManufacturerMarkingsPhotos] = React.useState([]);
   const [closeUpOfWindowsDamagePhotos, setCloseUpOfWindowsDamagePhotos] = React.useState([]);
   const [closeUpOfWindowsDamage, setCloseUpOfWindowsDamage] = React.useState('');
   const [withEffectedWindowCircled, setWithEffectedWindowCircled] = React.useState('');
   const [elevationsPhotos, setElevationsPhotos] = React.useState([]);
   

   useEffect(() => {

      setShow(inspectionWindowsBlockInfo.showBlock)
   }, [inspectionWindowsBlockInfo]);

   useEffect(() => {

      setAdditionalNotes('');
      setCoverings('');
      setCoveringsPhotos([]);
      setInterior('');
      setInteriorPhotos([]);
      setStyle('');
      setExterior('');
      setMeasurementsSash('');
      setMeasurementsFullFrame('');
      setManufacturerMarkings('');
      setManufacturerMarkingsPhotos([]);
      setCloseUpOfWindowsDamagePhotos([]);
      setCloseUpOfWindowsDamage('');
      setWithEffectedWindowCircled('');
      setElevationsPhotos([]);
   }, [flagCleanAllControlsData]);

   useEffect(() => {


      if(flagNeedToUpdatePhotosForWindowsBlock == true) {
         dispatch({ type: 'DROP_UPDATE_PHOTO_FOR_WINDOWS' })

         // console.log("We're in Windows. Updating the photos after we shoot them. inspectionAllData:")
         // console.log(inspectionAllData)
         // console.log("photosDoesTheJobSideHaveRemoteAccess:")
         // console.log(photosDoesTheJobSideHaveRemoteAccess)

         if(inspectionAllData.hasOwnProperty('coveringsPhotos') && showCameraFor.property == 'coveringsPhotos') {
            setCoveringsPhotos([
               ...inspectionAllData.coveringsPhotos]
            )
         }
         if(inspectionAllData.hasOwnProperty('interiorPhotos') && showCameraFor.property == 'interiorPhotos') {
            setInteriorPhotos([
               ...inspectionAllData.interiorPhotos]
            )
         }
         if(inspectionAllData.hasOwnProperty('manufacturerMarkingsPhotos') && showCameraFor.property == 'manufacturerMarkingsPhotos') {
            setManufacturerMarkingsPhotos([
               ...inspectionAllData.manufacturerMarkingsPhotos]
            )
         }
         if(inspectionAllData.hasOwnProperty('closeUpOfWindowsDamagePhotos') && showCameraFor.property == 'closeUpOfWindowsDamagePhotos') {
            setCloseUpOfWindowsDamagePhotos([
               ...inspectionAllData.closeUpOfWindowsDamagePhotos]
            )
         }
         if(inspectionAllData.hasOwnProperty('elevationsPhotos') && showCameraFor.property == 'elevationsPhotos') {
            setElevationsPhotos([
               ...inspectionAllData.elevationsPhotos]
            )
         }




      }

   }, [flagNeedToUpdatePhotosForWindowsBlock]);

   useEffect(() => {

                  const transformSavingData = (prop) => {

                     return prop.map((fileData, index) => {
                        if(fileData.IS_NEW == true) {
                           prop[index].IS_NEW = false
                           return fileData.FILE_ID
                        }
                     }).filter(x => x !== undefined)
                  }


      if(saveRequest === true) {

         let windowsBlockData = {


            PROPERTY_1143: additionalNotes, //windows.Additional Notes
            PROPERTY_1141: coverings, //windows.Window Coverings
            PROPERTY_1139: transformSavingData(coveringsPhotos), //windows.Window Coverings Photos
            PROPERTY_1137: interior, //windows.Interior
            PROPERTY_1135: transformSavingData(interiorPhotos),  //windows.Interior Photos
            PROPERTY_1133: style, //windows.Style
            PROPERTY_1131: exterior, //windows.Exterior
            PROPERTY_1129: measurementsSash, //windows.Measurements Sash (W x H)
            PROPERTY_1127: measurementsFullFrame, //windows.Measurements Full Frame (W x H)
            PROPERTY_1125: manufacturerMarkings, //windows.Manufacturer markings
            PROPERTY_1123: transformSavingData(manufacturerMarkingsPhotos), //windows.Manufacturer markings.Photos
            PROPERTY_1121: transformSavingData(closeUpOfWindowsDamagePhotos),
            PROPERTY_1119: closeUpOfWindowsDamage,
            PROPERTY_1117: withEffectedWindowCircled,
            PROPERTY_1115: transformSavingData(elevationsPhotos),

            NO_PHOTOS_DATA: {
               "Additional Notes": additionalNotes, //windows.Additional Notes
               "Window Coverings": coverings, //windows.Window Coverings
               "Interior": interior, //windows.Interior
               "Style": style, //windows.Style
               "Exterior": exterior, //windows.Exterior
               "Measurements Sash (W x H)": measurementsSash, //windows.Measurements Sash (W x H)
               "Measurements Full Frame (W x H)": measurementsFullFrame, //windows.Measurements Full Frame (W x H)
               "Manufacturer markings": manufacturerMarkings, //windows.Manufacturer markings
               "CloseUp Of Windows Damage": closeUpOfWindowsDamage,
               "With Effected Window Circled": withEffectedWindowCircled
            }

         }

         dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA_WITH_WINDOWS_BLOCK', payload: windowsBlockData } )

console.log('Updationg windowsBlockData with:')
console.log(windowsBlockData)


         // triggering Save in InspectionEdit component
         dispatch({ type: 'SET_WINDOWS_READY_TO_SAVE_FLAG' })

      }
   }, [saveRequest]);

   useEffect(() => {

      //if(!inspectionAllData.hasOwnProperty('PROPERTY_799' )) return;

      //console.log('RELOADING data... in windows.jsx')
      // console.log('current inspectionAllData:')
      // console.log(inspectionAllData)



      setAdditionalNotes(inspectionAllData.PROPERTY_1143)
      setCoverings(inspectionAllData.PROPERTY_1141)
      const coveringsPhotos = async () => {
         if(inspectionAllData.hasOwnProperty('coveringsPhotos' )) {
            if(inspectionAllData.coveringsPhotos.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.coveringsPhotos.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.coveringsPhotos[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'FILE_ID': inspectionAllData.coveringsPhotos[i].FILE_ID })
               }
               setCoveringsPhotos(images)
               dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                  payload: {...inspectionAllData,
                  'coveringsPhotos': images
               }})
            }
         }
      }
      coveringsPhotos()
      setInterior(inspectionAllData.PROPERTY_1137)
      const interiorPhotos = async () => {
         if(inspectionAllData.hasOwnProperty('interiorPhotos' )) {
            if(inspectionAllData.interiorPhotos.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.interiorPhotos.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.interiorPhotos[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'FILE_ID': inspectionAllData.interiorPhotos[i].FILE_ID })
               }
               setInteriorPhotos(images)
               dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                  payload: {...inspectionAllData,
                     'interiorPhotos': images
                  }})
            }
         }
      }
      interiorPhotos()
      setStyle(inspectionAllData.PROPERTY_1133)
      setExterior(inspectionAllData.PROPERTY_1131)
      setMeasurementsSash(inspectionAllData.PROPERTY_1129)
      setMeasurementsFullFrame(inspectionAllData.PROPERTY_1127)
      setManufacturerMarkings(inspectionAllData.PROPERTY_1125)
      const manufacturerMarkingsPhotos = async () => {
         if(inspectionAllData.hasOwnProperty('manufacturerMarkingsPhotos' )) {
            if(inspectionAllData.manufacturerMarkingsPhotos.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.manufacturerMarkingsPhotos.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.manufacturerMarkingsPhotos[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'FILE_ID': inspectionAllData.manufacturerMarkingsPhotos[i].FILE_ID })
               }
               setManufacturerMarkingsPhotos(images)
               dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                  payload: {...inspectionAllData,
                     'manufacturerMarkingsPhotos': images
                  }})
            }
         }
      }
      manufacturerMarkingsPhotos()
      const closeUpOfWindowsDamagePhotos = async () => {
         if(inspectionAllData.hasOwnProperty('closeUpOfWindowsDamagePhotos' )) {
            if(inspectionAllData.closeUpOfWindowsDamagePhotos.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.closeUpOfWindowsDamagePhotos.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.closeUpOfWindowsDamagePhotos[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'FILE_ID': inspectionAllData.closeUpOfWindowsDamagePhotos[i].FILE_ID })
               }
               setCloseUpOfWindowsDamagePhotos(images)
               dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                  payload: {...inspectionAllData,
                     'closeUpOfWindowsDamagePhotos': images
                  }})
            }
         }
      }
      closeUpOfWindowsDamagePhotos()
      setCloseUpOfWindowsDamage(inspectionAllData.PROPERTY_1119)
      setWithEffectedWindowCircled(inspectionAllData.PROPERTY_1117)
      const elevationsPhotos = async () => {
         if(inspectionAllData.hasOwnProperty('elevationsPhotos' )) {
            if(inspectionAllData.elevationsPhotos.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.elevationsPhotos.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.elevationsPhotos[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL, 'FILE_ID': inspectionAllData.elevationsPhotos[i].FILE_ID })
               }
               setElevationsPhotos(images)
               dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                  payload: {...inspectionAllData,
                     'elevationsPhotos': images
                  }})
            }
         }
      }
      elevationsPhotos()


   }, [flagReloadDatafromInspectionAllData]);



   const toBase64 = file => new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
   });


   const [drawerVar, setDrawerVar] = React.useState([])
   const [drawerVarName, setDrawerVarName] = React.useState('')
   const [drawerSetter, setDrawerSetter] = React.useState('')
   const [state, setState] = React.useState({top: false, left: false, bottom: false, right: false,});

   const toggleDrawer = (anchor, open, photosValues) => (event) => {
      if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
         return;
      }

      setDrawerVar(photosValues)
      setDrawerVarName(anchor)

      setState({ ...state, [anchor]: open });
   };

   const list = (drawerVar) => (
      <Box
         sx={{ width: '400px' }}
         role="presentation"
         onClick={toggleDrawer(drawerVarName, false, drawerVar)}
         onKeyDown={toggleDrawer(drawerVarName, false,drawerVar)}
      >

         <Stack direction="row" justifyContent="center" alignItems="center" padding={2}>
            <Button variant="text" onClick={toggleDrawer(drawerVarName, false, drawerVar)}>Back</Button>
         </Stack>


         <ImageList sx={{ width: '400px' }} cols={1} >
            {drawerVar.map((item) => (
               <ImageListItem key={item.DETAIL_URL}>
                  <img
                     src={`${item.DETAIL_URL}`}
                     srcSet={`${item.DETAIL_URL}`}
                     loading="lazy"
                  />

                  <Button
                     disableElevation={true}
                     value={item.FILE_ID}
                     onClick={ (event) => {
                        event.stopPropagation()

                        const updatedPhotos = drawerVar.filter( photo1 => photo1.FILE_ID != event.target.value )


// console.log(`drawerVarName = ${drawerVarName}`)
// console.log(`event.target.value = ${event.target.value}`)
// console.log("drawerVar:")
// console.log(drawerVar)
// console.log(`updated photos:`)
// console.log(updatedPhotos)


                        if(drawerVarName == "coveringsPhotos") {
                           setCoveringsPhotos(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }
                        if(drawerVarName == "interiorPhotos") {
                           setInteriorPhotos(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }
                        if(drawerVarName == "manufacturerMarkingsPhotos") {
                           setManufacturerMarkingsPhotos(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }
                        if(drawerVarName == "closeUpOfWindowsDamagePhotos") {
                           setCloseUpOfWindowsDamagePhotos(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }
                        if(drawerVarName == "closeUpOfWindowsDamagePhotos") {
                           setCloseUpOfWindowsDamagePhotos(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }
                        if(drawerVarName == "elevationsPhotos") {
                           setElevationsPhotos(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }




                        let tmp = inspectionAllData
                        const updatedPhotos2 = tmp[drawerVarName].filter( photo1 => photo1.FILE_ID != event.target.value )
                        tmp[drawerVarName] = updatedPhotos2

// console.log(`tmp[drawerVarName]:`)
// console.log(tmp[drawerVarName])


                        dispatch({ type: 'SET_INSPECTION_ALL_DATA', payload: tmp } )
                     }}>Delete
                  </Button>

                  <br/>
               </ImageListItem>
            ))}
         </ImageList>
      </Box>
   );




   return (


      <Stack
         direction="column"
         justifyContent="left"
         alignItems="left"
         padding={2}
         spacing={1}
         sx={{ display: show === true ? 'flex' : 'none' }}
      >



         <Stack direction="row" justifyContent="left" alignItems="left" padding={0}>

            <React.Fragment key={'right'} ><Drawer anchor={'right'} open={state[drawerVarName]} onClose={toggleDrawer(drawerVarName, false, drawerVar)} sx={{zIndex: 9999}}>{list(drawerVar)}</Drawer></React.Fragment>

            <Typography sx={{my: 'auto'}}>Elevation Photos</Typography>
            <IconButton color="primary" aria-label="upload picture" component="label"
                        onClick={ () => {
                           dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'elevationsPhotos', page: 'windows'} })
                        }}>
               <PhotoCamera />
            </IconButton>
            <IconButton color="primary" aria-label="upload picture" component="label">
               <input
                  type="file" accept="image/*" multiple hidden name="myImage"
                  onChange={(event) => {

                     const files = event.target.files
                     let photos = []

                     //files.forEach((file) => {
                     for (let i = 0; i < files.length; i++) {
                        reduce.toBlob(files[i], { max: 900 }).then(blob => {
                           const wrapSaveImage = async (dataUri) => {
                              //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                              const wrapper = async () => {
                                 const base64 = await toBase64(dataUri);
                                 const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', '').replace('data:image/png;base64,', ''), generateUniqueName: true}
                                 const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                 dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, elevationsPhotos: [...elevationsPhotos, ...photos, savedPhotoData]} })

                                 const updatedPhotos = [...photos, savedPhotoData]
                                 setElevationsPhotos([...elevationsPhotos, ...updatedPhotos])
                                 photos.push(savedPhotoData)
                              }
                              wrapper()
                           }
                           wrapSaveImage(blob)
                        });
                     }


                  }}
               />
               <GalleryIcon />
            </IconButton>

         </Stack>
         <Stack direction="row" alignItems="center" spacing={1}>
            {elevationsPhotos.map((image, index) => (
               <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('elevationsPhotos', true, elevationsPhotos)}/>
            ))}
         </Stack>

         <Stack direction="row" justifyContent="left" alignItems="left" padding={0}>
            <FormGroup>
               <FormControlLabel control={<Checkbox checked={withEffectedWindowCircled} onChange={
                  (event) => {
                     setWithEffectedWindowCircled(event.target.checked);
                  }
               }/>} label="With Affected windows circled" />
            </FormGroup>
         </Stack>

         <Stack direction="row" justifyContent="left" alignItems="left" padding={0}>
            <FormGroup>
               <FormControlLabel control={<Checkbox checked={closeUpOfWindowsDamage} onChange={
                  (event) => {
                     setCloseUpOfWindowsDamage(event.target.checked);
                  }
               }/>} label="Close-up of Windows + Damage" />
            </FormGroup>

            <IconButton color="primary" aria-label="upload picture" component="label"
                        onClick={ () => {
                           dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'closeUpOfWindowsDamagePhotos', page: 'windows'} })
                        }}>
               <PhotoCamera />
            </IconButton>
            <IconButton color="primary" aria-label="upload picture" component="label">
               <input
                  type="file" accept="image/*" multiple hidden name="myImage"
                  onChange={(event) => {

                     const files = event.target.files
                     let photos = []

                     //files.forEach((file) => {
                     for (let i = 0; i < files.length; i++) {
                        reduce.toBlob(files[i], { max: 900 }).then(blob => {
                           const wrapSaveImage = async (dataUri) => {
                              //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                              const wrapper = async () => {
                                 const base64 = await toBase64(dataUri);
                                 const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', '').replace('data:image/png;base64,', ''), generateUniqueName: true}
                                 const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                 dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, closeUpOfWindowsDamagePhotos: [...closeUpOfWindowsDamagePhotos, ...photos, savedPhotoData]} })

                                 const updatedPhotos = [...photos, savedPhotoData]
                                 setCloseUpOfWindowsDamagePhotos([...closeUpOfWindowsDamagePhotos, ...updatedPhotos])
                                 photos.push(savedPhotoData)
                              }
                              wrapper()
                           }
                           wrapSaveImage(blob)
                        });
                     }


                  }}
               />
               <GalleryIcon />
            </IconButton>
         </Stack>
         <Stack direction="row" alignItems="center" spacing={1}>
            {closeUpOfWindowsDamagePhotos.map((image, index) => (
               <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('closeUpOfWindowsDamagePhotos', true, closeUpOfWindowsDamagePhotos)}/>
            ))}
         </Stack>


         <Stack direction="row" justifyContent="left" alignItems="left" padding={0}>
            <FormControl>
               <FormLabel id="demo-row-radio-buttons-group-label">Manufacturer markings:</FormLabel>
               <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={manufacturerMarkings}
                  onChange={(event) => {
                     setManufacturerMarkings(event.target.value)
                  }}
               >
                  <FormControlLabel value="289" control={<Radio />} label="On Handle" />
                  <FormControlLabel value="291" control={<Radio />} label="Lock" />
                  <FormControlLabel value="293" control={<Radio />} label="Space Between Glass" />
                  <FormControlLabel value="295" control={<Radio />} label="Marking on Glass" />
               </RadioGroup>
            </FormControl>

            <IconButton color="primary" aria-label="upload picture" component="label"
                        onClick={ () => {
                           dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'manufacturerMarkingsPhotos', page: 'windows'} })
                        }}>
               <PhotoCamera />
            </IconButton>
            <IconButton color="primary" aria-label="upload picture" component="label">
               <input
                  type="file" accept="image/*" multiple hidden name="myImage"
                  onChange={(event) => {

                     const files = event.target.files
                     let photos = []

                     //files.forEach((file) => {
                     for (let i = 0; i < files.length; i++) {
                        reduce.toBlob(files[i], { max: 900 }).then(blob => {
                           const wrapSaveImage = async (dataUri) => {
                              //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                              const wrapper = async () => {
                                 const base64 = await toBase64(dataUri);
                                 const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', '').replace('data:image/png;base64,', ''), generateUniqueName: true}
                                 const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                 dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, manufacturerMarkingsPhotos: [...manufacturerMarkingsPhotos, ...photos, savedPhotoData]} })

                                 const updatedPhotos = [...photos, savedPhotoData]
                                 setManufacturerMarkingsPhotos([...manufacturerMarkingsPhotos, ...updatedPhotos])
                                 photos.push(savedPhotoData)
                              }
                              wrapper()
                           }
                           wrapSaveImage(blob)
                        });
                     }


                  }}
               />
               <GalleryIcon />
            </IconButton>
         </Stack>
         <Stack direction="row" alignItems="center" spacing={1}>
            {manufacturerMarkingsPhotos.map((image, index) => (
               <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('manufacturerMarkingsPhotos', true, manufacturerMarkingsPhotos)}/>
            ))}
         </Stack>


         <Stack direction="row" justifyContent="left" alignItems="left" padding={0}>
            <TextField fullWidth id="approximate-age-of-structure" label="Measurements Full Frame (W x H)" variant="outlined"
                       value={measurementsFullFrame}
                       onChange={(event) => {
                          setMeasurementsFullFrame(event.target.value)
                       }}
            />
         </Stack>

         <Stack direction="row" justifyContent="left" alignItems="left" padding={0}>
            <TextField fullWidth id="approximate-age-of-structure" label="Measurements Sash (W x H)" variant="outlined"
                       value={measurementsSash}
                       onChange={(event) => {
                          setMeasurementsSash(event.target.value)
                       }}
            />
         </Stack>

         <Stack direction="row" justifyContent="left" alignItems="left" padding={0}>
            <FormControl>
               <FormLabel id="demo-row-radio-buttons-group-label">Exterior:</FormLabel>
               <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={exterior}
                  onChange={(event) => {
                     setExterior(event.target.value)
                  }}
               >
                  <FormControlLabel value="297" control={<Radio />} label="Aluminum" />
                  <FormControlLabel value="299" control={<Radio />} label="Vinyl" />
                  <FormControlLabel value="301" control={<Radio />} label="Wood" />
               </RadioGroup>
            </FormControl>
         </Stack>

         <Stack direction="row" justifyContent="left" alignItems="left" padding={0}>
            <FormControl>
               <FormLabel id="demo-row-radio-buttons-group-label">Style:</FormLabel>
               <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={style}
                  onChange={(event) => {
                     setStyle(event.target.value)
                  }}
               >
                  <FormControlLabel value="303" control={<Radio />} label="Casement" />
                  <FormControlLabel value="305" control={<Radio />} label="Dlt" />
                  <FormControlLabel value="307" control={<Radio />} label="Pict" />
                  <FormControlLabel value="309" control={<Radio />} label="Awning" />
                  <FormControlLabel value="311" control={<Radio />} label="Slider" />
                  <FormControlLabel value="313" control={<Radio />} label="Hooper" />
               </RadioGroup>
            </FormControl>
         </Stack>


         <Stack direction="row" justifyContent="left" alignItems="left" padding={0}>
            <FormControl>
               <FormLabel id="demo-row-radio-buttons-group-label">Interior:</FormLabel>
               <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={interior}
                  onChange={(event) => {
                     setInterior(event.target.value)
                  }}
               >
                  <FormControlLabel value="315" control={<Radio />} label="Casing" />
                  <FormControlLabel value="317" control={<Radio />} label="Stain" />
                  <FormControlLabel value="319" control={<Radio />} label="Paint" />
               </RadioGroup>
            </FormControl>

            <IconButton color="primary" aria-label="upload picture" component="label"
                        onClick={ () => {
                           dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'interiorPhotos', page: 'windows'} })
                        }}>
               <PhotoCamera />
            </IconButton>
            <IconButton color="primary" aria-label="upload picture" component="label">
               <input
                  type="file" accept="image/*" multiple hidden name="myImage"
                  onChange={(event) => {

                     const files = event.target.files
                     let photos = []

                     //files.forEach((file) => {
                     for (let i = 0; i < files.length; i++) {
                        reduce.toBlob(files[i], { max: 900 }).then(blob => {
                           const wrapSaveImage = async (dataUri) => {
                              //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                              const wrapper = async () => {
                                 const base64 = await toBase64(dataUri);
                                 const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', '').replace('data:image/png;base64,', ''), generateUniqueName: true}
                                 const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                 dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, interiorPhotos: [...interiorPhotos, ...photos, savedPhotoData]} })

                                 const updatedPhotos = [...photos, savedPhotoData]
                                 setInteriorPhotos([...interiorPhotos, ...updatedPhotos])
                                 photos.push(savedPhotoData)
                              }
                              wrapper()
                           }
                           wrapSaveImage(blob)
                        });
                     }


                  }}
               />
               <GalleryIcon />
            </IconButton>
         </Stack>
         <Stack direction="row" alignItems="center" spacing={1}>
            {interiorPhotos.map((image, index) => (
               <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('interiorPhotos', true, interiorPhotos)}/>
            ))}
         </Stack>


         <Stack direction="row" justifyContent="left" alignItems="left" padding={0}>
            <FormControl>
               <FormLabel id="demo-row-radio-buttons-group-label">Window Coverings:</FormLabel>
               <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={coverings}
                  onChange={(event) => {
                     setCoverings(event.target.value)
                  }}
               >
                  <FormControlLabel value="321" control={<Radio />} label="Blinds" />
                  <FormControlLabel value="323" control={<Radio />} label="Dropes" />
                  <FormControlLabel value="325" control={<Radio />} label="None" />
               </RadioGroup>
            </FormControl>

            <IconButton color="primary" aria-label="upload picture" component="label"
                        onClick={ () => {
                           dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'coveringsPhotos', page: 'windows'} })
                        }}>
               <PhotoCamera />
            </IconButton>
            <IconButton color="primary" aria-label="upload picture" component="label">
               <input
                  type="file" accept="image/*" multiple hidden name="myImage"
                  onChange={(event) => {

                     const files = event.target.files
                     let photos = []

                     //files.forEach((file) => {
                     for (let i = 0; i < files.length; i++) {
                        reduce.toBlob(files[i], { max: 900 }).then(blob => {
                           const wrapSaveImage = async (dataUri) => {
                              //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                              const wrapper = async () => {
                                 const base64 = await toBase64(dataUri);
                                 const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', '').replace('data:image/png;base64,', ''), generateUniqueName: true}
                                 const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                 dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, coveringsPhotos: [...coveringsPhotos, ...photos, savedPhotoData]} })

                                 const updatedPhotos = [...photos, savedPhotoData]
                                 setCoveringsPhotos([...coveringsPhotos, ...updatedPhotos])
                                 photos.push(savedPhotoData)
                              }
                              wrapper()
                           }
                           wrapSaveImage(blob)
                        });
                     }


                  }}
               />
               <GalleryIcon />
            </IconButton>
         </Stack>
         <Stack direction="row" alignItems="center" spacing={1}>
            {coveringsPhotos.map((image, index) => (
               <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('coveringsPhotos', true, coveringsPhotos)}/>
            ))}
         </Stack>

         <Stack direction="row" justifyContent="left" alignItems="left" padding={0}>
            <TextField fullWidth id="approximate-age-of-structure" label="Additional Notes" variant="outlined"
                       value={additionalNotes}
                       onChange={(event) => {
                          setAdditionalNotes(event.target.value)
                       }}
            />
         </Stack>







      </Stack>


   );
}