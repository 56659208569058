import {
   Accordion,
   AccordionDetails,
   AccordionSummary, Autocomplete,
   Button, Checkbox, Drawer,
   FormControl,
   FormControlLabel,
   FormGroup, FormLabel, ImageList, ImageListItem, InputLabel, MenuItem, Radio, RadioGroup, Select,
   Stack, TextField
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import {PhotoCamera} from "@mui/icons-material";
import Box from "@mui/material/Box";
import AssistantDirectionIcon from "@mui/icons-material/AssistantDirection";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import * as React from "react";
import {useContext, useEffect} from "react";
import ECContext from "../../../context/inspections/ECContext";
import {getImageUrl, savePhotoIntoFolder} from "../../../context/inspections/ECActions";
import GalleryIcon from "@mui/icons-material/CollectionsOutlined";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import {Image} from "mui-image";
import RoofVentilation from "./roofVentilation";


export default function RoofField() {

   const { showCameraFor, flagReloadDatafromInspectionAllData, saveRequest, flagNeedToUpdatePhotosForRoofFieldBlock, flagCleanAllControlsData, inspectionRoofSUBBlockInfo, dispatch, inspectionAllData, access_token_data } = useContext(ECContext)
   const [show, setShow] = React.useState(false);
   const reduce = require('image-blob-reduce')();

   const [typeOfRoof, setTypeOfRoof] = React.useState('');
   const [numberOfStories, setNumberOfStories] = React.useState('');
   const [photosNumberOfStories, setPhotosNumberOfStories] = React.useState([]);
   const [pitchGauge, setPitchGauge] = React.useState([]);
   const [pitchGaugeEdit, setPitchGaugeEdit] = React.useState('');
   const [asphalt3tab, setAsphalt3tab] = React.useState('');
   const [asphaltArch, setAsphaltArch] = React.useState('');
   const [asphaltDesigner, setAsphaltDesigner] = React.useState('');
   const [woodShake, setWoodShake] = React.useState('');
   const [wood, setWood] = React.useState('');
   const [flat, setFlat] = React.useState('');
   const [concreteTile, setConcreteTile] = React.useState('');
   const [clayTile, setClayTile] = React.useState('');
   const [otherLayer, setOtherLayer] = React.useState('');
   const [shingleLayers, setShingleLayers] = React.useState('');
   const [photosShingleLayers, setPhotosShingleLayers] = React.useState([]);
   const [roofOverview, setRoofOverview] = React.useState([]);
   const [roofLeftSlope, setRoofLeftSlope] = React.useState([]);
   const [roofRightSlope, setRoofRightSlope] = React.useState([]);
   const [roofRearSlope, setRoofRearSlope] = React.useState([]);
   const [roofFrontSlope, setRoofFrontSlope] = React.useState([]);
   const [eaveFlashing, setEaveFlashing] = React.useState('');
   const [eaveFlashingGutterApron, setEaveFlashingGutterApron] = React.useState('');
   const [photosEaveFlashing, setPhotosEaveFlashing] = React.useState([]);
   const [starterShingle, setStarterShingle] = React.useState([]);
   const [starterShingleEdit, setStarterShingleEdit] = React.useState([]);
   const [starterShingleEave, setStarterShingleEave] = React.useState('');
   const [starterShingleRake, setStarterShingleRake] = React.useState('');


   const [ridgeCapShingle, setRidgeCapShingle] = React.useState('');
   const [photosRidgeCapShingle, setPhotosRidgeCapShingle] = React.useState([]);
   const [redeckRequired, setRedeckRequired] = React.useState('');
   const [photosRedeckRequired, setPhotosRedeckRequired] = React.useState([]);
   const [iceWaterShield, setIceWaterShield] = React.useState('');
   const [photosIceWaterShield, setPhotosIceWaterShield] = React.useState([]);
   const [inchesOfEaveOverhang, setInchesOfEaveOverhang] = React.useState('');
   const [photosInchesOfEaveOverhang, setPhotosInchesOfEaveOverhang] = React.useState([]);
   const [setsOfValleys, setSetsOfValleys] = React.useState('');
   const [setsOfValleysRadio, setSetsOfValleysRadio] = React.useState('');
   const [layersOfUnderlayment, setLayersOfUnderlayment] = React.useState('');
   const [comments, setComments] = React.useState('');
   const [existingShingle, setExistingShingle] = React.useState('');
   const [fieldRoofPhotos, setFieldRoofPhotos] = React.useState([]);






   useEffect(() => {

      if(inspectionRoofSUBBlockInfo.elevationType == "roofField")
         setShow(inspectionRoofSUBBlockInfo.showBlock)
      else
         setShow(false)


   }, [inspectionRoofSUBBlockInfo]);

   useEffect(() => {
      setTypeOfRoof('');
      setNumberOfStories('');
      setPhotosNumberOfStories([]);
      setPitchGauge([]);
      setAsphalt3tab('');
      setAsphaltArch('');
      setAsphaltDesigner('');
      setWoodShake('');
      setWood('');
      setFlat('');
      setConcreteTile('');
      setClayTile('');
      setOtherLayer('');
      setShingleLayers('');
      setPhotosShingleLayers([]);
      setRoofOverview([]);
      setRoofLeftSlope([]);
      setRoofRightSlope([]);
      setRoofRearSlope([]);
      setRoofFrontSlope([]);
      setEaveFlashing('');
      setPhotosEaveFlashing([]);
      setStarterShingle([]);
      setStarterShingleEdit('');
      setRidgeCapShingle('');
      setPhotosRidgeCapShingle([]);
      setRedeckRequired('');
      setPhotosRedeckRequired([]);
      setIceWaterShield('');
      setPhotosIceWaterShield([]);
      setInchesOfEaveOverhang('');
      setPhotosInchesOfEaveOverhang([]);
      setSetsOfValleys('');
      setSetsOfValleysRadio('');
      setLayersOfUnderlayment('');
      setStarterShingleEave('');
      setStarterShingleRake('');
      setEaveFlashingGutterApron('')
      setComments('')
      setExistingShingle('')
      setPitchGaugeEdit('')
      setFieldRoofPhotos([])

   }, [flagCleanAllControlsData]);

   useEffect(() => {

      if(flagNeedToUpdatePhotosForRoofFieldBlock == true) {
         dispatch({ type: 'DROP_UPDATE_PHOTO_FOR_ROOFFIELD' })

         console.log("We're in RoofField. Updating the photos after we shoot them. inspectionAllData:")
         console.log(inspectionAllData)

         if(inspectionAllData.hasOwnProperty('photosNumberOfStories') && showCameraFor.property == 'photosNumberOfStories') {
            setPhotosNumberOfStories([...inspectionAllData.photosNumberOfStories])
         }
         if(inspectionAllData.hasOwnProperty('pitchGauge') && showCameraFor.property == 'pitchGauge') {
            setPitchGauge([...inspectionAllData.pitchGauge])
         }
         if(inspectionAllData.hasOwnProperty('photosShingleLayers') && showCameraFor.property == 'photosShingleLayers') {
            setPhotosShingleLayers([...inspectionAllData.photosShingleLayers])
         }
         if(inspectionAllData.hasOwnProperty('roofOverview') && showCameraFor.property == 'roofOverview') {
            setRoofOverview([...inspectionAllData.roofOverview])
         }
         if(inspectionAllData.hasOwnProperty('roofLeftSlope') && showCameraFor.property == 'roofLeftSlope') {
            setRoofLeftSlope([...inspectionAllData.roofLeftSlope])
         }
         if(inspectionAllData.hasOwnProperty('roofRightSlope') && showCameraFor.property == 'roofRightSlope') {
            setRoofRightSlope([...inspectionAllData.roofRightSlope])
         }
         if(inspectionAllData.hasOwnProperty('roofRearSlope') && showCameraFor.property == 'roofRearSlope') {
            setRoofRearSlope([...inspectionAllData.roofRearSlope])
         }
         if(inspectionAllData.hasOwnProperty('roofFrontSlope') && showCameraFor.property == 'roofFrontSlope') {
            setRoofFrontSlope([...inspectionAllData.roofFrontSlope])
         }
         if(inspectionAllData.hasOwnProperty('photosEaveFlashing') && showCameraFor.property == 'photosEaveFlashing') {
            setPhotosEaveFlashing([...inspectionAllData.photosEaveFlashing])
         }
         if(inspectionAllData.hasOwnProperty('starterShingle') && showCameraFor.property == 'starterShingle') {
            setStarterShingle([...inspectionAllData.starterShingle])
         }
         if(inspectionAllData.hasOwnProperty('photosRidgeCapShingle') && showCameraFor.property == 'photosRidgeCapShingle') {
            setPhotosRidgeCapShingle([...inspectionAllData.photosRidgeCapShingle])
         }
         if(inspectionAllData.hasOwnProperty('photosRedeckRequired') && showCameraFor.property == 'photosRedeckRequired') {
            setPhotosRedeckRequired([...inspectionAllData.photosRedeckRequired])
         }
         if(inspectionAllData.hasOwnProperty('photosIceWaterShield') && showCameraFor.property == 'photosIceWaterShield') {
            setPhotosIceWaterShield([...inspectionAllData.photosIceWaterShield])
         }
         if(inspectionAllData.hasOwnProperty('photosInchesOfEaveOverhang') && showCameraFor.property == 'photosInchesOfEaveOverhang') {
            setPhotosInchesOfEaveOverhang([...inspectionAllData.photosInchesOfEaveOverhang])
         }
         if(inspectionAllData.hasOwnProperty('fieldRoofPhotos') && showCameraFor.property == 'fieldRoofPhotos') {
            setFieldRoofPhotos([...inspectionAllData.fieldRoofPhotos])
         }

      }

   }, [flagNeedToUpdatePhotosForRoofFieldBlock]);

   useEffect(() => {

               const transformSavingData = (prop) => {

                  return prop.map((fileData, index) => {
                     if(fileData.IS_NEW == true) {
                        prop[index].IS_NEW = false
                        return fileData.FILE_ID
                     }
                  }).filter(x => x !== undefined)
               }

      if(saveRequest === true) {

         let jobsiteBlockData = {

            PROPERTY_877: typeOfRoof,              //roof.RoofField.Type of Roof
            PROPERTY_879: numberOfStories,         //roof.RoofField.Number of Stories
            PROPERTY_881: transformSavingData(photosNumberOfStories),                         //roof.RoofField.Number of Stories.Photo
               // inspectionAllData.hasOwnProperty('photosNumberOfStories') ?
               //    inspectionAllData.photosNumberOfStories.map((fileData, index) => {
               //          return fileData.FILE_ID
               //       }
               //    ) : '',
            PROPERTY_883: transformSavingData(pitchGauge),                         //roof.RoofField.Pitch Gauge.Photo
               // inspectionAllData.hasOwnProperty('pitchGauge') ?
               //    inspectionAllData.pitchGauge.map((fileData, index) => {
               //          return fileData.FILE_ID
               //       }
               //    ) : '',
            PROPERTY_885: asphalt3tab,             //roof.RoofField.ExistingLayers.Asphalt 3-tab
            PROPERTY_887: asphaltArch,             //roof.RoofField.ExistingLayers.Asphalt Arch
            PROPERTY_937: transformSavingData(photosInchesOfEaveOverhang),                         //# of inches of eave overhang.Photos
               // inspectionAllData.hasOwnProperty('photosInchesOfEaveOverhang') ?
               //    inspectionAllData.photosInchesOfEaveOverhang.map((fileData, index) => {
               //          return fileData.FILE_ID
               //       }
               //    ) : '',
            //PROPERTY_945:                        //roof.RoofField.# Layers of Underlayment.Photos
            PROPERTY_943: layersOfUnderlayment,    //roof.RoofField.# Layers of Underlayment
            //PROPERTY_941:                        //roof.RoofField.# of sets of valleys.Photos
            PROPERTY_939: setsOfValleys,           //roof.RoofField.# of sets of valleys
            PROPERTY_935: inchesOfEaveOverhang,    //roof.RoofField.# of inches of eave overhang
            PROPERTY_917: eaveFlashing,            //roof.RoofField.Eave Flashing
            PROPERTY_933: transformSavingData(photosIceWaterShield),                         //roof.RoofField.Ice & Water Shield.Photos
               // inspectionAllData.hasOwnProperty('photosIceWaterShield') ?
               //    inspectionAllData.photosIceWaterShield.map((fileData, index) => {
               //          return fileData.FILE_ID
               //       }
               //    ) : '',
            PROPERTY_931: iceWaterShield,          //roof.RoofField.Ice & Water Shield
            PROPERTY_929: transformSavingData(photosRedeckRequired),                         //roof.RoofField.Re-deck Required.Photos
               // inspectionAllData.hasOwnProperty('photosRedeckRequired') ?
               //    inspectionAllData.photosRedeckRequired.map((fileData, index) => {
               //          return fileData.FILE_ID
               //       }
               //    ) : '',
            PROPERTY_927: redeckRequired,          //roof.RoofField.Re-deck Required
            PROPERTY_925: transformSavingData(photosRidgeCapShingle),                         //roof.RoofField.Ridge Cap Shingle.Photos
               // inspectionAllData.hasOwnProperty('photosRidgeCapShingle') ?
               //    inspectionAllData.photosRidgeCapShingle.map((fileData, index) => {
               //          return fileData.FILE_ID
               //       }
               //    ) : '',
            PROPERTY_923: ridgeCapShingle,         //roof.RoofField.Ridge Cap Shingle
            PROPERTY_921: starterShingle,          //roof.RoofField.Starter Shingle (Eaves and Rakes)
            PROPERTY_1281: starterShingleEdit,  //roof.RoofField.Starter ShingleEdit
            PROPERTY_1283: starterShingleEave,
            PROPERTY_1285: starterShingleRake,

            PROPERTY_919: transformSavingData(photosEaveFlashing),                         //roof.RoofField.Eave Flashing.Photos
               // inspectionAllData.hasOwnProperty('photosEaveFlashing') ?
               //    inspectionAllData.photosEaveFlashing.map((fileData, index) => {
               //          return fileData.FILE_ID
               //       }
               //    ) : '',
            PROPERTY_889: asphaltDesigner,         //roof.RoofField.ExistingLayers.Asphalt Designer
            PROPERTY_915: roofFrontSlope,          //roof.RoofField.Roof Front Slope
            PROPERTY_913: roofRearSlope,           //roof.RoofField.Roof Rear Slope
            PROPERTY_911: roofRearSlope,           //roof.RoofField.Roof Right Slope
            PROPERTY_909: roofLeftSlope,           //roof.RoofField.Roof Left Slope
            PROPERTY_907: transformSavingData(roofOverview),                         //roof.RoofField.Roof Overview (5-10 photos)
               // inspectionAllData.hasOwnProperty('roofOverview') ?
               //    inspectionAllData.roofOverview.map((fileData, index) => {
               //          return fileData.FILE_ID
               //       }
               //    ) : '',
            PROPERTY_895: flat,                    //roof.RoofField.ExistingLayers.Flat
            PROPERTY_893: wood,                    //roof.RoofField.ExistingLayers.Wood
            PROPERTY_891: woodShake,               //roof.RoofField.ExistingLayers.Wood Shake
            PROPERTY_897: concreteTile,            //roof.RoofField.ExistingLayers.Concrete Tile
            PROPERTY_899: clayTile,                //roof.RoofField.ExistingLayers.Clay Tile
            PROPERTY_901: otherLayer,              //roof.RoofField.ExistingLayers.Other
            PROPERTY_903: shingleLayers,           //roof.RoofField.ShingleLayers
            PROPERTY_905: transformSavingData(photosShingleLayers),   //roof.RoofField.ShingleLayers.Photos
               // inspectionAllData.hasOwnProperty('photosShingleLayers') ?
               //    inspectionAllData.photosShingleLayers.map((fileData, index) => {
               //          return fileData.FILE_ID
               //       }
               //    ) : '',
            PROPERTY_1287: eaveFlashingGutterApron,
            PROPERTY_1289: comments,
            PROPERTY_1293: setsOfValleysRadio,
            PROPERTY_1295: existingShingle,
            PROPERTY_1297: pitchGaugeEdit,
            PROPERTY_1037: transformSavingData(fieldRoofPhotos),                              //roof.Photos

            NO_PHOTOS_DATA: {
               "Type of Roof": typeOfRoof,              //roof.RoofField.Type of Roof
               "Number of Stories": numberOfStories,         //roof.RoofField.Number of Stories
               "ExistingLayers.Asphalt 3-tab": asphalt3tab,             //roof.RoofField.ExistingLayers.Asphalt 3-tab
               "ExistingLayers.Asphalt Arch": asphaltArch,             //roof.RoofField.ExistingLayers.Asphalt Arch
               "# Layers of Underlayment": layersOfUnderlayment,    //roof.RoofField.# Layers of Underlayment
               "# of sets of valleys": setsOfValleys,           //roof.RoofField.# of sets of valleys
               "# of inches of eave overhang": inchesOfEaveOverhang,    //roof.RoofField.# of inches of eave overhang
               "Eave Flashing": eaveFlashing,            //roof.RoofField.Eave Flashing
               "Ice & Water Shield": iceWaterShield,          //roof.RoofField.Ice & Water Shield
               "Re-deck Required": redeckRequired,          //roof.RoofField.Re-deck Required
               "Ridge Cap Shingle": ridgeCapShingle,         //roof.RoofField.Ridge Cap Shingle
               "Starter Shingle (Eaves and Rakes)": starterShingle,          //roof.RoofField.Starter Shingle (Eaves and Rakes)
               "Starter ShingleEdit": starterShingleEdit,  //roof.RoofField.Starter ShingleEdit
               "Starter Shingle Eave": starterShingleEave,
               "Starter Shingle Rake": starterShingleRake,

               "ExistingLayers.Asphalt Designer": asphaltDesigner,         //roof.RoofField.ExistingLayers.Asphalt Designer
               "Roof Front Slope": roofFrontSlope,          //roof.RoofField.Roof Front Slope
               "Roof Rear Slope": roofRearSlope,           //roof.RoofField.Roof Rear Slope
               "Roof Right Slope": roofRearSlope,           //roof.RoofField.Roof Right Slope
               "Roof Left Slope": roofLeftSlope,           //roof.RoofField.Roof Left Slope
               "ExistingLayers.Flat": flat,                    //roof.RoofField.ExistingLayers.Flat
               "ExistingLayers.Wood": wood,                    //roof.RoofField.ExistingLayers.Wood
               "ExistingLayers.Wood Shake": woodShake,               //roof.RoofField.ExistingLayers.Wood Shake
               "ExistingLayers.Concrete Tile": concreteTile,            //roof.RoofField.ExistingLayers.Concrete Tile
               "ExistingLayers.Clay Tile": clayTile,                //roof.RoofField.ExistingLayers.Clay Tile
               "RoofField.ExistingLayers.Other": otherLayer,              //roof.RoofField.ExistingLayers.Other
               "RoofField.ShingleLayers": shingleLayers,           //roof.RoofField.ShingleLayers
               "Eave Flashing Gutter Apron": eaveFlashingGutterApron,
               "Comments": comments,
               "Sets Of Valleys": setsOfValleysRadio,
               "Existing Shingle": existingShingle,
               "Pitch Gauge Edit": pitchGaugeEdit,
            }


         }

         dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA_WITH_ROOFFIELD_BLOCK', payload: jobsiteBlockData } )

         // triggering Save in InspectionEdit component
         dispatch({ type: 'SET_ROOFFIELD_READY_TO_SAVE_FLAG' })

      }
   }, [saveRequest]);

   useEffect(() => {

      if(!inspectionAllData.hasOwnProperty('PROPERTY_799' )) return;

      console.log('RELOADING data... in roofField.jsx')

         setTypeOfRoof(inspectionAllData.PROPERTY_877)
         setNumberOfStories(inspectionAllData.PROPERTY_879)
         setAsphalt3tab(inspectionAllData.PROPERTY_885)
         setAsphaltArch(inspectionAllData.PROPERTY_887)
         setLayersOfUnderlayment(inspectionAllData.PROPERTY_943)
         setSetsOfValleys(inspectionAllData.PROPERTY_939)
         setInchesOfEaveOverhang(inspectionAllData.PROPERTY_935)
         setEaveFlashing(inspectionAllData.PROPERTY_917)
         setIceWaterShield(inspectionAllData.PROPERTY_931)
         setRedeckRequired(inspectionAllData.PROPERTY_927)
         setRidgeCapShingle(inspectionAllData.PROPERTY_923)
         setStarterShingle(inspectionAllData.PROPERTY_921)
         setStarterShingleEdit(inspectionAllData.PROPERTY_1281)
         setAsphaltDesigner(inspectionAllData.PROPERTY_889)
         setRoofFrontSlope(inspectionAllData.PROPERTY_915)
         setRoofRearSlope(inspectionAllData.PROPERTY_913)
         setRoofRearSlope(inspectionAllData.PROPERTY_911)
         setRoofLeftSlope(inspectionAllData.PROPERTY_909)
         setFlat(inspectionAllData.PROPERTY_895)
         setWood(inspectionAllData.PROPERTY_893)
         setWoodShake(inspectionAllData.PROPERTY_891)
         setConcreteTile(inspectionAllData.PROPERTY_897)
         setClayTile(inspectionAllData.PROPERTY_899)
         setOtherLayer(inspectionAllData.PROPERTY_901)
         setShingleLayers(inspectionAllData.PROPERTY_903)
         setStarterShingleEave(inspectionAllData.PROPERTY_1283)
         setStarterShingleRake(inspectionAllData.PROPERTY_1285)
         setEaveFlashingGutterApron(inspectionAllData.PROPERTY_1287)
         setComments(inspectionAllData.PROPERTY_1289)
         setSetsOfValleysRadio(inspectionAllData.PROPERTY_1293)
         setExistingShingle((inspectionAllData.PROPERTY_1295))
         setPitchGaugeEdit(inspectionAllData.PROPERTY_1297)


      const photosNumberOfStories = async () => {
            if(inspectionAllData.hasOwnProperty('photosNumberOfStories' ))
               if(inspectionAllData.photosNumberOfStories.length > 0) {
                  let images = []
                  for (let i = 0; i < inspectionAllData.photosNumberOfStories.length; i++) {
                     const imageUrl = await getImageUrl(access_token_data, inspectionAllData.photosNumberOfStories[i].ID)
                     images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
                  }
                  setPhotosNumberOfStories(images)
                  dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                     payload: {...inspectionAllData,
                        'photosNumberOfStories': images
                     }})
               }
         }
         photosNumberOfStories()

         const pitchGauge = async () => {
            if(inspectionAllData.hasOwnProperty('pitchGauge' ))
               if(inspectionAllData.pitchGauge.length > 0) {
                  let images = []
                  for (let i = 0; i < inspectionAllData.pitchGauge.length; i++) {
                     const imageUrl = await getImageUrl(access_token_data, inspectionAllData.pitchGauge[i].ID)
                     images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
                  }
                  setPitchGauge(images)
                  dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                     payload: {...inspectionAllData,
                        'pitchGauge': images
                     }})

               }
         }
         pitchGauge()

         const photosInchesOfEaveOverhang = async () => {
            if(inspectionAllData.hasOwnProperty('photosInchesOfEaveOverhang' ))
               if(inspectionAllData.photosInchesOfEaveOverhang.length > 0) {
                  let images = []
                  for (let i = 0; i < inspectionAllData.photosInchesOfEaveOverhang.length; i++) {
                     const imageUrl = await getImageUrl(access_token_data, inspectionAllData.photosInchesOfEaveOverhang[i].ID)
                     images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
                  }
                  setPhotosInchesOfEaveOverhang(images)
                  dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                     payload: {...inspectionAllData,
                        'photosInchesOfEaveOverhang': images
                     }})

               }
            }
            photosInchesOfEaveOverhang()

            const photosIceWaterShield = async () => {
               if(inspectionAllData.hasOwnProperty('photosIceWaterShield' ))
                  if(inspectionAllData.photosIceWaterShield.length > 0) {
                     let images = []
                     for (let i = 0; i < inspectionAllData.photosIceWaterShield.length; i++) {
                        const imageUrl = await getImageUrl(access_token_data, inspectionAllData.photosIceWaterShield[i].ID)
                        images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
                     }
                     setPhotosIceWaterShield(images)
                     dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                        payload: {...inspectionAllData,
                           'photosIceWaterShield': images
                        }})

                  }
               }
               photosIceWaterShield()

               const photosRedeckRequired = async () => {
                  if(inspectionAllData.hasOwnProperty('photosRedeckRequired' ))
                     if(inspectionAllData.photosRedeckRequired.length > 0) {
                        let images = []
                        for (let i = 0; i < inspectionAllData.photosRedeckRequired.length; i++) {
                           const imageUrl = await getImageUrl(access_token_data, inspectionAllData.photosRedeckRequired[i].ID)
                           images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
                        }
                        setPhotosRedeckRequired(images)
                        dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                           payload: {...inspectionAllData,
                              'photosRedeckRequired': images
                           }})

                     }
               }
               photosRedeckRequired()


                  const photosRidgeCapShingle = async () => {
                     if(inspectionAllData.hasOwnProperty('photosRidgeCapShingle' ))
                        if(inspectionAllData.photosRidgeCapShingle.length > 0) {
                           let images = []
                           for (let i = 0; i < inspectionAllData.photosRidgeCapShingle.length; i++) {
                              const imageUrl = await getImageUrl(access_token_data, inspectionAllData.photosRidgeCapShingle[i].ID)
                              images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
                           }
                           setPhotosRidgeCapShingle(images)
                           dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                              payload: {...inspectionAllData,
                                 'photosRidgeCapShingle': images
                              }})

                        }
                     }
                     photosRidgeCapShingle()


                     const photosEaveFlashing = async () => {
                        if(inspectionAllData.hasOwnProperty('photosEaveFlashing' ))
                           if(inspectionAllData.photosEaveFlashing.length > 0) {
                              let images = []
                              for (let i = 0; i < inspectionAllData.photosEaveFlashing.length; i++) {
                                 const imageUrl = await getImageUrl(access_token_data, inspectionAllData.photosEaveFlashing[i].ID)
                                 images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
                              }
                              setPhotosEaveFlashing(images)
                              dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                                 payload: {...inspectionAllData,
                                    'photosEaveFlashing': images
                                 }})

                           }
                        }
                        photosEaveFlashing()


                        const roofOverview = async () => {
                           if(inspectionAllData.hasOwnProperty('roofOverview' ))
                              if(inspectionAllData.roofOverview.length > 0) {
                                 let images = []
                                 for (let i = 0; i < inspectionAllData.roofOverview.length; i++) {
                                    const imageUrl = await getImageUrl(access_token_data, inspectionAllData.roofOverview[i].ID)
                                    images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
                                 }
                                 setRoofOverview(images)
                                 dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                                    payload: {...inspectionAllData,
                                       'roofOverview': images
                                    }})

                              }
                           }
                           roofOverview()


                           const photosShingleLayers = async () => {
                              if(inspectionAllData.hasOwnProperty('photosShingleLayers' ))
                                 if(inspectionAllData.photosShingleLayers.length > 0) {
                                    let images = []
                                    for (let i = 0; i < inspectionAllData.photosShingleLayers.length; i++) {
                                       const imageUrl = await getImageUrl(access_token_data, inspectionAllData.photosShingleLayers[i].ID)
                                       images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
                                    }
                                    setPhotosShingleLayers(images)
                                    dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                                       payload: {...inspectionAllData,
                                          'photosShingleLayers': images
                                       }})

                                 }
                           }
                           photosShingleLayers()

      const roofLeftSlope = async () => {
         if(inspectionAllData.hasOwnProperty('roofLeftSlope' ))
            if(inspectionAllData.roofLeftSlope.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.roofLeftSlope.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.roofLeftSlope[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               setRoofLeftSlope(images)
               dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                  payload: {...inspectionAllData,
                     'roofLeftSlope': images
                  }})

            }
      }
      roofLeftSlope()

      const roofRightSlope = async () => {
         if(inspectionAllData.hasOwnProperty('roofRightSlope' ))
            if(inspectionAllData.roofRightSlope.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.roofRightSlope.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.roofRightSlope[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               setRoofRightSlope(images)
               dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                  payload: {...inspectionAllData,
                     'roofRightSlope': images
                  }})

            }
      }
      roofRightSlope()

      const roofRearSlope = async () => {
         if(inspectionAllData.hasOwnProperty('roofRearSlope' ))
            if(inspectionAllData.roofRearSlope.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.roofRearSlope.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.roofRearSlope[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               setRoofRearSlope(images)
               dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                  payload: {...inspectionAllData,
                     'roofRearSlope': images
                  }})

            }
      }
      roofRearSlope()

      const roofFrontSlope = async () => {
         if(inspectionAllData.hasOwnProperty('roofFrontSlope' ))
            if(inspectionAllData.roofFrontSlope.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.roofFrontSlope.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.roofFrontSlope[i].ID)
                  images.push({'FILE_ID': inspectionAllData.roofFrontSlope[i].FILE_ID, 'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               setRoofFrontSlope(images)
               dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                  payload: {...inspectionAllData,
                     'roofFrontSlope': images
                  }})

            }
      }
      roofFrontSlope()


      const starterShingle = async () => {
         if(inspectionAllData.hasOwnProperty('starterShingle' ))
            if(inspectionAllData.starterShingle.length > 0) {
               let images = []
               for (let i = 0; i < inspectionAllData.starterShingle.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.starterShingle[i].ID)
                  images.push({'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               setStarterShingle(images)
               dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                  payload: {...inspectionAllData,
                     'starterShingle': images
                  }})

            }
      }
      starterShingle()

      const fieldRoofPhotos = async () => {
         if(inspectionAllData.hasOwnProperty('fieldRoofPhotos' )) {
            if(inspectionAllData.fieldRoofPhotos.length > 0) {

               let images = []
               for (let i = 0; i < inspectionAllData.fieldRoofPhotos.length; i++) {
                  const imageUrl = await getImageUrl(access_token_data, inspectionAllData.fieldRoofPhotos[i].ID)
                  images.push({'FILE_ID': inspectionAllData.fieldRoofPhotos[i].FILE_ID, 'ID': imageUrl.ID, 'DETAIL_URL': imageUrl.DOWNLOAD_URL})
               }
               setFieldRoofPhotos(images)
               dispatch({ type: 'SET_INSPECTION_ALL_DATA',
                  payload: {...inspectionAllData,
                     'fieldRoofPhotos': images
                  }})
            }
         }
      }
      fieldRoofPhotos()


   }, [flagReloadDatafromInspectionAllData]);



   const [drawerVar, setDrawerVar] = React.useState([])
   const [drawerVarName, setDrawerVarName] = React.useState('')
   const [drawerSetter, setDrawerSetter] = React.useState('')
   const [state, setState] = React.useState({top: false, left: false, bottom: false, right: false,});

   const toggleDrawer = (anchor, open, photosValues) => (event) => {
      if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
         return;
      }

      setDrawerVar(photosValues)
      setDrawerVarName(anchor)

      setState({ ...state, [anchor]: open });
   };

   const list = (drawerVar) => (
      <Box
         sx={{ width: '400px' }}
         role="presentation"
         onClick={toggleDrawer(drawerVarName, false, drawerVar)}
         onKeyDown={toggleDrawer(drawerVarName, false,drawerVar)}
      >

         <Stack direction="row" justifyContent="center" alignItems="center" padding={2}>
            <Button variant="text" onClick={toggleDrawer(drawerVarName, false, drawerVar)}>Back</Button>
         </Stack>


         <ImageList sx={{ width: '400px' }} cols={1} >
            {drawerVar.map((item) => (
               <ImageListItem key={item.DETAIL_URL}>
                  <img
                     src={`${item.DETAIL_URL}`}
                     srcSet={`${item.DETAIL_URL}`}
                     loading="lazy"
                  />

                  <Button
                     disableElevation={true}
                     value={item.FILE_ID}
                     onClick={ (event) => {
                        event.stopPropagation()

                        const updatedPhotos = drawerVar.filter( photo1 => photo1.FILE_ID != event.target.value )


                        console.log(`drawerVarName = ${drawerVarName}`)
                        console.log(`event.target.value = ${event.target.value}`)
                        console.log("drawerVar:")
                        console.log(drawerVar)
                        console.log(`updated photos:`)
                        console.log(updatedPhotos)


                        if(drawerVarName == "photosNumberOfStories") {
                           setPhotosNumberOfStories(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }
                        if(drawerVarName == "pitchGauge") {
                           setPitchGauge(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }
                        if(drawerVarName == "photosShingleLayers") {
                           setPhotosShingleLayers(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }
                        if(drawerVarName == "roofOverview") {
                           setRoofOverview(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }
                        if(drawerVarName == "roofLeftSlope") {
                           setRoofLeftSlope(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }
                        if(drawerVarName == "roofRightSlope") {
                           setRoofRightSlope(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }
                        if(drawerVarName == "roofRearSlope") {
                           setRoofRearSlope(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }
                        if(drawerVarName == "roofFrontSlope") {
                           setRoofFrontSlope(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }
                        if(drawerVarName == "photosEaveFlashing") {
                           setPhotosEaveFlashing(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }
                        if(drawerVarName == "starterShingle") {
                           setStarterShingle(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }
                        if(drawerVarName == "photosRidgeCapShingle") {
                           setPhotosRidgeCapShingle(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }
                        if(drawerVarName == "photosRedeckRequired") {
                           setPhotosRedeckRequired(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }
                        if(drawerVarName == "photosIceWaterShield") {
                           setPhotosIceWaterShield(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }
                        if(drawerVarName == "photosInchesOfEaveOverhang") {
                           setPhotosInchesOfEaveOverhang(updatedPhotos)
                           setDrawerVar(updatedPhotos)
                        }


                        let tmp = inspectionAllData
                        const updatedPhotos2 = tmp[drawerVarName].filter( photo1 => photo1.FILE_ID != event.target.value )
                        tmp[drawerVarName] = updatedPhotos2

                        console.log(`tmp[drawerVarName]:`)
                        console.log(tmp[drawerVarName])


                        dispatch({ type: 'SET_INSPECTION_ALL_DATA', payload: tmp } )
                     }}>Delete
                  </Button>

                  <br/>
               </ImageListItem>
            ))}
         </ImageList>
      </Box>
   );





   const toBase64 = file => new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
   });


   return (

      <>
         <Box sx={{mx: 'auto', mt: 2, width: '100%', display: show === true ? 'flex' : 'none', flexDirection: 'column'}}>


            <React.Fragment key={'right'} >
               <Drawer
                  anchor={'right'}
                  open={state[drawerVarName]}
                  //open={state[drawerVarName]}
                  onClose={toggleDrawer(drawerVarName, false, drawerVar)}
                  sx={{zIndex: 9999}}
               >
                  {list(drawerVar)}
               </Drawer>
            </React.Fragment>

            <Stack
               direction="column"
               justifyContent="left"
               alignItems="left"
               padding={2}
            >
               <FormControl>
                  <FormLabel id="type-of-roof-radio-buttons-group-label">Type of Roof</FormLabel>
                  <RadioGroup
                     row
                     aria-labelledby="type-of-roof-radio-buttons-group-label"
                     name="type-of-roof-buttons-group"
                     value={typeOfRoof}
                     onChange={(event) => {
                        setTypeOfRoof(event.target.value)
                     }}
                  >
                     <FormControlLabel value="225" control={<Radio />} label="Gable" />
                     <FormControlLabel value="227" control={<Radio />} label="Hip Roof" />
                     <FormControlLabel value="229" control={<Radio />} label="Flat" />
                     <FormControlLabel value="231" control={<Radio />} label="Combination" />
                     <FormControlLabel value="233" control={<Radio />} label="Other" />

                  </RadioGroup>
               </FormControl>

               <br/>

               <Stack direction="row" justifyContent="left" alignItems="left" padding={0}>
                  <FormControl>
                     <FormLabel id="type-of-roof-radio-buttons-group-label">Number of Stories</FormLabel>
                     <RadioGroup
                        row
                        aria-labelledby="type-of-roof-radio-buttons-group-label"
                        name="type-of-roof-buttons-group"
                        value={numberOfStories}
                        onChange={(event) => {
                           setNumberOfStories(event.target.value)
                        }}
                     >
                        <FormControlLabel value="235" control={<Radio />} label="1" />
                        <FormControlLabel value="237" control={<Radio />} label="2" />
                        <FormControlLabel value="239" control={<Radio />} label="3" />
                        <FormControlLabel value="241" control={<Radio />} label=">3" />
                     </RadioGroup>
                  </FormControl>

                  <Stack direction="row" justifyContent="right" alignItems="right" padding={2} sx={{ml: 'auto', width: '200px'}}>
                     <IconButton color="primary" aria-label="upload picture" component="label"
                                 onClick={ () => {
                                    dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'photosNumberOfStories', page: 'roofField'} })
                                 }}>
                        <PhotoCamera />
                     </IconButton>
                     <IconButton color="primary" aria-label="upload picture" component="label">
                        <input
                           type="file" accept="image/*" multiple hidden name="myImage"
                           onChange={(event) => {

                              console.log('event.target.files:')
                              console.log(event.target.files)

                              const files = event.target.files
                              let photos = []

                              //files.forEach((file) => {
                              for (let i = 0; i < files.length; i++) {
                                 reduce.toBlob(files[i], { max: 900 }).then(blob => {
                                    const wrapSaveImage = async (dataUri) => {
                                       //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                                       const wrapper = async () => {
                                          const base64 = await toBase64(dataUri);
                                          const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', ''), generateUniqueName: true}
                                          const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                          dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, photosNumberOfStories: [...photosNumberOfStories, ...photos, savedPhotoData]} })
                                          //dispatch({ type: 'SET_UPDATE_PHOTO_FOR_JOBSITE' })

                                          const updatedPhotos = [...photos, savedPhotoData]
                                          setPhotosNumberOfStories([...photosNumberOfStories, ...updatedPhotos])
                                          photos.push(savedPhotoData)
                                       }
                                       wrapper()
                                    }
                                    wrapSaveImage(blob)
                                 });
                              }


                           }}
                        />
                        <GalleryIcon />
                     </IconButton>
                  </Stack>

               </Stack>

               <Stack direction="row" alignItems="center" spacing={1}>
                  {photosNumberOfStories.map((image, index) => (
                     <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('photosNumberOfStories', true, photosNumberOfStories)}/>
                  ))}
               </Stack>


               <br/>
               <Stack direction="row" justifyContent="left" alignItems="left" padding={0}>
                  {/*<Typography>Pitch Gauge</Typography>*/}

                  <TextField sx={{width: "150px"}} id="asphalt" label="Pitch Gauge" variant="outlined"
                             value={pitchGaugeEdit}
                             onChange={(event) => {
                                setPitchGaugeEdit(event.target.value)
                             }}
                  />



                  <Stack direction="row" justifyContent="right" alignItems="right" padding={2} sx={{ml: 'auto', width: '200px'}}>
                     <IconButton color="primary" aria-label="upload picture" component="label"
                                 onClick={ () => {
                                    dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'pitchGauge', page: 'roofField'} })
                                 }}>
                        <PhotoCamera />
                     </IconButton>
                     <IconButton color="primary" aria-label="upload picture" component="label">
                        <input
                           type="file" accept="image/*" multiple hidden name="myImage"
                           onChange={(event) => {

                              console.log('event.target.files:')
                              console.log(event.target.files)

                              const files = event.target.files
                              let photos = []

                              //files.forEach((file) => {
                              for (let i = 0; i < files.length; i++) {
                                 reduce.toBlob(files[i], { max: 900 }).then(blob => {
                                    const wrapSaveImage = async (dataUri) => {
                                       //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                                       const wrapper = async () => {
                                          const base64 = await toBase64(dataUri);
                                          const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', ''), generateUniqueName: true}
                                          const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                          dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, pitchGauge: [...pitchGauge, ...photos, savedPhotoData]} })
                                          //dispatch({ type: 'SET_UPDATE_PHOTO_FOR_JOBSITE' })

                                          const updatedPhotos = [...photos, savedPhotoData]
                                          setPitchGauge([...pitchGauge, ...updatedPhotos])
                                          photos.push(savedPhotoData)
                                       }
                                       wrapper()
                                    }
                                    wrapSaveImage(blob)
                                 });
                              }


                           }}
                        />
                        <GalleryIcon />
                     </IconButton>
                  </Stack>

               </Stack>

               <Stack direction="row" alignItems="center" spacing={1}>
                  {pitchGauge.map((image, index) => (
                     <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('pitchGauge', true, pitchGauge)}/>
                  ))}
               </Stack>



               <br/>

{/*               <Typography>Existing Shingle</Typography>*/}

               <FormControl>
                  <FormLabel id="type-of-roof-radio-buttons-group-label">Existing Shingle</FormLabel>
                  <RadioGroup
                     row
                     aria-labelledby="type-of-roof-radio-buttons-group-label"
                     name="type-of-roof-buttons-group"
                     value={existingShingle}
                     onChange={(event) => {
                        setExistingShingle(event.target.value)
                     }}
                  >
                     <FormControlLabel value="333" control={<Radio />} label="Asphalt 3-tab" />
                     <FormControlLabel value="335" control={<Radio />} label="Asphalt Arch" />
                     <FormControlLabel value="337" control={<Radio />} label="Asphalt Designer" />
                     <FormControlLabel value="339" control={<Radio />} label="Wood Shake  " />
                     <FormControlLabel value="341" control={<Radio />} label="Wood" />
                     <FormControlLabel value="343" control={<Radio />} label="Flat" />
                     <FormControlLabel value="345" control={<Radio />} label="Concrete Tile" />
                     <FormControlLabel value="347" control={<Radio />} label="Clay Tile" />
                     <FormControlLabel value="349" control={<Radio />} label="Other" />
                  </RadioGroup>
               </FormControl>

{/*               <Stack direction="row" justifyContent="left" alignItems="left" padding={0}>
                  <Button variant="text" onClick={() => setAsphalt3tab(Number(asphalt3tab)-1) }><RemoveCircleOutlineIcon/></Button>
                  <TextField sx={{width: "150px"}} id="asphalt" label="Asphalt 3-tab" variant="outlined"
                             value={asphalt3tab}
                             onChange={(event) => {
                                setAsphalt3tab(event.target.value)
                             }}
                  />
                  <Button variant="text" onClick={() => setAsphalt3tab(Number(asphalt3tab)+1) }><AddCircleOutlineIcon/></Button>
               </Stack>

               <Stack direction="row" justifyContent="left" alignItems="left" padding={0}>
                  <Button variant="text" onClick={() => setAsphaltArch(Number(asphaltArch)-1) }><RemoveCircleOutlineIcon/></Button>
                  <TextField sx={{width: "150px"}} id="asphalt" label="Asphalt Arch(?)" variant="outlined"
                             value={asphaltArch}
                             onChange={(event) => {
                                setAsphaltArch(event.target.value)
                             }}
                  />
                  <Button variant="text" onClick={() => setAsphaltArch(Number(asphaltArch)+1) }><AddCircleOutlineIcon/></Button>
               </Stack>

               <Stack direction="row" justifyContent="left" alignItems="left" padding={0}>
                  <Button variant="text" onClick={() => setAsphaltDesigner(Number(asphaltDesigner)-1) }><RemoveCircleOutlineIcon/></Button>
                  <TextField sx={{width: "150px"}} id="asphalt" label="Asphalt Designer" variant="outlined"
                             value={asphaltDesigner}
                             onChange={(event) => {
                                setAsphaltDesigner(event.target.value)
                             }}
                  />
                  <Button variant="text" onClick={() => setAsphaltDesigner(Number(asphaltDesigner)+1) }><AddCircleOutlineIcon/></Button>
               </Stack>

               <Stack direction="row" justifyContent="left" alignItems="left" padding={0}>
                  <Button variant="text" onClick={() => setWoodShake(Number(woodShake)-1) }><RemoveCircleOutlineIcon/></Button>
                  <TextField sx={{width: "150px"}} id="asphalt" label="Wood Shake" variant="outlined"
                             value={woodShake}
                             onChange={(event) => {
                                setWoodShake(event.target.value)
                             }}
                  />
                  <Button variant="text" onClick={() => setWoodShake(Number(woodShake)+1) }><AddCircleOutlineIcon/></Button>
               </Stack>

               <Stack direction="row" justifyContent="left" alignItems="left" padding={0}>
                  <Button variant="text" onClick={() => setWood(Number(wood)-1) }><RemoveCircleOutlineIcon/></Button>
                  <TextField sx={{width: "150px"}} id="wood" label="Wood" variant="outlined"
                             value={wood}
                             onChange={(event) => {
                                setWood(event.target.value)
                             }}
                  />
                  <Button variant="text" onClick={() => setWood(Number(wood)+1) }><AddCircleOutlineIcon/></Button>
               </Stack>

               <Stack direction="row" justifyContent="left" alignItems="left" padding={0}>
                  <Button variant="text" onClick={() => setFlat(Number(flat)-1) }><RemoveCircleOutlineIcon/></Button>
                  <TextField sx={{width: "150px"}} id="tpo" label="Flat" variant="outlined"
                             value={flat}
                             onChange={(event) => {
                                setFlat(event.target.value)
                             }}
                  />
                  <Button variant="text" onClick={() => setFlat(Number(flat)+1) }><AddCircleOutlineIcon/></Button>
               </Stack>

               <Stack direction="row" justifyContent="left" alignItems="left" padding={0}>
                  <Button variant="text" onClick={() => setConcreteTile(Number(concreteTile)-1) }><RemoveCircleOutlineIcon/></Button>
                  <TextField sx={{width: "150px"}} id="concrete_tile" label="Concrete Tile" variant="outlined"
                             value={concreteTile}
                             onChange={(event) => {
                                setConcreteTile(event.target.value)
                             }}
                  />
                  <Button variant="text" onClick={() => setConcreteTile(Number(concreteTile)+1) }><AddCircleOutlineIcon/></Button>
               </Stack>

               <Stack direction="row" justifyContent="left" alignItems="left" padding={0}>
                  <Button variant="text" onClick={() => setClayTile(Number(clayTile)-1) }><RemoveCircleOutlineIcon/></Button>
                  <TextField sx={{width: "150px"}} id="clay_tile" label="Clay Tile" variant="outlined"
                             value={clayTile}
                             onChange={(event) => {
                                setClayTile(event.target.value)
                             }}
                  />
                  <Button variant="text" onClick={() => setClayTile(Number(clayTile)+1) }><AddCircleOutlineIcon/></Button>
               </Stack>

               <Stack direction="row" justifyContent="left" alignItems="left" padding={0}>
                  <Button variant="text" onClick={() => setOtherLayer(Number(otherLayer)-1) }><RemoveCircleOutlineIcon/></Button>
                  <TextField sx={{width: "150px"}} id="other" label="Other" variant="outlined"
                             value={otherLayer}
                             onChange={(event) => {
                                setOtherLayer(event.target.value)
                             }}
                  />
                  <Button variant="text" onClick={() => setOtherLayer(Number(otherLayer)+1) }><AddCircleOutlineIcon/></Button>
               </Stack>*/}

               <Stack direction="row" justifyContent="left" alignItems="left" padding={0} marginTop={1} >
                  <FormControl>
                     <FormLabel id="shingle-layers-radio-buttons-group-label">Shingle Layers</FormLabel>
                     <RadioGroup
                        row
                        aria-labelledby="shingle-layers-radio-buttons-group-label"
                        name="shingle-layers-buttons-group"
                        value={shingleLayers}
                        onChange={(event) => {
                           setShingleLayers(event.target.value)
                        }}
                     >
                        <FormControlLabel value="243" control={<Radio />} label="1" />
                        <FormControlLabel value="245" control={<Radio />} label="2" />
                        <FormControlLabel value="247" control={<Radio />} label="3" />
                        <FormControlLabel value="249" control={<Radio />} label=">3" />
                     </RadioGroup>
                  </FormControl>

                  <Stack direction="row" justifyContent="right" alignItems="right" padding={2} sx={{ml: 'auto', width: '200px'}}>
                     <IconButton color="primary" aria-label="upload picture" component="label"
                                 onClick={ () => {
                                    dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'photosShingleLayers', page: 'roofField'} })
                                 }}>
                        <PhotoCamera />
                     </IconButton>
                     <IconButton color="primary" aria-label="upload picture" component="label">
                        <input
                           type="file" accept="image/*" multiple hidden name="myImage"
                           onChange={(event) => {

                              console.log('event.target.files:')
                              console.log(event.target.files)

                              const files = event.target.files
                              let photos = []

                              //files.forEach((file) => {
                              for (let i = 0; i < files.length; i++) {
                                 reduce.toBlob(files[i], { max: 900 }).then(blob => {
                                    const wrapSaveImage = async (dataUri) => {
                                       //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                                       const wrapper = async () => {
                                          const base64 = await toBase64(dataUri);
                                          const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', ''), generateUniqueName: true}
                                          const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                          dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, photosShingleLayers: [...photosShingleLayers, ...photos, savedPhotoData]} })
                                          //dispatch({ type: 'SET_UPDATE_PHOTO_FOR_JOBSITE' })

                                          const updatedPhotos = [...photos, savedPhotoData]
                                          setPhotosShingleLayers([...photosShingleLayers, ...updatedPhotos])
                                          photos.push(savedPhotoData)
                                       }
                                       wrapper()
                                    }
                                    wrapSaveImage(blob)
                                 });
                              }


                           }}
                        />
                        <GalleryIcon />
                     </IconButton>
                  </Stack>

               </Stack>

               <Stack direction="row" alignItems="center" spacing={1}>
                  {photosShingleLayers.map((image, index) => (
                     <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('photosShingleLayers', true, photosShingleLayers)}/>
                  ))}
               </Stack>



               <br/>

               <Stack direction="row" justifyContent="left" alignItems="left" padding={0}>
                  <Typography>Roof Overview (5-10 photos)</Typography>

                  <Stack direction="row" justifyContent="right" alignItems="right" padding={2} sx={{ml: 'auto', width: '200px'}}>
                     <IconButton color="primary" aria-label="upload picture" component="label"
                                 onClick={ () => {
                                    dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'roofOverview', page: 'roofField'} })
                                 }}>
                        <PhotoCamera />
                     </IconButton>
                     <IconButton color="primary" aria-label="upload picture" component="label">
                        <input
                           type="file" accept="image/*" multiple hidden name="myImage"
                           onChange={(event) => {

                              console.log('event.target.files:')
                              console.log(event.target.files)

                              const files = event.target.files
                              let photos = []

                              //files.forEach((file) => {
                              for (let i = 0; i < files.length; i++) {
                                 reduce.toBlob(files[i], { max: 900 }).then(blob => {
                                    const wrapSaveImage = async (dataUri) => {
                                       //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                                       const wrapper = async () => {
                                          const base64 = await toBase64(dataUri);
                                          const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', ''), generateUniqueName: true}
                                          const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                          dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, roofOverview: [...roofOverview, ...photos, savedPhotoData]} })
                                          //dispatch({ type: 'SET_UPDATE_PHOTO_FOR_JOBSITE' })

                                          const updatedPhotos = [...photos, savedPhotoData]
                                          setRoofOverview([...roofOverview, ...updatedPhotos])
                                          photos.push(savedPhotoData)
                                       }
                                       wrapper()
                                    }
                                    wrapSaveImage(blob)
                                 });
                              }


                           }}
                        />
                        <GalleryIcon />
                     </IconButton>
                  </Stack>

               </Stack>

               <Stack direction="row" alignItems="center" spacing={1}>
                  {roofOverview.map((image, index) => (
                     <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('roofOverview', true, roofOverview)}/>
                  ))}
               </Stack>



               <Stack direction="row" justifyContent="left" alignItems="left" padding={0}>
                  <Typography>Roof Left Slope</Typography>

                  <Stack direction="row" justifyContent="right" alignItems="right" padding={2} sx={{ml: 'auto', width: '200px'}}>
                     <IconButton color="primary" aria-label="upload picture" component="label"
                                 onClick={ () => {
                                    dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'roofLeftSlope', page: 'roofField'} })
                                 }}>
                        <PhotoCamera />
                     </IconButton>
                     <IconButton color="primary" aria-label="upload picture" component="label">
                        <input
                           type="file" accept="image/*" multiple hidden name="myImage"
                           onChange={(event) => {

                              console.log('event.target.files:')
                              console.log(event.target.files)

                              const files = event.target.files
                              let photos = []

                              //files.forEach((file) => {
                              for (let i = 0; i < files.length; i++) {
                                 reduce.toBlob(files[i], { max: 900 }).then(blob => {
                                    const wrapSaveImage = async (dataUri) => {
                                       //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                                       const wrapper = async () => {
                                          const base64 = await toBase64(dataUri);
                                          const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', ''), generateUniqueName: true}
                                          const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                          dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, roofLeftSlope: [...roofLeftSlope, ...photos, savedPhotoData]} })
                                          //dispatch({ type: 'SET_UPDATE_PHOTO_FOR_JOBSITE' })

                                          const updatedPhotos = [...photos, savedPhotoData]
                                          setRoofLeftSlope([...roofLeftSlope, ...updatedPhotos])
                                          photos.push(savedPhotoData)
                                       }
                                       wrapper()
                                    }
                                    wrapSaveImage(blob)
                                 });
                              }


                           }}
                        />
                        <GalleryIcon />
                     </IconButton>
                  </Stack>

               </Stack>

               <Stack direction="row" alignItems="center" spacing={1}>
                  {roofLeftSlope.map((image, index) => (
                     <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('roofLeftSlope', true, roofLeftSlope)}/>
                  ))}
               </Stack>



               <Stack direction="row" justifyContent="left" alignItems="left" padding={0}>
                  <Typography>Roof Right Slope</Typography>
                  <Stack direction="row" justifyContent="right" alignItems="right" padding={2} sx={{ml: 'auto', width: '200px'}}>
                     <IconButton color="primary" aria-label="upload picture" component="label"
                                 onClick={ () => {
                                    dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'roofRightSlope', page: 'roofField'} })
                                 }}>
                        <PhotoCamera />
                     </IconButton>
                     <IconButton color="primary" aria-label="upload picture" component="label">
                        <input
                           type="file" accept="image/*" multiple hidden name="myImage"
                           onChange={(event) => {

                              console.log('event.target.files:')
                              console.log(event.target.files)

                              const files = event.target.files
                              let photos = []

                              //files.forEach((file) => {
                              for (let i = 0; i < files.length; i++) {
                                 reduce.toBlob(files[i], { max: 900 }).then(blob => {
                                    const wrapSaveImage = async (dataUri) => {
                                       //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                                       const wrapper = async () => {
                                          const base64 = await toBase64(dataUri);
                                          const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', ''), generateUniqueName: true}
                                          const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                          dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, roofRightSlope: [...roofRightSlope, ...photos, savedPhotoData]} })
                                          //dispatch({ type: 'SET_UPDATE_PHOTO_FOR_JOBSITE' })

                                          const updatedPhotos = [...photos, savedPhotoData]
                                          setRoofRightSlope([...roofRightSlope, ...updatedPhotos])
                                          photos.push(savedPhotoData)
                                       }
                                       wrapper()
                                    }
                                    wrapSaveImage(blob)
                                 });
                              }


                           }}
                        />
                        <GalleryIcon />
                     </IconButton>
                  </Stack>

               </Stack>

               <Stack direction="row" alignItems="center" spacing={1}>
                  {roofRightSlope.map((image, index) => (
                     <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('roofRightSlope', true, roofRightSlope)}/>
                  ))}
               </Stack>



               <Stack direction="row" justifyContent="left" alignItems="left" padding={0}>
                  <Typography>Roof Rear Slope</Typography>

                  <Stack direction="row" justifyContent="right" alignItems="right" padding={2} sx={{ml: 'auto', width: '200px'}}>
                     <IconButton color="primary" aria-label="upload picture" component="label"
                                 onClick={ () => {
                                    dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'roofRearSlope', page: 'roofField'} })
                                 }}>
                        <PhotoCamera />
                     </IconButton>
                     <IconButton color="primary" aria-label="upload picture" component="label">
                        <input
                           type="file" accept="image/*" multiple hidden name="myImage"
                           onChange={(event) => {

                              console.log('event.target.files:')
                              console.log(event.target.files)

                              const files = event.target.files
                              let photos = []

                              //files.forEach((file) => {
                              for (let i = 0; i < files.length; i++) {
                                 reduce.toBlob(files[i], { max: 900 }).then(blob => {
                                    const wrapSaveImage = async (dataUri) => {
                                       //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                                       const wrapper = async () => {
                                          const base64 = await toBase64(dataUri);
                                          const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', ''), generateUniqueName: true}
                                          const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                          dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, roofRearSlope: [...roofRearSlope, ...photos, savedPhotoData]} })
                                          //dispatch({ type: 'SET_UPDATE_PHOTO_FOR_JOBSITE' })

                                          const updatedPhotos = [...photos, savedPhotoData]
                                          setRoofRearSlope([...roofRearSlope, ...updatedPhotos])
                                          photos.push(savedPhotoData)
                                       }
                                       wrapper()
                                    }
                                    wrapSaveImage(blob)
                                 });
                              }


                           }}
                        />
                        <GalleryIcon />
                     </IconButton>
                  </Stack>

               </Stack>

               <Stack direction="row" alignItems="center" spacing={1}>
                  {roofRearSlope.map((image, index) => (
                     <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('roofRearSlope', true, roofRearSlope)}/>
                  ))}
               </Stack>



               <Stack direction="row" justifyContent="left" alignItems="left" padding={0}>
                  <Typography>Roof Front Slope</Typography>

                  <Stack direction="row" justifyContent="right" alignItems="right" padding={2} sx={{ml: 'auto', width: '200px'}}>
                     <IconButton color="primary" aria-label="upload picture" component="label"
                                 onClick={ () => {
                                    dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'roofFrontSlope', page: 'roofField'} })
                                 }}>
                        <PhotoCamera />
                     </IconButton>
                     <IconButton color="primary" aria-label="upload picture" component="label">
                        <input
                           type="file" accept="image/*" multiple hidden name="myImage"
                           onChange={(event) => {

                              console.log('event.target.files:')
                              console.log(event.target.files)

                              const files = event.target.files
                              let photos = []

                              //files.forEach((file) => {
                              for (let i = 0; i < files.length; i++) {
                                 reduce.toBlob(files[i], { max: 900 }).then(blob => {
                                    const wrapSaveImage = async (dataUri) => {
                                       //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                                       const wrapper = async () => {
                                          const base64 = await toBase64(dataUri);
                                          const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', ''), generateUniqueName: true}
                                          const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                          dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, roofFrontSlope: [...roofFrontSlope, ...photos, savedPhotoData]} })
                                          //dispatch({ type: 'SET_UPDATE_PHOTO_FOR_JOBSITE' })

                                          const updatedPhotos = [...photos, savedPhotoData]
                                          setRoofFrontSlope([...roofFrontSlope, ...updatedPhotos])
                                          photos.push(savedPhotoData)
                                       }
                                       wrapper()
                                    }
                                    wrapSaveImage(blob)
                                 });
                              }


                           }}
                        />
                        <GalleryIcon />
                     </IconButton>
                  </Stack>

               </Stack>

               <Stack direction="row" alignItems="center" spacing={1}>
                  {roofFrontSlope.map((image, index) => (
                     <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('roofFrontSlope', true, roofFrontSlope)}/>
                  ))}
               </Stack>





               <Stack direction="row" justifyContent="left" alignItems="left" padding={1} >

                  <FormControl>
                     <FormLabel id="eave-flashing-radio-buttons-group-label">Eave Flashing</FormLabel>
                     <RadioGroup
                        row
                        aria-labelledby="eave-flashing-radio-buttons-group-label"
                        name="eave-flashing-buttons-group"
                        value={eaveFlashing}
                        onChange={(event) => {
                           setEaveFlashing(event.target.value)
                        }}
                     >
                        <FormControlLabel value="251" control={<Radio />} label="Drip Edge" />
{/*                        <FormControlLabel value="253" control={<Radio />} label="Gutter Apron" />*/}
                        <FormControlLabel value="255" control={<Radio />} label="Siders Edge" />
                     </RadioGroup>
                  </FormControl>
                  <FormControlLabel
                     control={
                        <Checkbox checked={eaveFlashingGutterApron} name="Gutter Apron"
                                  onChange={(event) => {
                                     setEaveFlashingGutterApron(event.target.checked)
                                  }}
                        />
                     }
                     label="Gutter Apron"
                  />


                  <Stack direction="row" justifyContent="right" alignItems="right" padding={2} sx={{ml: 'auto', width: '200px'}}>
                     <IconButton color="primary" aria-label="upload picture" component="label"
                                 onClick={ () => {
                                    dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'photosEaveFlashing', page: 'roofField'} })
                                 }}>
                        <PhotoCamera />
                     </IconButton>
                     <IconButton color="primary" aria-label="upload picture" component="label">
                        <input
                           type="file" accept="image/*" multiple hidden name="myImage"
                           onChange={(event) => {

                              console.log('event.target.files:')
                              console.log(event.target.files)

                              const files = event.target.files
                              let photos = []

                              //files.forEach((file) => {
                              for (let i = 0; i < files.length; i++) {
                                 reduce.toBlob(files[i], { max: 900 }).then(blob => {
                                    const wrapSaveImage = async (dataUri) => {
                                       //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                                       const wrapper = async () => {
                                          const base64 = await toBase64(dataUri);
                                          const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', ''), generateUniqueName: true}
                                          const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                          dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, photosEaveFlashing: [...photosEaveFlashing, ...photos, savedPhotoData]} })
                                          //dispatch({ type: 'SET_UPDATE_PHOTO_FOR_JOBSITE' })

                                          const updatedPhotos = [...photos, savedPhotoData]
                                          setPhotosEaveFlashing([...photosEaveFlashing, ...updatedPhotos])
                                          photos.push(savedPhotoData)
                                       }
                                       wrapper()
                                    }
                                    wrapSaveImage(blob)
                                 });
                              }


                           }}
                        />
                        <GalleryIcon />
                     </IconButton>
                  </Stack>

               </Stack>

               <Stack direction="row" alignItems="center" spacing={1}>
                  {photosEaveFlashing.map((image, index) => (
                     <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('photosEaveFlashing', true, photosEaveFlashing)}/>
                  ))}
               </Stack>


               <Stack direction="row" justifyContent="left" alignItems="center" padding={1}>
                  <Typography>Starter shingle(Eaves and Rakes)</Typography>
               </Stack>
               <Stack direction="row" justifyContent="left" alignItems="flex-start" padding={1}>
{/*
                  <TextField sx={{width: "120px"}} id="asphalt" label="Starter shingle" variant="outlined"
                             value={starterShingleEdit}
                             onChange={(event) => {
                                setStarterShingleEdit(event.target.value)
                             }}
                  />
*/}
                  <FormControlLabel
                     control={
                        <Checkbox checked={starterShingleEave} name="Eave"
                                  onChange={(event) => {
                                     setStarterShingleEave(event.target.checked)
                                  }}
                        />
                     }
                     label="Eave"
                  />
                  <FormControlLabel
                     control={
                        <Checkbox checked={starterShingleRake} name="Rake"
                                  onChange={(event) => {
                                     setStarterShingleRake(event.target.checked)
                                  }}
                        />
                     }
                     label="Rake"
                  />



                  <Stack direction="row" justifyContent="right" alignItems="right" padding={2} sx={{ml: 'auto', width: '200px'}}>
                     <IconButton color="primary" aria-label="upload picture" component="label"
                                 onClick={ () => {
                                    dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'starterShingle', page: 'roofField'} })
                                 }}>
                        <PhotoCamera />
                     </IconButton>
                     <IconButton color="primary" aria-label="upload picture" component="label">
                        <input
                           type="file" accept="image/*" multiple hidden name="myImage"
                           onChange={(event) => {

                              console.log('event.target.files:')
                              console.log(event.target.files)

                              const files = event.target.files
                              let photos = []

                              //files.forEach((file) => {
                              for (let i = 0; i < files.length; i++) {
                                 reduce.toBlob(files[i], { max: 900 }).then(blob => {
                                    const wrapSaveImage = async (dataUri) => {
                                       //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                                       const wrapper = async () => {
                                          const base64 = await toBase64(dataUri);
                                          const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', ''), generateUniqueName: true}
                                          const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                          dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, starterShingle: [...starterShingle, ...photos, savedPhotoData]} })
                                          //dispatch({ type: 'SET_UPDATE_PHOTO_FOR_JOBSITE' })

                                          const updatedPhotos = [...photos, savedPhotoData]
                                          setStarterShingle([...starterShingle, ...updatedPhotos])
                                          photos.push(savedPhotoData)
                                       }
                                       wrapper()
                                    }
                                    wrapSaveImage(blob)
                                 });
                              }


                           }}
                        />
                        <GalleryIcon />
                     </IconButton>
                  </Stack>

               </Stack>

               <Stack direction="row" alignItems="center" spacing={1}>
                  {starterShingle.map((image, index) => (
                     <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('starterShingle', true, starterShingle)}/>
                  ))}
               </Stack>



               <Stack direction="row" justifyContent="left" alignItems="left" padding={0} >
                  <FormControl>
                     <FormLabel id="ridge-cap-shingle-radio-buttons-group-label">Ridge Cap Shingle</FormLabel>
                     <RadioGroup
                        row
                        aria-labelledby="ridge-cap-shingle-radio-buttons-group-label"
                        name="ridge-cap-shingle-required-buttons-group"
                        value={ridgeCapShingle}
                        onChange={(event) => {
                           setRidgeCapShingle(event.target.value)
                        }}

                     >
                        <FormControlLabel value="257" control={<Radio />} label="High Profile" />
                        <FormControlLabel value="259" control={<Radio />} label="Standard Profile" />
                     </RadioGroup>
                  </FormControl>

                  <Stack direction="row" justifyContent="right" alignItems="right" padding={2} sx={{ml: 'auto', width: '200px'}}>
                     <IconButton color="primary" aria-label="upload picture" component="label"
                                 onClick={ () => {
                                    dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'photosRidgeCapShingle', page: 'roofField'} })
                                 }}>
                        <PhotoCamera />
                     </IconButton>
                     <IconButton color="primary" aria-label="upload picture" component="label">
                        <input
                           type="file" accept="image/*" multiple hidden name="myImage"
                           onChange={(event) => {

                              console.log('event.target.files:')
                              console.log(event.target.files)

                              const files = event.target.files
                              let photos = []

                              //files.forEach((file) => {
                              for (let i = 0; i < files.length; i++) {
                                 reduce.toBlob(files[i], { max: 900 }).then(blob => {
                                    const wrapSaveImage = async (dataUri) => {
                                       //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                                       const wrapper = async () => {
                                          const base64 = await toBase64(dataUri);
                                          const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', ''), generateUniqueName: true}
                                          const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                          dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, photosRidgeCapShingle: [...photosRidgeCapShingle, ...photos, savedPhotoData]} })
                                          //dispatch({ type: 'SET_UPDATE_PHOTO_FOR_JOBSITE' })

                                          const updatedPhotos = [...photos, savedPhotoData]
                                          setPhotosRidgeCapShingle([...photosRidgeCapShingle, ...updatedPhotos])
                                          photos.push(savedPhotoData)
                                       }
                                       wrapper()
                                    }
                                    wrapSaveImage(blob)
                                 });
                              }


                           }}
                        />
                        <GalleryIcon />
                     </IconButton>
                  </Stack>

               </Stack>

               <Stack direction="row" alignItems="center" spacing={1}>
                  {photosRidgeCapShingle.map((image, index) => (
                     <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('photosRidgeCapShingle', true, photosRidgeCapShingle)}/>
                  ))}
               </Stack>



               <Stack direction="row" justifyContent="left" alignItems="left" padding={0} >
                  <FormControl>
                     <FormLabel id="redneck-required-radio-buttons-group-label">Re-deck Required (Tape measure gaps from Attic)</FormLabel>
                     <RadioGroup
                        row
                        aria-labelledby="redneck-required-radio-buttons-group-label"
                        name="redneck-required-buttons-group"
                        value={redeckRequired}
                        onChange={(event) => {
                           setRedeckRequired(event.target.value)
                        }}
                     >
                        <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                        <FormControlLabel value="no" control={<Radio />} label="No" />
                     </RadioGroup>
                  </FormControl>

                  <Stack direction="row" justifyContent="right" alignItems="right" padding={2} sx={{ml: 'auto', width: '200px'}}>
                     <IconButton color="primary" aria-label="upload picture" component="label"
                                 onClick={ () => {
                                    dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'photosRedeckRequired', page: 'roofField'} })
                                 }}>
                        <PhotoCamera />
                     </IconButton>
                     <IconButton color="primary" aria-label="upload picture" component="label">
                        <input
                           type="file" accept="image/*" multiple hidden name="myImage"
                           onChange={(event) => {

                              console.log('event.target.files:')
                              console.log(event.target.files)

                              const files = event.target.files
                              let photos = []

                              //files.forEach((file) => {
                              for (let i = 0; i < files.length; i++) {
                                 reduce.toBlob(files[i], { max: 900 }).then(blob => {
                                    const wrapSaveImage = async (dataUri) => {
                                       //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                                       const wrapper = async () => {
                                          const base64 = await toBase64(dataUri);
                                          const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', ''), generateUniqueName: true}
                                          const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                          dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, photosRedeckRequired: [...photosRedeckRequired, ...photos, savedPhotoData]} })
                                          //dispatch({ type: 'SET_UPDATE_PHOTO_FOR_JOBSITE' })

                                          const updatedPhotos = [...photos, savedPhotoData]
                                          setPhotosRedeckRequired([...photosRedeckRequired, ...updatedPhotos])
                                          photos.push(savedPhotoData)
                                       }
                                       wrapper()
                                    }
                                    wrapSaveImage(blob)
                                 });
                              }


                           }}
                        />
                        <GalleryIcon />
                     </IconButton>
                  </Stack>

               </Stack>

               <Stack direction="row" alignItems="center" spacing={1}>
                  {photosRedeckRequired.map((image, index) => (
                     <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('photosRedeckRequired', true, photosRedeckRequired)}/>
                  ))}
               </Stack>



               <Stack direction="row" justifyContent="left" alignItems="left" padding={0} >
                  <FormControl>
                     <FormLabel id="icewater-required-radio-buttons-group-label">Ice & Water Shield</FormLabel>
                     <RadioGroup
                        row
                        aria-labelledby="icewater-required-radio-buttons-group-label"
                        name="icewater-required-buttons-group"
                        value={iceWaterShield}
                        onChange={(event) => {
                           setIceWaterShield(event.target.value)
                        }}
                     >
                        <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                        <FormControlLabel value="no" control={<Radio />} label="No" />
                     </RadioGroup>
                  </FormControl>

                  <Stack direction="row" justifyContent="right" alignItems="right" padding={2} sx={{ml: 'auto', width: '200px'}}>
                     <IconButton color="primary" aria-label="upload picture" component="label"
                                 onClick={ () => {
                                    dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'photosIceWaterShield', page: 'roofField'} })
                                 }}>
                        <PhotoCamera />
                     </IconButton>
                     <IconButton color="primary" aria-label="upload picture" component="label">
                        <input
                           type="file" accept="image/*" multiple hidden name="myImage"
                           onChange={(event) => {

                              console.log('event.target.files:')
                              console.log(event.target.files)

                              const files = event.target.files
                              let photos = []

                              //files.forEach((file) => {
                              for (let i = 0; i < files.length; i++) {
                                 reduce.toBlob(files[i], { max: 900 }).then(blob => {
                                    const wrapSaveImage = async (dataUri) => {
                                       //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                                       const wrapper = async () => {
                                          const base64 = await toBase64(dataUri);
                                          const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', ''), generateUniqueName: true}
                                          const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                          dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, photosIceWaterShield: [...photosIceWaterShield, ...photos, savedPhotoData]} })
                                          //dispatch({ type: 'SET_UPDATE_PHOTO_FOR_JOBSITE' })

                                          const updatedPhotos = [...photos, savedPhotoData]
                                          setPhotosIceWaterShield([...photosIceWaterShield, ...updatedPhotos])
                                          photos.push(savedPhotoData)
                                       }
                                       wrapper()
                                    }
                                    wrapSaveImage(blob)
                                 });
                              }


                           }}
                        />
                        <GalleryIcon />
                     </IconButton>
                  </Stack>

               </Stack>

               <Stack direction="row" alignItems="center" spacing={1}>
                  {photosIceWaterShield.map((image, index) => (
                     <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('photosIceWaterShield', true, photosIceWaterShield)}/>
                  ))}
               </Stack>



               <Stack direction="row" justifyContent="left" alignItems="left" padding={0}>
                  {/*<Button variant="text" onClick={() => setInchesOfEaveOverhang(Number(inchesOfEaveOverhang)-1) }><RemoveCircleOutlineIcon/></Button>*/}
                  <TextField sx={{width: "250px"}} id="other" label="Overhang LF" variant="outlined"
                             value={inchesOfEaveOverhang}
                             onChange={(event) => {
                                setInchesOfEaveOverhang(event.target.value)
                             }}
                  />
                  {/*<Button variant="text" onClick={() => setInchesOfEaveOverhang(Number(inchesOfEaveOverhang)+1) }><AddCircleOutlineIcon/></Button>*/}

                  <Stack direction="row" justifyContent="right" alignItems="right" padding={2} sx={{ml: 'auto', width: '200px'}}>
                     <IconButton color="primary" aria-label="upload picture" component="label"
                                 onClick={ () => {
                                    dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'photosInchesOfEaveOverhang', page: 'roofField'} })
                                 }}>
                        <PhotoCamera />
                     </IconButton>
                     <IconButton color="primary" aria-label="upload picture" component="label">
                        <input
                           type="file" accept="image/*" multiple hidden name="myImage"
                           onChange={(event) => {

                              console.log('event.target.files:')
                              console.log(event.target.files)

                              const files = event.target.files
                              let photos = []

                              //files.forEach((file) => {
                              for (let i = 0; i < files.length; i++) {
                                 reduce.toBlob(files[i], { max: 900 }).then(blob => {
                                    const wrapSaveImage = async (dataUri) => {
                                       //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                                       const wrapper = async () => {
                                          const base64 = await toBase64(dataUri);
                                          const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', ''), generateUniqueName: true}
                                          const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                          dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, photosInchesOfEaveOverhang: [...photosInchesOfEaveOverhang, ...photos, savedPhotoData]} })
                                          //dispatch({ type: 'SET_UPDATE_PHOTO_FOR_JOBSITE' })

                                          const updatedPhotos = [...photos, savedPhotoData]
                                          setPhotosInchesOfEaveOverhang([...photosInchesOfEaveOverhang, ...updatedPhotos])
                                          photos.push(savedPhotoData)
                                       }
                                       wrapper()
                                    }
                                    wrapSaveImage(blob)
                                 });
                              }


                           }}
                        />
                        <GalleryIcon />
                     </IconButton>
                  </Stack>

               </Stack>

               <Stack direction="row" alignItems="center" spacing={1}>
                  {photosInchesOfEaveOverhang.map((image, index) => (
                     <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('photosInchesOfEaveOverhang', true, photosInchesOfEaveOverhang)}/>
                  ))}
               </Stack>


               <FormControl>
                  <FormLabel id="valleys-radio-buttons-group-label">Valleys</FormLabel>
                  <RadioGroup
                     row
                     aria-labelledby="valleys-radio-buttons-group-label"
                     name="valleys-required-buttons-group"
                     value={setsOfValleysRadio}
                     onChange={(event) => {
                        setSetsOfValleysRadio(event.target.value)
                     }}
                  >
                     <FormControlLabel value="329" control={<Radio />} label="Open" />
                     <FormControlLabel value="331" control={<Radio />} label="Close" />
                  </RadioGroup>
               </FormControl>

{/*
               <Stack direction="row" justifyContent="left" alignItems="left" padding={0}>
                  <Button variant="text" onClick={() => setSetsOfValleys(Number(setsOfValleys)-1) }><RemoveCircleOutlineIcon/></Button>
                  <TextField sx={{width: "250px"}} id="other" label="# of sets of valleys" variant="outlined"
                             value={setsOfValleys}
                             onChange={(event) => {
                                setSetsOfValleys(event.target.value)
                             }}
                  />
                  <Button variant="text" onClick={() => setSetsOfValleys(Number(setsOfValleys)+1) }><AddCircleOutlineIcon/></Button>
               </Stack>
*/}




{/*
               <Stack direction="row" justifyContent="left" alignItems="left" padding={0}>
                  <Button variant="text" onClick={() => setLayersOfUnderlayment(Number(layersOfUnderlayment)-1) }><RemoveCircleOutlineIcon/></Button>
                  <TextField sx={{width: "250px"}} id="other" label="# Layers of Underlayment" variant="outlined"
                             value={layersOfUnderlayment}
                             onChange={(event) => {
                                setLayersOfUnderlayment(event.target.value)
                             }}
                  />
                  <Button variant="text" onClick={() => setLayersOfUnderlayment(Number(layersOfUnderlayment)+1) }><AddCircleOutlineIcon/></Button>
               </Stack>
*/}


            </Stack>


            <RoofVentilation/>


            <Stack direction="row" justifyContent="left" alignItems="left" padding={0}>
               <Typography sx={{my: 'auto'}}>Roof Photos</Typography>

               <Stack direction="row" justifyContent="right" alignItems="right" padding={2} sx={{ml: 'auto', width: '200px'}}>
                  <IconButton color="primary" aria-label="upload picture" component="label"
                              onClick={ () => {
                                 dispatch({ type: 'SET_SHOW_CAMERA', payload: {property: 'fieldRoofPhotos', page: 'roofField'} })
                              }}>
                     <PhotoCamera />
                  </IconButton>
                  <IconButton color="primary" aria-label="upload picture" component="label">
                     <input
                        type="file" accept="image/*" multiple hidden name="myImage"
                        onChange={(event) => {

                           console.log('event.target.files:')
                           console.log(event.target.files)

                           const files = event.target.files
                           let photos = []

                           //files.forEach((file) => {
                           for (let i = 0; i < files.length; i++) {
                              reduce.toBlob(files[i], { max: 900 }).then(blob => {
                                 const wrapSaveImage = async (dataUri) => {
                                    //setPhotosDoesTheDrivewayHaveExistingCracks([spinner])
                                    const wrapper = async () => {
                                       const base64 = await toBase64(dataUri);
                                       const params = {id: inspectionAllData.photoFolderId, data: { NAME: "inspectionPhoto.jpg"}, fileContent: base64.replace('data:image/jpeg;base64,', ''), generateUniqueName: true}
                                       const savedPhotoData = await savePhotoIntoFolder(access_token_data, params)

                                       dispatch({ type: 'UPDATE_INSPECTION_ALL_DATA', payload: {...inspectionAllData, fieldRoofPhotos: [...fieldRoofPhotos, ...photos, savedPhotoData]} })
                                       //dispatch({ type: 'SET_UPDATE_PHOTO_FOR_JOBSITE' })

                                       const updatedPhotos = [...photos, savedPhotoData]
                                       setFieldRoofPhotos([...fieldRoofPhotos, ...updatedPhotos])
                                       photos.push(savedPhotoData)
                                    }
                                    wrapper()
                                 }
                                 wrapSaveImage(blob)
                              });
                           }


                        }}
                     />
                     <GalleryIcon />
                  </IconButton>
               </Stack>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={1}>
               {fieldRoofPhotos.map((image, index) => (
                  <Image src={image.DETAIL_URL} showLoading width={30} style={{ borderRadius: 6 }} onClick={toggleDrawer('fieldRoofPhotos', true, fieldRoofPhotos)}/>
               ))}
            </Stack>



            <Stack
               direction="column"
               justifyContent="left"
               alignItems="left"
               padding={2}
            >

               <Stack direction="row" justifyContent="left" alignItems="left" padding={0} >
                  <TextField sx={{width: "100%"}} id="comments" label="Comments" variant="outlined"
                             value={comments}
                             onChange={(event) => {
                                setComments(event.target.value)
                             }}
                             multiline
                             rows={3}
                  />
               </Stack>
            </Stack>





         </Box>
      </>

   );
}